<template>
  <div id="tabbar">
    <router-view />
    <div class="outBox">
      <div class="appInstallBox" @click="goupdata">
        <div class="text">前往淘百街APP，还有更多惊喜在等着您。</div>
        <div class="btn">去下载</div>
      </div>
      <van-tabbar
        replace
        v-model="chooseTab"
        active-color="#3CCFC7"
        inactive-color="#1B1B1B"
        class="tabbar"
        router
      >
        <van-tabbar-item
          v-for="(item, index) in tabbarList"
          :key="index"
          :badge="item.badge > 0 ? item.badge : ''"
          :to="item.src"
        >
          <span class="text">{{ item.text }}</span>
          <template #icon="props">
            <span
              class="iconfont"
              :class="props.active ? item.chooseIcon : item.icon"
            ></span>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["active"],

  data() {
    return {
      tabbarList: [
        {
          icon: "icon-Frame-11",
          text: "首页",
          chooseIcon: "icon-Frame1",
          src: "/house_home",
          badge: 0,
        },
        {
          icon: "icon-Frame10",
          text: "发布",
          chooseIcon: "icon-Frame9",
          src: "/house_publish",
          badge: 0,
        },
        {
          icon: "icon-Frame7",
          text: "我的",
          chooseIcon: "icon-Frame6",
          src: "/house_mine",
          badge: 0,
        },
      ],
      chooseTab: this.active,
    };
  },
  watch: {},
  computed: {},
  methods: {
    switchTab(index) {
      if (this.chooseTab == index) return;
      if (this.tabbarList[index].src) {
        this.chooseTab = index;
      }
    },
    goupdata() {
      console.log("下载");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.van-tabbar{
  height: 62px;
}
.van-tabbar--fixed {
  position: relative;
}
.tabbar {
  // position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  .innerBox {
    display: flex;
    .home {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text {
        font-size: 1em;
      }
    }

    .chooseTab {
      .iconfont {
        color: red;
      }

      .text {
        color: red;
      }
    }
  }
  .text {
    font-size: 10px;
  }
  .iconfont {
    font-size: 1.2rem;
  }
}
.outBox {
  max-width: 750px;

  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  .appInstallBox {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    transform: translate(-0%, -100%);
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
    .text {
      color: #ffffff;
      line-height: 20px;
      font-size: 13px;
      font-weight: 400;
      font-style: normal;
      margin-left: 16px;
      text-align: left;
    }
    .btn {
      line-height: 20px;
      font-size: 13px;
      color: #ffffff;
      border-radius: 35px 35px 35px 35px;
      background: #FC223B;
      margin-right: 16px;
      padding: 2px 11px;
      white-space: nowrap;
    }
  }
}
</style>