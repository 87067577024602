import { render, staticRenderFns } from "./service_order.vue?vue&type=template&id=d42643f8&scoped=true"
import script from "./service_order.vue?vue&type=script&lang=js"
export * from "./service_order.vue?vue&type=script&lang=js"
import style0 from "./service_order.vue?vue&type=style&index=0&id=d42643f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d42643f8",
  null
  
)

export default component.exports