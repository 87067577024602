<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";

export default {
  name: "setDetails",
  components: {
    navigationBar,
  },
  data() {
    return {
      title: '',
      type:1,
      nickname:'',
      oldPass:'',
      newPass:'',
    }
  },
  mounted() {
    this.title = this.$route.params.title
    this.type = this.$route.params.type
    if(this.$route.params.nickName){
      this.nickname = this.$route.params.nickName
    }
  },
  methods: {
    submit() {
      console.log('提交')
      console.log(this.oldPass)
      console.log(this.newPass)
    },
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="title" class="navigation"></navigationBar>
      <div v-if="type==1">
        <div class="card">
          <div class="line">
            <div class="left">旧密码</div>
            <div class="right">
              <input type="text" class="input" placeholder="请输入旧密码" v-model="oldPass">
            </div>
          </div>
        </div>
        <div class="card">

          <div class="line">
            <div class="left">新密码</div>
            <div class="right">
              <input type="text" class="input" placeholder="请输入新密码"  v-model="newPass">
            </div>
          </div>
        </div>
      </div>
      <div v-if="type==2" class="name">
        <div class="card">
          <div class="line">
            <div class="right">
              <input type="text"  v-model="nickname" class="input" placeholder="请输入昵称">
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBtn">
        <div class="toggle btn" @click="submit">确认修改</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
container {
  background: #F8F8F8;
}

.navigation {
  background: white !important;
}

.area {
  background: #F8F8F8;
  min-height: 100vh;
}

.card {
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin: 10px 16px;
  padding: 0 10px;

  .line {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    border-bottom: 1px solid #F7F7F7;
    padding: 16px 0;
    font-family: PingFang SC, PingFang SC;

    .left {
      font-weight: 400;
      font-size: 15px;
      color: #1B1B1B;
      line-height: 24px;
    }

    .right {

      .input {
        background: transparent;
        border: none;
        margin-left: 20px;
        color: #1B1B1B;
        font-size: 16px;
      }
      .input::placeholder{
        color: #C7C7C7;
        font-size: 14px;
      }
    }
  }

  .line:nth-last-of-type(1) {
    border-bottom: 1px solid white;
  }
}
.name{
  .card{
    margin:10px 0;
  }
}
.bottomBtn {
  position: absolute;
  bottom: 10px;
  left: 0%;
  width: 100%;

  .btn {
    background: #FFFFFF;
    margin: 10px 16px;
    border-radius: 10px;
    padding: 13px;
    font-weight: 400;
    font-size: 15px;
    color: #1B1B1B;
    line-height: 24px;
  }
}

</style>