<template>
  <div class="serveapp">
    <div class="topImg"></div>
    <loding v-if="loding"></loding>
    <div class="container">
      <div class="area">
        <navigationBar :title="'室内快修'" class="navigation"></navigationBar>
        <div class="top">
          <div v-for="(item, index) in tabList" :key="index" class="out" @click="choosTab(index)">
            <div class="topItem">
              <img :src="item.image" alt="" class="image">
              <span class="text" :class="chooseIndex == index ? 'chooseText' : ''">{{ item.name }}</span>
            </div>
            <img src="../../../../public/image/servenext.png" alt="" class="nextImg" v-if="index != tabList.length - 1">
          </div>
        </div>
        <div class="body">
          <works_weixiu style="padding:0 16px" v-if="workShow" :identity="identity" :choosekanchayuan="choosekanchayuan"
                        :obj="obj" :obj1="obj1"></works_weixiu>
          <reconnaissance ref="reconnaissance"
                          :identity="identity"
                          v-if="chooseIndex == 0"
                          @yuangong="getyuangong"
                          :maintenance_type="maintenance_type"
                          :obj="obj"
                          :obj1="obj1"
          ></reconnaissance>

          <orderDetails ref="orderDetails"
                        v-if="chooseIndex == 1"
                        :identity="identity"
                        :maintenance_type="maintenance_type"
                        :obj="obj"
                        :obj1="obj1"
                        @baogan='getmaintenance_price'

          ></orderDetails>

          <acceptance v-if="chooseIndex == 2"
                      ref="acceptance"
                      :obj="obj"
                      :obj1="obj1"
                      :identity="identity"
                      :maintenance_type="maintenance_type"
          ></acceptance>
          <evaluate v-if="chooseIndex == 3"
                    ref="evaluate"
                    :obj="obj"
                    :obj1="obj1"
                    :identity="identity"
                    :maintenance_type="maintenance_type"
          ></evaluate>
        </div>
        <!--        {{Boolean(obj.id)}}-->
        <!--            v-if="!obj.order_id&&identity ==1 || obj.order_id&&identity ==2"-->
        <bottomfun
            v-if="botomShow"
            :chooseIndex="chooseIndex" @submitOrder="submitOrder" @employeeDoor="employeeDoor"
            @userSubscribe="userSubscribe"
            @userPayTwo="userPayTwo"
            @userQuestion="userQuestion"
            @yanshouBtn="yanshouBtn"
            @submitEvaluate="submitEvaluate"
            :serverTime1="serverTime"
            :identity="identity" :obj="obj" :obj1="obj1" :maintenance_price="maintenance_price"></bottomfun>
        <div style="width: 100%;height: 100px;"></div>

      </div>

    </div>
  </div>
</template>

<script>
import navigationBar from '../../navigation-bar/navigation-bar.vue';
import reconnaissance from './service_reconnaissance.vue'
import orderDetails from './service_order_details.vue'
import evaluate from './service_evaluate.vue'
import acceptance from './service_acceptance.vue'
import works_weixiu from "./works_weixiu/works_weixiu.vue";


import bottomfun from './bottom_line/user_bottom.vue'
import {Toast} from "vant";
import {
  add_maintenance_wenti, getNowTime, order_comments, order_confirm,
  Receiving_authority_create,
  Receiving_authority_up,
  up_authority_order,
  user_apply_embellish
} from "@/utils/api";
import {_throttle} from "@/utils/tool";
import Loding from "@/components/common/loding/loding.vue";

export default {
  components: {
    Loding,
    navigationBar,
    reconnaissance,
    orderDetails,
    acceptance,
    evaluate,
    bottomfun,
    works_weixiu,
  },
  props: {},
  data() {
    return {
      tabList: [
        {name: '预约勘察', image: require('../../../../public/image/serve1.png')},
        {name: '订单信息', image: require('../../../../public/image/serve2.png')},
        {name: '快修验收', image: require('../../../../public/image/serve3.png')},
        {name: '用户评价', image: require('../../../../public/image/serve4.png')},
      ],
      chooseIndex: 0,
      // workShow: false,
      choosekanchayuan: {},//选择的勘察员
      obj: {},//
      obj1: {},// 装修对象
      otherInFlag: false,//其他页面进入（首页进入为正常进入
      maintenance_type: 1,////1质保外  2质保内 3装修质保
      tabIndexFlag: 0,//
      identity: 1,//自定义身份 1用户  2勘察员
      maintenance_price: 0,
      loding: false,

      serverTime: "",//服务器时间
      botomShow: true,
    };
  },
  watch: {
    // details_maintenance_price(){
    //
    // }
  },
  computed: {
    workShow() {
      return this.obj.authority_user_id || this.obj.maintenance_user_id
    },
  },
  methods: {

    submitOrder(e) {
      console.log(e)

      if (!this.$refs.orderDetails.userChooseWeixiu) {
        Toast('请输入维修内容')
        return
      }
      if (this.$refs.orderDetails.kanchaUpload.length <= 0) {
        Toast('请上传图片')
        return;
      }
      if (!this.$refs.orderDetails.yvyue_maintenance_time) {
        Toast('请选择维修时间')
        return;
      }
      if (!this.$refs.orderDetails.chooseWeixiushifu.id) {
        Toast('请选择维修师傅')
        return;
      }
      let data = {
        token: this.$store.state.token,
        order_id: this.obj.order_id,
        maintenance_user_id: this.$refs.orderDetails.chooseWeixiushifu.id,
        surveyor_img: JSON.stringify(this.$refs.orderDetails.kanchaUpload),//勘察员上传照片、视频
        material_img: '',//材料照片
        yvyue_maintenance_time: this.$refs.orderDetails.yvyue_maintenance_time,
        maintenance_type: this.$refs.orderDetails.radio,//1质保外  2质保内 3装修质保
        notes: this.$refs.orderDetails.maintenance_notes ? this.$refs.orderDetails.maintenance_notes : '',//备注
        tool: this.$refs.orderDetails.toolStr ? this.$refs.orderDetails.toolStr : '',
        material: '',//材料
        material_price: this.$refs.orderDetails.material_price ? this.$refs.orderDetails.material_price : 0.00,
        maintenance_price: this.$refs.orderDetails.maintenance_price,
        maintenance_content: this.$refs.orderDetails.userChooseWeixiu,
      }
      if (e == '提交订单') {
        Receiving_authority_create(data.token, data.order_id, data.maintenance_user_id, data.surveyor_img, data.material_img,
            data.yvyue_maintenance_time, data.maintenance_type, data.notes, data.tool, data.material,
            data.material_price, data.maintenance_price, data.maintenance_content).then(res => {
          if (res.data.status == true) {
            let msg = {type: 'u_order_4', user_id: this.obj.user_id}
            this.$store.state.websocket.send(JSON.stringify(msg))
            Toast('提交成功')
            this.$router.go(-1)
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }

      if (e == '修改订单') {
        Receiving_authority_up(data.token, data.order_id, data.maintenance_user_id, data.surveyor_img, data.material_img,
            data.yvyue_maintenance_time, data.maintenance_type, data.notes, data.tool, data.material,
            data.material_price, data.maintenance_price, data.maintenance_content).then(res => {
          if (res.data.status == true) {
            let msg = {type: 'u_order_4', user_id: this.obj.user_id}
            this.$store.state.websocket.send(JSON.stringify(msg))
            Toast('提交成功')
            this.$router.go(-1)
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }

    },
    employeeDoor() {
      console.log(this.$refs.orderDetails.DoorUpload)
      if (this.obj.authority_user_status == 2) {
        return;
      }
      if (this.$refs.orderDetails.DoorUpload.length <= 0) {
        Toast('请上传上门图片')
        return
      }

      up_authority_order(this.$store.state.token, JSON.stringify(this.$refs.orderDetails.DoorUpload), this.obj.order_id).then(res => {
        if (res.data.status == true) {
          this.obj.authority_user_status = 2
          Toast('打卡成功')
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })


      console.log('打卡')
      console.log(this.$refs.orderDetails.DoorUpload)
    },
    yanshouBtn() {
      console.log('验收')
      if (this.identity == 2) {
        return
      }
      if (this.obj.confirm_time) {
        return
      }
      order_confirm(this.$store.state.token, this.obj.order_id).then(res => {
        if (res.data.status) {
          let msg = {type: 'u_order_4', user_id: this.obj.user_id}
          this.$store.state.websocket.send(JSON.stringify(msg))
          this.$router.go(-1)
          Toast('验收通过')
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    submitEvaluate() {
      var date1 = new Date(this.obj.confirm_time);
      var date2 = new Date(this.serverTime * 1000);
      var date3 = (date2.getTime() - date1.getTime()) / 1000;//相差秒数
      if (date3 > 60 * 60 * 24 * 7) {
        console.log("开始时间与结束时间间隔大于7天！");
        Toast('不能评价')
        return
      }

      // console.log(this.$refs.evaluate.userComment)
      // console.log(this.$refs.evaluate.userComment2)

      // console.log(this.$refs.evaluate.kanchaIndex)
      // console.log(this.$refs.evaluate.weixiuIndex)
      let data = {
        token: this.$store.state.token,
        order_id: this.obj.order_id,
        content: this.$refs.evaluate.userComment ? this.$refs.evaluate.userComment : '',
        contents: this.$refs.evaluate.userComment2 ? this.$refs.evaluate.userComment2 : '',
        datas: [
          {user_id: this.obj.authority_user_id, status: this.$refs.evaluate.kanchaIndex},
          {user_id: this.obj.maintenance_user_id, status: this.$refs.evaluate.weixiuIndex}
        ]
      }
      console.log(data)

      order_comments(data.token, data.order_id, data.content, data.contents, JSON.stringify(data.datas)).then(res => {
        console.log(res)
        if (res.data.status == true) {
          Toast('提交成功')
          let msg1 = {type: 'u_order_weixiu'}
          let msg2 = {type: 'u_order_bottled'}
          this.$store.state.websocket.send(JSON.stringify(msg1))
          this.$store.state.websocket.send(JSON.stringify(msg2))
          this.$router.go(-1)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })

    },
    userQuestion() {

      if (this.obj.confirm == 2) {
        return
      }
      let data = {
        order_id: this.obj.order_id,
        title: this.$refs.acceptance.userYanshouValue,
        mp3: '',
        felis: JSON.stringify(this.$refs.acceptance.yanshouload),
      }
      if (!this.$refs.acceptance.userYanshouValue) {
        Toast('请输入问题')
        return;
      }
      if (this.$refs.acceptance.yanshouload.length <= 0) {
        Toast('请上传问题图片')
        return;
      }
      add_maintenance_wenti(this.$store.state.token, data.order_id, data.title, data.felis).then(res => {
        console.log(res)
        if (res.data.status == true) {
          Toast('提交成功')
          this.$router.go(-1)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })


      console.log(data)
      console.log('整改')


    },
    userPayTwo() {
      let data = {
        token: this.$store.state.token,
        ids: this.obj.order_id,
        'params[trade_type]': 'JSAPI',
        payment_type: 1,
        payment_code: 'wechatpay'
      }
      this.loding = true
      this.pay(data)
    },
    pay: _throttle(function (data) {
      console.log(data)
      this.loding = false
      this.$router.push({
        name: "pay",
        params: {data: this.obj, backStep: 2, type: "u_order_weixiu"}
      })
    }, 1000),

    userSubscribe() {

      if (this.obj.pay_status != 1) {
        if (this.otherInFlag) {
          return
        }
      }
      console.log(123, this.$refs.reconnaissance.chooseKanchaObj)
      if (!this.$refs.reconnaissance.kanchaTime) {
        Toast('请选择勘察时间')
        return
      }
      if (!this.$refs.reconnaissance.chooseKanchaObj.id) {
        Toast('请选择勘察员')
        return
      }
      if (!this.$refs.reconnaissance.userChooseWeixiu) {
        Toast('请选择维修项目')
        return
      }
      if (!this.$refs.reconnaissance.userInfo.addId) {
        Toast('请选择维修地址')
        return
      }
      if (this.$refs.reconnaissance.userUpload.length <= 0) {
        Toast('请上传图片')
        return;
      }
      if (!this.$refs.reconnaissance.userInfo.addId) {
        Toast('请选择地址')
        return;
      }

      let ship = {}
      ship['name'] = this.$refs.reconnaissance.userInfo.name
      ship['yx'] = this.$refs.reconnaissance.userInfo.ys
      ship['remark'] = ''
      ship['id'] = this.$refs.reconnaissance.userInfo.addId
      ship['ds'] = this.$refs.reconnaissance.userInfo.shipAddress
      ship['address'] = this.$refs.reconnaissance.userInfo.address
      ship['mobile'] = this.$refs.reconnaissance.userInfo.mobile
      ship['time'] = this.$refs.reconnaissance.kanchaTime
      ship['maintenance_type'] = this.maintenance_type
      let files = this.$refs.reconnaissance.userUpload
      let data = {
        token: this.$store.state.token,
        title: this.$refs.reconnaissance.userChooseWeixiu,
        files: JSON.stringify(files),
        mp3: '',
        ship: JSON.stringify(ship),
        order_id: '',
        authority_user_id: this.$refs.reconnaissance.chooseKanchaObj.id,

      }

      if (this.obj.pay_status == 1 && this.obj.status == 1) {
        let obj = {
          authority_user_id: this.obj.authority_user_id,
          ctime: new Date(this.obj.ctime.replace(' ', 'T')).getTime() / 1000,
          distance: this.obj.distance,
          goods_amount: Number(this.obj.goods_amount),
          order_amount: Number(this.obj.order_amount),
          order_id: this.obj.order_id,
          order_type: this.obj.order_type,
          remark: this.obj.remark,
          ship_address: this.obj.ship_address,
          ship_address_ds: this.obj.ship_address_ds,
          ship_address_yx: this.obj.ship_address_yx,
          ship_id: this.obj.ship_id,
          ship_mobile: this.obj.ship_mobile,
          ship_name: this.obj.ship_name,
          source: this.obj.source,
          kt_yj: this.obj.kt_yj,
          user_id: this.obj.user_id,
          yvyue_time: this.obj.yvyue_time,
          yvyue_times: this.obj.yvyue_times,
        }
        console.log(obj)
      } else {
        if (this.obj.status == 3) return;
        console.log(data)
        if (this.obj1.order_id) {
          data.order_id = this.obj1.order_id
        }
        console.log(this.obj1)
        console.log(this.obj)
        console.log(Boolean(this.obj.order_id))

          user_apply_embellish(data.token, data.title, data.files, data.mp3, data.ship, data.authority_user_id, data.order_id).then(res => {
            console.log(res)
            if (res.data.status) {
              if (this.maintenance_type == 1) {
                this.$router.push({
                  name: "pay",
                  params: {data: res.data.data, backStep: 1, type: "u_order_bottled"}
                })
              } else {
                Toast('预约成功')
                if (this.maintenance_type == 2) {

                  let data = {
                    token: this.$store.state.token,
                    order_id: this.obj.order_id,
                    maintenance_user_id:'',
                    surveyor_img: this.obj.items.files_user_surveyor,//勘察员上传照片、视频
                    material_img: '',//材料照片
                    yvyue_maintenance_time: '',
                    maintenance_type: this.$refs.reconnaissance.radio,//1质保外  2质保内 3装修质保
                    notes: this.obj.items.notes ? this.obj.items.notes : '',//备注
                    tool: this.obj.items.tool ? this.obj.items.tool : '',
                    material: this.obj.items.material,//材料
                    material_price: this.obj.items.material_price ? this.obj.items.material_price : 0.00,
                    maintenance_price: this.obj.items.maintenance_price,
                    maintenance_content: this.obj.items.maintenance_content,
                  }
                  Receiving_authority_create(
                      this.$store.state.token,
                      data.order_id,
                      data.maintenance_user_id,
                      data.surveyor_img,
                      data.material_img,
                      data.yvyue_maintenance_time,
                      data.maintenance_type,
                      data.notes,
                      data.tool,
                      data.material,
                      data.material_price,
                      data.maintenance_price,
                      data.maintenance_content,
                  ).then(res=>{
                    console.log(res)
                    if(res.data.status == true){
                      this.go(-1)
                    }else{
                      Toast(res.data.msg)
                    }
                  })




                  console.log('maintenance_type==2')


                } else {
                  Toast('提交成功')
                  setTimeout(() => {
                    this.go(-1)
                  }, 1000);
                }


                let msg = {type: 'u_order_bottled'}
                console.log(msg)
              }


            } else {
              Toast(res.data.msg)
            }
          })
      }


    },
    choosTab(i) {
      if (i > this.tabIndexFlag) return;
      if (i == this.chooseIndex) return;
      this.chooseIndex = i
    },
    getyuangong(obj) {
      this.choosekanchayuan = obj.kancha
    },
    getmaintenance_price(e) {
      this.maintenance_price = e
    },
    otherIn(it) {
      let obj = JSON.parse(it);
      this.maintenance_type = obj.items.maintenance_type
      console.log(obj)
      console.log(this.$route.query)
      console.log(this.$refs.reconnaissance)
      // if (!this.$refs.reconnaissance.chooseKanchaObj.id) {
      //   Toast('请选择勘察员')
      //   return
      // }
      if (obj.maintenance_user_id) { ///维修工id
        if (obj.maintenance_user.jiguan) {
          console.log(obj.maintenance_user.jiguan.split(',')[0])
        }
        // this.$refs.reconnaissance.chooseKanchaObj = obj.maintenance_user
      }
      if (obj.authority_user_id) {///勘察员
        if (obj.authority) {
          if (obj.authority.jiguan) {
            console.log(obj.authority.jiguan.split(',')[0])
          }
        }
        this.$refs.reconnaissance.chooseKanchaObj = obj.authority
        this.$refs.reconnaissance.chooseKanchaObj.id = obj.authority_user_id

      }
      if (this.$route.query.type == 'set') {
        this.botomShow = true
        if (obj.Industry_attributes) {
          this.maintenance_type = 3
        } else {
          this.maintenance_type = 2
        }
        if (obj.items) {
          //
        }
        this.obj1 = obj
      } else {
        this.botomShow = false

        if (obj.items.maintenance_type == 1) {
          this.maintenance_type = 1
        }
        if (obj.items.maintenance_type == 2) {
          this.maintenance_type = 2
        }
        if (obj.items.maintenance_type == 3) {
          this.maintenance_type = 3
        }
        if (obj.pay_status == 1) {
          //
        }

        console.log(this.$refs)
        this.$refs.reconnaissance.userUpload = JSON.parse(obj.items.files_user)
        this.$refs.reconnaissance.fileList = JSON.parse(obj.items.files_user).map(item => {
          return {
            name: item,
            url: item
          }
        })

        console.log(this.$refs)
        if (obj.wenti) {
          if (obj.wenti.length > 0) {
            obj.wenti.forEach(item => {
              item.mp32 = JSON.parse(item.mp3)
              item.feliss = JSON.parse(item.felis)
            })
          }
        }

        if (obj.items.files_user_surveyor) {
          console.log(JSON.parse(obj.items.files_user_surveyor))//勘察上传图
        }
        if (obj.items.files_user_maintenance) {
          console.log(JSON.parse(obj.items.files_user_maintenance))//维修图
        }


      }
      if(obj.pay_status==1||obj.pay_status==6) {
        // isExitFlag:false,
        //     payText:"订单支付",
        //     cancelText:'取消订单'
      }else if(obj.pay_status==2){
        // isExitFlag:true,
        //     payText:"支付成功",
        //     cancelText:'申请售后',
      }else if(obj.pay_status==5){
          // cancelText:'已退款'
      }
      if(obj.confirm_time) {
        // yanshouText:'验收完成'
      }
      if(obj.is_comment==2){
        // 评价是否超过7天
        var date1=new Date(obj.confirm_time);
        var date2=new Date();
        var date3=(date2.getTime()-date1.getTime())/1000;//相差秒数
        if(date3>60*60*24*7){
          // this.setData({
          //   isPingjia:true
          // })
        }
        if(obj.authority_comment){
          obj.authority_comment.forEach(item=>{
            if(item.authority_user_id==obj.authority_user_id){
              // this.setData({
              //   isYeas:item.status
              // })
            }
            if(item.authority_user_id==obj.maintenance_user_id){
              // this.setData({
              //   isYeasT:item.status
              // })
            }
          })
        }

      }
      if(obj.items.status==2){
        // this.setData({
        //   weixiugongDisabled:true,
        //   payText:"已支付",
        // })
      }
      if(obj.items.status==1){
        // this.setData({
        //   weixiugongDisabled:true,
        //   payText:"提交订单",
        // })
        if(obj.payment_time){
          // this.setData({
          //   payText:"已支付",
          // })
        }
      }

      if(obj.confirm==2){
        // this.setData({
        //   weixiugongDisabled:true,
        //   payText:"已完成",
        // })
      }
      if(obj.items.status==2){
        // this.setData({
        //   weixiugongDisabled:true,
        // })
      }
        // fileList
      // userUpload

      this.obj = obj

    },
    kanchaLi(it) {
      let obj = JSON.parse(it);
      this.maintenance_type = obj.items.maintenance_type


      this.obj = obj
    },
  },
  created() {
  },
  async mounted() {
    if (this.$route.query.index) {
      console.log(this.$route.query, 'this.$route.query')
      this.chooseIndex = Number(this.$route.query.index)
      this.tabIndexFlag = Number(this.$route.query.index)
      if (this.$route.query.item) {
        this.identity = this.$route.query.identity
        if (this.$route.query.identity == 1) {
          this.otherIn(this.$route.query.item)
        } else {
          this.kanchaLi(this.$route.query.item)
        }
      }
    }
    this.serverTime = (await getNowTime()).data.data
  }
};
</script>
<style lang="scss" scoped>

.serveapp {
  background: #F4F6FA;
}

.navigation {
  background: transition !important;
}

.topImg {
  position: absolute;
  top: 0;
  left: 0;
  height: 280px;
  background: linear-gradient(180deg, #FFF4F0, rgba(250, 252, 255, 0%)) !important;
  width: 100%;
  z-index: 1;
}

.chooseText {
  font-weight: 600;
  font-size: 14px;
  color: #1B1B1B;
}

.container {
  position: relative;
  z-index: 2;

  .top {
    display: flex;
    // margin: 0 16px;
    justify-content: center;

    .out {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      width: 25%;
      position: relative;
    }

    .topItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .image {
        width: 50px;

      }

      .text {
        font-size: 13px;
        color: #666;
        line-height: 24px;
      }
    }

    .nextImg {
      width: 25px;
      position: absolute;
      right: 0;
      top: 40%;
      transform: translate(50%, -50%);
    }
  }
}


</style>