<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {_throttle} from "@/utils/tool";
import Loding from "@/components/common/loding/loding.vue";
import {create, getuserShip} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "createOrderFirst",
  components: {Loding, navigationBar},
  data() {
    return {
      obj: {},
      nums: 1,
      loding: false,
      address: {},
    }
  },
  computed: {
    allprice() {
      return this.obj.price * this.nums;
    },
  },
  methods: {
    add() {
      this.nums += 1
    },
    unadd() {
      if (this.nums <= 1) return;
      this.nums -= 1
    },
    creatOrder() {
      this.loding = true
      if (this.obj.id) {
        this.jiekou()
      } else {
        this.loding = false
      }

    },
    jiekou: _throttle(function () {
      console.log(123)
      let order_type = 4
      let ship = {
        yx: '', ds: '', address: '', name: '', mobile: '', time: '', remark: ''
      }
      let cart_ids = {}
      if (this.obj.goods_type_id == 6) {
        order_type = 4
      }
      if (this.obj.goods_type_id == 5) {
        order_type = 5
        ship['name'] = this.addresst.name,
            ship['yx'] = this.addresst.ship_yx ,
            ship['ds'] = this.addresst.address_yx + this.addresst.address,
            ship['address'] = this.addresst.address_yx,
            ship['mobile'] = this.addresst.mobile,
            ship['time'] = this.shipTime,
            ship['remark'] = this.remark,
            ship['id'] = this.addresst.id
      }
      cart_ids['nums'] = this.nums
      cart_ids['id'] = this.obj.id

      create(this.$store.state.token, order_type, JSON.stringify(cart_ids), JSON.stringify(ship)).then(res => {
        if (res.data.status == true) {
          this.$store.commit('updateWaterOrder',{})
          this.$router.push({
            name:"pay",
            params:{data:res.data.data,backStep:2,type:""}
          })
        }else{
          Toast(res.dada.msg)
        }
      })
      this.loding = false
    }, 1000),
    initialize() {
      if (this.$store.state.user.addId) {
        getuserShip(this.$store.state.token).then(res => {
          if (res.data.status == true) {
            let addressList = res.data.data
            let moren = {}
            if (addressList.length > 0) {
              addressList.forEach((item) => {
                if (item.is_def == 1) {
                  item.chekc = true
                  moren = item

                } else {
                  item.chekc = false
                  if (item.chekc) {
                    moren = item

                  }
                }
              })
              if (!moren.address) {
                moren = addressList[0]
                addressList[0].chekc = true
              }
              this.addresst = moren
              console.log(this.addresst)
            }
            console.log(res.data.date)
            this.$store.commit('updateShipingAddress', res.data.data)

          } else {
            Toast(res.data.msg)
          }
        })
      } else {
        Toast('请选择地址')
      }
    },
  },
  mounted() {
    this.obj = this.$route.params.object
    console.log(this.obj)
    if (this.obj.goods_type_id == 6) {
      this.obj.image = this.obj.water_img
    }
    this.initialize()
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'创建订单'" class="navigation"></navigationBar>
      <div class="inner">
        <div class="card">
          <div class="title">商品信息</div>
          <div class="goodBody">
            <div class="left">
              <img :src="$store.state.imageHost+obj.image" alt="" class="image">
            </div>
            <div class="rightInfo">
              <div class="name">
                {{ obj.water_name }}
              </div>
              <div class="spacl">
                {{ obj.goods_type_id == 6 ? '规格：百街水票-' + obj.package_nember + '张' : obj.specifications }}
              </div>
              <div class="price">
                <div class="leftA"><span class="unit">￥</span>{{ obj.price }}</div>
                <div class="rightAdd">
                  <div class="unadd" @click="unadd"></div>
                  <div class="input">{{ nums }}</div>
                  <div class="add" @click="add"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tip">
          <van-icon name="volume-o" class="icon"/>
          <span class="text">送桶装水数量应与收回空桶数量相符，如若不符您需购买差额空桶
        </span>
        </div>
      </div>
      <div class="bottom">
        <div class="bottomInner">
          <div class="left">￥{{ allprice }}</div>
          <div class="btn" @click="creatOrder">提交订单</div>
        </div>
      </div>
      <div class="bottomPromit">
        <div style="color:#666666;font-size: 14px;">
          注：
        </div>
        <div>
          <div class="promitEvery">
            1.购买桶装水时，若您没有空桶，则需购买空桶;购买数量应与送水桶数相等
          </div>
          <div class="promitEvery">
            2.若您自有空桶，可抵同等数量的差额空桶
          </div>
          <div class="promitEvery">
            3.送水桶数与回收桶数应当一致，否则您需购买差额空桶
          </div>
        </div>
      </div>
      <loding v-if="loding"></loding>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  height: 100vh;
  background: #F4F6FA;
}

.navigation {
  background-color: #fff
}

.inner {
  margin: 0 16px;

  .card {
    background: #fff;
    margin: 10px 0;
    border-radius: 10px;
    padding: 10px;

    .title {
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
      text-align: left;
    }

    .goodBody {
      display: flex;

      .left {
        width: 50px;
        height: 50px;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .rightInfo {
        flex: 1;
        text-align: left;
        padding-left: 10px;

        .name {
          font-weight: 500;
          font-size: 14px;
          color: #1B1B1B;
          line-height: 24px;
        }

        .spacl {
          font-weight: 400;
          font-size: 12px;
          color: #7F7F7F;
          line-height: 18px;
          text-align: left;
        }

        .price {
          display: flex;
          justify-content: space-between;

          .leftA {
            font-weight: 600;
            color: #FD2A39;
            font-size: 18px;

            .unit {
              font-size: 14px;
            }
          }

          .rightAdd {
            display: flex;
            align-items: center;

            .add, .unadd {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: #398ae9;
              position: relative;
            }

            .add::after, .unadd::after {
              content: '';
              display: block;
              position: absolute;
              transform: translate(-50%, -50%);
              width: 50%;
              height: 10%;
              background-color: #ffffff;
              top: 50%;
              left: 50%;
              border-radius: 100px;

            }

            .add::before {
              content: '';
              display: block;
              position: absolute;
              transform: translate(-50%, -50%);
              width: 10%;
              height: 50%;
              background-color: white;
              top: 50%;
              left: 50%;
              border-radius: 100px;

            }

            .input {
              width: 24px;
              height: 24px;
              font-size: 18px;
              color: #1B1B1B;
              text-align: center;
              margin: 0 2px;
              font-weight: 500;
              white-space: nowrap;
              overflow: scroll;
            }
            .input::-webkit-scrollbar{
              display: none;
            }
          }
        }
      }

    }
  }

  .tip {
    border-radius: 4px;
    margin: 10px auto 10px auto;
    font-size: 12px;
    background-color: #F1F6FD;
    padding: 4px 10px;
    color: #286CFB;
    display: flex;
    border-radius: 10px;
    align-items: center;
    border: 1px solid #286CFB;

    .icon {
      margin-right: 5px;
      font-size: 17px;
    }

    .text {
      font-size: 12px;
      color: #286CFB;
      width: 95%;
      line-height: 24px;
      text-align: left;
    }
  }


}

.bottom {
  position: fixed;
  width: 100%;
  background: #fff;
  left: 0;
  bottom: 0;

  .bottomInner {
    display: flex;
    margin: 10px 16px;
    justify-content: space-between;
    align-items: center;

    .left {
      color: #FD2A39;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }

    .btn {
      background: #0085FF;
      border-radius: 32px;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
      padding: 10px 31px;
    }
  }

}

.bottomPromit {
  width: 90%;
  margin: 0 auto 20px auto;
  border-radius: 5px;
  padding: 5px;
  line-height: 22px;
  display: flex;
}

.promitEvery {
  text-align: left;
  font-size: 12px;
  color: #666666;
  position: relative;
}
</style>