<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Compressor from "compressorjs";
import {
  getRegAuthentDetail,
  getRegAuthshop,
  regAuthentication,
  regAuthenticationShop,
  upload_file,
  upload_files
} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "idle_certification",
  components: {navigationBar},
  data() {
    return {
      imfomationLimit: 2,
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg"],
      frontUpload: '',
      frontFiles: [],//正面
      contraryUpload: '',
      contraryFiles: [],//反面
      logoUpload:'',
      logoFiles:[],//logo
      name:'',//姓名
      standing:'',//证件号码
      shop_name:'',



      is_Individual:false,
      is_shop:false,
      topShow:true,
      botShow:true,
    }
  },
  methods: {
    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)
        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 'front') {
              this.frontUpload = res.data.data.saveurl
            }
            if (item.filename == 'contrary') {
              this.contraryUpload = res.data.data.saveurl
            }
            if (item.filename == 'logo') {
              this.logoUpload = res.data.data.saveurl
            }
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            console.log(res.data.data)
            // this.userUpload
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'video'})
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange(file, infomationFiles) {
      let list = []
      infomationFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.frontFiles = list;
    },
    fileChange2(file, infomationFiles) {
      let list = []
      infomationFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.contraryFiles = list;
    },
    fileChange3(file, infomationFiles) {
      let list = []
      infomationFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.logoFiles = list;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.yanshoufileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },


    submit(){
      if(this.submitFlag){
        if(!this.is_Individual){
          if(this.topShow){
            this.Individual()
          }
        }
        if(!this.is_shop){
          if(this.botShow){
            this.shopSubmit()
          }
        }
      }
    },

    Individual(){
      let img = [this.frontUpload,this.contraryUpload]
      regAuthentication(this.$store.state.token,'身份证',this.name,this.standing,JSON.stringify(img)).then((res)=>{
        if(res.data.status){
          console.log(res.data)
          Toast('提交成功')
          this.$router.go(-1)
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    shopSubmit(){
      let img = [this.frontUpload,this.contraryUpload]
      regAuthenticationShop(this.$store.state.token,this.shop_name,this.logoUpload,JSON.stringify(img)).then((res)=>{
        if(res.data.status){
          console.log(res.data)
          Toast('提交成功')
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },


    getIndividual(){
      getRegAuthentDetail(this.$store.state.token).then(res=>{
        if(res.data.status == true){
          console.log(res.data.data)
          if(res.data.data == null){
            this.is_Individual= false
          }else{
            this.is_Individual= true
            let obj = res.data.data
            this.name = obj.name
            this.standing = obj.idcard
            console.log(obj.img)
            this.frontUpload = (obj.img).split(',')[0]
            this.contraryUpload = (obj.img).split(',')[1]
          }
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    getShowSubmit(){
      getRegAuthshop(this.$store.state.token).then(res=>{
        if(res.data.status == true){
          console.log(res.data.data)
          if(res.data.data == null){
            // this.is_Individual= false
          }else{
            // this.is_Individual= true
            // let obj = res.data.data
            // this.name = obj.name
            // this.standing = obj.idcard
            // this.frontUpload = JSON.parse(obj.img)[0]
            // this.contraryUpload = JSON.parse(obj.img)[1]
          }
        }else{
          // Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
  mounted() {
    this.getIndividual()
    if(this.$route.query.type == 'personage'){
      this.topShow = true
      this.botShow = false
    }
    // if(this.$route.query.type == 'personage'){
    //   this.topShow = false
    //   this.botShow = true
    // }
    if(this.$route.query.show == true){
      this.topShow = true
      this.botShow = true
    }
  },
  computed:{
    submitFlag(){
      let flag = false
      if((this.name&&this.frontUpload&&this.contraryUpload&&this.standing)&&!this.shop_name&&!this.logoUpload){
        if(this.is_Individual){
          flag = false
        }else{
          flag = true
        }
      }
      if(this.name&&this.frontUpload&&this.contraryUpload&&this.standing&&this.shop_name&&this.logoUpload){
        flag = true
      }
      return flag
    },
  },
}
</script>

<template>
  <div class="container cartification">
    <div class="area">
      <navigationBar :title="'实名认证'" class="navigationBar"></navigationBar>
      <div class="inner">
        <div class="topBox">
          <div class="cont" v-if="topShow">
            <div class="card info">
              <div class="line">
                <div class="title">证件类型</div>
                <div class="right">居民身份证</div>
              </div>
              <div class="line">
                <div class="title">姓名</div>
                <input type="text" placeholder="请输入姓名" v-model="name" maxlength="20" :disabled="is_Individual">
              </div>
              <div class="line">
                <div class="title">证件号码</div>
                <input type="text" placeholder="请输入身份证号" v-model="standing" maxlength="30" :disabled="is_Individual">
              </div>
            </div>
            <div class="uploadCard">
              <div class="tile">
                上传身份证
              </div>
              <div class="uploadImgBox">
                <el-upload
                    v-if="!is_Individual"
                    class="avatar-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="imfomationLimit"
                    :on-remove="handleRemove"
                    :file-list="frontFiles"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                    multiple
                    name="front"
                >
                  <div v-if="!frontUpload">
                    <div class="innerBox"
                         :style="`background-image: url('${$store.state.imageHost}/img/Rectangle@2x.png')`">
                      <img :src="$store.state.imageHost+'/img/Group_1538%402x.png'" alt="" class="image">
                    </div>
                    <div class="text">上传身份证正面</div>
                  </div>
                  <img :src="$store.state.imageHost+frontUpload" alt="" class="showImg" v-if="frontUpload">
                </el-upload>

                <el-image
                    v-if="frontUpload&&is_Individual"
                    class="imageShow"
                    :src="$store.state.imageHost+frontUpload"
                    :preview-src-list="[$store.state.imageHost+frontUpload]">
                </el-image>
                <el-upload
                    v-if="!is_Individual"
                    class="avatar-uploader"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="imfomationLimit"
                    :on-remove="handleRemove"
                    :file-list="contraryFiles"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange2"
                    multiple
                    name="contrary"
                >
                  <div v-if="!contraryUpload">
                    <div class="innerBox"
                         :style="`background-image: url('${$store.state.imageHost}/img/Frame@2x（1.png')`">
                      <img :src="$store.state.imageHost+'/img/Group_1538%402x.png'" alt="" class="image">
                    </div>
                    <div class="text">上传身份证反面</div>
                  </div>
                  <img :src="$store.state.imageHost+contraryUpload" alt="" class="showImg" v-if="contraryUpload">
                </el-upload>
                <el-image
                    v-if="contraryUpload&&is_Individual"
                    class="imageShow"
                    :src="$store.state.imageHost+contraryUpload"
                    :preview-src-list="[$store.state.imageHost+contraryUpload]">
                </el-image>
<!--                <img :src="$store.state.imageHost+contraryUpload" alt="" v-if="contraryUpload&&is_Individual" class="imageShow">-->
              </div>
            </div>
          </div>
        </div>
        <div class="bottomBox">

        </div>


        <div class="bottomBtn" :class="submitFlag?'yesBtn':''" @click="submit">
          提交认证审核
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.navigationBar {
  background: #fff;
}

.container {
  background: #F4F6FA;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
}

.topBox {
  min-height: 2px;
  background: #fff;
  border-radius: 0 0 10px 10px;

  .info {
    margin: 10px 16px;
    text-align: left;

    .line {
      display: flex;
      min-height: 24px;
      padding: 10px 16px;
      border-bottom: 1px solid #f6f6f6;

      .title {
        min-width: 70px;
        color: var(--666666, #666666);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      .right {
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        flex: 1;
      }

      input {
        border: none;
        color: #1b1b1b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

      input::placeholder {
        color: #c7c7c7;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }

    }

    .line:nth-last-of-type(1) {
      border-bottom: none;
    }
  }

  .uploadCard {
    text-align: left;
    padding: 10px 16px;

    .title {
      color: #3CCFC7;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .uploadImgBox {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .imageShow{
        width: 45%;
        height: 127px;
        object-fit: cover;
      }

      .avatar-uploader {
        width: 45%;
        min-height: 127px;
        border-radius: 10px;
        background: #eeeeee;
        border: none;

        .innerBox {
          background: #fff;
          margin: 6px 8px;
          border-radius: 10px;
          min-height: 90px;
          background-repeat: no-repeat;
          background-size: 80%;
          background-position: center;
          display: flex;
          align-items: center;
          justify-content: center;

          .image {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }

        .text {
          color: #1b1b1b;
          font-weight: 400;
          font-size: 14px;
          line-height: 18.2px;
        }
      }
    }


  }
}
.showImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #66666640;
}

.bottomBtn{
  border-radius: 10px;
  background: #fec7ce;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 13px;
  position: fixed;
  left: 50%;
  bottom: 10px;
  width: 80%;
  transform: translate(-50%,0);
}
.yesBtn{
  background: #fc223b;
  color: #ffffff;
}

input:disabled {
  background: #fff;
}
</style>
<style>
.cartification {
  .avatar-uploader {
    .el-upload--text {
      border: none;
    }
  }
}
</style>