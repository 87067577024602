<script>
export default {
  name: "idle_tabbar",
  components: {},
  props: ["active"],

  data() {
    return {
      tabbarList: [
        {
          icon: "icon-Frame-11",
          text: "首页",
          chooseIcon: "icon-Frame1",
          src: "/idle_home",
          badge: 0,
        },
        {
          icon: "icon-Frame-19",
          text: "自由市场",
          chooseIcon: "icon-Frame28",
          src: "/liberty",
          badge: 0,
        },
        {
          icon: "icon-Frame5",
          text: "发布",
          chooseIcon: "icon-Frame4",
          src: "/publish",
          badge: 0,
        },
        {
          icon: "icon-Frame5",
          text: "消息",
          chooseIcon: "icon-Frame4",
          src: "/idel_message",
          badge: 0,
        },
        {
          icon: "icon-Frame7",
          text: "我的",
          chooseIcon: "icon-Frame6",
          src: "/idel_mine",
          badge: 0,
        },

      ],
      chooseTab: this.active,
    };
  },
  methods: {
    switchTab(index) {
      if (this.chooseTab == index) return;
      if (this.tabbarList[index].src) {
        this.chooseTab = index;
      }
    },
    goupdata() {
      console.log("下载");
    },
  },
  created() {},
  mounted() {},
}
</script>

!<template>
  <div>

    <router-view />
    <div class="outBox"  ref="homeTabbar">
      <van-tabbar replace  v-model="chooseTab" active-color="#FC223B" inactive-color="#1B1B1B" class="tabbar" router >
        <van-tabbar-item v-for="(item,index) in tabbarList" :key="index" :badge="item.badge>0?item.badge:''" :to="item.src">
          <span class="text" v-if="index!=2">{{ item.text }}</span>
          <div class="center" v-else>
            <div class="centeriNN" >
              <img src="https://picture.bjbjkj.top/image/fabuText.png" alt="" class="center_img">
            </div>
          </div>
          <template #icon="props">
            <svg class="icon" aria-hidden="true" v-if="index!=2&&(props.active&&index==1)">
              <use :xlink:href="props.active?'#'+item.chooseIcon:'#'+item.icon" class="iconfont"></use>
            </svg>
            <span class="iconfont" :class="props.active?item.chooseIcon:item.icon"  v-if="index!=2&&(props.active&&index!=1)||(!props.active&&index!=2)"></span>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>

    <!-- <div class="tabbar">
      <div class="innerBox">
        <div class="home" v-for="(item, index) in tabbarList" :key="index" :class="index == chooseTab ? 'chooseTab' : ''"
          @click="switchTab(index)">
          <span class="iconfont" :class="item.icon"></span>
          <span class="text">{{ item.text }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style scoped lang="scss">
.van-tabbar{
  height: 62px;
}
.icon{
  width: .8em;
  height: .8em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.center{
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 0px -1px 2px 0px rgba(255,127,142,0.1);
  .centeriNN{
    border-radius: 50%;
    width: 54px;
    height: 54px;
    background-color: #FC223B;
    line-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    .center_img{
      width: 36px;
      object-fit: cover;
    }
  }

}
</style>