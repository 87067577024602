<template>
  <div>
    <div class="works_box">
      <div class="kancha line" v-if="obj.authority_user_id&&(identity==1||!identity)">
        <div class="avatarBox">
          <img :src="$store.state.imageHost+obj.authority.avatar" class="avatar" alt="">
          <div class="span">勘察员</div>
        </div>
        <div class="rightInfo">
          <div class="title">工号：BJKX-{{ obj.authority_user_id }}</div>
          <div class="info">
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">{{ obj.authority.name }}</div>
            </div>
            <div class="everyImem">
              <div class="text">籍贯</div>
              <div class="value">{{ obj.authority.jiguan }}</div>
            </div>
            <div class="everyImem">
              <div class="text">工龄</div>
              <div class="value">{{ obj.authority.gonling }}</div>
            </div>
            <div class="everyImem">
              <div class="text">评分</div>
              <div class="value">5.0</div>
            </div>
            <div class="everyImem mobile">
              <a :href="'tel:'+obj.authority.mobile"><span class="iconfont icon-dianhua1"></span></a>
<!--              <span class="iconfont icon-dianhua1"></span>-->
            </div>
          </div>
        </div>
      </div>
      <div class="weixiu line" v-if="obj.maintenance_user_id&&(identity==1||!identity)">
        <div class="avatarBox">
          <img :src="$store.state.imageHost+obj.maintenance_user.avatar" class="avatar" alt="">
          <div class="span">维修工</div>
        </div>
        <div class="rightInfo">
          <div class="title">工号：BJKX-{{ obj.maintenance_user_id }}</div>
          <div class="info">
            <div class="everyImem">
              <div class="text">姓名</div>
              <div class="value">{{ obj.maintenance_user.name }}</div>
            </div>
            <div class="everyImem">
              <div class="text">籍贯</div>
              <div class="value">{{ obj.maintenance_user.jiguan }}</div>
            </div>
            <div class="everyImem">
              <div class="text">工龄</div>
              <div class="value">{{ obj.maintenance_user.gonling }}</div>
            </div>
            <div class="everyImem">
              <div class="text">评分</div>
              <div class="value">5.0</div>
            </div>
            <div class="everyImem mobile" @click="tel">
              <a :href="'tel:'+obj.maintenance_user.mobile"><span class="iconfont icon-dianhua1"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    choosekanchayuan:{
      type: [Object],
    },
    chooseWeixiugong:{
      type: [Object],
    },
    obj:{
      type: [Object],
    },
    obj1:{
      type: [Object],
    },
    identity:{
      type: [Number,String],
    },

  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
    tel(){

    },
  },
  created() {},
  mounted() {
    console.log(this.choosekanchayuan)
  }
};
</script>
<style lang="scss" scoped>
.works_box {
  text-align: left;

  .line {
    margin: 10px 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    .avatarBox {
      position: relative;
      display: flex;
      overflow: hidden;
      border-radius: 10px;

      .avatar {
        width: 58px;
        height: 58px;
        background-color: #666666;
      }

      .span {
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        bottom: 0;
        font-size: 12px;
        width: 100%;
        text-align: center;
        line-height: 18px;
        color: #fff;
      }
    }

    .rightInfo {
      width: 80%;
      margin-left: 10px;

      .title {
        color: #1B1B1B;
        font-size: 14px;
      }

      .info {
        display: flex;
        //align-items: center;

        .everyImem {
          width: 20%;
          align-items: center;
          text-align: center;

          .text {
            font-size: 12px;
            line-height: 18px;
            color: #666666;
          }

          .value {
            font-size: 14px;
            line-height: 18px;
            color: #1B1B1B;
          }
        }

        .mobile {
          .iconfont {
            background-color: #F5F5F5;
            padding: 6px;
            border-radius: 8px;
            color: #FC223B;
            font-size: 24px;
          }
        }
      }
    }
  }

}
</style>