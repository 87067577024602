<template>
  <div class="container">
    <div class="area">
      <div class="innerarea innerareaOne">
        <div class="title">1.请对勘察员的服务满意度评分</div>
        <div class="yesOno">
          <div
            class="yes"
            :class="kanchaIndex == 1 ? 'choose zhutiColor' : ''"
            @click="kancha(1)"
          >
            <img
              class="img"
              src="https://picture.bjbjkj.top/image/yes1.png"
              alt=""
            />
            满意
          </div>
          <div
            class="no"
            :class="kanchaIndex == 2 ? 'choose zhutiColor' : ''"
            @click="kancha(2)"
          >
            <img
              class="img"
              src="https://picture.bjbjkj.top/image/no1.png"
              alt=""
            />
            不满意
          </div>
        </div>
        <div class="title">2.您对勘察员还有什么建议或意见吗？（非必填）</div>
        <div class="textareaBox">
          <textarea
            name="kancha"
            id=""
            class="textarea"
            placeholder="请填写您对物察员的真实评价！"
            v-model="userComment"
          ></textarea>
        </div>
      </div>
      <div class="innerarea innerareaOne">
        <div class="title">1.请对维修工的服务满意度评分</div>
        <div class="yesOno">
          <div
            class="yes"
            :class="weixiuIndex == 1 ? 'choose zhutiColor' : ''"
            @click="weixiu(1)"
          >
            <img
              class="img"
              src="https://picture.bjbjkj.top/image/yes1.png"
              alt=""
            />
            满意
          </div>
          <div
            class="no"
            :class="weixiuIndex == 2 ? 'choose zhutiColor' : ''"
            @click="weixiu(2)"
          >
            <img
              class="img"
              src="https://picture.bjbjkj.top/image/no1.png"
              alt=""
            />
            不满意
          </div>
        </div>
        <div class="title">2.您对维修工还有什么建议或意见吗？（非必填）</div>
        <div class="textareaBox">
          <textarea
            name="weixiu"
            id=""
            class="textarea"
            placeholder="请填写您对维修工的真实评价！"
            v-model="userComment2"
          ></textarea>
        </div>
      </div>
      <div class="Tips">
        <van-icon name="volume-o" class="zhutiColor" />
        <div class="text zhutiColor">
          本次快修评价保留七日权限，逾期未做评价将自动评价为满意
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  components: {},
  props: {
    maintenance_type: {
      type: [String, Number],
    },
    obj: {
      type: [String, Number, Object],
    },
    obj1: {
      type: [String, Number, Object],
    },
    identity: {
      type: [String, Number],
    },
    serverTime1: {
      type: [String, Number],
    },

  },
  data() {
    return {
      kanchaIndex: 1,
      weixiuIndex: 1,
      userComment:'',
      userComment2:'',
    };
  },
  watch: {},
  computed: {},
  methods: {
    kancha(e){
      if(e == this.kanchaIndex)return;

      var date1=new Date(this.obj.confirm_time);
      var date2=new Date(this.serverTime1*1000);
      var date3=(date2.getTime()-date1.getTime())/1000;//相差秒数
      if(date3>60*60*24*7){
        console.log("开始时间与结束时间间隔大于7天！");
        Toast('不能评价')
        return
      }
      this.kanchaIndex = e
    },
    weixiu(e){
      if(e == this.weixiuIndex)return;
      var date1=new Date(this.obj.confirm_time);
      var date2=new Date(this.serverTime1*1000);
      var date3=(date2.getTime()-date1.getTime())/1000;//相差秒数
      if(date3>60*60*24*7){
        console.log("开始时间与结束时间间隔大于7天！");
        Toast('不能评价')
        return
      }
      this.weixiuIndex = e
    },
  },
  created() {},
  async mounted() {


    this.$nextTick(()=>{
      this.userComment = this.obj.content
      this.userComment2 = this.obj.contents
      if(this.obj.authority_comment){
        this.obj.authority_comment.forEach(item=>{
          if(item.authority_user_id==this.obj.authority_user_id){
            this.kanchaIndex = item.status
          }
          if(item.authority_user_id==this.obj.maintenance_user_id){
            this.weixiuIndex = item.status
          }
        })
      }
      console.log(this.obj)

    })
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0 0;
}
.innerareaOne {
  padding: 10px;
  margin: 16px;
  .title {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
  }
  .yesOno {
    display: flex;
    justify-content: space-between;
    text-align: center;
    .yes,
    .no {
      display: flex;
      align-items: center;
      justify-content: center;
      // justify-content: space-evenly;
      width: 45%;
      background: #f9f9f7;
      padding: 10px 0;
      border-radius: 10px;
      color: #999999;
      font-size: 12px;
      .img {
        width: 20px;
        height: 20px;
        margin-right: 16px;
      }
    }
    .choose {
      background: rgba(255, 236, 228, 0.5);
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #ff9667;
    }
  }
  .textareaBox {
    width: 100%;
    text-align: left;
    .textarea {
      background: #f9f9f7;
      border-radius: 10px 10px 10px 10px;
      margin-top: 10px;
      padding: 5px 10px;
      color: #1b1b1b;
      font-size: 14px;
      width: 95%;
    }
  }
}
.Tips {
  background: rgba(255, 236, 228, 0.5);
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ff9667;
  margin: 0 16px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  .text {
    text-align: left;
    font-size: 12px;
    margin-left: 10px;
  }
}
</style>