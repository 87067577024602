<script>
import invite_tabbar from "@/components/common/tabbar/invite_tabbar.vue";
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Swiper from "swiper";
import carditem from "@/components/common/invite_job/cardItem/cardItem.vue";
import {_throttle} from "@/utils/tool";
import {Job_list} from "@/utils/api";
import {Toast} from "vant";
import ExpectJob from "@/components/common/invite_job/expect_job/expect_job.vue";

export default {
  name: "store_recruitment",
  components: {
    ExpectJob,
    carditem,
    NavigationBar,
    invite_tabbar
  },
  data() {
    return {
      location: {
        address: '北京',
      },
      swiper: {},

      navList:[
        {text:'最新发布',},
        {text:'热招岗位',},
        {text:'急招岗位',},
        {text:'兼职专区',},
        {text:'岗位筛选',},
      ],
      chooseNav:0,
      ulWidth:0,

      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      page:0,
      limit:10,
      industry_id:'',
      industry_type_id:2,
      choosePositionObj: {},
      expect_job_show:false,

      list:[],
    }
  },
  computed:{
    page_view(){
      let str = ''
      if(this.chooseNav == 1){
        str = 'desc'//热招
      }
      if(this.chooseNav == 2){
        str = 'asc'//急招
      }
      return str
    },
    ctime_status(){
      let str = ''
      if(this.chooseNav == 0){
        str = 1//热招
      }
      return str
    },
    job_type(){
      let str = 1
      //工作类型 1或者 2 1=全职 2=兼职
      if(this.chooseNav == 3){
        str = 2
      }
      return str
    }
  },
  methods:{
    chooseNavFun(index){





      if(this.chooseNav == index)return;
      if(index == this.navList.length-1){
        this.expect_job_show = true
        return;
      }
      this.chooseNav=index;
      this.list = []
      this.page = 0
      this.qingqiu()

    },

    containerScroll(event){
      // const dialog = event.target
      // if(dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight){
      //     console.log('触底');
      // }
      //   console.log(event);
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      //   console.log(scrollStep, this.oldScrollTop);
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const { scrollHeight, clientHeight, scrollTop } = event.target;
        // console.log(scrollHeight - (scrollTop + clientHeight));
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          // console.log((scrollHeight - (scrollTop + clientHeight)) / clientHeight);
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    qingqiu: _throttle(async function () {
      this.page+=1
      Job_list(this.$store.state.token,this.page,this.limit,this.page_view,this.ctime_status,'',this.job_type,this.chooseNav == this.navList.length-1?this.industry_id:'',this.industry_type_id).then(res=>{
        if(res.data.status == true){
          console.log(res.data.data)
          let list = res.data.data.data
          list.forEach(item=>{
            item.benefitsLi = item.benefits.split(',')
          })
          this.list = this.list.concat(list)
          if (this.list >= res.data.data.total) {
            this.hasMore = false
          }
          this.loadLock = false
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },500),
    godetails(item){
      this.$router.push({
        path:'/invite_details',
        query:{item:JSON.stringify(item)}
      })
    },
    closeExpoect(e) {
      this.expect_job_show = e
    },
    choosePosition(e) {
      if(this.disable == true)return
      this.choosePositionObj = e
      this.navList[this.navList.length-1].text = e.industry_name
      this.expect_job_show = false
      this.chooseNav = this.navList.length-1
      this.industry_id = e.id
    },
    chooseIndustry_type_id(e){
      this.list = []
      this.page = 0
      this.industry_type_id = e
      this.qingqiu()
    },
  },
  mounted() {
    this.swiper = new Swiper('.swiper', {
      pagination: {
        el: ".swiper-pagination",
      },
      loop: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });
    this.ulWidth = this.$refs.ul.offsetWidth;
    this.qingqiu()
  },
  created() {

  }
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="''" class="navigationBar" :particular="'idle'">
        <template #search>
          <div class="searchBox">
            <div class="left"><span class="iconfont icon-weizhi1"></span>{{ location.address }}
              <van-icon name="arrow-down" class="icon"/>
            </div>
            <div class="right">
              <div class="searchOut">
                <div class="leftIcon"><span class="iconfont icon-Group"></span></div>
                <div class="text">搜索职位/公司</div>
              </div>
            </div>
          </div>
        </template>
      </navigationBar>

      <ExpectJob
          class="expect_job"
          @closePop="closeExpoect"
          @choosePosition="choosePosition"
          @chooseTop="chooseIndustry_type_id"
          :invalidObj="choosePositionObj"
          v-if="expect_job_show"
          :home="'recruitment'"
      ></ExpectJob>

      <div class="swiper publicitySwiper">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in 3" :key="index"
               class="swiper-slide"
               :style="`background-image:url(${$store.state.imageHost}/tp2024071803001500257731617.png)`"
          ></div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination">
        </div>
      </div>
      <div class="nav">
        <div class="ul" ref="ul">
          <div class="line" :style="'transform: translateX('+(((((ulWidth/navList.length)/2).toFixed(0)*(chooseNav+1))+(((ulWidth/navList.length)/2).toFixed(0)*chooseNav))-10)+'px) translateX(-50%);transition-duration: 0.3s;'"></div>
          <div class="li" v-for="(item,index) in navList" :key="index" :class="chooseNav==index?'chooseNav':''" @click="chooseNavFun(index)">
            <span class="text">{{item.text}}<span class="iconfont icon-a-Frame3274" v-if="index == navList.length-1"></span></span>
          </div>
        </div>
      </div>

      <div class="list">
        <div class="ul">
          <div class="li">
            <carditem v-for="(item,index) in list" :key="index" :item="item" @godetailsSon="godetails"></carditem>
          </div>
          <div class="loader-box">
            <div id="loader" v-if="loadLock"></div>
            <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
          </div>
        </div>
      </div>
      <invite_tabbar :active="1"></invite_tabbar>
    </div>
  </div>
</template>

<style scoped lang="scss">
.expect_job {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff!important;
  left: 0;
  top: 0;
}
.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  margin: 10px 16px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .swiper-slide {
    max-height: 180px;
  }


  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    //background: rgba(51, 51, 51, 0.75);
    width: 100%;
    // right: 10px;
    border-radius: 64px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  //.text{
  //  color: #333333;
  //  font-weight: 400;
  //  font-size: 12px;
  //  line-height: 17px;
  //}

}

.swiper-wrapper {

  .page {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .every {
      width: 20%;
    }
  }
}

.publicity .swiper-slide {
  max-height: 88px;
}

.container {
  background: #f4f6fa;
  height: 100vh;
}

.navigationBar {
  background-image: url("https://picture.bjbjkj.top/img/inviteMask.png");
  background-repeat: no-repeat;
  background-size: 100%;

  .searchBox {
    display: flex;
    width: 80%;

    .left {
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 25px;
      }

      .icon {
        margin-left: 5px;
      }
    }

    .right {

      flex: 1;
      margin-left: 10px;

      .searchOut {
        border-radius: 20px;
        padding: 4px 13px;
        background-color: white;
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #8F8F8F;
        line-height: 24px;
        .leftIcon {
          margin-right: 10px;
          .iconfont {
            font-size: 15px;
          }
        }
      }
    }
  }
}


.nav{

  .ul{
    text-align: left;
    margin: 10px 16px;
    display: flex;
    position: relative;
    justify-content: space-between;
    .li{
      text-align: center;
      color: #55565a;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: -webkit-flex;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-block;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      position: relative;

      .text{
        display: inline-block;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .iconfont{
        vertical-align: middle;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(28%,-50%);
        font-size: 22px;
        margin-left: -5px;
      }
    }
    .li:nth-last-of-type(1){
      padding-right: 10px;
    }
    .chooseNav{
      text-align: center;
      color: #0393f5;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    .line{
      position: absolute;
      width: 24px;
      height: 2px;
      border-radius: 17px;
      background: #0393f5;
      bottom: -4px;
      left: 0;
    }

  }
  .vantabs{
    margin: 10px 16px;

  }
  //.van-tabs__nav{
  //  background-color: red;
  //}
}
.list{
  padding-top: 1px;
  padding-bottom: 100px;
}
</style>