<template>
  <div>
    <div class="container">
      <div class="area">
        <navigationBar
          :title="'选择发布类型'"
          :backBtn="false"
          class="navigation"
        ></navigationBar>
        <div class="topTitle">
          <div class="left">商业用地</div>
          <div class="right">发布规则</div>
        </div>

        <div class="chooseType">
          <van-radio-group v-model="houType" class="radioGroup">
            <van-radio :name="1">
              <template #icon="props">
                <div class="radio" :class="props.checked ? 'choose' : ''"></div>
              </template>
              <template #default>
                <div class="text">个人</div>
              </template>
            </van-radio>
            <van-radio :name="2">
              <template #icon="props">
                <div class="radio" :class="props.checked ? 'choose' : ''"></div>
              </template>
              <template #default>
                <div class="text">经纪人</div>
              </template>
            </van-radio>
          </van-radio-group>
        </div>
        <div class="body">
          <div class="li" v-for="(item, index) in funLi" :key="index">
            <div class="left">
              <div class="purposeItemtitle nowarp">{{ item.name }}</div>
              <div class="purposeItemtext nowarp">{{ item.text }}</div>
            </div>
            <div class="right">
              <img class="img" :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :active="1"></tabbar>
  </div>
</template>

<script>
import tabbar from "@/components/common/tabbar/house_tabbar.vue";
// import navigationBar from "@components/navigation/navigation.vue"
import navigationBar from "../../navigation-bar/navigation-bar.vue";

export default {
  components: {
    tabbar,
    navigationBar,
  },
  props: {},
  data() {
    return {
      houType: 1,
      funLi: [
        {
          name: "商铺租赁",
          img: "https://picture.bjbjkj.top//img/shopsale.png",
          text: "门面、摊位、柜台等出租出售信息",
        },
        {
          name: "写字楼租赁",
          img: "https://picture.bjbjkj.top//img/hotle.png",
          text: "写字楼、商务公寓等出租出售信息",
        },
        {
          name: "商铺转让",
          img: "https://picture.bjbjkj.top//img/shoptransfer.png",
          text: "门面、摊位、柜台等出租转让信息",
        },
        {
          name: "住宅租赁",
          img: "https://picture.bjbjkj.top//img/dwelling.png",
          text: "门面、摊位、柜台等出租转让信息",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.area {
  padding: 0 16px;
}
.topTitle {
  display: flex;
  .left {
    font-weight: 400;
    font-size: 16px;
    color: #1b1b1b;
    line-height: 24px;
    text-align: left;
  }
  .right {
    font-weight: 400;
    font-size: 12px;
    color: #3ccfc7;
    line-height: 24px;
    text-align: center;
    padding: 0 6px;
    border-radius: 25px 25px 25px 25px;
    border: 1px solid #3ccfc7;
    margin-left: 10px;
  }
}
.radioGroup {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .radio {
    border: 1px solid #3ccfc7;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    position: relative;
  }
  .radio::after {
    display: block;
    position: absolute;
    content: "";
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 80%;
    height: 80%;
    background: #3ccfc7;
    border-radius: 50%;
    opacity: 0.5;
  }
  .choose:after {
    opacity: 1;
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    color: #1b1b1b;
    line-height: 20px;
    text-align: left;
  }
  .van-radio {
    margin-right: 10px;
  }
}
.body {
  margin-top: 20px;
  .li {
    display: flex;
    box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;
    .left {
      text-align: left;
      .purposeItemtitle {
        font-weight: 400;
        font-size: 16px;
        color: #1b1b1b;
        line-height: 24px;
        text-align: left;
      }
      .purposeItemtext {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
        text-align: left;
      }
      
    }
    .right {
      width: 48px;
      height: 48px;
      .img {
        width: 48px;
        height: 48px;
      }
    }
  }
}
</style>