<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'用户信息'" class="navigation"></navigationBar>
      <div class="card">
        <div class="line" @click="setAvatar">
          <div class="left">
            修改头像
          </div>
          <div class="right">
            <img class="avatar" src="https://picture.bjbjkj.top/tp2024062803111511572202305.png" alt="">
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
        <div class="line" @click="setNickName">
          <div class="left">修改昵称</div>
          <div class="right">
            试试
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="line" @click="serPssword">
          <div class="left">修改密码</div>
          <div class="right">
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="line" @click='checkUserAgreement'>
          <div class="left">用户协议</div>
          <div class="right">
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
        <div class="line" @click="privacyAgreement">
          <div class="left">隐私协议</div>
          <div class="right">
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
      </div>
      <div class="bottomBtn">
        <div class="toggle btn" @click="toggle">切换账号</div>
        <div class="exit btn" @click="exitLogin">退出登录</div>
      </div>
    </div>
  </div>
</template>
<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";

export default {
  name: "userInfo",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    navigationBar
  },
  methods: {
    checkUserAgreement() {
      console.log('用户协议')
    },
    privacyAgreement() {
      console.log('隐私')
    },
    serPssword() {
      this.$router.push({
        name: 'setDetails',
        params:{title:'修改密码',type:1},
      })
    },
    setNickName() {
      this.$router.push({
        name: 'setDetails',
        params:{title:'修改昵称',nickName:'试试',type:2},
      })
    },
    setAvatar() {
      console.log('头像')
    },
    toggle() {
      console.log('切换账号')
    },
    exitLogin() {
      console.log('退出登录')
    },
  }
}
</script>


<style scoped lang="scss">
container {
  background: #F8F8F8;
}

.area {
  background: #F8F8F8;
  min-height: 100vh;
}

.bottomBtn {
  position: absolute;
  bottom: 10px;
  left: 0%;
  width: 100%;

  .btn {
    background: #FFFFFF;
    margin: 10px 16px;
    border-radius: 10px;
    padding: 13px;
    font-weight: 400;
    font-size: 15px;
    color: #1B1B1B;
    line-height: 24px;
  }
}

.navigation {
  background: white !important;
}

.card {
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin: 10px 16px;
  padding: 0 10px;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F7F7F7;
    padding: 16px 0;
    font-family: PingFang SC, PingFang SC;

    .left {
      font-weight: 400;
      font-size: 15px;
      color: #1B1B1B;
      line-height: 24px;
    }

    .right {
      display: flex;
      align-items: center;
      color: #999999;
      font-size: 14px;

      .iconfont {
        font-size: 24px;
        color: #B8B8B8;
      }

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .line:nth-last-of-type(1) {
    border-bottom: 1px solid white;
  }
}


</style>