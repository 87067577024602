<template>
  <div class="house" @scroll.passive="houseScroll">
    <div class="container">
      <div class="backgImg"></div>
      <div class="area">
        <navigationBar :particular="2">
          <template #search>
            <div class="searchBox">
              <div class="addressText">北京</div>
              <div class="iconfont icon-Frame12"></div>
              <div class="line"></div>
              <div class="placeHolder">你想搜哪里呢？</div>
            </div>
          </template>
        </navigationBar>
        <div class="topswiper">
          <div class="swiperOut">
            <div class="swiper">
              <div class="swiper-wrapper">
                <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd.png" alt="" class="img"></div> -->
                <div
                  class="swiper-slide"
                  :style="'background-image:url(https://picture.bjbjkj.top/tp2024062702331433414434306.jpeg)'"
                ></div>
                <div
                  class="swiper-slide"
                  :style="'background-image:url(https://picture.bjbjkj.top/tp2024062702331433414434306.jpeg)'"
                ></div>
                <div
                  class="swiper-slide"
                  :style="'background-image:url(https://picture.bjbjkj.top/tp2024062702331433414434306.jpeg)'"
                ></div>
                <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd1.png" alt="" class="img"></div> -->
              </div>
              <!-- 如果需要分页器 -->
              <div class="pagination">
                <div class="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="funBox">
          <div class="li" v-for="(item, index) in funList" :key="index">
            <img class="img" :src="item.img" alt="" v-lazy="item.img"/>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>

        <div class="houseBody">
          <houseList :list="list1"></houseList>
        </div>
      </div>

      <div style="width: 100%; height: 100px; background: #fff"></div>
      <tabbar :active="0"></tabbar>
    </div>
  </div>
</template>

<script>
import navigationBar from "../../navigation-bar/navigation-bar.vue";
import tabbar from "@/components/common/tabbar/house_tabbar.vue";
import houseList from "./house_list/house_list.vue";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";

import { _throttle } from "../../../utils/tool.js";
export default {
  components: {
    navigationBar,
    tabbar,
    houseList,
  },
  props: {},
  data() {
    return {
      swiper: {},
      funList: [
        { name: "商铺租赁", img: "https://picture.bjbjkj.top//img/house1.png" },
        {
          name: "写字楼租赁",
          img: "https://picture.bjbjkj.top//img/house2.png",
        },
        { name: "商铺转让", img: "https://picture.bjbjkj.top//img/house3.png" },
        { name: "业主房源", img: "https://picture.bjbjkj.top//img/house4.png" },
        { name: "住宅租赁", img: "https://picture.bjbjkj.top//img/house5.png" },
      ],
      list1: [
        {
          name: "碑林",
          img: "https://picture.bjbjkj.top/tp202407060300150052350336.png",
          tag: "商铺租赁",
          price: "4",
        },
        {
          name: "300m²临街店铺出租300m²超级搜索",
          img: "https://picture.bjbjkj.top/tp2024070601521352037796144.png",
          tag: "写字楼租赁",
          price: "5",
        },
        {
          name: "三里屯",
          img: "https://picture.bjbjkj.top/tp2024070601461346329228381.png",
          tag: "商铺转让",
          price: "6",
        },
        {
          name: "碑林",
          img: "https://picture.bjbjkj.top/tp202407060300150052350336.png",
          tag: "商铺租赁",
          price: "7",
        },
        {
          name: "300m²临街店铺出租300m²超级搜索",
          img: "https://picture.bjbjkj.top/tp2024070601521352037796144.png",
          tag: "写字楼租赁",
          price: "8",
        },
        {
          name: "三里屯",
          img: "https://picture.bjbjkj.top/tp2024070601461346329228381.png",
          tag: "商铺转让",
          price: "9",
        },
        {
          name: "碑林",
          img: "https://picture.bjbjkj.top/tp202407060300150052350336.png",
          tag: "商铺租赁",
          price: "91",
        },
        {
          name: "300m²临街店铺出租300m²超级搜索",
          img: "https://picture.bjbjkj.top/tp2024070601521352037796144.png",
          tag: "写字楼租赁",
          price: "912",
        },
        {
          name: "三里屯",
          img: "https://picture.bjbjkj.top/tp2024070601461346329228381.png",
          tag: "商铺转让",
          price: "9123",
        },
      ],
      // 滚动前，滚动条距文档顶部的距离
      oldScrollTop: 0,
      page:1,
    };
  },
  watch: {},
  computed: {},
  methods: {
    houseScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const { scrollHeight, clientHeight, scrollTop } = event.target;
        if (
          (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
          0.05
        ) {
          this.getQingqiu();
        }
      }
    },
    getQingqiu: _throttle(function () {
        // console.log("触底",this);
        if(this.page<=2){
            this.list1 = this.list1.concat(this.list1)
            this.page +=1
        }
    }, 2000),
  },
  created() {},
  mounted() {
    this.swiper = new Swiper(".swiper", {
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
      loop: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });

    // window.onscroll = (e)=>{
    // console.log(123,e);
    // }
  },
};
</script>
<style lang="scss" scoped>
.house {
  overflow: scroll;
  height: 100vh;
}
.container {
  position: relative;
  .backgImg {
    position: absolute;
    width: 100%;
    height: 280px;
    background: linear-gradient(180deg, #f2fffe, #ffffff) !important;
  }
}
.area {
  position: relative;
  z-index: 2;
}
.searchBox {
  display: flex;
  background: #ffffff;
  border-radius: 30px 30px 30px 30px;
  border: 1px solid #3ccfc7;
  width: 85%;
  align-items: center;
  .addressText {
    margin-left: 15px;
    font-size: 12px;
  }
  .iconfont {
    color: #3ccfc7;
  }
  .line {
    height: 10px;
    background: #3ccfc7;
    width: 2px;
    margin: 0 10px 0 6px;
  }
  .placeHolder {
    font-weight: 400;
    font-size: 14px;
    color: #c7c7c7;
    line-height: 24px;
    padding: 6px 0;
  }
}

.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}
.swiper {
  margin: 10px 16px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .swiper-slide {
    max-height: 180px;
  }

  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}
.funBox {
  white-space: nowrap;
  .li {
    display: inline-block;
    width: 20%;
    .img {
      width: 34px;
      height: 34px;
    }
    .name {
      font-weight: 400;
      font-size: 12px;
      color: #1b1b1b;
      line-height: 14px;
    }
  }
}
.houseBody {
  border-top: 10px solid #f5f6f7;
  margin: 0 16px;
}
</style>