<script>
import {Toast} from "vant";

export default {
  name: "addContact",
  data(){
    return{
      form:{
        name:'',
        mobile:'',
      },
    }
  },
  methods: {
    close(){
      this.$emit('closePop', false);
    },
    submit() {
      if(this.form.name==''){
        Toast('请输入姓名')
      }
      if(this.form.name==''){
        Toast('请输入电话')
      }

      this.$emit('submit', this.form);
    },
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <div class="top">
        <div class="backBtn" @click="close">
          <span class="iconfont icon-Frame-4"></span>
        </div>
        <div class="title">
          <div class="btn" @click="submit">确定</div>
        </div>
      </div>

      <div class="form">
          <div class="line">
            <div class="title">姓名</div>
            <input type="text" class="" v-model="form.name" placeholder="请添加新联系人姓名" maxlength="10" >
          </div>
        <div class="line">
            <div class="title">电话</div>
            <input type="text" class="" v-model="form.mobile" placeholder="请添加新联系人电话">
          </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.area{
  height: 100vh;
  position: relative;
}
.top{
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0;
  .backBtn{
    width: 10%;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
      font-size: 36px;
    }
  }
  .title{
    width: 85%;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
.form{
  margin: 10px 16px;
  text-align: left;
  .line{
    border-bottom: 1px solid #99999926;
    padding-bottom: 10px;
    .title{
     padding-bottom: 10px;
     padding-top: 10px;
    }
    input{
      border: none;
    }
  }
}
</style>