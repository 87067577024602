<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";

export default {
  name: "invoiceManagement",
  components:{
    navigationBar
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'发票管理'" class="navigationBar"></navigationBar>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>