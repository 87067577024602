<script>
// import idle_tabbar from "@/components/common/tabbar/idle_tabbar.vue";

import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Compressor from "compressorjs";
import {
  getCategory,
  getRegAuthentDetail,
  getUserShop,
  publish,
  updatePublish,
  upload_file,
  upload_files
} from "@/utils/api";
import {Dialog, Toast} from "vant";

export default {
  name: "publish",
  components: {navigationBar},
  data() {
    return {
      ruleForm: {
        title: '',//发布标题
        information: '',//宝贝描述
        name: '',//分类
        name_id: '',//分类id
        price: '',//价格
        pay_status: '自提',//交易方式
        brand: '',//品牌
        qualit_guarantee: 2,//是否质保
        model: '',//型号
        use_time: '',//使用时间
        invoice: 2,//是否 发票
        advance: 2,//是否 预售
        advance_time: '',//预售时间
        original_price: '',//原价
        address: '',//取件地址
        address_details:'',//详细地址
        pay_time: '',//交易时间
        shop_name: '',//交易时间
      },
      rules: {
        name: [
          {required: true, message: '请选择分类', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        title: [
          {required: true, message: '请输入发布标题', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        information: [
          {required: true, message: '请输入宝贝描述', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        price: [
          {required: true, message: '请输入价格', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        pay_status: [
          {required: true, message: '请选择交易方式', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        qualit_guarantee: [
          {required: true, message: '请选择是否质保', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        use_time: [
          {required: true, message: '请选择使用时间', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        invoice: [
          {required: true, message: '是否有发票', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        advance: [
          {required: true, message: '是否预售', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        advance_time: [],

        address: [
          {required: true, message: '请选择取件地址', trigger: 'blur'},
        ],
        address_details: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
        pay_time: [
          {required: true, message: '请选择交易时间', trigger: 'blur'},
        ],
      },
      infomationLoad: [],
      infomationFiles: [],
      imfomationLimit: 9,
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],


      popType: '', //classification 分类     transaction_mode 交易方式    hours_of_use 使用时间   advance_time_P 预售时间   pay_time_P 交易时间   choose_address_P 选择地址
      popShow: false,
      columns: [],
      KongLi: [],

      PickerChangeValue: [],//分类



      pay_status_list: ['自提'],//交易方式
      use_time_list: ['3月以内', '1年以内', '1-3年', '3-5年', '5年以上'],//使用时间
      useTimeChangeValue: [],
      currentDate: new Date(),
      advance_currentDate: new Date(),
      minDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()),
      maxDate: new Date(new Date().getFullYear() + 1, new Date().getMonth() + 1, new Date().getDate()),


      pay_time_li: [
        // 第一列
        {
          values: ['00：00', '01：00', '02：00', '03：00', '04：00', '05：00', '06：00', '07：00', '08：00', '09：00', '10：00', '11：00', '12：00', '13：00', '14：00', '15：00', '16：00', '17：00', '18：00', '19：00', '20：00', '21：00', '22：00', '23：00'],
          values1: ['00：00', '01：00', '02：00', '03：00', '04：00', '05：00', '06：00', '07：00', '08：00', '09：00', '10：00', '11：00', '12：00', '13：00', '14：00', '15：00', '16：00', '17：00', '18：00', '19：00', '20：00', '21：00', '22：00', '23：00'],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: ['01：00', '02：00', '03：00', '04：00', '05：00', '06：00', '07：00', '08：00', '09：00', '10：00', '11：00', '12：00', '13：00', '14：00', '15：00', '16：00', '17：00', '18：00', '19：00', '20：00', '21：00', '22：00', '23：00','00：00' ],
          values1: ['01：00', '02：00', '03：00', '04：00', '05：00', '06：00', '07：00', '08：00', '09：00', '10：00', '11：00', '12：00', '13：00', '14：00', '15：00', '16：00', '17：00', '18：00', '19：00', '20：00', '21：00', '22：00', '23：00','00：00' ],
          defaultIndex: 0,
        },
      ],
      payTimeValue:[],
      address_li:this.$store.state.allAddress,
      chooseAddress:[],
      showShop:false,
      logoUpload:"",

      loadObj:{},
    }
  },
  methods: {
    async submitForm(formName) {

      if(!this.$store.state.token){
          Dialog.confirm({
            title: '您还未登录，请先登录',
            confirmButtonText:'去登录'
          })
              .then(() => {
                // on confirm
                this.$router.push('/login')
              })
              .catch(() => {
                // on cancel
              });
        return
      }
      const {data} = await getRegAuthentDetail(this.$store.state.token)
      if(data.status == true){
        let infomation = data.data
        if(!infomation){
          Dialog.confirm({
            title: '您还未进行身份认证，请前往认证',
            confirmButtonText:'去认证'
          })
              .then(() => {
                // on confirm
                this.$router.push({path:'/idle_certification',query:{type:'personage'}})

              })
              .catch(() => {
                // on cancel
              });
          return
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // title: '',//发布标题
            // information: '',//宝贝描述
            // name: '',//分类
            // price: '',//价格
            // pay_status: '自提',//交易方式
            // brand: '',//品牌
            // qualit_guarantee: 2,//是否质保
            // model: '',//型号
            // use_time: '',//使用时间
            // invoice: 2,//是否 发票
            // advance: 2,//是否 预售
            // advance_time: '',//预售时间
            // original_price: '',//原价
            // address: '',//取件地址
            // address_details:'',//详细地址
            // pay_time: '',//交易时间

            this.submitAxios()



          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }

    },

    submitAxios(){
      if(this.loadObj.id){
        updatePublish(
            this.$store.state.token,
            this.ruleForm.title,
            this.ruleForm.name_id,
            String(this.infomationLoad),
            this.ruleForm.price,
            this.ruleForm.original_price,
            this.ruleForm.brand,
            this.ruleForm.qualit_guarantee,
            this.ruleForm.model,
            this.ruleForm.use_time,
            this.ruleForm.invoice,
            this.ruleForm.advance,
            this.ruleForm.advance_time,
            this.ruleForm.address,
            this.ruleForm.address_details,
            this.ruleForm.information,
            this.ruleForm.pay_time,
            this.loadObj.id,
        ).then(res=>{
          console.log(res)
          if(res.data.status){
            Toast('发布成功')
            this.$router.go(-1)
          }else{
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })

      }else{
        publish(
            this.$store.state.token,
            this.ruleForm.title,
            this.ruleForm.name_id,
            String(this.infomationLoad),
            this.ruleForm.price,
            this.ruleForm.original_price,
            this.ruleForm.brand,
            this.ruleForm.qualit_guarantee,
            this.ruleForm.model,
            this.ruleForm.use_time,
            this.ruleForm.invoice,
            this.ruleForm.advance,
            this.ruleForm.advance_time,
            this.ruleForm.address,
            this.ruleForm.address_details,
            this.ruleForm.information,
            this.ruleForm.pay_time,
        ).then(res=>{
          console.log(res)
          if(res.data.status){
            // Toast('发布成功')
            Dialog.confirm({
              title: '宝贝已发布成功,是否继续添加宝贝',
            }).then(()=>{
              this.resetForm('ruleForm')
              this.infomationLoad = []
              this.infomationFiles = []
            }).catch(()=>{
              this.$router.go(-1)
            })
          }else{
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })

      }
    },
   async submitFormAndAgin(formName) {
     if(!this.$store.state.token){
       Dialog.confirm({
         title: '您还未登录，请先登录',
         confirmButtonText:'去登录'
       })
           .then(() => {
             // on confirm
             this.$router.push('/login')
           })
           .catch(() => {
             // on cancel
           });
       return
     }
     const {data} = await getRegAuthentDetail(this.$store.state.token)
     if(data.status == true) {
       let infomation = data.data
       if(!infomation){
         Dialog.confirm({
           title: '您还未进行身份认证，请前往认证',
           confirmButtonText:'去认证'
         })
             .then(() => {
               // on confirm
               this.$router.push({path:'/idle_certification',query:{type:'personage'}})
             })
             .catch(() => {
               // on cancel
             });
         return
       }
       this.$refs[formName].validate((valid) => {
         if (valid) {
           Dialog.confirm({
             title: '确认发布并继续添加宝贝吗',
           })
               .then(() => {
                 publish(
                     this.$store.state.token,
                     this.ruleForm.title,
                     this.ruleForm.name_id,
                     JSON.stringify(this.infomationLoad),
                     this.ruleForm.price,
                     this.ruleForm.original_price,
                     this.ruleForm.brand,
                     this.ruleForm.qualit_guarantee,
                     this.ruleForm.model,
                     this.ruleForm.use_time,
                     this.ruleForm.invoice,
                     this.ruleForm.advance,
                     this.ruleForm.advance_time,
                     this.ruleForm.address,
                     this.ruleForm.address_details,
                     this.ruleForm.information,
                     this.ruleForm.pay_time,
                 ).then(res=>{
                   console.log(res)
                   if(res.data.status){
                     Toast('发布成功')
                     this.resetForm(formName)
                     this.infomationLoad = []
                     this.infomationFiles = []
                   }else{
                     Toast(res.data.msg)
                   }
                 }).catch(err=>{
                   console.log(err)
                 })
               })
               .catch(() => {
                 // on cancel
               });
         } else {
           console.log('error submit!!');
           return false;
         }
       });
     }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    choose_type() {
      this.popShow = true
      this.popType = 'classification'
    },
    pay_statusFun() {
      this.popShow = true
      this.popType = 'transaction_mode'
    },
    choose_use_time() {
      this.popShow = true
      this.popType = 'hours_of_use'
    },
    choose_advance_time() {
      this.popShow = true
      this.popType = 'advance_time_P'
    },
    choose_address() {
      this.popShow = true
      this.popType = 'choose_address_P'
    },
    choose_pay_time() {
      this.popShow = true
      this.popType = 'pay_time_P'
    },


    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 22) {
              this.infomationLoad.push(res.data.data.saveurl)
            }
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push(res.data.data.saveurl)
            }
            if (item.filename == 22) {
              this.DoorUpload.push(res.data.data.saveurl)
            }
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange2(file, infomationFiles) {
      let list = []
      infomationFiles.forEach(item => {
        const FileExt = item.name.replace(/.+\./, "").toLowerCase();
        if (this.fileType.includes(FileExt)) {
          list.push(item)
          return true;
        } else {
          return false;
        }
      })
      this.infomationFiles = list;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.yanshoufileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    deleteImg(list, index, fileList) {
      list.splice(index, 1)
      fileList.splice(index, 1)
    },


    getCategory() {
      getCategory(this.$store.state.token).then(res => {
        if (res.data.status) {
          let list = res.data.data
          list.forEach(item => {
            console.log(item)
          })
          this.KongLi = list
          this.columns = list
        }
      }).catch(err => {
        console.log(err)
      })
    },

    onPickerChange(picker, values) {
      console.log(values)
      this.PickerChangeValue = values
    },
    onUseTimeChange(picker, values) {
      this.useTimeChangeValue = values
    },
    onPayTimeChange(picker, values) {
      this.payTimeValue = values
      // this.$nextTick(()=>{
      //   this.$nextTick(()=>{
          console.log(values)

      //   })
      // })
    },
    onPayTimeConfirm(value, index){
      console.log(value, index)
    },
    closePop() {
      this.popShow = false
    },
    savePop() {
      if (this.popType == 'classification') {
        if(this.$refs.classification.getValues()[0].id!=3&&this.$refs.classification.getValues()[0].id!=4){
          this.ruleForm.name = String(this.PickerChangeValue)
          let indexLI = this.$refs.classification.getIndexes()
          if(this.columns[indexLI[0]].children){
            this.ruleForm.name_id = this.columns[indexLI[0]].children[indexLI[1]].id
          }
        }else{
            this.$router.push({
              path:'/ShopTransfer',
              query:{choose:JSON.stringify(this.$refs.classification.getValues()[0])}
            })
        }
      }
      if (this.popType == 'transaction_mode') {
        this.ruleForm.pay_status = String(this.pay_status_list)
      }
      if (this.popType == 'hours_of_use') {
        this.ruleForm.use_time = String(this.useTimeChangeValue)
      }
      if (this.popType == 'advance_time_P') {
        let y = this.advance_currentDate.getFullYear()
        let m = (this.advance_currentDate.getMonth() + 1) >= 10 ? (this.advance_currentDate.getMonth() + 1) : '0' + (this.advance_currentDate.getMonth() + 1)
        let d = this.advance_currentDate.getDate() >= 10 ? this.advance_currentDate.getDate() : '0' + this.advance_currentDate.getDate()
        this.ruleForm.advance_time = y + '-' + m + '-' + d
      }
      if (this.popType == 'pay_time_P') {
        this.ruleForm.pay_time = String(this.$refs.payTime.getValues())
      }
      if(this.popType == 'choose_address_P'){
        this.chooseAddress = this.$refs.choose_address.getValues()
        this.ruleForm.address = String(this.chooseAddress.map(item=>{return item.name}))
      }
      this.popShow = false

    },

  },
  computed: {
    infomationsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.infomationLoad)).forEach(item => {
        list.push(this.$store.state.imageHost + item)
      })
      return list
    },

  },
  watch: {
    ruleForm: {
      handler(n) {
        this.showShop = false
        if(this.columns.find(item=>{return item.id == 1})){
          if((this.columns.find(item=>{return item.id == 1}).children).find(item=>{return item.id == n.name_id})){
            this.showShop = true
          }
        }
        if (n.advance == 1) {
          this.rules.advance_time = [
            {required: true, message: '请选择预售时间', trigger: 'blur'},
          ],
              console.log(this.rules)
        } else {
          this.rules.advance_time = []
        }
      },
      deep: true
    },
    popShow(n) {
      this.useTimeChangeValue = [this.use_time_list[0]]
      if (n == false) {
        this.popType = ''
        this.PickerChangeValue = [this.columns[0].cate_name, this.columns[0].children ? this.columns[0].children[0].cate_name : ""]
      }
    },
    columns: {
      handler(n) {
        n.forEach(item => {
          if (!item.children) {
            item.children = [{cate_name: '',id:item.id}]
          }
        })
        if (this.PickerChangeValue.length <= 0) {
          this.PickerChangeValue = [this.columns[0].cate_name, this.columns[0].children ? this.columns[0].children[0].cate_name : ""]
        }
      },
      deep: true
    },
    payTimeValue(n){
      this.pay_time_li[1].values = this.pay_time_li[1].values1.slice(this.pay_time_li[0].values1.findIndex(item=>{return item ==  n[0]}),this.pay_time_li[1].values1.length)
    },
  },
  mounted() {
    getUserShop(this.$store.state.token).then(res=>{
      if(res.data.status){
        if(res.data.status){
          let list = res.data.data
          list[0].title
          this.ruleForm.shop_name = list[0].title
          this.logoUpload = list[0].logo
        }
      }else{
        Toast(res.data.msg)
      }
    }).catch(err=>{
      console.log(err)
    })
    this.getCategory()

    if(this.$route.query.item){
      console.log()
      if(JSON.parse(this.$route.query.item).id){
        // title: '',//发布标题
        // information: '',//宝贝描述
        // name: '',//分类
        // price: '',//价格
        // pay_status: '自提',//交易方式
        // brand: '',//品牌
        // qualit_guarantee: 2,//是否质保
        // model: '',//型号
        // use_time: '',//使用时间
        // invoice: 2,//是否 发票
        // advance: 2,//是否 预售
        // advance_time: '',//预售时间
        // original_price: '',//原价
        // address: '',//取件地址
        // address_details:'',//详细地址
        // pay_time: '',//交易时间
        this.loadObj = JSON.parse(this.$route.query.item)


        this.ruleForm.title = JSON.parse(this.$route.query.item).product_name
        this.ruleForm.name_id = this.loadObj.category_id
        this.ruleForm.name = this.loadObj.cate_name
        this.ruleForm.information = this.loadObj.product_desc
        this.ruleForm.brand = this.loadObj.brand
        this.ruleForm.model = this.loadObj.product_mode
        this.ruleForm.use_time = this.loadObj.use_date
        this.ruleForm.address = this.loadObj.address
        this.ruleForm.address_details = this.loadObj.detail_address
        this.ruleForm.pay_time = this.loadObj.train_time
        this.ruleForm.original_price = Number(this.loadObj.origin_price)
        this.ruleForm.advance_time = this.loadObj.pre_sale_date
        this.ruleForm.advance = this.loadObj.is_sale   //是否预售 1是 2否',
        this.ruleForm.invoice = this.loadObj.is_invoice   // 发票 1是 2否',
        this.ruleForm.qualit_guarantee = Number(this.loadObj.warranty)   //',
        this.ruleForm.price = Number(this.loadObj.price)
        console.log(JSON.parse(this.$route.query.item).images.split(',').map(item=>{return this.$store.state.imageHost+item}))
        // this.ruleForm.title = JSON.parse(this.$route.query.item).images.split(',')
        this.infomationLoad = JSON.parse(this.$route.query.item).images.split(',')
        this.infomationFiles =  JSON.parse(this.$route.query.item).images.split(',').map(item => {
          return {
            name: item,
            url: item
          }
        });
        console.log(JSON.parse(this.$route.query.item))

      }
    }
  }
}
</script>

<template>
  <div class="container  idleContainer">
    <div class="area">
      <navigationBar :title="'发闲置'"></navigationBar>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
        <div class="card" @click.stop="choose_type">
          <el-form-item label="选择分类" prop="name" @click.stop="choose_type">
            <div class="inn" @click.stop="choose_type">
              <el-input v-model="ruleForm.name" placeholder="请选择"></el-input>
              <span class="iconfont icon-Frame-4"></span>
              <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
            </div>
          </el-form-item>
        </div>
        <div class="shopAuthentication" v-if="showShop">
          <div>
            <div class="cont" >
              <div class="title">店铺认证<span class="text">（如发布店铺闲置宝贝，务必填写完整信息）</span></div>
              <!--          <div class="line">-->
              <!--            <div class="left">店铺名称</div>-->
              <!--            <input type="text" class="right" placeholder="请填写" v-model="ruleForm.shop_name">-->
              <!--          </div>-->
              <el-form-item label="店铺名称" prop="shop_name">
                <div class="inn">
                  <el-input v-model="ruleForm.shop_name" type="text" placeholder="请输入" max="22" maxlength="22"></el-input>
                </div>
              </el-form-item>
              <div class="title" style="border-bottom: none">店铺LOGO</div>
              <div class="uploadBox">
                <el-image
                    v-if="logoUpload"
                    class="showImg"
                    fit="cover"
                    :src="$store.state.imageHost+logoUpload"
                    :preview-src-list="[$store.state.imageHost+logoUpload]">
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardTwo inputBox">
          <div class="top">
            <el-form-item label="" prop="title">
              <input type="text" placeholder="选择发布标题（22字以内）" class="title" v-model="ruleForm.title"
                     maxlength="22">
            </el-form-item>
          </div>
          <div class="body">
            <el-form-item label="" prop="information">
              <el-input type="textarea" v-model="ruleForm.information" placeholder="描述一下宝贝的信息"></el-input>
            </el-form-item>
          </div>
          <div class="img_box">
            <div class="img_list">
              <div class="evert li" v-for="(item,index) in infomationLoad" :key="index">
                <el-image
                    class="avatar"
                    fit="cover"
                    :src="$store.state.imageHost+item"
                    :preview-src-list="infomationsrcList">
                </el-image>
                <van-icon name="clear" class="icon" @click.stop="deleteImg(infomationLoad,index,infomationFiles)"/>
              </div>
              <el-upload
                  v-if="infomationLoad.length<=8"
                  class="avatar-uploader"
                  action
                  :http-request="uploadFile"
                  ref="upload"
                  :limit="imfomationLimit"
                  :on-remove="handleRemove"
                  :file-list="infomationFiles"
                  :on-exceed="handleExceed"
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                  :headers="headers"
                  :on-change="fileChange2"
                  multiple
                  name="22"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </div>

        <div class="card priceBox">
          <el-form-item label="价格" prop="price">
            <div class="inn">
              <el-input v-model="ruleForm.price" type="number" placeholder="请输入" maxlength="10"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="交易方式" prop="pay_status" @click="pay_statusFun">
            <div class="inn">
              <el-input v-model="ruleForm.pay_status" placeholder="请选择"></el-input>
              <span class="iconfont icon-Frame-4"></span>
              <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"
                   @click="pay_statusFun"></div>
            </div>
          </el-form-item>
        </div>

        <div class="card lastBox">
          <el-form-item label="品牌" prop="brand">
            <div class="inn">
              <el-input v-model="ruleForm.brand" type="text" placeholder="请输入"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="是否质保" prop="qualit_guarantee">
            <el-radio-group v-model="ruleForm.qualit_guarantee">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="型号" prop="model">
            <div class="inn">
              <el-input v-model="ruleForm.model" type="text" placeholder="请输入"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="使用时间" prop="use_time" @click="choose_use_time">
            <div class="inn" @click="choose_use_time">
              <el-input v-model="ruleForm.use_time" placeholder="请选择"></el-input>
              <span class="iconfont icon-Frame-4"></span>
              <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
            </div>
          </el-form-item>
          <el-form-item label="发票" prop="invoice">
            <el-radio-group v-model="ruleForm.invoice">
              <el-radio :label="1">有</el-radio>
              <el-radio :label="2">无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="预售" prop="advance">
            <el-radio-group v-model="ruleForm.advance">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
            <el-form-item label="" prop="advance_time" v-if="ruleForm.advance == 1">
              <div class="inn" @click="choose_advance_time">
                <el-input v-model="ruleForm.advance_time" placeholder="请选择预售时间"></el-input>
                <span class="iconfont icon-Frame-4"></span>
                <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
              </div>
            </el-form-item>
          </el-form-item>
          <el-form-item label="物品原价" prop="original_price">
            <div class="inn">
              <el-input v-model="ruleForm.original_price" type="number" placeholder="请输入"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="取件地址" prop="address" @click="choose_address">
            <div class="inn" @click="choose_address">
              <el-input v-model="ruleForm.address" placeholder="请选择"></el-input>
              <span class="iconfont icon-Frame-4"></span>
              <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
            </div>
          </el-form-item>
          <el-form-item label="详细地址" prop="address_details">
            <div class="inn">
              <el-input v-model="ruleForm.address_details" type="text" placeholder="请输入"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="交易时间" prop="pay_time" @click="choose_pay_time">
            <div class="inn" @click="choose_pay_time">
              <el-input v-model="ruleForm.pay_time" placeholder="请选择"></el-input>
              <span class="iconfont icon-Frame-4"></span>
              <div style="width: 100%;height: 20px;background-color: transparent;position:absolute;"></div>
            </div>
          </el-form-item>


        </div>

        <div class="bottomBtn" v-if="!loadObj.id">
<!--          <div @click="submitFormAndAgin('ruleForm')" class="submitAgin">-->
<!--            <span class="iconfont icon-fabu"></span>-->
<!--            发布并继续添加宝贝-->
<!--          </div>-->
          <div @click="submitForm('ruleForm')" class="setSubmit">发布</div>

<!--          <div @click="submitForm('ruleForm')" class="oneSubmit">单品发布</div>-->
        </div>
        <div class="bottomBtn" v-if="loadObj.id">
          <div @click="submitForm('ruleForm')" class="setSubmit">编辑</div>
        </div>
      </el-form>
      <!--    <idle_tabbar :active="2" ref="tabbar"></idle_tabbar>-->
      <van-popup v-model="popShow" round closeable position="bottom" class="popup">
        <div class="classification" v-if="popType=='classification'">
          <van-picker
              :columns="columns"
              @change="onPickerChange"
              value-key="cate_name"
              ref="classification"
          />
        </div>
        <div class="transaction_mode" v-if="popType=='transaction_mode'">
          <van-picker
              :columns="pay_status_list"
          />
        </div>

        <div class="hours_of_use" v-if="popType == 'hours_of_use'">
          <van-picker
              :columns="use_time_list"
              @change="onUseTimeChange"
          />
        </div>
        <div class="advance_time_P" v-if="popType =='advance_time_P'">
          <van-datetime-picker
              :show-toolbar="false"
              v-model="advance_currentDate"
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
          />
        </div>
        <div class="pay_time_P" v-if="popType == 'pay_time_P'">
          <van-picker  :columns="pay_time_li" @change="onPayTimeChange" @confirm="onPayTimeConfirm" ref="payTime"/>
        </div>
        <div class="choose_address_P" v-if="popType == 'choose_address_P'">
          <van-picker  :columns="address_li" value-key="name" ref="choose_address"/>
        </div>

        <div class="bottomBox">
          <div class="close btn" @click="closePop">取消</div>
          <div class="save btn" @click="savePop">确定</div>
        </div>
      </van-popup>

      <div style="width: 100%;height: 100px"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.showImg{
  width: 76px;
  height: 76px;
  object-fit: cover;
}
.shopAuthentication {
  background: #fff;
  flex: 1;
  border-radius: 10px 10px 0 0;
  padding-top: 10px;
  text-align: left;
  margin: 10px 16px;
  padding-bottom: 10px;
  border-radius: 10px;
  .cont{
    padding: 0 10px 10px 10px;
  }
  .title {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);
    padding: 10px 0px;
    margin-bottom: 10px;
    .text {
      color: #c7c7c7;
      font-size: 12px;
    }
  }

  .line {
    display: flex;
    margin: 0 16px;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);

    .left {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .right {
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding-left: 10px;
      border: none;
      flex: 1;
    }
    .right::placeholder{
      color: #c7c7c7;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .uploadBox{
    margin: 10px 0px;
    .inn{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconfont{
        font-size: 25px;
      }

    }
  }
}

.bottomBtn{
  max-width: 750px;
}
.container {
  background-color: #F9F9F9;
  height: 100vh;
  text-align: left;
  margin-bottom: 100px;
}

.el-form {
  //margin: 0 16px;
}

.el-form-item {
  margin-bottom: 0;

}

.is-error {
  margin-bottom: 22px !important;
}

.card {
  border-radius: 10px;
  background: #ffffff;
  margin: 0 16px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.cardTwo {
  padding-left: 10px;
}

.inputBox {
  .top {
    border-bottom: 1px solid #E9E9E9;

    .title {
      color: #1b1b1b;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      border: none;
      width: 100%;
      padding: 0 0 10px 0;
    }


  }

  .body {

    .el-textarea {

      .el-textarea__inner {
        padding: 5px 0px;

      }
    }
  }

}

.inn {
  display: flex;
  align-items: center;
  position: relative;

  .el-input__inner {
    border: none;
  }
}

.img_box {
  margin-top: 10px;

  .title {
    text-align: left;
    font-size: 12px;
    color: #999999;
  }

  .img_list {
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;

    .li {
      width: 97px;
      height: 97px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;
      margin-top: 15px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

.avatar-uploader {
  margin-top: 15px;
  width: 97px;
  height: 97px;
  line-height: 97px;
}

.priceBox {
  .el-form-item:nth-of-type(1) {
    //padding-bottom: 10px;
  }

  .el-form-item:nth-last-of-type(1) {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #F8F8F8;
  }
}

.lastBox {
  .el-form-item {
    margin-bottom: 20px;
  }

  .el-form-item:nth-last-of-type(1) {
    margin-bottom: 0px;
  }
}

.bottomBtn {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  justify-content: space-between;
  box-shadow: 0px -0.5px 2px #99999926;

  .submitAgin {
    margin-left: 16px;
    border-radius: 10px;
    background: #fc223b;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 15px;
    color: #fff;
    width: 50%;
    margin-right: 10px;

    .iconfont {
      font-size: 14px;
      margin-right: 5px;
    }
  }

  .oneSubmit {
    border-radius: 10px;
    background: #fc223b;
    color: #fff;
    padding: 12px 10px;
    margin-right: 16px;
    width: 40%;
    text-align: center;
  }
  .setSubmit{
    border-radius: 10px;
    background: #fc223b;
    color: #fff;
    padding: 12px 10px;
    margin: 5px auto;
    text-align: center;
    width: 80%;
  }
}

.popup {


  .bottomBox {
    display: flex;
    background: #fff;
    padding: 10px 60px;
    justify-content: space-around;

    .btn {
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      padding: 8px 40px;
      border-radius: 10px;
      background: #f0f0f0;
      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
    }

    .save {
      background: #05C160;
      color: #fff;
    }
  }
}
</style>


<style>
.idleContainer .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: '*';
  color: #F56C6C;
  margin-left: 4px;
}

.idleContainer .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

.idleContainer {
  .el-form-item__label {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .el-form-item__content {
    line-height: 20px;
  }

  .inn {
    .el-input__inner {
      border: none;
      height: 20px;
      line-height: 20px;
    }

    .iconfont {
      width: 20px;
      font-size: 28px;
      color: #999999;
    }

    .el-input.is-disabled .el-input__inner {
      background-color: #fff;
    }
  }

  .inputBox {
    .body {
      .el-textarea {
        .el-textarea__inner {
          padding: 5px 0px;
          background-color: #fff !important;
          color: #000000;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
        }
      }
    }

    .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  .el-radio-group {
    padding: 0 20px;
  }

  .el-form-item__label {
    text-align: left;
  }

  .priceBox {
    .el-form-item {
      .el-form-item__content {
        .inne {
        }

        .el-input {
          width: 90%;

          .el-input__inner {
            text-align: right;
          }
        }
      }
    }
  }

  .bottomBtn {
    .el-form-item {
      width: 100%;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      display: flex !important;
      width: 100%;
    }
  }
}
</style>