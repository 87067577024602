import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)


const state = {
    token:sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
    user:sessionStorage.getItem('userInfo')?typeof sessionStorage.getItem('userInfo')=='string'?JSON.parse(sessionStorage.getItem('userInfo')):'':'',
    imageHost:'',//静态文件域名
    sign:{
        essential:{
            sign_key:'EAB6gs49XYnOiMFIyPT5SWXzfHOoo7qF',
            app_id:'NNeUAJ9KfttTGODHbqAP2Q9k1ZhwAQEc',
            version:'1.0',
        },
        secret_key:'AHW6gs4fttT9k1Zhzf6gs49XAQnOeU',//密钥
    },//调用接口 合成密钥 用的到的几个参数 必要参数

    sharePopFlag: true,//分享弹框展示
    shipingAddressList:[],//选择的收货地址
    waterOrderLi:[],//水站订单
    chatDetails:{},//聊天室
    config:{},//配置

    websocket:null,
    fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],//文件上传格式
    is_vip:{},
    vipTime:0,

    nowPosition:{},//当前定位

    allAddress:[],

    client_type:0,//客户端类型 1:PC,2:安卓,3:10S,4:H5,5:小程序,6:微信内置H5,7:IPAD
    loding:false,


    nowCompany:{},//当前公司 //招聘者
}

const mutations = {
    updateallAddress(state,address){
      state.allAddress=address;
    },
    updateShare(state, msg) {
        state.sharePopFlag = msg
    },
    updataToken(state, msg) {
        state.token = msg
    },
    updataUser(state, msg) {//用户信息
        state.user = msg
    },
    updataimageHost(state, msg) {//图片域名
        state.imageHost = msg
    },
    updateShipingAddress(state, msg) {//
        state.shipingAddressList = (msg)
    },
    updateWaterOrder(state, msg) {//订单数据
        state.waterOrderLi = msg
    },
    updataChatDetail(state, msg) {//聊天室
        state.chatDetails = msg
    },
    updataConfig(state, msg) {//配置
        state.config = msg
    },
    updataWebsocket(state, msg) {
        state.websocket = msg
    },
    set_token(state, token) {
        state.token = token
        sessionStorage.token = token
        sessionStorage.setItem('token',token)
    },

    setvip(state, msg) {
        state.is_vip = msg
        state.vipTime = msg.time
    },
    outLogin(state) {
        state.token = ''
        state.user = ''
        state.is_vip = false
        state.vipTime = 0
        state.shipingAddressList = []
        sessionStorage.clear('userInfo')
        sessionStorage.clear('token')
    },


    updatanowPosition(state, msg) {
        state.nowPosition = msg
    },
    updateclient_type(state, msg) {
        state.client_type = msg
    },


    updateloding(state, msg) {
        state.loding = msg
    },
    updatenowCompany(state, msg) {
        state.nowCompany = msg
    }



}

const actions = {

}

const getters = {
    
}
const store = new Vuex.Store({

    //后期会写大量配置
    namespaced: true,
    state,
    mutations,
    actions,
    getters
})

export default store


// 导出仓库
// export default store
