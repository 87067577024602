<template>
  <div>
    <div class="container">
      <van-sticky @change="stickchange" @scroll="stickscroll">
        <div class="topBox" ref="sticky">
          <navigationBar class="nivatop">
            <template #search>
              <div class="searchBox">
                <div class="leftSearch">
                  <div class="icon">
                    <span
                        class="iconfont icon-grommet-icons_form-search-1"
                    ></span>
                  </div>
                  <div class="line"></div>
                  <div class="placeHolder">搜索你想要的物品...</div>
                </div>
                <div class="rightBtn">
                  <span class="iconfont icon-kefu" @click="kefu"></span>
                  <span class="iconfont icon-chaoshidingdan" @click="goOrderLi"></span>
                </div>
              </div>
            </template>
          </navigationBar>
          <div class="overScroll">
            <div class="innerSroll" ref="innerSroll">
              <div
                  class="li"
                  v-for="(i, t) in commodityLList"
                  :key="t"
                  :class="t == chooseTop ? 'chooseTop' : ''"
                  @click="chooseTopFun(t)"
                  ref="nvali"
              >
                <img
                    class="img"
                    :src="$store.state.imageHost+i.image"
                    alt=""
                />
                <div class="name">{{ i.name }}</div>
              </div>
            </div>
            <div class="moreBtn" @click="openTopNav">
              更多
              <span class="iconfont icon-Frame12"></span>
            </div>
            <van-popup v-model="topNavShow" round position="top">

              <div class="topBox" ref="sticky">
                <navigationBar class="nivatop">
                  <template #search>
                    <div class="searchBox">
                      <div class="leftSearch">
                        <div class="icon">
                    <span
                        class="iconfont icon-grommet-icons_form-search-1"
                    ></span>
                        </div>
                        <div class="line"></div>
                        <div class="placeHolder">搜索你想要的物品...</div>
                      </div>
                      <div class="rightBtn">
                        <span class="iconfont icon-kefu"></span>
                        <span class="iconfont icon-chaoshidingdan"></span>
                      </div>
                    </div>
                  </template>
                </navigationBar>
                <div class="overWrap">
                  <div class="innerSroll">
                    <div
                        class="li"
                        v-for="(i, t) in commodityLList"
                        :key="t"
                        :class="t == chooseTop ? 'chooseTop' : ''"
                        @click="chooseTopFun(t)"
                    >
                      <img
                          class="img"
                          :src="$store.state.imageHost+i.image"
                          alt=""
                      />
                      <div class="name">{{ i.name }}</div>
                    </div>
                  </div>
                </div>
              </div>


            </van-popup>
          </div>
        </div>
      </van-sticky>
      <div class="area">

        <div class="body" v-if="commodityLList.length>0">
          <div class="left" :class="fixedFlag?'fixed1':''" :style="'top:'+(this.stickyHieght)+'px'">
            <div
                class="li"
                v-for="(item, index) in leftLi"
                :key="index"
                :class="leftIndex == index ? 'chooseLeft' : ''"
                @click="chooseLeft(index)"
            >
              {{ item.name }}
            </div>
            <!-- <van-sidebar v-model="leftIndex">
            <van-sidebar-item  v-for="(item, index) in leftLi" :key="index"  :title="item" />
          </van-sidebar> -->
          </div>
          <div class="left" v-if="fixedFlag"></div>

          <div class="right" ref="container">
            <div class="swiperOut">
              <div class="swiper">
                <div class="swiper-wrapper">
                  <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd.png" alt="" class="img"></div> -->
                  <div
                      class="swiper-slide"
                      :style="'background-image:url(https://picture.bjbjkj.top/tp2024062702331433414434306.jpeg)'"
                  ></div>
                  <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd1.png" alt="" class="img"></div> -->
                </div>
                <!-- 如果需要分页器 -->
                <div class="pagination">
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>
            <div class="top">
              <div class="leftSort" :class="sortType==1?'defaultS':''" @click="defaultS">默认排序</div>
              <div class="rightSort">
                <div class="sale" :class="sortType==2?'saleS':''" @click="sale">销量</div>
                <div class="priceSort" @click="price" :class="sortType==3||sortType==4?'priceS'+sortType:''">
                  价格
                  <div class="iconfontBox">
                    <span class="iconfont icon-Vector"></span>
                    <span class="iconfont icon-a-Group2009"></span>
                  </div>
                  <!--                  <span class="iconfont icon-a-Group20093"></span>-->
                  <!--                  <svg class="icon" aria-hidden="true">-->
                  <!--&lt;!&ndash;                    <use xlink:href="#icon-a-Group20093"></use>&ndash;&gt;-->
                  <!--                    <use xlink:href="#icon-a-Group2009-11"></use>-->
                  <!--                  </svg>-->
                </div>
              </div>
            </div>
            <div class="tagLi">
              <div class="item" :class="tagIdex==index?'sortEvery ':''" v-for="(item,index) in tagLi" :key="index"
                   @click="chooseTag(index)">
                {{ item.name }}
              </div>
            </div>
            <div class="ul">
              <div class="li" v-for="(item, index) in goodsLi" :key="index" @click.stop="godetails(item)">
                <div class="leftImgBox">
                  <img :src="$store.state.imageHost+item.images" alt="" class="img"/>
                </div>
                <div class="info">
                  <div class="brand">
                    <div class="brandBox">品牌</div>
                    {{ item.brand.name }}
                  </div>
                  <div class="name">{{ item.name }}</div>
                  <div class="detail">
                    <div class="spacl">{{ item.specifications }}</div>
                    <div class="line"></div>
                    <div class="sale">{{ item.sell_num }}</div>
                  </div>
                  <div>
                    <div class="price_add">
                      <div class="leftBox">
                        <div class="leftPrice">
                          <div class="sellPrice">
                            ￥
                            <div class="yuan">{{ item.yuan }}</div>
                            .{{ item.mao }}
                          </div>
                          <div class="lastPrice">￥{{ item.mktprice }}</div>
                        </div>

                        <div class="vipBox" v-if="item.vipPr">
                          <div class="vipPrice">￥{{ item.vipPr }}</div>
                          <div class="">会员价</div>
                        </div>
                      </div>
                      <div class="rightAdd">
                        <div class="unadd" v-if="item.cart_nums>0" @click.stop="unadd(item)"></div>
                        <div class="input" v-if="item.cart_nums>0">{{ item.cart_nums }}</div>
                        <div class="add" @click.stop="add(item)"></div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-popup v-model="carshow" round position="bottom">
          <div class="popup">
            <div class="tioline"></div>
            <div class="title">
              <div class="left">购物车</div>
              <div class="right" @click="clearCar">清空</div>
            </div>
            <div class="carBox">
              <div class="item" v-for="(item, index) in carList" :key="index">
                <div class="leftImgBox">
                  <img :src="$store.state.imageHost+item.products.images" alt="" class="img">
                </div>
                <div class="rightInfo">
                  <div class="brand">
                    <div class="brandBox">品牌</div>
                    {{ item.products.brand.name }}
                  </div>
                  <div class="name">{{ item.products.name }}</div>
                  <div class="detail">
                    <div class="spacl">{{ item.products.specifications }}</div>
                    <div class="line"></div>
                    <div class="sale">{{ item.products.sell_num }}</div>
                  </div>

                  <div>
                    <div class="price_add">
                      <div class="leftBox">
                        <div class="leftPrice">
                          <div class="sellPrice">
                            ￥
                            <div class="yuan">{{ item.yuan }}</div>
                            .{{ item.mao }}
                          </div>
                          <div class="lastPrice">￥{{ item.mktprice }}</div>
                        </div>

                        <div class="vipBox" v-if="item.vipPr">
                          <div class="vipPrice">￥{{ item.vipPr }}</div>
                          <div class="">会员价</div>
                        </div>
                      </div>
                      <div class="rightAdd">
                        <div class="unadd" @click.stop="unadd1(item)" v-if="item.nums>0"></div>
                        <div class="input" v-if="item.nums>0">{{ item.nums }}</div>
                        <div class="add" @click.stop="add1(item)"></div>
                      </div>
                    </div>

                  </div>
                </div>
                <!--                <svg class="icon" aria-hidden="true">-->
                <!--                  <use xlink:href="#icon-kefu"></use>-->
                <!--                </svg>-->
              </div>
            </div>

            <div class="bottom">
              <div class="bottomBigbox">
                <div class="left">
                  <div class="car">
                    <img src="https://picture.bjbjkj.top/img/supermarkerCar.png" alt="" class="image busPos">
                    <div class="nums" v-if="cartNums>0">{{ cartNums>99?' 99+':cartNums }}</div>
                  </div>
                  <div class="price">
                    ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
                  >
                  </div>
                </div>
                <div class="right">
                  <!-- <div class="songshui btnBox">一键送水</div> -->
                  <div class="zhifu btnBox" @click="submit">提交订单</div>
                </div>
              </div>
            </div>

          </div>
        </van-popup>

        <div class="bottom"  v-if="carList.length>=1">
          <div class="bottomBigbox"  v-if="carList.length>=1">
            <div class="left" @click="opencar">
              <div class="car">
<!--                <img-->
<!--                    class="image busPos"-->
<!--                    src="https://picture.bjbjkj.top/img/Frame 2524@2x.png"-->
<!--                    alt=""-->
<!--                />-->
                <img src="https://picture.bjbjkj.top/img/supermarkerCar.png" alt="" class="image busPos">
                <div class="nums" v-if="cartNums>0">{{ cartNums>99?' 99+':cartNums }}</div>
              </div>
              <div class="price">
                ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
              >
              </div>
            </div>
            <div class="right">
              <!-- <div class="songshui btnBox">一键送水</div> -->
              <div class="zhifu btnBox" @click="submit">提交订单</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <loding v-if="loding"></loding>

  </div>
</template>

<script>
import navigationBar from "../../navigation-bar/navigation-bar.vue";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import {addCar, cart_del, get_cat_goods_list_type_6, get_list_6, getCartListAll} from "@/utils/api";
import loding from "@/components/common/loding/loding.vue";
import {Dialog, Toast} from "vant";
import {_getChatRoom, _throttle} from "@/utils/tool";

export default {
  components: {
    loding,
    navigationBar,
  },
  props: {},
  data() {
    return {
      container: '',
      chooseTop: 0,
      loding: false,
      commodityLList: [],
      goodsLi: [],
      tagIdex: 0,
      leftIndex: 0,
      swiper: {},
      allPirce: {
        yuan: 0,
        mao: 0,
      },
      carshow: false,
      topNavShow: false,
      fixedFlag: false,
      stickyHieght: 0,
      sortType: 1,
      tagLi: [],
      carList: [],
    };
  },
  watch: {
    amount(n) {
        this.allPirce.yuan = n.toFixed(2).split(".")[0]
        this.allPirce.mao = n.toFixed(2).split(".")[1]
    },
    chooseTop(n) {
      this.leftIndex = 0
      if (!this.commodityLList[n].goods_cat_idss[this.leftIndex].child) {
        this.getGoodsLi(this.commodityLList[n].goods_cat_idss[this.leftIndex].id)
      } else {
        this.tagLi = this.commodityLList[n].goods_cat_idss[this.leftIndex].child
      }
    },
    leftIndex(n) {
      if (!this.commodityLList[this.chooseTop].goods_cat_idss[n].child) {
        this.getGoodsLi(this.commodityLList[this.chooseTop].goods_cat_idss[n].id)
      } else {
        this.tagLi = this.commodityLList[this.chooseTop].goods_cat_idss[n].child
      }
    },
    tagIdex(n){
      // console.log(this.commodityLList[this.chooseTop].goods_cat_idss[this.leftIndex].child[n])
      this.goodsLi = this.commodityLList[this.chooseTop].goods_cat_idss[this.leftIndex].child[n].data
    },
    tagLi(n) {
      console.log(n[this.tagIdex])
      if (n.length >= 1) {
        this.goodsLi = n[this.tagIdex].data
      }else{
        this.goodsLi = []
      }
    },
    goodsLi(n) {
      n.forEach((item) => {
        item.yuan = item.price.split(".")[0]
        item.mao = item.price.split(".")[1]
        if (item.vip_price) {
          item.vipPr = item.vip_price
        }
      })

    },
   carList:{
     handler(n){
       // this.watchCar(n)
       let mapc = Object.fromEntries(n.map(({ product_id, nums }) => [product_id, nums]));
       n.forEach((item) => {
         item.yuan = item.products.price.split(".")[0]
         item.mao = item.products.price.split(".")[1]
       })
       console.log(mapc)
       this.goodsLi.forEach(it=>{
         it.cart_nums = mapc[it.id] ?? 0
       })
       // console.log(n.length,'n.length',this.cartNums.setter)
     },


     deep: true,
   }

  },
  computed: {
    cartNums:{
      get(){
        return  this.carList.reduce((total, item) =>
                total += item.nums
            , 0)
      },

      set(v){
        this.cartNums = v
      }, //eslint-disable-line

    },

    amount() {
      if (this.$store.state.is_vip.is_vip) {
        return this.carList.reduce((total, item) =>
                total += item.products.vip_price * item.nums
            , 0)

      } else {
        return this.carList.reduce((total, item) =>
                total += item.products.price * item.nums
            , 0)
      }

    },
    leftLi() {

      return this.commodityLList[this.chooseTop].goods_cat_idss
    },
    //  tagLi(){
    //   // return  this.getGoodsLi(this.commodityLList[this.chooseTop].goods_cat_idss[this.leftIndex].id)
    //   // return  this.commodityLList[this.chooseTop].goods_cat_idss[this.leftIndex].child
    //   return []
    // },
    // goodsLi(){
    //
    // },
  },
  methods: {
    watchCar:_throttle(function (n){
      let mapc = Object.fromEntries(n.map(({ product_id, nums }) => [product_id, nums]));
      console.log(mapc)
      // n.forEach((item) => {
      //   item.yuan = item.products.price.split(".")[0]
      //   item.mao = item.products.price.split(".")[1]
      // })
      // console.log(mapc)
      // this.goodsLi.forEach(it=>{
      //   it.cart_nums = mapc[it.id] ?? 0
      // })
      // console.log(n.length,'n.length',this.cartNums.setter)

    },1000),
    goOrderLi() {
      this.$router.push({
        name: "order_list",
        params: { id:'0', },
      })
    },
    kefu() {
      if (!this.$store.state.token) {
        Toast('您还未登录，请登录')
        return
      }
      //type 2 辅材 2 水站 3 超市 4 快修 5 房产
      _getChatRoom(3, 1, 2).then(res => {
        if (res) {
          this.$router.push({
            name: 'chat_details',
          })
        }
      })
    },
    godetails(item){
      this.$router.push({
        path: '/water_details',
        query:{goods:item.id}
      })
    },
    chooseTag(e) {
      this.tagIdex = e
    },
    openTopNav() {
      this.topNavShow = true
    },
    chooseLeft(e) {
      this.leftIndex = e;
    },
    chooseTopFun(e) {
      this.chooseTop = e;
      // console.log(this.$refs)
      // console.log(this.$refs)
      // console.log(this.$refs.nvali[e].scrollIntoView())
      // this.$refs.innerSroll.scrollLeft = this.$refs.nvali[0].offsetWidth*e
      if (e >= 2) {
        this.$refs.nvali[e - 2].scrollIntoView({block: 'end', behavior: 'smooth', inline: "start"})
      }
    },
    submit() {
      if (this.carshow) {
        console.log()
        this.$router.push({
          name:'createOrderSecond',
          params:{list:this.carList,allPrice:this.allPirce},
        })
        this.$store.commit('updateWaterOrder',this.carList)
      } else {
        this.carshow = true
      }
    },
    opencar() {
      this.carshow = true
    },
    stickchange(isFixed) {
      // console.log(isFixed)
      this.fixedFlag = isFixed
    },
    stickscroll(e) {
      e
      // console.log(e)
    },
    clearCar() {
      Dialog.confirm({
        title: '确认清空购物车？',
      })
          .then(() => {
            // on confirm
            if(!this.$store.state.token)return;
            let goods_id = []
            if(this.goodsLi.length==0)return
            this.carList.forEach(item=>{
              goods_id.push(item.id)
            })
            goods_id = String(goods_id)
            console.log(goods_id)
            cart_del(this.$store.state.token,goods_id).then(res=>{

              if(res.data.status==true){
                console.log(res.data.data)
                Toast(res.data.msg)
                // this.updataGoods()
                this.carList = []
                this.carshow = false
                this.cartLi = []
              }else{
                Toast(res.data.msg)
              }
            }).catch(err=>{
              console.log(err)})
          })
          .catch(() => {
            // on cancel
          });
    },
    add(item) {
      let nums = 1
      if (nums < item.sell_num) {
        nums = item.sell_num
      } else {
        nums = 1
      }
      addCar(this.$store.state.token,item.id,nums,1,5).then(res => {
        if(res.data.status == true){
          item.cart_nums+=nums
          this.getCart()
        }else{
          Toast(res.data.msg)
        }
      })

    },
    unadd(item) {
      if (item.cart_nums <= 0) return;
      if(item.cart_nums<=item.sell_num){
        item.cart_nums = 0
      }else{
        item.cart_nums -= 1
      }
      addCar(this.$store.state.token,item.id,item.cart_nums,2,5).then(res => {
          this.getCart()
        console.log(res.data)
      })

      // item.cart_nums -= 1
    },
    add1(item) {
      addCar(this.$store.state.token,item.product_id,1,1,5).then(res => {
        if(res.data.status==true){
          item.nums+=1
        }else{
          Toast(res.data.msg)
        }
      })
    },
    unadd1(item) {
      let nums = item.nums
      if(nums<=item.products.sell_num){
        nums = 0
      }else{
        nums = item.nums
      }
      addCar(this.$store.state.token,item.product_id,nums,2,5).then(res => {
        if(res.data.status==true){
          item.nums = (nums-=1)
        }else{
          Toast(res.data.msg)
        }
      })
    },
    getCart(){
      if(this.$store.state.token){
        getCartListAll(this.$store.state.token,5).then(res=>{
          console.log(res.data.data.data.list)
          if(res.data.status==true){
            this.carList = res.data.data.data.list
            this.carList.forEach(item=>{
              item.products.yuan = item.products.price.split('.')[0]
              item.products.mao = item.products.price.split('.')[1]
            })
            console.log(this.carList)
          }
        })
      }
    },
    defaultS() {
      this.sortType = 1
      this.goodsLi = this.goodsLi.sort(this.compareAlign('id','inverted'))
    },
    sale() {
      this.sortType = 2
      this.goodsLi = this.goodsLi.sort(this.compareAlign('buy_count','inverted'))
    },
    price() {
      let price = 'price'
      if(this.$store.state.is_vip.is_vip){
        price = 'vip_price'
      }
      let list = JSON.parse(JSON.stringify(this.goodsLi))
      list.forEach(item=>{
        item.price = Number(item.price)
        item.vip_price = Number(item.vip_price)
      })
      if (this.sortType == 3) {
        list = list.sort(this.compareAlign(price,'positive'))
        list.forEach(item=>{
          item.price = Number(item.price).toFixed(2)
          item.vip_price = Number(item.vip_price).toFixed(2)
        })
        this.sortType = 4
        this.goodsLi = list
      } else {
        list = list.sort(this.compareAlign(price,'inverted'))
        list.forEach(item=>{
          item.price = Number(item.price).toFixed(2)
          item.vip_price = Number(item.vip_price).toFixed(2)
        })
        this.sortType = 3
        this.goodsLi = list.sort(this.compareAlign(price,'inverted'))
      }
    },
    compareAlign(prop,align){
      return function(a,b){
        var value1=a[prop];
        var value2=b[prop];
        if(align=="positive"){//正序
          return new Date(value1)-new Date(value2);
        }else if(align=="inverted"){//倒序
          return new Date(value2)-new Date(value1);
        }
      }
    },

    getList() {
      this.loding = true
      get_cat_goods_list_type_6(this.$store.state.token).then(res => {
        if (res.data.status) {
          let list = res.data.data
          list.sort(function (a, b) {return a.sort - b.sort;})

          // list.forEach(item => {
          // console.log(item)
          // })
          this.commodityLList = list
          this.$nextTick(() => {
            this.stickyHieght = this.$refs.sticky.offsetHeight
            this.container = this.$refs.container
          })
          if(list[this.chooseTop].goods_cat_idss[this.leftIndex]){
            get_list_6(this.$store.state.token?this.$store.state.token:'', list[this.chooseTop].goods_cat_idss[this.leftIndex].id).then(res1 => {
              if (res1.data.status == true) {
                this.tagLi = res1.data.data
                this.commodityLList[this.chooseTop].goods_cat_idss[this.leftIndex].child = res1.data.data
              }
            })
          }

        } else {
          Toast(res.data.msg)
        }
        this.loding = false
      })
    },

    getGoodsLi(goods_cat_id) {
      get_list_6(this.$store.state.token?this.$store.state.token:'', goods_cat_id).then(res => {
        if (res.data.status == true) {
          this.tagLi = res.data.data
          this.commodityLList[this.chooseTop].goods_cat_idss[this.leftIndex].child = res.data.data
        }
      })


      // async function ac  (){
      //   const {data} = await get_list_6(this.$store.state.token,goods_cat_id)
      //   return data.data
      // }

      // console.log( ac())


      // return 123
    },
  },
  created() {
    this.$nextTick(() => {
      this.swiper = new Swiper(".swiper", {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      });
    })
  },
  mounted() {
    this.getList()
    this.getCart()
    // console.log(this.getGoodsLi(195))
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 100vh;
}

.popup {
  position: relative;
  padding: 20px 16px 0 16px;

  .carBox {
    max-height: 50vh;
    overflow: scroll;
    margin-top: 10px;
    margin-bottom: 70px;

    .item {
      display: flex;
    }

    .leftImgBox {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      overflow: hidden;

      .img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .rightInfo {
      flex: 1;
      padding-left: 10px;
      padding-bottom: 10px;

      .brand {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        text-overflow: ellipsis;
        align-items: center;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;

        .brandBox {
          font-weight: 500;
          font-size: 12px;
          color: #1b1b1b;
          line-height: 18px;
          padding: 0 4px;
          background: #e8e8e8;
          border-radius: 4px 4px 4px 4px;
          margin-right: 5px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        text-align: left;
      }

      .detail {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #7f7f7f;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .line {
          margin: 0 6px;
          width: 1px;
          height: 9px;
          background: #dddddd;
        }
      }

      .price_add {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftPrice {
          display: flex;
          align-items: baseline;

          .sellPrice {
            display: flex;
            font-size: 14px;
            color: #fd2a39;
            line-height: 24px;
            align-items: baseline;

            .yuan {
              font-size: 20px !important;
            }
          }

          .lastPrice {
            text-decoration-line: line-through;
            line-height: 18px;
            color: #7f7f7f;
            font-size: 12px;
          }
        }

        .rightAdd {
          display: flex;
          align-items: center;

          .unadd,
          .add {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #FC223B;
            position: relative;
          }

          .add::after,
          .unadd:after {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 10%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .add::before {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 10%;
            height: 50%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .input {
            height: 22px;
            width: 22px;
            line-height: 22px;
            //margin: 0 5px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #1b1b1b;
            overflow: scroll;
          }
          .input::-webkit-scrollbar{
            display: none;
          }
        }
      }

      .vipBox {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        color: #392723;
        line-height: 18px;

        .vipPrice {
          margin-right: 5px;
        }
      }
    }
  }

  .carBox::-webkit-scrollbar {
    display: none;
  }

  .tioline {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 10%;
    height: 5px;
    background: #FC223B;
    transform: translate(-50%, -0%);
    border-radius: 25px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
}

.fixed1 {
  position: fixed;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px -1px 2px 0px rgba(153, 153, 153, 0.15);

  z-index: 10;
}

.bottomBigbox {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: baseline;

    .car {
      width: 72rpx;
      position: relative;
      display: flex;
      align-items: center;
    }

    .price {
      margin-left: 10rpx;
      font-size: 14px;
    }

    .yuan {
      font-size: 24px;
    }

    .image {
      width: 36px;
      max-height: 36px;
    }

    .nums {
      position: absolute;
      left: 70%;
      top: 0;
      background-color: red;
      color: white;
      min-width: 14px;
      height: 14px;
      border-radius: 50px;
      text-align: center;
      line-height: 14px;
      padding: 2px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .btnBox {
      padding: 10px 24px;
      border-radius: 32px;
      color: white;
      font-size: 14px;
      background: #FC223B;
    }
  }
}

.price {
  color: #fd2a39;
}

.topBox {
  background: #fff9f1;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  padding-bottom: 6px;
}

.searchBox {
  display: flex;
  align-items: center;
  width: 88%;
  justify-content: space-between;

  .leftSearch {
    width: 80%;
    padding: 5px 13px;
    display: flex;
    background: #ffffff;
    border-radius: 30px 30px 30px 30px;
    border: 1px solid #ffb493;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .line {
      background: #66c23a;
      border-radius: 10px;
      border: 1px solid #ffb493;
      height: 9px;
      margin: 0 10px;
    }

    .placeHolder {
      font-weight: 400;
      font-size: 14px;
      color: #c7c7c7;
      line-height: 24px;
    }
  }

  .rightBtn {
    width: 20%;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .iconfont {
      color: #FC223B;
      font-size: 24px;
    }
  }
}

.nivatop {
  width: 100%;
}

.overScroll {
  overflow: hidden;
  width: 100%;
  //   background: red;
  position: relative;

  .innerSroll {
    white-space: nowrap;
    padding-top: 1px;
    overflow: scroll;
    //transition: background-color 0.3s;
    //transition-duration:0.3s;
    padding-right: 50px;

    .li {
      display: inline-block;
      width: 23%;
      text-align: center;

      .img {
        width: 48px;
        height: 48px;
        border-radius: 25%;
        border: 1px solid transparent;
      }

      .name {
        font-weight: 400;
        font-size: 11px;
        color: #1b1b1b;
        line-height: 15px;
        width: 70%;
        margin: 0 auto;
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        padding: 2px 6px;
        // text-overflow: ellipsis;
      }
    }

    .chooseTop {
      .name {
        color: white;
        background-color: #FC223B;
        border-radius: 15px 15px 15px 15px;
        padding: 2px 6px;
      }

      .img {
        border: 1px solid #FC223B;
      }
    }
  }

  .innerSroll::-webkit-scrollbar {
    display: none;
  }

  .moreBtn {
    writing-mode: vertical-rl;
    position: absolute;
    right: 0;
    top: -1px;
    height: 100%;
    width: 10%;
    font-weight: 500;
    font-size: 12px;
    color: #1b1b1b;
    line-height: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0rpx -1rpx 4rpx 0rpx rgb(153, 153, 153);
    background: #fff9f1;
  }
}

.overWrap {
  text-align: left;

  .innerSroll {
    padding-top: 1px;
    white-space: pre-wrap;
    padding-right: 0;

    .li {
      width: 20%;
    }
  }

}

.body {
  position: relative;
  z-index: 2;
  display: flex;
  //   margin-top: 10px;
  padding-bottom: 100px;

  .left {
    width: 25%;

    .li {
      font-weight: 400;
      font-size: 13px;
      color: #1b1b1b;
      line-height: 24px;
      padding: 14px 0;
      background: #fafafa;
    }

    .chooseLeft {
      background: #fff;
      font-size: 14px;
      color: #FC223B;
    }
  }

  .right {
    width: 71%;
    padding-left: 10px;

    .top {
      margin-right: 16px;
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      font-weight: 400;
      font-size: 13px;
      color: #7f7f7f;
      line-height: 24px;

      .leftSort {
      }

      .defaultS {
        color: #FC223B;
      }

      .rightSort {
        display: flex;
        justify-content: space-between;

        .saleS {
          color: #FC223B;
        }

        .priceS3 {
          color: #FC223B !important;

          .icon-Vector {
            color: #FC223B !important;
          }
        }

        .priceS4 {
          color: #FC223B !important;

          .icon-a-Group2009 {
            color: #FC223B !important;
          }
        }

        .iconfont {
          font-size: 25px;
        }

        .sale {
          margin-right: 20px;
        }

        .priceSort {
          display: flex;
          align-items: center;

          .iconfontBox {
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 10px;
              margin-right: -5px;
              display: flex;
              color: #7f7f7f;
            }
          }
        }
      }
    }

    .ul {
      .li {
        display: flex;
        padding-top: 10px;

        .leftImgBox {
          display: flex;

          .img {
            width: 75px;
            height: 75px;
            object-fit: cover;
          }
        }

        .info {
          width: 90%;
          border-bottom: 1px solid #e8e8e8;
          padding-left: 10px;
          padding-bottom: 10px;

          .brand {
            display: flex;
            width: 100%;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            font-weight: 500;
            font-size: 13px;
            color: #1b1b1b;
            line-height: 18px;

            .brandBox {
              font-weight: 500;
              font-size: 12px;
              color: #1b1b1b;
              line-height: 18px;
              padding: 0 4px;
              background: #e8e8e8;
              border-radius: 4px 4px 4px 4px;
              margin-right: 5px;
            }
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            color: #1b1b1b;
            line-height: 24px;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            text-align: left;
          }

          .detail {
            display: flex;
            font-weight: 400;
            font-size: 12px;
            color: #7f7f7f;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;

            .line {
              margin: 0 6px;
              width: 1px;
              height: 9px;
              background: #dddddd;
            }
          }

          .price_add {
            display: flex;
            justify-content: space-between;
            margin-right: 16px;
            align-items: center;

            .leftPrice {
              display: flex;
              align-items: baseline;

              .sellPrice {
                display: flex;
                font-size: 14px;
                color: #fd2a39;
                line-height: 24px;
                align-items: baseline;

                .yuan {
                  font-size: 20px !important;
                }
              }

              .lastPrice {
                text-decoration-line: line-through;
                line-height: 18px;
                color: #7f7f7f;
                font-size: 12px;
              }
            }

            .rightAdd {
              display: flex;
              align-items: center;

              .unadd,
              .add {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: #FC223B;
                position: relative;
              }

              .add::after,
              .unadd:after {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 10%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .add::before {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 10%;
                height: 50%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .input {
                height: 22px;
                width: 22px;
                line-height: 22px;
                //margin: 0 5px;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #1b1b1b;
                overflow: scroll;
              }
              .input::-webkit-scrollbar{
                display: none;
              }
            }
          }

          .vipBox {
            display: flex;
            font-weight: 600;
            font-size: 14px;
            color: #392723;
            line-height: 18px;

            .vipPrice {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .tagLi {
      text-align: left;

      .item {
        display: inline-block;
        margin-right: 12px;
        background-color: #F6F6F8;
        color: #404040;
        border-radius: 20px;
        padding: 2px 8px;
        font-size: 12px;
      }

      .sortEvery {
        background-color: #FC223B20 !important;
        color: #FC223B !important;
      }
    }
  }
}

.swiper-slide {
  // background: red;
  height: 96px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  margin: 0 10px 0 0;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  .swiper-slide {
    max-height: 96px;
  }

  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}
</style>