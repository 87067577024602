!
<template>
  <div id="app">
    <div class="backOne back"></div>
    <div class="backTow back"></div>
    <div class="backThe back"></div>
    <navigationBar :title="'登录'" class="navigation"></navigationBar>
    <div class="area">
      <div class="topLogoBox">
        <img class="img logo" src="../../../public/image/logo.png" alt="">
        <img class="img logoText" src="https://picture.bjbjkj.top/small_program_image/logo_text.png" alt="">
      </div>
      <div class="loginForom">
        <van-form @submit="login">
          <div v-if="type == 3">
            <!--            账号密码登录-->
            <van-field name="账号" v-model="username" label="账号" placeholder="用户名" label-width='4em'>
              <template #label>
                <span class="leftLable">账号</span>
              </template>
              <template #input>
                <input type="text" placeholder="请输入你的手机号" v-model="username" autoComplete class="input">
              </template>
            </van-field>
            <van-field type="password" v-model="password" name="密码" label="密码" label-width='4em'>
              <template #label>
                <span class="leftLable">密码</span>
              </template>
              <template #input>
                <input type="password" placeholder="请输入你的密码" v-model="password" autoComplete class="input">
              </template>
            </van-field>

          </div>
          <div v-if="type==1">
            <!--            忘记密码-->
            <van-field name="账号" v-model="username" label="账号" placeholder="请输入你的手机号" label-width='4em'>
              <template #label>
                <span class="leftLable">账号</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="text" placeholder="请输入你的手机号" v-model="mobile" autoComplete class="input">-->
              <!--              </template>-->
            </van-field>
            <van-field type="text" v-model="SMS" name="验证码" label="验证码" placeholder="请输入验证码" label-width='4em'
            >
              <template #label>
                <span class="leftLable">验证码</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="password" placeholder="请输入验证码" v-model="SMS" autoComplete class="input">-->
              <!--              </template>-->
              <template #button>
                <span class="smstext" @click="getSms" v-if="!timerFlag">获取验证码</span>
                <span class="smstext" v-if="timerFlag">{{ timerS }}s</span>
              </template>
            </van-field>

            <van-field type="password" autocomplete="" v-model="password" name="密码" label="密码"
                       placeholder="设置密码"
                       label-width='4em'
                       >
              <template #label>
                <span class="leftLable">设置密码</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="password" placeholder="请输入密码" v-model="password" autoComplete class="input">-->
              <!--              </template>-->
            </van-field>
            <van-field type="password" autocomplete="" v-model="confirmPassword" name="确认密码" label="确认密码"
                       placeholder="再次输入密码" label-width='4em'
                       >
              <template #label>
                <span class="leftLable">确认密码</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="password" placeholder="请输入密码" v-model="confirmPassword" autoComplete class="input">-->
              <!--              </template>-->
            </van-field>
          </div>
          <div v-if="type==2">
            <!--            手机号登录-->
            <van-field name="账号" v-model="username" label="账号" placeholder="请输入你的手机号" label-width='4em'>
              <template #label>
                <span class="leftLable">账号</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="text" placeholder="请输入你的手机号" v-model="mobile" autoComplete class="input">-->
              <!--              </template>-->
            </van-field>
            <van-field type="text" v-model="SMS" name="验证码" label="验证码" placeholder="请输入验证码" label-width='4em'
            >
              <template #label>
                <span class="leftLable">验证码</span>
              </template>
              <!--              <template #input>-->
              <!--                <input type="password" placeholder="请输入验证码" v-model="SMS" autoComplete class="input">-->
              <!--              </template>-->
              <template #button>
                <span class="smstext" @click="getSms" v-if="!timerFlag">获取验证码</span>
                <span class="smstext" v-if="timerFlag">{{ timerS }}s</span>
              </template>
            </van-field>



          </div>
          <van-checkbox name="协议" v-model="checked" checked-color="#EC3530" class="checkOutBox" v-if="type==3">
            <span class="checkBox">阅读并同意<span class="aHerf">《用户协议》</span>及<span
                class="aHerf">《隐私政策》</span></span>
          </van-checkbox>
          <div class="submitLog">
            <van-button round block type="info" native-type="submit" class="btn" v-if="type == 3">登录</van-button>
            <van-button round block type="info" native-type="submit" class="btn" v-if="type == 2">登录</van-button>
            <van-button round block type="info" native-type="submit" class="btn" v-if="type == 1">确认</van-button>
          </div>
          <div class="bottomFun">
            <div class="leftFun Fun" @click="forFetPsword" v-if="type==3">忘记密码</div>
            <div class="leftFun Fun" @click="gologin" v-if="type==1||type==2">立即登录</div>
            <div class="rightBtn Fun" @click="SignIn">注册账户</div>
          </div>
        </van-form>
      </div>

      <div class="otherLoginMethotd" v-if="type == 3||type == 2">
        <div class="topLine">
          其他方式登录
        </div>
        <div class="otherLi">
          <!--          <div class="other-item">-->
          <!--            <span class="iconfont icon-weixin"></span>-->
          <!--            微信-->
          <!--          </div>-->
          <div class="other-item" @click="otherLogin(2)" v-if="type == 3">
            <span class="iconfont icon-Frame25"></span>
            手机号登录
          </div>
          <div class="other-item" @click="otherLogin(3)" v-if="type == 2">
            <span class="iconfont icon-Frame-17"></span>
            密码登录
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import navigationBar from '../navigation-bar/navigation-bar.vue';
import {Checkbox, CheckboxGroup, Form, Field, Dialog, Toast} from 'vant';
import Vue from 'vue';
import {app_mobile_login, sendSms} from "@/utils/api";

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Dialog);
export default {
  name: 'home_login',
  components: {
    navigationBar
  },
  props: {},
  data() {
    return {
      username: '',
      password: '',
      checked: false,
      type: 3,// 1忘记密码   2手机号登录  3账户密码登录
      SMS: '',
      timerS: 60,
      timer: null,
      timerFlag: false,
      confirmPassword:'',
      regIdCard: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
    };
  },
  watch: {
    timerS(n){
      if(n<=0){
        this.timerFlag = false
        clearInterval(this.timer)
        this.timerS = 60
      }
    },
  },
  computed: {},
  methods: {
    otherLogin(e) {
      this.type =  e
    },
    getSms() {
      if (!this.regIdCard.test(this.username)) {
        Toast('输入正确的手机号');
        return
      }
      sendSms(this.username).then(res => {
        const {data} = res;
        if (data.status == true) {
          this.timerFlag = true
          this.timer = setInterval(() => {
            this.timerS -= 1
          }, 1000);
        } else {
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
        // Toast(err.data.msg)
      })

    },

    login(values) {
      if(this.type==3){
        if (!this.checked) {
          Dialog.alert({
            message: '请阅读协议',
          }).then(() => {
            // on close
          });
          return
        }
        app_mobile_login(values['账号'], values['密码']).then(res => {
          console.log(res.data)
          if (res.data.status) {
            Toast('登录成功')
            sessionStorage.setItem('token', res.data.data.data.token);
            let userInfo = res.data.data.data.user
            userInfo = JSON.parse(JSON.stringify(userInfo))
            console.log(userInfo)

            sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
            this.$store.commit("updataToken", res.data.data.data.token);
            this.$store.commit("updataUser", res.data.data.data.user);
            this.$router.go(-1)
          } else {
            Toast(res.data.msg)
          }
        })
      }else if(this.type==1){
        console.log('忘记密码')
      }else if(this.type ==2){
        console.log('手机号登录')
      }
      console.log('submit', values);
    },
    forFetPsword() {
      this.type = 1
      this.checked = false
    },
    SignIn() {
      this.$router.push("sign");
    },
    gologin() {
      this.type = 3
      this.checked = false
    },
  },
  created() {
  },
  mounted() {
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
};
</script>
<style lang="scss" scoped>

//app
@media (min-width: 768px) {
  #app {
    width: 750px !important;
    margin: 0 auto !important;
  }
}

.navigation {
  position: relative;
  z-index: 4;
}

#app {
  min-height: 100vh;
  // background: red;

  position: relative;
  background: #F9FBFD;
  overflow-y: scroll;
  overflow-x: hidden;

  .back {
    position: absolute;
    height: 492px;
    border-radius: 190px;
    opacity: .8;
    filter: blur(90px);
  }

  .backOne {
    width: 388px;
    transform: translate(40%, -50%) rotate(45deg);
    background: #F2F9FB;
    right: 0;
    top: 0;
    border-radius: 114px;
  }

  .backTow {
    width: 286px;
    transform: translate(-50%, -50%) rotate(50deg);
    background: #ECECFB;
    left: 0;
    top: 55%;
  }

  .backThe {
    width: 422px;
    background: #EEF5FF;
    transform: translate(30%, 40%) rotate(45deg);
    right: 0;
    bottom: 0;
  }

}

.area {
  position: relative;
  z-index: 4;

  .topLogoBox {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    justify-content: center;
    align-items: center;

    .img {
      width: 84px;
    }

    .logoText {
      margin-top: 20px;
    }
  }
}

.loginForom {
  margin-top: 5vh;

  .van-cell {
    background: transparent !important;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    padding-bottom: 20px;

    .leftLable {
      font-size: 16px;
      font-weight: 500;
    }

    .input {
      border: none;
      background: transparent;
      font-size: 14px;
      color: #666666;
    }
  }

  .checkOutBox {
    margin-left: 30px;
    margin-top: 3vh;

    .aHerf {
      color: #FC223B;
    }
  }

  .submitLog {
    margin: 5vh 30px 0 30px;

    .btn {
      background: #FC223B;
      border: 1px solid #FC223B;
      border-radius: 10px;
      padding: 26px 0;
      font-size: 16px;
    }

    .btn::after,
    .btn::before {
      display: none;
    }
  }

  .bottomFun {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .Fun {
      color: #333333;
      font-size: 14px;
    }
    .leftFun{
      margin-left: 30px;
    }
    .rightFun{
      margin-right: 30px;
    }
    .rightBtn{
      margin-right: 30px;
    }
  }
}

.otherLoginMethotd {
  margin-top: 15vh;

  .topLine {
    position: relative;
    color: #666666;
    font-size: 12px;
    line-height: 24px;
    display: inline-block;
  }

  .topLine:after,
  .topLine:before {
    content: '';
    display: block;
    position: absolute;
    width: 38px;
    height: 2px;
    transform: translate(-50%, -50%)
  }

  .topLine:before {
    right: -100%;
    top: 50%;
    background: linear-gradient(90deg, rgba(249, 100, 34, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    // background: red;
  }

  .topLine::after {
    left: -80%;
    top: 50%;
    background: linear-gradient(90deg, rgba(249, 100, 34, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    // background: #000
    transform: rotate(180deg);
  }

  .otherLi {
    margin-top: 2vh;
    display: flex;
    justify-content: center;

    .other-item {
      display: flex;
      flex-direction: column;
      font-size: 12px;

      .img {
        width: 39.88px;
      }

      .iconfont {
        font-size: 20px;
      }
    }
  }
}
</style>