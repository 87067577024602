<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {delPublish, enablePublish, getMyPublishList} from "@/utils/api";
import {Dialog, Toast} from "vant";
import {isValidJSON} from "@/utils/tool";

export default {
  name: "myRelease",
  components: {navigationBar},
  data() {
    return {
      active: 0,
      tabsLi: [
        {text: '全部', list: [], page: 1, limit: 10,loading: false,noMore: false,},
        {text: '在卖', list: [], page: 1, limit: 10,loading: false,noMore: false,},
        {text: '已下架', list: [], page: 1, limit: 10,loading: false,noMore: false,},
      ],
    }
  },
  methods: {
    getList() {
      getMyPublishList(this.$store.state.token, this.tabsLi[this.active].limit, this.tabsLi[this.active].page, this.is_sale).then(res => {
        if (res.data.status == true) {
          res.data.data.total
          let list = res.data.data.data
          list.forEach(item => {
            console.log(item)
            if (item.images) {
              console.log(isValidJSON(item.images))
              if (isValidJSON(item.images)) {
                item.showImg = JSON.parse(item.images)[0]
              } else {
                item.showImg = item.images.split(',')[0]
              }
              if (typeof item.showImg == 'string') {
                if (!item.showImg.startsWith('https:')) {
                  item.showImg = this.$store.state.imageHost + item.showImg
                } else {
                  item.showImg = item.showImg
                }
              } else {
                item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
              }
            }
          })
          this.tabsLi[this.active].list = list
          // this.tabsLi[this.active].list = this.tabsLi[this.active].list.concat(list);
          console.log(this.tabsLi[this.active].list)
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    tabsChange() {
      this.getList()
    },

    load() {
      console.log('加载=========')
      this.tabsLi[this.active].loading = true
      if (this.tabsLi[this.active].noMore) {
        return
      }
      let page = this.tabsLi[this.active].page
      let limit = this.tabsLi[this.active].limit += 10
        getMyPublishList(this.$store.state.token,  limit, page, this.is_sale).then(res => {
          if (res.data.status == true) {
            console.log(res.data.data.data)
            let maxlength = res.data.data.total
            let list = res.data.data.data
            list.forEach(item => {
              item.showImg = item.images[0]
              if (item.images) {
                console.log(isValidJSON(item.images))
                if (isValidJSON(item.images)) {
                  item.showImg = JSON.parse(item.images)[0]
                } else {
                  item.showImg = item.images.split(',')[0]
                }
                if (typeof item.showImg == 'string') {
                  if (!item.showImg.startsWith('https:')) {
                    item.showImg = this.$store.state.imageHost + item.showImg
                  } else {
                    item.showImg = item.showImg
                  }
                } else {
                  item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
                }
              }
            })
            this.tabsLi[this.active].list = list
            if (list.length >= maxlength) {
              this.tabsLi[this.active].noMore = true
              this.tabsLi[this.active].loading = false
            }
            this.tabsLi[this.active].loading = false
            this.tabsLi[this.active].maxlength = maxlength
          } else {
            Toast(res.data.msg)
          }
        })



    },



    deleteBtn(item,index){
      console.log('删除')
      Dialog.confirm({
        title: '确定删除宝贝吗？',
      })
          .then(() => {
            delPublish(this.$store.state.token,item.id).then(res => {
              console.log(res)
              if(res.data.status == true){
                Toast('删除成功')
                this.tabsLi[this.active].list.splice(index,1)
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    editBtn(e){
      console.log('编辑')
      this.$router.push({
        path:'/publish',
        query:{item:JSON.stringify(e)}
      })
    },
    putawayBtn(item){
      Dialog.confirm({
        title: '确定上架宝贝吗？',
      }).then(()=>{
        enablePublish(this.$store.state.token,item.id,1).then(res=>{
          if(res.data.status == true){
            item.on_sale = 1
          }
        })
      }).catch(()=>{

      })

    },
    downBtn(item){
      Dialog.confirm({
        title: '确定下架宝贝吗？',
      }).then(()=>{
        enablePublish(this.$store.state.token,item.id,2).then(res=>{
          if(res.data.status == true){
            item.on_sale = 2
          }
        })
      }).catch(()=>{

      })

    },

  },
  computed: {
    is_sale() {
      return this.active == 0 ? 0 : this.active == 1 ? 1 : 2
    },
    disabled() {
      return (this.active!=-1?this.tabsLi[this.active].loading:true) || (this.active!=-1?this.tabsLi[this.active].noMore:false)
    },
    noMore() {
      return this.tabsLi[this.active].list.length >= this.tabsLi[this.active >= 0 ? this.active : 0].maxlength
    },
    
    
  },
  mounted() {
    // this.getList()
  },

}
</script>

<template>
  <div class="container release">
    <div class="area">
      <navigationBar :title="'我的发布'" class="navigationBar"></navigationBar>
      <van-tabs v-model="active" animated class="vanTabs" @change="tabsChange">
<!--        <van-tab v-for="(item,index) in tabsLi" :key="index" :title="item.text">-->
<!--          <div class="ul" v-infinite-scroll="load"-->
<!--               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">-->

<!--            <p v-if="tabsLi[index].loading">加载中...</p>-->
<!--            <p v-if="tabsLi[index].noMore">没有更多了</p>-->
<!--          </div>-->
<!--        </van-tab>-->


        <van-tab
            v-for="(item, index) in tabsLi"
            :title="item.name"
            :key="index"
        >
          <template #title>{{ item.text }}</template>
          <div class="ul" v-infinite-scroll="load"
               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">
            <div v-for="(itemc,indexcc) in tabsLi[index].list" :key="indexcc+'itemc'" class="li">
              <!--                  {{active}}-->
                <div class="item">
<!--                  on_sale:{{itemc.on_sale}}-->
                  <!--              <div class="top">-->
                  <!--                <img src="https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png" class="avatar" alt="">-->
                  <!--                <span class="nickname">o泡果奶o泡果奶o泡果奶o泡果奶o泡果奶</span>-->
                  <!--              </div>-->
                  <div class="center">
                    <div class="left">
                      <img :src="itemc.showImg" alt="" class="showimg">
                    </div>
                    <div class="right">
                      <div class="name">{{ itemc.product_name }}</div>
                      <div class="price">￥<span class="nums">{{itemc.price}}</span></div>
                    </div>
                  </div>
                  <div class="footer">
                    <div class="left">
                      <div class="btn" @click="deleteBtn(itemc,indexcc)" v-if="itemc.on_sale == 1">删除</div>
                    </div>
                    <div class="right">
                      <div class="btn" @click="editBtn(itemc)" v-if="itemc.on_sale == 1">编辑</div>
                      <div class="btn" @click="putawayBtn(itemc)" v-if="itemc.on_sale == 2">上架 </div>
                      <div class="btn" @click="downBtn(itemc)"  v-if="itemc.on_sale == 1">下架 </div>
                    </div>
                  </div>
                </div>
            </div>
            <p v-if="tabsLi[index].loading">加载中...</p>
            <p v-if="tabsLi[index].noMore">没有更多了</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #f4f6fa;
  height: 100vh;
}
.container::-webkit-scrollbar{
  display: none;
}
.area {
  height: 100vh;
}

.navigationBar {
  background: #fff;
}

.vanTabs .van-tabs__wrap {
  background: #fff;
}

.vanTabs {

  .ul {
    height: 100vh;
    overflow: scroll;

    .li {

      .item {

        border-radius: 10px;
        background: #ffffff;
        margin: 10px 16px;
        padding: 10px;

        .top {
          text-align: left;

          .avatar {
            width: 20px;
            height: 20px;
            object-fit: cover;
            vertical-align: middle;
          }

          .nickname {
            color: #999999;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle;
            display: inline-block;
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
        }

        .center {
          display: flex;

          .left {
            width: 74px;
            height: 74px;

            .showimg {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
          }

          .right {
            flex: 1;
            text-align: left;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name{
              display: -webkit-box;
              /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
              -webkit-box-orient:vertical;
              /*要显示的行数*/
              -webkit-line-clamp:2;
              /* 溢出部分隐藏 */
              overflow:hidden;
              color: #1b1b1b;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px
            }
            .price{
              font-size: 10px;
              color: #fd2a39;
              font-weight: 400;
              line-height: 24px;

              .nums{
                font-weight: 500;
                font-size: 18px;
              }
            }
          }
        }

        .footer {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          .left{
            width: 25%;
            text-align: left;
            .btn{

            }
          }
          .right{
            width: 75%;
            display: flex;
            justify-content: flex-end;
            .btn{
              margin-left: 10px;
            }
          }
          .btn{
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            padding: 6px 10px;
            border-radius: 25px;
            border: 0.5px solid #999999;
            color: #1b1b1b;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .ul::-webkit-scrollbar{
    display: none;
  }
}
</style>
<style>

.release .van-tab {
  flex: none;
  width: 15%;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.release .van-tab--active {
  text-align: center;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.release .van-tabs--line .van-tabs__wrap {
  padding-bottom: 10px;
}
</style>