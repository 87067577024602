<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {_throttle} from "@/utils/tool";
import {getRecoveryShopList} from "@/utils/api";

export default {
  name: "WorkerList",
  components: {navigationBar},
  data() {
    return {
      obj: {},
      page: 0,
      limit: 10,
      list: [],
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      oldScrollTop: 0,


    }
  },
  methods: {
    getList() {
      getRecoveryShopList(this.$store.state.token, this.obj.type).then(res => {
        if (res.data.status == true) {
          console.log(res.data.data.data)
          let list = res.data.data.data
          //`````````````````````````````````````` 没分页
          this.list = list
          this.hasMore = false//是否可以继续滚动
          //`````````````````// 没分页

          //``````````````````````````````````````` 分页
          // this.list = this.list.concat(list)//分页·
          // if(this.list.length>=res.data.data.total){
          //   this.hasMore=false//是否可以继续滚动
          // }
          //````````````````` 分页
          this.loadLock = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    qingqiu: _throttle(async function () {
      this.page += 1
      this.getList()
    }, 500),

    mobile(){

    },
  },
  mounted() {
    this.obj = JSON.parse(this.$route.query.item)
    console.log(this.obj)
    this.qingqiu()
  },
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <!--      师傅电话列表-->
      <navigationBar :title="this.obj.title" class="navigationBar"></navigationBar>

      <div class="ul">
        <div class="li" v-for="(item,index) in list" :key="index">
          <div class="item">
            <div class="left">
              <img src="" class="image" alt="">
            </div>
            <div class="right">
              <div class="topOu">
                <div class="top">
                  <span class="name">{{ item.nickname }}</span>
                  <span class="tag">已实名</span>
                  <img :src="$store.state.imageHost+'/img/idle_work_card.png'" class="cardImg" alt="">
                </div>
                <a :href="'tel:'+item.mobile"><span class="iconfont icon-dianhua2" @click='mobile(item)'></span></a>
              </div>
              <div class="text">
                北京朝阳高价上门回收各种废品、闲置物品
              </div>
              <div class="text">
                回收类型：
                北京朝阳高价上门回收各种废品、闲置物品
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.navigationBar {
  background-color: #fff;
}

.container {
  height: 100vh;

  .area {
    height: 100vh;
    background: #f4f6fa;
  }
}

.ul {

  .li {
    border-radius: 10px;
    background: #ffffff;
    margin: 10px 16px;
    padding: 10px;
    border-radius: 10px;

    .item {
      display: flex;

      .left {
        width: 20%;
        .image {
          width: 52px;
          height: 52px;
          border-radius: 43px;
        }
      }

      .right {
        padding-left: 10px;
        width: 80%;
        .text{
          color: #c7c7c7;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          text-align: left;
          margin-bottom: 4px;
        }
        .topOu{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 6px;
          .iconfont{
            font-size: 20px;
            color: #666666;
          }
        }
        .top{
          width: 85%;
          text-align: left;
          span{
            display: inline-block;
          }
          .name{
            max-width: 65%;
            color: #1b1b1b;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            vertical-align: middle;
            text-align: left;
          }
          .tag{
            width: fit-content;
            height: fit-content;
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            padding: 2px 6px;
            border-radius: 5px;
            background: #fff4e9;
            color: #ff9f4d;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            vertical-align: middle;
            margin: 0 5px;

          }
          .cardImg{
            width: 24px;
            height: 24px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

</style>