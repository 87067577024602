import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import routes from './routes'
// import {sendApp} from "@/utils/tool";

if (sessionStorage.getItem('token')) {
    store.commit('set_token', sessionStorage.getItem('token'))
}
Vue.use(VueRouter);

let whiteList = [
    '/home',//首页
    '/mine', //我的
    '/message',//信息
    '/car', //购物车
    '/share',//分享
    '/Login',//登录
    '/sign',//注册
    '/water',//水站
    '/water_details',//水站详情

    "/buyPail",//购买空桶
    '/service_page',//室内快修
    '/my_acceptance',//我的质保
    '/fitment_details',//装修质保
    '/service_order',//快修订单
    '/supmarket',//超市
    '/house_home',//房产
    '/house_publish',//发布tab
    '/house_mine',//房产我的tab
    '/materials_home',//建材市场首页tabbar
    '/materials_car',//建材购物车
    '/materials_order',//建材订单
    '/userInfo',//我的  用户信息
    '/order_list',//我的  订单列表
    // "/exitPail",//我的   退桶押金
    // "/myWallet",//我的   我的钱包
    "/userAddress",//我的   用户地址
    "/invoiceManagement",//我的   发票管理
    // "/setDetails",//我的  修改详情
    "/water_coupon",//水站百街水票
    "/one_key_send_water",//水站一键送水
    "/vip_page",//vip页面
    "/agreement",//协议
    "/idle_home",//闲置转让
    "/agreement",//闲置转让
    "/invite_home",//


    // "/employee_home"
]
const router = new VueRouter({
    routes,
});

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
    if (sessionStorage.getItem('token')) {
        next()
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            if (to.path != '/Login') {
                if (window.ReactNativeWebView) {
                    // window.ReactNativeWebView.postMessage("8");
                    // sendApp()
                    window.sendFun()
                    // next()
                } else {
                    next('/Login')
                }
            }
            next()
        }


    }

});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if (resolve || reject) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch(() => {

    })
}


export default router;