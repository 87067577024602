<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {editShip, getUserShip, getUserShip_ster, removeShip} from "@/utils/api";
import {Dialog, Toast} from "vant";
import Bus from "@/utils/Bus";
export default {
  name: "userAddress",
  components: {
    navigationBar
  },
  data(){
    return{
      userAddress:[],
      list:[],
      type:1,//1列表  2选择
      moren:'',
    }
  },
  methods:{
    getList(){
      getUserShip(this.$store.state.token).then((res)=>{
        if(res.data.status==true){
          let list = res.data.data
          list.forEach(item=>{
            if(item.is_def == 1){
              this.moren = item.id
            }
          })
          if(this.$route.query.id){
            this.type = 2
            this.moren = list.find(item=>{return item.id == this.$route.query.id}).id
          }
          console.log(list,this.moren)
          this.list = list
        }
      })
    },
    addAddress(){
      this.$router.push({
        path:'/addAddress',
        query:{},
      })
    },
    edit(e){
      this.$router.push({
        path:'/addAddress',
        query:{item:JSON.stringify(e)},
      })
    },
    deleteFun(e){
      Dialog.confirm({
        title: '确定删除地址吗？',
      }).then(()=>{
        removeShip(this.$store.state.token,e.id).then(res=>{
          if(res.data.status==true){
            this.getList()
          }else{
            Toast(res.data.msg)
          }
        })
      }).catch(()=>{
        //
      })
    },

    radioChange(item){
      editShip(
          this.$store.state.token,
          item.name,
          item.address,
          item.mobile,
          1,
          item.province_name,
          item.city_name,
          item.district_name,
          item.province_id,
          item.city_id,
          item.district_id,
          item.details_name?item.details_name:'',
          item.id ? item.id : '',
      ).then(res=>{
        if(res.data.status == true){
          this.getList()
        }
      })
    },


    radioChangeChoose(item){
      console.log(item)
      getUserShip_ster(this.$store.state.token,item.id).then((res)=>{
        if(res.data.status==true){
          Bus.$emit("updataUserInfo", '1');
          Bus.$on('sendUserInfo',(msg)=>{
            console.log(msg,'msg')
            this.$router.go(-1)
          })
        }else{
          Toast(res.data.msg)
        }
      })
    },
  },
  async mounted() {
    await this.getList()
    if(this.$route.query.id){
      this.type = 2
    }
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="'用户地址'"></navigationBar>
    <div class="ul" v-if="list.length>0">
      <div class="li" v-for="(item,index) in list" :key="index">
        <div class="iteme">
          <van-radio-group v-model="moren" >
            <div class="item">
              <div class="head"  v-if="type==1">
                <div class="head_top">
                  <span class="name">{{item.name}}</span>
                  <span class="mobile">{{item.mobile}}</span>
                  <van-tag color="#FFF3ED" text-color="#F96422" style="margin-left: 0;" v-if="item.is_def === 1">默认</van-tag>
                </div>
                <div class="addressDetails">{{item.province_name+item.city_name+item.district_name+item.address}}</div>

              </div>
              <div class="foot" v-if="type==1">
                <div class="left">
                  <van-radio @click="radioChange(item)" :name="item.id" checked-color="#ee0a24">单选框 1</van-radio>
                  <!--                <van-radio v-model="item.moren" class="radio" icon-size="18px">默认收货地址</van-radio>-->
                </div>
                <div class="right">
                  <div class="righttext" @click="edit(item)">
                    <span class="iconfont icon-bianji1 icon"></span> 编辑
                  </div>
                  <div class="righttext deleteBtn"  @click="deleteFun(item)">
                    <span class="iconfont icon-shanchu1 icon"></span>删除
                  </div>
                </div>
              </div>
              <div class="outItem" v-if="type == 2">
                <van-radio class="radioFun" @click="radioChangeChoose(item)" :name="item.id" checked-color="#ee0a24">
                  <div class="head" v-if="type == 2">
                    <div class="head_top">
                      <span class="name">{{item.name}}</span>
                      <span class="mobile">{{item.mobile}}</span>
                      <van-tag color="#FFF3ED" text-color="#F96422" style="margin-left: 0;" v-if="item.is_def === 1">默认</van-tag>
                    </div>
                    <div class="addressDetails">{{item.province_name+item.city_name+item.district_name+item.address}}</div>
                  </div>
                </van-radio>
              </div>

            </div>
          </van-radio-group>
          <div class="editRight" v-if="type === 2">
            <div class="righttext" bindtap="edit" >
              <span class="iconfont icon-bianji1 icon"></span> 编辑
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-if="list.length<=0">
      暂无收货地址
    </div>
    <div class="bottomBox">
      <button @click="addAddress" class="sendBtn">添加地址</button>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.bottomBox{
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 20px 0;
  width: 100%;
  .sendBtn{
    border-radius: 10px;
    background: #fc223b;
    border: none;
    color: #fff;
    padding: 14px;
    width: 85%;
  }
}
.ul{
  padding-bottom: 100px;
  .li{
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 2px #66666640;
    margin: 10px 16px;
    padding: 10px;
    .item{
      .head{
        text-align: left;
        .head_top{
          padding-bottom: 4px;
        }
        .addressDetails{
          padding-bottom: 10px;
        }
      }
      .outItem{
        display: flex;
        align-items: center;
        .radioFun{
          margin-right: 15px;
        }
        .head{
          flex: 1;
        }
      }

      .foot{
        border-top: 1px solid #eeeeee;
        margin: 0px 10px;
        padding: 10px 0 0 0 ;
        display: flex;
        justify-content: space-between;
        .left{
          color: #999999;
          font-size: 14px;
        }
        .right{
          display: flex;
          align-items: center;
          .righttext {
            text-align: center;
            font-size: 13px;
            color: #999999;
          }
          .deleteBtn {
            margin-left: 15px;
          }
        }
      }

    }
    .iteme{
      display: flex;
      align-items: center;
      .van-radio-group{
        flex: 1;
      }
    }
    .editRight{

      .righttext{
        display: flex;
        flex-direction: column;
        font-size: 13px;
        color: #666666;
      }

    }

  }
}
</style>