<template>
  <div class="accpetance">
    <navigationBar :title="'我的质保'" class="navigation"></navigationBar>
    <div class="container">
      <div class="area">
        <div class="topNav">
          <div class="kuaixiu btn" :class="chooseIndex == 1 ? 'chooseInde' : ''" @click='chooseIndexFun(1)'>快修质保
          </div>
          <div class="zhibao btn" :class="chooseIndex == 2 ? 'chooseInde' : ''" @click='chooseIndexFun(2)'>装修质保
          </div>
        </div>
        <div class="ul">
          <div class="" v-for="(item,index) in list" :key="index">
            <div class="li" @click.stop="godetails(item)">
              <div class="top">
                <div class="leftimg">
                  <span class="imgText kuaixiu" v-if="chooseIndex == 1">快修质保</span>
                  <span class="imgText zhuangxiu" :class="item.status == 2? 'no':'yes'"
                        v-if="chooseIndex == 2">装修质保</span>
                  <img
                      :src="$store.state.imageHost+item.image.tempFilePath" alt=""
                      class="orderImg" v-if="item.image.fileType=='image'">
                </div>
                <div class="rightInfo">
                  <div class="titeBox">
                    <div class="title">{{ item.maintenance_content }}</div>
                    <div class="time">快修质保期剩余
                      <van-count-down class="shengyu" :time="item.shengyu" format="DD"/>
                      天
                    </div>
                  </div>
                  <div class="orderNums">订单编号：{{ item.order_id }}</div>
                </div>
              </div>
              <div class="bottom">
                <div class="time zhutiColor">验收时间：{{ item.confirm_timec }}</div>
                <div class="address">{{ chooseIndex == 1 ? '维修地址' : '装修地址' }}：{{ item.ship_address_ds }}</div>
              </div>
              <div class="botton">
                <div class="zhibao" v-if="userId==-1&&chooseIndex==1" @click.stop="zhibao(item)">一键质保</div>
                <div class="zhibao" :class="item.status==1?'keyizhuangxiu':'zhuangxiu'"
                     v-if="userId==-1&&chooseIndex==2" @click.stop="zhibao(item)">一键质保
                </div>
              </div>
            </div>
            <div class="nopay" v-if="userId==-1&&chooseIndex == 2&& item.status==2&&item.shengyu>0">
              <!--            <div class="nopay">-->
              请您尽快结清余款，启动一键质保
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigationBar from '../../navigation-bar/navigation-bar.vue';
import {get_user_construction, get_user_order, get_zhibao_status, getNowTime} from "@/utils/api";
import {Toast} from "vant";

export default {
  components: {
    navigationBar
  },
  props: {},
  data() {
    return {
      chooseIndex: 1,
      userId: -1,
      list: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    chooseIndexFun(e) {
      if (this.chooseIndex == e) return;
      this.chooseIndex = e
      if (this.chooseIndex == 1) {
        if (this.userId == -1) {
          this.getList()
        } else {
          this.getList(this.userId)
        }
      }
      if (this.chooseIndex == 2) {
        this.getZzhuangxiuList(this.$store.state.user.id)
      }
    },
    zhibao(item) {
      if (this.userId == -1) {
        // if(this)
        console.log(item)
        get_zhibao_status(this.$store.state.token,item.order_id).then(res=>{
          if (res.data.status==true){
              this.$router.push({
                path: '/service_page',
                query: {item: JSON.stringify(item),index:0,identity:1,type:'set'}
                // query:{item:JSON.stringify(obj),index:fourStepFlag,identity:1},
              })
            console.log(res)
          }else{
            Toast(res.data.msg)
          }
        })
      }
      console.log(222);
    },
    godetails(item) {
      if (this.userId != -1) return;
      // this.$router.push('fitment_details')
      // this.$router.push({
      //   name:'/fitment_details',
      //   params: { id: 1 }
      // })
      if (item.behalf_party) {
        this.$router.push({
          path: '/fitment_details',
          query: {id: item.id}
        })
      } else {
        this.chakn(item)
        // }
      }


    },
    chakn(e) {
      if (e.behalf_party) {
        this.$router.push({
          path: '/fitment_details',
          query: {id: e.id}
        })
      }else{
        let obj = e
        let fourStepFlag = 2
        if(obj.status==2){ //1正常 2完成 3取消
          fourStepFlag = 3
          if(this.userId!=-1){
            fourStepFlag = 2
          }
          this.$router.push({
            path:'/service_page',
            query:{item:JSON.stringify(obj),index:fourStepFlag,identity:1},
          })
          return
        }

        if(obj.items.status!=2){
          fourStepFlag = 1
        }
        if(obj.items.status==2){
          fourStepFlag = 1
        }
        if(obj.items.maintenance_status==2){
          fourStepFlag = 2
        }
        if(obj.items.maintenance_status==3){
          fourStepFlag = 2
        }
        if(obj.confirm==2){
          fourStepFlag = 3
          if(this.$store.state.user.user_authority!=10){
            fourStepFlag = 2
          }
        }
        this.$router.push({
          path:'/service_page',
          query:{item:JSON.stringify(obj),index:fourStepFlag,identity:1},
        })
      }
    },
    getData(time) {
      let date = new Date(time * 1000);
      let y = date.getFullYear();
      let mon = date.getMonth() + 1;
      let d = date.getDate();
      let h = date.getHours();
      let min = date.getMinutes();
      mon = mon < 10 ? '0' + mon : mon
      d = d < 10 ? '0' + d : d
      h = h < 10 ? '0' + h : h
      min = min < 10 ? '0' + min : min
      return y + '-' + mon + '-' + d + ' ' + h + ':' + min
    },
    getList(e) {
      if (this.chooseIndex == 1) {
        this.getMylist(e)
      }
      if (this.chooseIndex == 2) {
        this.getZzhuangxiuList(e)
      }
    },
    getMylist(e) {
      get_user_order(this.$store.state.token, e ? e : this.$store.state.user.id).then(res => {
        console.log(res)
        if (res.data.status == true) {
          let list = res.data.data
          list.forEach(item => {
            console.log(item)
            item.confirm_timec = this.getData(item.confirm_time)
            item.shengyu = (item.confirm_time * 1000 + (60 * 60 * 24 * 30 * 1000)) - new Date(this.serverTime * 1000).getTime()
            item.image = JSON.parse(item.files_user)[0]
          })
          this.list = list
        } else {
          Toast(res.data.msg)
        }
      })
    },
    getZzhuangxiuList(e) {
      get_user_construction(this.$store.state.token, e ? e : this.$store.state.user.id).then(res => {
        if (res.data.status == true) {
          let list = res.data.data
          let day = 0
          list.forEach(item => {
            console.log(item);
            // 图片
            item.image = {
              fileType: 'image',
              tempFilePath: item.rendering
            }
            // 名称`
            item.maintenance_content = item.name
            // 剩余时间
            item.shengyu = (new Date(item.construction_delay_end).getTime() + (60 * 60 * 24 * day * 1000)) - new Date(this.serverTime * 1000).getTime()
            if (item.shengyu <= 0) {
              item.status = 2
            }
            // 验收时间
            item.confirm_timec = item.completion_acceptance_time
            // 维修地址
            item.ship_address_ds = item.decoration_address
            item.order_id = item.id
          })
          this.list = list
        } else {
          Toast(res.data.msg)
        }
      })

    },
  },
  created() {
  },
  async mounted() {
    const {data} = await getNowTime()
    this.serverTime = data.data
    if (this.$route.query.id) {
      this.getList(this.$route.query.id)
    } else {
      this.getList()
    }
  }
};
</script>
<style lang="scss" scoped>
.navigation {
  background: #fff;
}

.accpetance {
  background-color: #F4F6FA;
}

.area {
  padding: 0 16px;

  .topNav {
    margin: 10px auto;
    display: flex;
    border: 1px solid rgba(153, 153, 153, 0.15);
    border-radius: 10px 10px 10px 10px;

    .kuaixiu {
    }

    .zhibao {
    }

    .btn {
      width: 49%;
      font-size: 13px;
      color: #666666;
      padding: 11px;
    }

    .chooseInde {
      background: #FF824B;
      border-radius: 10px 10px 10px 10px;
      color: #fff;
    }
  }

  .ul {
    .li {
      background: #fff;
      border-radius: 10px;
      padding: 10px;

      .top {
        display: flex;

        .leftimg {
          width: 70px;
          height: 70px;
          border-radius: 10px;
          position: relative;

          .imgText {
            position: absolute;
            left: 50%;
            bottom: 0;
            font-size: 12px;
            line-height: 18px;
            border-radius: 17px 17px 17px 17px;
            background: #FC223B;
            color: #fff;
            padding: 0 5px;
            transform: translate(-50%, -0%);
            display: block;
            white-space: nowrap;
          }

          .kuaixiu {
            background-color: #286CFB;
            color: white;
          }

          .zhuangxiu {
            background-color: #666666 !important;
          }

          .no {
            background-color: #666666 !important;
          }

          .yes {
            background-color: #f96422 !important;
          }

          .orderImg {
            width: 70px;
            height: 70px;
            border-radius: 10px;
          }
        }

        .rightInfo {
          width: 80%;
          margin-left: 10px;

          .titeBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              width: 50%;
              text-align: left;
              font-weight: 400;
              font-size: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #1B1B1B;
            }

            .time {
              width: 50%;
              text-align: right;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // color: #FC223B;
              font-size: 13px;
              display: flex;
              align-items: center;
            }
          }

          .orderNums {
            text-align: left;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #666666;
            font-size: 12px;
          }
        }
      }

      .bottom {
        margin-top: 4px;
        color: #666666;
        text-align: left;
        font-size: 14px;
        display: -webkit-box;
        /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
        -webkit-box-orient: vertical;
        /*要显示的行数*/
        -webkit-line-clamp: 2;
        /* 溢出部分隐藏 */
        overflow: hidden;
      }

      .botton {
        margin-top: 4px;
        text-align: right;

        .zhibao {
          display: inline-block;
          color: #fff;
          border-radius: 8px 8px 8px 8px;
          background: #FC223B;
          padding: 8px 16px;
        }

        .keyizhuangxiu {
          background-color: #f96422 !important;
        }

        .zhuangxiu {
          background-color: #666666 !important;
        }
      }
    }

    .nopay {
      margin: -5px auto 0 auto;
      text-align: center;
      background-color: #FFE4E4;
      border-radius: 0 0 10px 10px;
      padding-top: 15px;
      width: 100%;
      font-size: 14px;
      color: #F74440;
      padding-bottom: 5px;
    }
  }
}

</style>