<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getParentIdNew} from "@/utils/tool";
import {editShip} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "addAddress",
  components: {navigationBar},
  data() {
    return {
      ruleForm: {
        address: '',
        province_name: '',
        province_id: '',
        city_name: '',
        city_id: '',
        district_name: '',
        district_id: '',
        details_name: '',
        detailsAddress: '',
        name: '',
        mobile: '',
      },
      rules: {
        address: [
          {required: true, message: '请选择地址', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], detailsAddress: [
          {required: true, message: '请填写详细地址', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], name: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], mobile: [
          {required: true, message: '请输入联系人电话', trigger: 'blur'},
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
      },
      addressList: this.$store.state.allAddress,
      addPop: false,
      options: [],

      cascaderValue: '',
      is_default: false,

      ieldnames: {
        text: 'name',
        value: 'id',
      },

    }
  },
  watch: {
    addressList(n) {
      this.options = n.filter(item => {
        return item.id == 110000
      }).map(item => {
        return {name: item.name, value: item.id, children: []}
      })
    }
  },
  computed:{
    id_def(){
      return this.is_default?1:2
    },
  },
  mounted() {
    this.options = this.$store.state.allAddress.filter(item => {
      return item.id == 110000
    }).map(item => {
      return {name: item.name, value: item.id, children: []}
    })

    if(this.$route.query.item){
      if(JSON.parse(this.$route.query.item).id){
        let obj = JSON.parse(this.$route.query.item)
        this.ruleForm = {
              address:obj.province_name+' '+obj.city_name+' '+obj.district_name+' '+obj.details_name,
              province_name: obj.province_name,
              province_id:obj.province_id,
              city_name: obj.city_name,
              city_id: obj.city_id,
              district_name: obj.district_name,
              district_id: obj.district_id,
              details_name: obj.details_name?obj.details_name:'',
              detailsAddress:obj.address,
              name: obj.name,
              mobile: obj.mobile,
              id: obj.id,
        }
          this.is_default = obj.is_def == 1?true:false
        console.log(JSON.parse(this.$route.query.item))
      }
    }
  },
  methods: {
    submitFormc(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editShip(
              this.$store.state.token,
              this.ruleForm.name,
              this.ruleForm.detailsAddress,
              this.ruleForm.mobile,
              this.id_def,
              this.ruleForm.province_name,
              this.ruleForm.city_name,
              this.ruleForm.district_name,
              this.ruleForm.province_id,
              this.ruleForm.city_id,
              this.ruleForm.district_id,
              this.ruleForm.details_name,
              this.ruleForm.id ? this.ruleForm.id : '',
          ).then(res=>{
            console.log(res.data.status)
            if(res.data.status == true){
              this.$router.go(-1)
            }else{
              Toast(res.data.msg)
            }
          })
        }
      })
    },
    openAddressPop() {
      this.options = this.$store.state.allAddress.filter(item => {
        return item.id == 110000
      }).map(item => {
        return {name: item.name, id: item.id, children: []}
      })
      this.addPop = true
    },
    // onFinish({ selectedOptions }){
    onFinish(e) {
      this.addPop = false;
      this.cascaderValue = e.selectedOptions.map((item) => item.name).join(' ')
      this.ruleForm.address = this.cascaderValue
      this.ruleForm.province_name = e.selectedOptions[0].name
      this.ruleForm.province_id = e.selectedOptions[0].id
      this.ruleForm.city_name = e.selectedOptions[1].name
      this.ruleForm.city_id = e.selectedOptions[1].id
      this.ruleForm.district_name = e.selectedOptions[2].name
      this.ruleForm.district_id = e.selectedOptions[2].id
      this.ruleForm.details_name = e.selectedOptions[3].name
    },
    onChange(e) {
      if (e.tabIndex == 0) {
        if (e.value === this.options[0].id) {
          setTimeout(() => {
            this.options[0].children = getParentIdNew(this.$store.state.allAddress, 'id', e.value, 'children').children.map(item => {
              return {name: item.name, id: item.id, children: []}
            })
          }, 200);
        }
      }
      if (e.tabIndex == 1) {
        if (e.value === this.options[0].children[0].id) {
          setTimeout(() => {
            let list = (getParentIdNew(this.$store.state.allAddress, 'id', e.value, 'children').children.find(item => {
              return item.id == 110105
            }))

            this.options[0].children[0].children = [
              {
                name: list.name,
                id: list.id,
                children: [
                  {name: '三里屯SOHO', id: 1},
                  {name: '通盈中心', id: 2},
                ]
              },
            ]

          }, 200);
        }
      }
    },
    changeSwitch() {
      this.is_default = !this.is_default
    },
  },
}
</script>

<template>
  <div class="ccontainer addressPage">
    <div class="area">
      <navigationBar :title="'添加地址'"></navigationBar>
      <div class="card addAddress">
        <div class="titleBox">
          <div class="leftTitle">地址信息</div>
          <div class="rightSwitch" @click="changeSwitch">
            默认收货地址
            <van-switch class="switch" v-model="is_default" size="12px" active-color="#FC223B"
                        inactive-color="#e9e9ea"/>
          </div>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="所在地区" prop="address" @click="openAddressPop">
            <div class="line">
              <!--            <input type="text" placeholder="" class="title input" v-model="ruleForm.address"-->
              <!--                   maxlength="22" disabled  @click="openAddressPop">-->
              <div class="texte" :class="ruleForm.address?'':'placeholder'" @click="openAddressPop">
                {{ ruleForm.address ? ruleForm.address : '省、市、区、街道' }}
              </div>
              <span class="iconfont icon-Frame-4" @click="openAddressPop"></span>
            </div>
          </el-form-item>
          <el-form-item label="收货地址" prop="detailsAddress">
            <div class="line">
              <input type="text" placeholder="请填写详细收货地址" class="title input" v-model="ruleForm.detailsAddress"
                     maxlength="50">
            </div>
          </el-form-item>
          <el-form-item label="联系人" prop="name">
            <div class="line">
              <input type="text" placeholder="名字" class="title input" v-model="ruleForm.name"
                     maxlength="25">
            </div>
          </el-form-item>
          <el-form-item label="联系号码" prop="mobile">
            <div class="line">
              <input type="text" placeholder="手机号" class="title input" v-model="ruleForm.mobile"
                     maxlength="15">
              <span class="text">+86</span>
            </div>
          </el-form-item>

          <div class="bottomBox">
            <span class="sendBtn" @click="submitFormc('ruleForm')">确认</span>
          </div>
        </el-form>
      </div>
      <van-popup v-model="addPop" round position="bottom">
        <van-cascader
            v-model="cascaderValue"
            title="请选择所在地区"
            :options="options"
            :field-names="ieldnames"
            @close="addPop = false"
            @finish="onFinish"
            @change="onChange"
        />
      </van-popup>
      <div class="tag">
        <span class="red">*</span>目前配送范围限<span class="red">三里屯SOHO、通盈中心</span>内，我们正在努力建设更多配送点，敬请期待
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.input {
  border: none;
  width: 100%;
}

.card {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 2px #66666640;
  padding: 10px;
  margin: 10px 16px;

  .titleBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .leftTitle {
      color: #1b1b1b;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .rightSwitch {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;

      .switch {
        margin-left: 6px;
      }
    }
  }

  .line {
    border-radius: 10px;
    background: #f9f9f9;
    display: flex;
    padding: 0 0px 0 10px;
    justify-content: space-between;

    .input {
      background-color: transparent;
    }

    .iconfont {
      color: #666666;
      font-size: 24px;
    }

    .input::placeholder {
      color: var(--999999, #999999);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .texte {
      text-align: justify;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text {
      margin-right: 10px;
      color: #cccccc;
      font-weight: 400;
      font-size: 16px;
    }
  }

}

.tag {
  color: #9e9c9d;
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin: 10px 16px;
  text-align: left;

  .red {
    color: #fc223b;
  }
}

.bottomBox {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 20px 0;
  width: 100%;

  .sendBtn {
    border-radius: 10px;
    background: #fc223b;
    border: none;
    color: #fff;
    padding: 14px;
    width: 85%;
    display: inline-block;

  }
}

.placeholder {
  color: var(--999999, #999999) !important;
  font-weight: 400;
  font-size: 14px;
}
</style>
<style>
.addressPage .van-popup {
  background-color: #fff !important;
}

.addressPage .van-popup .van-tabs__wrap {
  margin: 0px 16px;
  border-bottom: 1px solid #e6e6e6;
}

.addressPage .van-popup .van-tabs__line {
  width: 28px;
  height: 2px;
  background: #fc223b;
}

.addressPage .van-popup .van-cascader__option {
  color: #1b1b1b;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
</style>