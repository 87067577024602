<script>
export default {
  name: "loding"
}
</script>

<template>
<!--  <div class="lodingOut">-->
    <div class="loading">
<!--      <span class="icon">123</span>-->
      <van-loading type="spinner"/>
    </div>
<!--  </div>-->
</template>

<style scoped lang="scss">
.lodingOut{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(27, 27, 27, 0.6);
  z-index: 10;
}
  .loading{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
    width: 120px;
    height: 120px;
    text-align: center;
    border-radius: 5px;
    color: hsla(0,0%,100%,.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #4c4c4c;
    .icon{
      //animation: uploader-spinner 1s steps(12) infinite;
    }
  }
@keyframes uploader-spinner{
  0%{
    transform:rotate(0deg);
    -webkit-transform:rotate(0deg);
  }
  50%{
    transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
  }
  100%{
    transform:rotate(360deg);
    -webkit-transform:rotate(360deg);
  }
}
</style>