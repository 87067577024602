<script>
import idle_tabbar from "@/components/common/tabbar/idle_tabbar.vue";
import {getFiveCus, getNowTime, getShopMessageList, kefu_list, upUnread} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "message",
  components: {idle_tabbar},
  props: {},
  data() {
    return {
      messageList:[],
      serverTime:0,
      allmessNum:0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    deleteMessage(item){
      console.log(item)
    },
    formatTime2(date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours()
      var minute = date.getMinutes()
      var second = date.getSeconds()
      console.log([year, month, day].map(this.formatNumber2).join('/'))
      return [year, month, day].map(this.formatNumber2).join('/') + ' ' + [hour, minute, second].map(this.formatNumber2).join(
          ':')
    },
    formatNumber2(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    dataChange(data) {
      // 时间处理 主要根据当前时间的凌晨和当前时间周一的凌晨 做判断依据 （时间都转化成时间戳好计算，自认为）
      var time = this.formatTime2(new Date(this.serverTime*1000));
      // let date = time.replace(/\//g, "-")
      let date = time
      var date1 = new Date(date)//eslint-disable-line
      var lc = new Date(this.serverTime*1000).setHours(0, 0, 0, 0) // 当前时间的凌晨的时间戳
      // console.log("当前时间的凌晨的时间戳 =>", lc)
      var nowlc = new Date(lc);
      var nowTime = nowlc.getTime();
      var day = nowlc.getDay();
      var oneDay = 24 * 60 * 60 * 1000;
      var Monday = nowTime - (day - 1) * oneDay;
      var monday = new Date(Monday);
      var mondaytamp = Number(monday); //当前时间周一凌晨的时间戳
      // console.log("当前时间周一凌晨的时间戳 =>", mondaytamp) //
      var date2 = new Date(data)
      var timetamp = Number(new Date(data)); // 转化从时间戳
      // console.log("后台获取的要处理的时间的时间戳 =>", timetamp)
      let d = ((lc - date2) / 1000) // 两个时间相差的秒数
      if (d > 0) {
        var min = (d / 60).toFixed(0)
        var h = (min / 60).toFixed(0)
        if (h <= 24) {
          data = "昨天"
        } else if (h <= 48) {
          data = "前天"
        } else if (h > 48) {
          let d = timetamp - mondaytamp
          if (d >= 0) {
            var l = ["日", "一", "二", "三", "四", "五", "六"];
            let z = date2.getDay()
            data = "周" + l[z]
            // if (z == 1) {
            //   data = "周一"
            // } else if (z == 2) {
            //   data = "周二"
            // } else if (z == 3) {
            //   data = "周三"
            // } else if (z == 4) {
            //   data = "周四"
            // } else if (z == 5) {
            //   data = "周五"
            // } else if (z == 6) {
            //   data = "周六"
            // } else if (z == 0) {
            //   data = "周日"
            // }
          } else if (d < 0) {
            let y = date2.getFullYear()
            let m = date2.getMonth()
            let d = date2.getDate()
            data = y + "年" + (m + 1) + "月" + d + "日"
          }
        }
      } else {
        // 当时时间段  时分
        let h = date2.getHours()
        let m = date2.getMinutes()
        h = h<10?'0'+h:h
        m = m<10?'0'+m:m
        data = h + ":" + m
      }
      return data
    },
    compareAlign(prop,align){
      return function(a,b){
        var value1=a[prop];
        var value2=b[prop];
        if(align=="positive"){//正序
          return new Date(value1)-new Date(value2);
        }else if(align=="inverted"){//倒序
          return new Date(value2)-new Date(value1);
        }
      }
    },
    getShopMessageList(){
      getShopMessageList(this.$store.state.token).then((res) => {
        if(res.data.status == true){
          console.log(res.data.data)
          let allmessNum=0//eslint-disable-line
          //cus_type   主材9   五金店2 辅材10  产品定制8  7平台客服     16水站 17超市 18快修 19房产
          let zhucai = 0
          let wujindian = 0
          let fucai = 0
          let chanpindingzhi = 0
          let pingtaikefu = 0//eslint-disable-line
          let shuizhan = 0
          let chaoshi = 0
          let kuaixiu = 0
          let fangchan = 0
          let list2 = res.data.data
          let dataInfo = {};
          let list = []
          for (const key in list2) {
            console.log(key);
            const element = list2[key];
            if(key =='ware'||key =='main'||key =='materials'||key =='product'){
              if(element.data.length>0){
                element.data.forEach(item=>{
                  list = list.concat(item)
                })
              }
            }else{
              if(element.data.length>0){
                list = list.concat(element.data)
              }
            }
          }
          list = JSON.parse(JSON.stringify(list))
          list = list.flat(Infinity)
          list.forEach(item=>{
            if(item.message.length>0){
              item.lastSendTime =  item.message[item.message.length-1].sendtime.replace(/-/g, '/')
              item.timeText = this.dataChange(item.lastSendTime)
              allmessNum+=Number(item.unread)
              console.log(item.message[item.message.length-1].type,'sdasd');
              if(item.message[item.message.length-1].type == 2){
                item.message[item.message.length-1].message_1 = '[图片]'
              }
              if(item.message[item.message.length-1].type == 4){
                item.message[item.message.length-1].message_1 = '[视频]'
              }
              if(item.message[item.message.length-1].type == 5){
                item.message[item.message.length-1].message_1 = '[位置]'
              }
              //  '1=聊天  2=图片 3=表情  4=视频',
            }else{
              item.lastSendTime =  item.time.replace(/-/g, '/')
              item.timeText = this.dataChange(item.lastSendTime)
            }
            let { cus_type } = item;
            if(!item.cus_type) {
              cus_type = item.userinfo.cus_type
            }
            console.log(cus_type,dataInfo,item);
            if (!dataInfo[cus_type]) {
              dataInfo[cus_type] = {
                cus_type,
                child: []
              }
            }
            dataInfo[cus_type].child.push(item);
            if(item.cus_type == 9){
              item.anotherinfo.user_name = '主材客服'
              zhucai = zhucai += Number(item.unread)
              item.key = 'main'
            }
            if(item.cus_type == 2){
              item.anotherinfo.user_name = '五金店客服'
              wujindian = wujindian += Number(item.unread)
              item.key = 'ware'
            }
            if(item.cus_type == 10){
              item.anotherinfo.user_name = '辅材客服'
              fucai = fucai += Number(item.unread)
              item.key = 'materials'
            }
            if(item.cus_type == 8){
              item.anotherinfo.user_name = '产品定制客服'
              chanpindingzhi = chanpindingzhi += Number(item.unread)
              item.key = 'product'
            }
            if(item.cus_type == 7){
              item.anotherinfo.user_name = '平台客服'
              pingtaikefu = pingtaikefu += Number(item.unread)
              // item.key = 'product'
            }
            if(item.cus_type == 16){
              item.anotherinfo.user_name = '水站客服'
              shuizhan = shuizhan += Number(item.unread)
              item.key = 'water'
            }
            if(item.cus_type == 17){
              item.anotherinfo.user_name = '超市客服'
              chaoshi = chaoshi += Number(item.unread)
              item.key = 'mark'
            }
            if(item.cus_type == 18){
              item.anotherinfo.user_name = '快修客服'
              kuaixiu = kuaixiu += Number(item.unread)
              item.key = 'zation'
            }
            if(item.cus_type == 19){
              item.anotherinfo.user_name = '房产客服'
              fangchan = fangchan += Number(item.unread)
              item.key = 'house_cus'
            }
            if(item.cus_type&&item.cus_type!=111){
              item.anotherinfo.avatar = this.$store.state.config.filter(item=>{return item.key == 'tbj_shop_logo'})[0].value
            }
          })
          for (const key in dataInfo) {
            if(dataInfo[key].child.length>1&&dataInfo[key].cus_type!=''&&dataInfo[key].cus_type!=111){
              dataInfo[key].child.forEach(item=>{
                list.splice(list.findIndex(item2=>{return item2.id == item.id}),1)
              })
              if(dataInfo[key].child.length<=0){
                list.push(dataInfo[key].child[0])
              }else{
                // cus_type 重复 数量大于2
                //   保存一下 所有的聊天记录
                let mesLi = dataInfo[key].child.map(item=>{return item.message}).flat()
                mesLi.map(item => {
                  if(item.sendtime) {
                    item.sendtimecc = item.sendtime.replace(/-/g, '/')
                  }
                  return item
                })
                mesLi.sort(this.compareAlign('sendtimecc','positive'))

                if(dataInfo[key].child.filter(item=>{return item.is_enable == 1}).length<=1){
                  // 过滤禁用之后 剩余不禁用的账户  数量小于等于1，拿第一个
                  let obj = dataInfo[key].child.filter(item=>{return item.is_enable == 1})[0]
                  obj.message = mesLi
                  list.push(obj)
                }else{
                  // 过滤禁用之后 剩余不禁用的账户  数量大于1，过滤不在线的
                  let obj = {}
                  if(dataInfo[key].child.filter(item=>{return item.is_enable == 1}).filter(item=>{return item.anotherinfo.isonline == 1}).length ==0){
                    obj = dataInfo[key].child[0]
                  }
                  if(dataInfo[key].child.filter(item=>{return item.is_enable == 1}).filter(item=>{return item.anotherinfo.isonline == 1}).length <0){
                    obj = dataInfo[key].child.filter(item=>{return item.is_enable == 1}).filter(item=>{return item.anotherinfo.isonline == 1})[0]
                  }
                  if(dataInfo[key].child.filter(item=>{return item.is_enable == 1}).filter(item=>{return item.anotherinfo.isonline == 1}).length >=1){
                    obj = dataInfo[key].child.filter(item=>{return item.is_enable == 1}).filter(item=>{return item.anotherinfo.isonline == 1})[0]
                  }
                  obj.message = mesLi
                  list.push(obj)
                  console.log(mesLi);
                }
              }
            }
          }
          list.sort(this.compareAlign('lastSendTime','inverted'))
          list.forEach(item=>{
            console.log(item.message)
            if(item.cus_type == 9){
              item.unread = zhucai
            }
            if(item.cus_type == 2){
              item.unread = wujindian
            }
            if(item.cus_type == 10){
              item.unread = fucai
            }
            if(item.cus_type == 8){
              item.unread = chanpindingzhi
            }
            if(item.cus_type == 7){
              item.unread = pingtaikefu
            }
            if(item.cus_type == 16){
              item.unread = shuizhan
            }
            if(item.cus_type == 17){
              item.unread = chaoshi
            }
            if(item.cus_type == 18){
              item.unread = kuaixiu
            }
            if(item.cus_type == 19){
              item.unread = fangchan
            }
          })


          this.messageList = list
          this.allmessNum = allmessNum

          console.log(this.messageList)

        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    godetailsFun(itemc){
      getShopMessageList(this.$store.state.token).then((res) => {
        if(res.data.status == true){
          let list2 = res.data.data
          let list = []
          let lastObj = itemc
          for (const key in list2) {
            const element = list2[key];
            if(key =='ware'||key =='main'||key =='materials'||key =='product'){
              if(element.data.length>0){
                element.data.forEach(item=>{
                  list = list.concat(item)
                })
              }
            }else{
              if(element.data.length>0){
                list = list.concat(element.data)
              }
            }
          }
          list = JSON.parse(JSON.stringify(list))
          list = list.flat(Infinity)
          list = list.filter(item=>{return (item.cus_type == itemc.cus_type||item.anotherinfo.cus_type == itemc.cus_type)})

          if(itemc.cus_type&&itemc.cus_type!=111){
            let mesLi = list.filter(item=>{return (item.cus_type == itemc.cus_type||item.anotherinfo.cus_type == itemc.cus_type)}).length>0?list.filter(item=>{return (item.cus_type == itemc.cus_type||item.anotherinfo.cus_type == itemc.cus_type)}).map(item=>{return item.message}).flat():[]
            mesLi.map(item => {
              if(item.sendtime) {
                item.sendtimecc = item.sendtime.replace(/-/g, '/')
              }
              return item
            })
            mesLi.sort(this.compareAlign('sendtimecc','positive'))

            const map = new Map();
            const newArr = mesLi.filter(v => !map.has(v.id) && map.set(v.id, v));
            // const newArr = [...new Map(arrObj.map((v) => [v.id, item])).values()];
            mesLi = newArr
            if(itemc.key){
              let type = -1
              if(itemc.cus_type == 16){
                type = 2
              }
              if(itemc.cus_type == 10){
                type = 1
              }
              if(itemc.cus_type == 17){
                type = 3
              }
              if(itemc.cus_type == 18){
                type = 4
              }
              if(itemc.cus_type == 19){
                type = 5
              }
              let data = {
                token:this.$store.state.token,
                type
              }
              getFiveCus(data.token,data.type).then(res=>{
                if(res.data.status){
                  let list = res.data.data.cus_list
                  let cus_id = list[0].id

                  kefu_list(this.$store.state.token, cus_id, itemc.type,).then(res=>{
                    if(res.status==true){
                      let list = res.data
                      lastObj = list.find(item2=>{return item2.anotherid == cus_id})
                    }
                  })
                }
              })
            }else{
              if(list.find(item=>{return item.chatid == itemc.chatid}).isonline == 1){
                // 如果点击的在线
                // isonline
                lastObj = itemc
              }else{
                // 如果点击的不在线  那data的is_show    判断列表是否有 another  = data的is_show的
                // 如果点击的不在线，查询现有列表 是否有在线的
                if(list.find(item=>{return item.isonline == 1})){
                  // 其他有在线的
                  lastObj = list.find(item=>{return item.isonline == 1})
                }else{
                  // 没有在线的
                  //cus_type   主材9   五金店2 辅材10  产品定制8  7平台客服     16水站 17超市 18快修 19房产
                  // 1辅材 2水站 3超市 4快修 5房产
                  let type = -1
                  if(itemc.cus_type == 16){
                    type = 2
                  }
                  if(itemc.cus_type == 10){
                    type = 1
                  }
                  if(itemc.cus_type == 17){
                    type = 3
                  }
                  if(itemc.cus_type == 18){
                    type = 4
                  }
                  if(itemc.cus_type == 19){
                    type = 5
                  }
                  let data = {
                    token:this.$store.state.token,
                    type
                  }
                  getFiveCus(data.token,data.type).then(res=>{
                    if(res.data.status){
                      let list = res.data.data.cus_list
                      let cus_id = list[0].id
                      kefu_list(this.$store.state.token, cus_id, 3).then(res=>{
                        if(res.status==true){
                          let list = res.data
                          lastObj = list.find(item2=>{return item2.anotherid == cus_id})
                        }
                      })
                    }
                  })
                }
              }
            }
            lastObj.message = mesLi.length>0?mesLi.sort(this.compareAlign('sendtimecc','positive')):[]
            if(lastObj.cus_type == 9){
              lastObj.anotherinfo.user_name = '主材客服'
            }
            if(lastObj.cus_type == 2){
              lastObj.anotherinfo.user_name = '五金店客服'
            }
            if(lastObj.cus_type == 10){
              lastObj.anotherinfo.user_name = '辅材客服'
            }
            if(lastObj.cus_type == 8){
              lastObj.anotherinfo.user_name = '产品定制客服'
            }
            if(lastObj.cus_type == 7){
              lastObj.anotherinfo.user_name = '平台客服'
            }
            if(lastObj.cus_type == 16){
              lastObj.anotherinfo.user_name = '水站客服'
            }
            if(lastObj.cus_type == 17){
              lastObj.anotherinfo.user_name = '超市客服'
            }
            if(lastObj.cus_type == 18){
              lastObj.anotherinfo.user_name = '快修客服'
            }
            if(lastObj.cus_type == 19){
              lastObj.anotherinfo.user_name = '房产客服'
            }
            lastObj.nickname = lastObj.anotherinfo.user_name
            lastObj.anotherinfo.avatar = this.$store.state.config.filter(item=>{return item.key == 'tbj_shop_logo'})[0].value
            lastObj.avatar = this.$store.state.config.filter(item=>{return item.key == 'tbj_shop_logo'})[0].value
          }
          console.log(lastObj)
          this.$store.commit('updataChatDetail',lastObj)
          this.$router.push('chat_details')
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },


    clearUnready(){
      upUnread(this.$store.state.token).then(res=>{
        console.log(res)
        this.allmessNum = 0
        this.messageList.forEach(item=>{
          item.unread = 0
        })
      }).catch(err=>{
        console.log(err)
      })
    },
  },
  created() {
  },
  async mounted() {
    this.serverTime = await getNowTime()
    this.getShopMessageList()
  }
};
</script>

<template>
  <div class="container messagePage">
    <div class="area">
      <div class="navigation">
        <div class="title">消息</div>
        <div class="num" v-if="allmessNum>0">（{{allmessNum}}）</div>
        <div class="clear" @click="clearUnready">
          <van-icon name="filter-o"/>
          清除未读
        </div>
      </div>
      <div class="list">
        <div class="" v-for="(item,index) in messageList" :key="index">
          <van-swipe-cell>
            <div class="li"  @click.stop="godetailsFun(item)">
              <!--          {{$store.state.imageHost}}{{item.anotherinfo.avatar?item.anotherinfo.avatar:item.anotherinfo.logo}}-->
              <div class="leftImg">
                <img class="avatar" :src="$store.state.imageHost+(item.anotherinfo.avatar?item.anotherinfo.avatar:item.anotherinfo.logo)" alt="">
              </div>
              <div class="rightBig">
                <div class="Rittop">
                  <div class="rttopTitle" v-if="!item.cus_type">{{ item.anotherinfo.seller_name?item.anotherinfo.seller_name:item.anotherinfo.nickname }}</div>
                  <div class="rttopTitle" v-if="item.cus_type">{{ item.anotherinfo.user_name?item.anotherinfo.user_name:item.anotherinfo.nickname }}</div>
                  <div class="rttopTime">{{item.timeText}}</div>
                </div>
                <div class="Rittop">
                  <div class="rttopTitle message">
                    {{item.message.length>0?item.message[item.message.length-1].type == 2||item.message[item.message.length-1].type == 5||item.message[item.message.length-1].type == 4?item.message[item.message.length-1].message_1:item.message[item.message.length-1].message:''}}
<!--                    {{item.message[item.message.length-1]}}-->
                  </div>
                  <div class="rttopTime unread" v-if="item.unread>0">
                    {{item.unread}}
                  </div>
                </div>
              </div>
            </div>
            <template #right>
              <van-button square type="danger" text="删除" @click="deleteMessage(item)" />
            </template>
          </van-swipe-cell>


        </div>


        <div style="width: 100%;height: 100px"></div>

      </div>

      <idle_tabbar :active="3" ref="tabbar"></idle_tabbar>
    </div>


  </div>
</template>

<style lang="scss" scoped>
.navigation {
  display: flex;
  padding: 20px 0 20px 16px;
  align-items: baseline;

  .title {
    line-height: 16px;
    font-size: 20px;

  }

  .van-icon {
    font-size: 12px;
  }

  .clear {
    font-size: 14px;
    margin-left: 5px;
  }

}

.list {
  height: 85vh;
  overflow-y: scroll;
  padding: 0 0 0 16px;

  .li {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    .leftImg {
      //display: flex;

      .avatar {
        width: 54px;
        height: 54px;
        border-radius: 10px;
      }
    }

    .rightBig {
      //flex: 1;
      margin-left: 10px;
      //padding-right: 10px;
      border-bottom: 1px solid #99999926;
      //border-bottom: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //padding-bottom: 5px;
      width:80%;
      .Rittop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .rttopTitle {
          max-width: 55%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #1b1b1b;
        }

        .rttopTime {
          max-width: 40%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          color: #999999;
        }

        .message {
          font-size: 14px;
          color: #999999;
          max-width: 80%;
          min-height: 20px;
        }

        .unread{
          color: white;
          background-color: #F74440;
          line-height: 14px;
          min-height: 14px;
          min-width: 18px;
          text-align: center;
          border-radius: 50px;
          padding: 3px;
        }
      }

      .Rittop:nth-last-of-type(1) {
        margin-top: 2px;
      }

      padding-bottom: 12px;
    }
  }
}

.list::-webkit-scrollbar {
  display: none;
}
</style>
<style>
.messagePage .van-swipe-cell__right .van-button{
  height: 100%;
}
</style>
