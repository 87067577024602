<template>
  <div>
    <div class="container">
      <div class="navigationTow"></div>
      <div class="area">
        <!-- <navigationBar :title="'我的'" :backBtn="false" class="navigation"></navigationBar> -->
        <div class="topInfo">
          <div class="userInfo">
            <div class="left">
              <img
                class="avatar"
                src="https://picture.bjbjkj.top/tp2024062803111511572202305.png"
                alt=""
              />
            </div>
            <div class="right">
              <div class="name">Circle</div>
              <div class="mobile">133 3333 3333</div>
            </div>
          </div>
        </div>
        <div class="Myrecord">
          <div class="every">
            <div class="title">预约看房</div>
            <div class="bottoms">
              <div class="nums">{{ Myrecord.yvyue }}</div>
              人
            </div>
          </div>
          <div class="every">
            <div class="title">看房记录</div>
            <div class="bottoms">
              <div class="nums">{{ Myrecord.Success }}</div>
              人
            </div>
          </div>
          <div class="every">
            <div class="title">我的评价</div>
            <div class="bottoms">
              <div class="nums">{{ Myrecord.comment }}</div>
              条
            </div>
          </div>
        </div>
        <div class="myFun">
          <div class="li" v-for="(item, index) in myFun" :key="index">
            <div class="topimg"><img class="img" :src="item.img" alt="" /></div>
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
        <div class="bodyFunction">
          <div
            class="bodyItem"
            v-for="(item, index) in bodyFunList"
            :key="index"
          >
            <div class="leftname">{{ item.name }}</div>
            <van-icon name="arrow" class="icon" />
          </div>
        </div>
      </div>
    </div>
    <tabbar :active="2"></tabbar>
  </div>
</template>

<script>
import tabbar from "@/components/common/tabbar/house_tabbar.vue";
import Bus from "@/utils/Bus";
// import navigationBar from '../../navigation-bar/navigation-bar.vue';

export default {
  components: {
    tabbar,
    // navigationBar
  },
  props: {},
  data() {
    return {
      Myrecord: {
        yvyue: 0,
        Success: 0,
        comment: 0,
      },
      myFun: [
        {
          name: "我的预约",
          img: "https://picture.bjbjkj.top//img/subscribe.png",
        },
        {
          name: "我的预约",
          img: "https://picture.bjbjkj.top//img/publish.png",
        },
        {
          name: "我的收藏",
          img: "https://picture.bjbjkj.top//img/Collect.png",
        },
        {
          name: "浏览历史",
          img: "https://picture.bjbjkj.top//img/footmark.png",
        },
      ],
      bodyFunList: [
        { name: "个人认证", id: 1 },
        { name: "经纪人认证", id: 2 },
        { name: "建议反馈", id: 3 },
        { name: "隐私政策", id: 4 },
        { name: "用户协议", id: 5 },
        { name: "资质证明", id: 6 },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {
    Bus.$emit("updataUserInfo", '1');
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
};
</script>
<style lang="scss" scoped>
.area {
  padding: 0 16px;
  position: relative;
  z-index: 2;
}
.container {
  position: relative;
}
.navigationTow {
  background: linear-gradient(180deg, #f2fffe, #ffffff) !important;
  height: 280px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 1;
}
.avatar {
  width: 54px;
  height: 54px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}
.topInfo {
  padding-top: 50px;
  // padding-left: 16px;
  // padding-right: 16px;
}
.userInfo {
  display: flex;
  .right {
    margin-left: 10px;
  }
  .name,
  .mobile {
    font-weight: 400;
    font-size: 14px;
    color: #1b1b1b;
    line-height: 24px;
    text-align: left;
  }
  .name {
    font-size: 18px;
  }
}
.Myrecord {
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  display: flex;
  // margin: 0 20px 10px 20px;
  border-radius: 10px;
  padding: 10px 0;
  position: relative;
  z-index: 10;
  background-color: white;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  .every {
    width: 33%;
    text-align: center;
    position: relative;
    .title {
      color: #444444;
      font-size: 14px;
    }
    .bottoms {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: center;
      font-size: 13px;
      margin-top: 5px;
      font-weight: 500;
    }
    .nums {
      font-size: 24px;
    }
    .title,
    .nums {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .every::after {
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    height: 18px;
    background-color: #e1e1e1;
    right: 0;
    top: 0;
    transform: translate(-0%, -50%);
    top: 50%;
    border-radius: 10px;
  }
  .every:nth-last-of-type(1)::after {
    display: none;
  }
}
.myFun {
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  border-radius: 20rpx;
  padding: 10px 0;
  position: relative;
  z-index: 10;
  background-color: white;
  .li {
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img {
      width: 42px;
      height: 42px;
    }
    .text {
      font-size: 12px;
      color: #1b1b1b;
    }
  }
}
.bodyFunction {
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  /* display: flex; */
  margin: 19px 0px;
  border-radius: 10px;
  /* padding: 20rpx 0; */
  position: relative;
  z-index: 100;
  background-color: white;
  .bodyItem {
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);
    display: flex;
    justify-content: space-between;
    padding: 15px 14px 15px 11px;
    .leftname {
      width: 85%;
      font-size: 14px;
      text-align: left;
    }
    .icon {
      font-size: 15px;
    }
  }
  .bodyItem:nth-last-child(1) {
    border-bottom: none;
  }
}
</style>