<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Swiper from "swiper";
import {addCar, cart_del, getCartListAll, superGoods_index} from "@/utils/api";
import {Dialog, Toast} from "vant";
import SukPop from "@/components/common/sukPop.vue";

export default {
  name: "water_details",
  components: {SukPop, navigationBar},
  data() {
    return {
      swiper: {},
      swiperLi: [],
      obj: {},
      xizhi: [
        '划线价格：商品展示的划横线的价格是参考价格，并非原价，该价格可能是商品的专柜标价、商品吊牌价或由品牌供应商提供的正品零售价（如厂商指导价、建议零售价等）或该商品曾经在百街展示过的销售价格等，该价格仅供您参考。',
        '未划线价格：未划线的价格是商品在百街的实时销售标价，是您最终决定是否购买商品的依据。最终成交价格以订单结算页价格为准。',
        '到手价以实际的实时销售标价减去各类优惠，或使用积分享受抵现活动为准。',
        '每日的配送时间为08：00~20：00，夏冬时间略有差异，具体留意下单页提示',
        '商品详情图品仅供参考，具体以实物为准。',
      ],
      cartLi:[],
      carshow:false,
      allPirce: {
        yuan: 0,
        mao: 0,
      },
      addCarpop:false,
      num:0,

      addCAR:0,
    }
  },
  computed:{
    carNums() {
      return  this.cartLi.reduce((total, item) =>
              total += item.nums
          , 0)
    },
    chaoshi(){
      return this.obj.goods_type_id== 6
    },
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(".swiper", {
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        loop: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      });
    },

    getDetails(id) {
      superGoods_index(id).then(res => {
        if (res.data.status == true) {
          this.obj = res.data.data
          this.obj.mao = this.obj.price.split('.')[1]
          this.obj.yuan = this.obj.price.split('.')[0]
          this.swiperLi = res.data.data.images.split(',')
          this.getCart()
        }
      })
    },
    checkSwiper(item) {
      const image = new Image()
      image.src = (this.$store.state.imageHost + item)
      image.onload = () => {
        const previewContatiner = document.createElement("div");
        previewContatiner.style.position = 'fixed'
        previewContatiner.style.top = 0
        previewContatiner.style.bottom = 0
        previewContatiner.style.left = 0
        previewContatiner.style.right = 0
        previewContatiner.style.zIndex = 9999
        previewContatiner.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContatiner.style.display = 'flex'
        previewContatiner.style.justifyContent = 'center'
        previewContatiner.style.alignItems = 'center'
        document.body.appendChild(previewContatiner)
        //在弹出层增加图片
        const previewImage = document.createElement('img')
        previewImage.src = (this.$store.state.imageHost + item)
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewImage.style.zIndex = 9999
        previewContatiner.appendChild(previewImage)
        previewContatiner.addEventListener('click', () => {
          document.body.removeChild(previewContatiner)
        })
      }


      image.onerror = function () {
        console.log('图片加载失败')
      }
    },
    getCart() {
      if (this.$store.state.token) {
        console.log(this.chaoshi)
        console.log(this.obj.goods_type_id)
        if(!this.chaoshi){
          getCartListAll(this.$store.state.token).then(res => {
            console.log(res.data.data.data)
            if (res.data.status == true) {
              this.cartLi = res.data.data.data.list
              this.cartLi.forEach(item => {
                item.products.yuan = item.products.price.split('.')[0]
                item.products.mao = item.products.price.split('.')[1]
              })
            }
          })
        }else{
          getCartListAll(this.$store.state.token,5).then(res=>{
            console.log(res.data.data.data.list)
            if(res.data.status==true){
              this.cartLi = res.data.data.data.list
              this.cartLi.forEach(item=>{
                item.products.yuan = item.products.price.split('.')[0]
                item.products.mao = item.products.price.split('.')[1]
              })
            }
          })
        }
      }
    },

    clearCar() {
      Dialog.confirm({
        title: '确认清空购物车？',
      })
          .then(() => {
            // on confirm
            if (!this.$store.state.token) return;
            let goods_id = []
            this.cartLi.forEach(item => {
              goods_id.push(item.id)
            })
            goods_id = String(goods_id)
            cart_del(this.$store.state.token, goods_id).then(res => {
              if (res.data.status == true) {
                console.log(res.data.data)
                Toast(res.data.msg)
                this.carshow = false
                this.cartLi = []
              } else {
                Toast(res.data.msg)
              }
            }).catch(err => {
              console.log(err)
            })
          })
          .catch(() => {
            // on cancel
          });
    },

    submit(){

    },

    openCar(){
      if(this.cartLi.length<=0)return;
      this.carshow = true
    },
    openAddPop(){
      this.addCarpop = true
    },
    addcar(e){
          // .find(item=>{return item.product_id = e.id})
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = e.nums
      let goods_type_id = 1
      if(this.cartLi.find(item=>{return item.products.id = e.id})){
        nums+=this.cartLi.find(item=>{return item.products.id = e.id}).nums
      }
      if(e.goods_type_id == 6){
        goods_type_id = 5
      }
      addCar(this.$store.state.token,e.id,nums,2,goods_type_id).then(res => {
      // addCar(this.$store.state.token).then(res => {
        console.log(res)
            if (res.data.status == true) {
              Toast('加入成功')
              this.getCart()
              this.addCarpop = false
              this.addCAR = 1
            }else{
              Toast(res.data.msg)
            }
      })


    },
    scroll(e){
      console.log()
      if (e.target.scrollTop < 375) {
          this.num=(e.target.scrollTop / 375).toFixed(1)
      }else{
        this.num=1
      }
    },
    add1(item){
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      addCar(this.$store.state.token, item.product_id, nums, 1, item.type).then(res => {
        if (res.data.status == true) {
          item.nums += nums
          // let mapc = Object.fromEntries(this.cartLi.map(({product_id, nums}) => [product_id, nums]));
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
        // Toast(err.data.msg)
      })
    },
    unadd1(item){
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      if (item.nums <= item.products.sell_num) {
        nums = item.products.sell_num
      } else {
        nums = 1
      }
      addCar(this.$store.state.token, item.product_id, nums, 2, item.type).then(res => {
        if (res.data.status == true) {
          item.nums -= nums
        } else {
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
  mounted() {
    console.log()
    this.initSwiper()
    this.getDetails(this.$route.query.goods)
  },
}
</script>

<template>
  <div class="container" @scroll="scroll">
    <div class="area">
      <navigationBar :title="''" class="navigation" :style="`background-color: rgba(255, 255, 255, ${num});`"></navigationBar>
      <div class="swiper">
        <div class="swiper-wrapper">
          <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd.png" alt="" class="img"></div> -->
          <div
              v-for="(item,index) in swiperLi" :key="index"
              class="swiper-slide"
              :style="`background-image:url(${$store.state.imageHost}${item})`"
              @click="checkSwiper(item)"
          ></div>
          <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd1.png" alt="" class="img"></div> -->
        </div>
        <!-- 如果需要分页器 -->
        <div class="pagination">
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="center">
        <div class="price">
          <div class="nowPirce"><span class="unit">￥</span><span class="yuan">{{ obj.yuan }}</span>.<span
              class="mao">{{ obj.mao }}</span></div>
          <div class="oldPrice">￥{{ obj.mktprice }}</div>
        </div>
        <div class="name">{{ obj.name }}</div>
        <div class="space">
          <div class="inner">
            <div class="item">
              <div class="brand" v-if="obj.brand">{{ obj.brand.name }}</div>
              <div class="text">品牌</div>
            </div>
            <div class="item">
              <div class="brand">{{ obj.unit }}</div>
              <div class="text">单位</div>
            </div>
            <div class="item">
              <div class="brand">{{ obj.specifications }}</div>
              <div class="text">规格</div>
            </div>
          </div>
        </div>
        <div class="introduce">
          <div class="item">
            <span class="iconfont icon-shijian1"></span>
            当日配送时间为08：00~20：00
          </div>
          <div class="item">
            <span class="iconfont icon-mian"></span>
            三里屯SOHO、通盈中心范围内免费配送
          </div>
          <div class="item">
            <span class="iconfont icon-jin"></span>
            超出三里屯SOHO、通盈中心范围内暂无法配送最快20分钟之内送达
          </div>
        </div>
      </div>
      <div class="details">
        <div class="topTitle">
          产品信息
        </div>
        <div class="inner">
          <div class="line">
            <div class="left">品牌</div>
            <div class="right" v-if="obj.brand">{{ obj.brand.name }}</div>
          </div>
          <div class="line">
            <div class="left">商品名称</div>
            <div class="right" v-if="obj.brand">{{ obj.name }}</div>
          </div>
          <div class="line">
            <div class="left">商品编号</div>
            <div class="right" v-if="obj.brand">{{ obj.id }}</div>
          </div>
          <div class="line">
            <div class="left">商品单位</div>
            <div class="right" v-if="obj.brand">{{ obj.unit }}</div>
          </div>
          <div class="line">
            <div class="left">商品规格</div>
            <div class="right" v-if="obj.brand">{{ obj.specifications }}</div>
          </div>
          <div class="line">
            <div class="left">商品分类</div>
            <div class="right" v-if="obj.brand">{{ obj.brand.name }}</div>
          </div>
        </div>
        <div class="detailsImg"></div>
        <div class="topTitle">
          购买须知
        </div>
        <div class="xuzhi">
          <div class="item" v-for="(item,index) in xizhi" :key="index">
            <div class="unit">{{ index + 1 }}、</div>
            <div class="text">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="inner">
          <div class="left" @click="openCar">
            <img src="https://picture.bjbjkj.top/img/waterCar.png" alt="" class="carImg" v-if="!chaoshi">
            <img src="https://picture.bjbjkj.top/img/supermarkerCar.png" alt="" class="carImg" v-if="chaoshi">
            <div class="nums" v-if="carNums>0">{{ carNums }}</div>
          </div>
          <div class="right" :class="chaoshi?'chaoshiBtn':''">
            <div class="button" @click="openAddPop">加入购物车</div>
          </div>
        </div>
      </div>
      <van-popup v-model="carshow" round position="bottom">
        <div class="popup">
          <div class="tioline"></div>
          <div class="title">
            <div class="left">购物车</div>
            <div class="right" @click="clearCar">清空</div>
          </div>
          <div class="carBox">
            <div class="item" v-for="(item, index) in cartLi" :key="index">

              <div class="leftImgBox">
                <img :src="$store.state.imageHost+item.products.images" alt="" class="img">
              </div>
              <div class="rightInfo">
                <div class="brand">
                  <div class="brandBox">品牌</div>
                  {{ item.products.brand.name }}
                </div>
                <div class="name">{{ item.products.name }}</div>
                <div class="detail">
                  <div class="spacl">
                    规格:{{
                      item.products.goods_type_id == 6 ? item.products.nums + '张/套餐' : item.products.specifications
                    }}
                  </div>
                  <div class="line"></div>
                  <div class="sale">{{ item.sale }}</div>
                </div>

                <div class="price_add">
                  <div class="leftPrice">
                    <div class="sellPrice">
                      <div>￥</div>
                      <div class="yuan">{{ item.products.yuan }}</div>
                      <div>.{{ item.products.mao }}</div>
                    </div>
                    <div class="lastPrice">￥{{ item.products.mktprice }}</div>
                  </div>
                  <div class="rightAdd">
                    <div class="unadd" v-if="item.nums>0" @click.stop="unadd1(item)"></div>
                    <div class="input" v-if="item.nums>0">{{ item.nums }}</div>
                    <div class="add" @click.stop="add1(item)"></div>
                  </div>
                </div>
                <!--                  <div class="vipBox">-->
                <!--                    <div class="vipPrice">￥{{ item.vipPr }}</div>-->
                <!--                    <div class="">会员价</div>-->
                <!--                  </div>-->
              </div>
              <!--                <svg class="icon" aria-hidden="true">-->
              <!--                  <use xlink:href="#icon-kefu"></use>-->
              <!--                </svg>-->
            </div>
          </div>
          <div class="bottom">
            <div class="bottomBigbox">
              <div class="left">
                <div class="car">
                  <img src="https://picture.bjbjkj.top/img/waterCar.png" alt="" class="carImg">
                  <div class="nums" v-if="carNums>0">{{ carNums }}</div>
                </div>
                <div class="price">
                  ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
                >
                </div>
              </div>
              <div class="right">
                <!-- <div class="songshui btnBox">一键送水</div> -->
                <div class="zhifu btnBox" @click="submit">去支付</div>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="addCarpop" round position="bottom">
        <suk-pop :goods="obj" @addCarc="addcar" :num="addCAR" :chaoshi="chaoshi"></suk-pop>
      </van-popup>
    </div>
  </div>
</template>

<style scoped lang="scss">

.bottom {
  box-shadow: 0px 0px 2px 0px rgba(153, 153, 153, 0.15);
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .inner{
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
  }

  .left {
    position: relative;

    .carImg {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
    .nums {
      position: absolute;
      right: 0;
      top: 0;
      background: red;
      width: 14px;
      height: 14px;
      line-height: 14px;
      border-radius: 50%;
      font-size: 11px;
      color: #fff;
    }

  }

  .right {
    .button{
      background: #0085FF;
      border-radius: 32px 32px 32px 32px;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
      text-align: left;
      padding: 10px 24px;
    }
  }
  .chaoshiBtn{
    .button{
      background: #FC223B;
    }
  }
}
.popup {
  position: relative;
  padding: 20px 16px 0 16px;
  background: #fff;
  .bottomBigbox {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .car {
        width: 72rpx;
        position: relative;
        display: flex;
        align-items: center;
      }

      .price {
        margin-left: 10rpx;
        font-size: 14px;
        color: #fd2a39;

      }

      .yuan {
        font-size: 24px;
      }

      .image {
        width: 36px;
        max-height: 36px;
      }

      .nums {
        position: absolute;
        top: 0;
        right: 0;
        background-color: red;
        color: white;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        text-align: center;
        line-height: 14px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .btnBox {
        padding: 10px 24px;
        border-radius: 32px;
        color: white;
        font-size: 14px;
        background: #0085FF;
      }
    }
  }

  .carBox {
    max-height: 50vh;
    overflow: scroll;
    margin-top: 10px;
    padding-bottom: 70px;

    .item {
      display: flex;
    }

    .leftImgBox {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      overflow: hidden;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .rightInfo {
      flex: 1;
      padding-left: 10px;
      padding-bottom: 10px;

      .brand {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        text-overflow: ellipsis;
        align-items: center;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;

        .brandBox {
          font-weight: 500;
          font-size: 12px;
          color: #1b1b1b;
          line-height: 18px;
          padding: 0 4px;
          background: #e8e8e8;
          border-radius: 4px 4px 4px 4px;
          margin-right: 5px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        text-align: left;
      }

      .detail {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #7f7f7f;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .line {
          margin: 0 6px;
          width: 1px;
          height: 9px;
          background: #dddddd;
        }
      }

      .price_add {
        display: flex;
        justify-content: space-between;
        margin-right: 16px;
        align-items: flex-end;
        font-weight: 500;

        .leftPrice {
          display: flex;
          //align-items: baseline;
          align-items: flex-end;

          .sellPrice {
            display: flex;
            font-size: 14px;
            color: #fd2a39;
            line-height: 18px;
            align-items: baseline;

            .yuan {

              font-size: 20px !important;
            }
          }

          .lastPrice {
            text-decoration-line: line-through;
            line-height: 18px;
            color: #7f7f7f;
            font-size: 12px;
          }
        }

        .rightAdd {
          display: flex;

          .unadd,
          .add {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #398ae9;
            position: relative;
          }

          .add::after,
          .unadd:after {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 10%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .add::before {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 10%;
            height: 50%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .input {
            height: 22px;
            width: 22px;
            line-height: 22px;
            margin: 0 5px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #1b1b1b;
            white-space: nowrap;
            overflow: scroll;
          }
          .input::-webkit-scrollbar{
            display: none;
          }

          .addBox {
            background: #0085FF;
            border-radius: 5px 5px 5px 5px;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
            padding: 0 10px;
          }
        }
      }

      .vipBox {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        color: #392723;
        line-height: 18px;

        .vipPrice {
          margin-right: 5px;
        }
      }
    }
  }
  .input {
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: #1B1B1B;
    text-align: center;
    margin: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    overflow: scroll;
  }
  .input::-webkit-scrollbar{
    display: none;
  }
  .carBox::-webkit-scrollbar {
    display: none;
  }

  .tioline {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 10%;
    height: 5px;
    background: #0085ff;
    transform: translate(-50%, -0%);
    border-radius: 25px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
  .input {
    color: red!important;
  }

}

.details {
  background-color: #fff;
  margin-top: 10px;

  .topTitle {
    font-weight: 500;
    font-size: 16px;
    color: #1B1B1B;
    line-height: 24px;
    text-align: left;
    padding: 10px 16px;

  }

  .inner {
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #F4F6FA;
    margin: 0 16px;

    .line {
      padding: 6px 0px;
      margin: 0 10px;
      display: flex;
      border-bottom: 1px solid #F4F6FA;

      .left {
        width: 20%;
        white-space: nowrap;
        margin-right: 10px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }

      .right {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: #1B1B1B;
        line-height: 24px;
      }
    }
  }

  .xuzhi {

    .item {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin: 0 16px;
      font-size: 11px;
      color: #B0B0B0;
      line-height: 18px;
      text-align: left;
    }
  }

  padding-bottom: 100px;

}


.container {
  background-color: #F4F6FA;
  height: 100vh;
}

.center {
  margin-top: 10px;
  background-color: #fff;
  padding: 10px 0;

  .price {
    margin: 0 16px;
    display: flex;
    align-items: baseline;

    .nowPirce {
      color: #FD2A39;

      .unit {
        font-size: 14px;
      }

      .yuan {
        font-size: 26px;
      }

      .mao {
        font-size: 20px;
      }
    }
    .oldPrice{
      font-weight: 400;
      font-size: 12px;
      color: #7F7F7F;
      line-height: 18px;
      text-align: left;
      text-decoration-line: line-through;
    }
  }

  .name {
    margin: 0 16px;
    text-align: left;
    font-size: 18px;
  }

  .space {
    margin: 0 16px;

    .inner {
      background-color: #F7F8FA;
      margin: 10px;
      border-radius: 6px;
      padding: 8px 0;
      display: flex;
      justify-content: space-around;

      .item {
        position: relative;
        width: 33%;

        .brand {
          font-weight: 500;
          font-size: 14px;
          color: #131313;
          line-height: 19px;
        }

        .text {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 18px;
          margin-top: 1px;
        }
      }

      .item:after {
        position: absolute;
        content: '';
        display: block;
        width: 1px;
        height: 18px;
        background-color: #D9D9D9;
        right: -10%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .item:nth-last-of-type(1):after {
        display: none;
      }
    }
  }

  .introduce {
    .item {
      border-top: 1px solid #F7F8FA;
      padding: 6px 16px;
      text-align: left;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 24px;

      .iconfont {
        color: #FD2A39;
        margin-right: 8px;
        font-size: 22px;
      }
    }
  }
}


.navigation {
  position: fixed;
  z-index: 10;
}

.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  overflow: hidden;
  position: relative;
  height: 375px;

  .swiper-slide {
    height: 375px;
  }

  .img {
    width: 100%;
    object-fit: cover;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}

</style>