import { render, staticRenderFns } from "./corporation_home_page.vue?vue&type=template&id=4ab54c0a&scoped=true"
import script from "./corporation_home_page.vue?vue&type=script&lang=js"
export * from "./corporation_home_page.vue?vue&type=script&lang=js"
import style0 from "./corporation_home_page.vue?vue&type=style&index=0&id=4ab54c0a&prod&scoped=true&lang=scss"
import style1 from "./corporation_home_page.vue?vue&type=style&index=1&id=4ab54c0a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab54c0a",
  null
  
)

export default component.exports