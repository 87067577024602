<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import order_li from "@/components/common/home_tabbar_page/order/order_li.vue";
import tabbar from "@/components/common/tabbar/build_tabbar.vue";
export default {
  name: "order_list",
  components: {
    navigationBar,
    // eslint-disable-next-line vue/no-unused-components
    order_li,
    tabbar,
  },
  data() {
    return {
      tabActive: 0,
      shareTabList: [
        {name: '全部', value: 0, page: 1, limit: 10, list: [],},
        {name: '待付款', value: 1, page: 1, limit: 10, list: []},
        {name: '待发货', value: 2, page: 1, limit: 10, list: []},
        {name: '待收货', value: 3, page: 1, limit: 10, list: []},
        {name: '待评价', value: 4, page: 1, limit: 10, list: []},
        {name: '退款/售后', value: 5, page: 1, limit: 10, list: []},
      ],

      list: [],
      loading: false,
      finished: false,
      error: false,

      maxlength: 50,
      immediateCheck:true,

      timer:''
    }
  },
  methods: {
    tabsChange() {
      this.loading = false
      this.finished = false
      this.error = false
      this.immediateCheck=false
      clearTimeout(this.timer)
    },
    tabsClick(name){
      console.log(name)
      if(name==4){
        console.log('评价')
      }
    },
    onClickDisabled(){
      console.log('评价')
    },
    onLoad(list,index) {
      list,index
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
  console.error('=====')
      this.timer = setTimeout(() => {
        // this.shareTabList[index].list = list;
        for (let i = 0; i < 10; i++) {
          // console.log(this.shareTabList[this.tabActive].list.length)
          this.shareTabList[this.tabActive].list.push(this.shareTabList[this.tabActive].list.length + 1);
      console.log(this.tabActive,'index')
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.shareTabList[this.tabActive].list.length >= this.maxlength) {
          this.finished = true;
        }
        console.log(this.shareTabList[this.tabActive].list,this.tabActive)
        console.log(this.shareTabList)

      }, 1000);
    },


    // checkOrderDetails(){
    //   console.log('查看详情')
    // },


  },
  mounted() {
    // this.list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'订单'" :backBtn="false" class="navigation"></navigationBar>
      <div class="top-background-img"></div>
      <div class="innerContent">
        <div class="topSearch">
          <span class="iconfont icon-grommet-icons_form-search-1"></span>
          <div class="line"></div>
          <div class="placeHolder">搜索你想要的订单...</div>
        </div>
        <div class="navTag">
          <van-tabs v-model="tabActive" animated @change="tabsChange" @click="tabsClick" @disabled="onClickDisabled">
            <van-tab
                v-for="(item, index) in shareTabList"
                :title="item.name"
                :key="index"
                :disabled="index==4"
            >
              <template #title>{{ item.name }}</template>
              <!--         :immediate-check='false'  van-list页面进入是否立即刷新-->
              <van-list
                  :immediate-check="immediateCheck"
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  :error.sync="error"
                  error-text="请求失败，点击重新加载"
                  @load="onLoad(item.list,index)"
              >
                <div v-for="itemc in item.list" :key="itemc">
                  {{ loading }}
                  {{ finished }}
                  {{ error }}

                  <div class="order-body">
                    <!--                    <div class="li" v-for="item in list" :key="item">-->
                    <order_li :item="itemc"></order_li>
                    <!--                    </div>-->
                  </div>
                </div>
              </van-list>
            </van-tab>
          </van-tabs>
        </div>
      </div>
      <tabbar :active="2"></tabbar>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top-background-img {
  background: linear-gradient(0deg, rgba(248, 251, 255, 0), #FFF9F1);
  width: 100%;
  height: 280px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.van-list{
  padding-bottom: 50px;
}
.navigation {
  position: relative;
  z-index: 2;
}

.innerContent {
  position: relative;
  z-index: 2;

  .topSearch {
    background: #FFFFFF;
    border-radius: 40px 40px 40px 40px;
    border: 1px solid #FC223B;
    margin: 0 16px;
    display: flex;
    align-items: center;
    padding: 6px 0;
    cursor: pointer;

    .iconfont {
      color: #FC223B;
      font-size: 24px;
      margin-left: 15px;
    }

    .line {
      height: 12px;
      width: 2px;
      background: #FC223B;
      margin: 0 10px;
    }

    .placeHolder {
      color: #666666;
      font-size: 12px;
    }
  }

  .navTag {
    margin: 0 16px;

    .van-tab--disabled{

    }
    .van-tab__text {
      font-weight: 400;
      font-size: 16px;
      color: #1B1B1B;
      color: red;
      line-height: 24px;
      text-align: left;
    }

    .van-tab--active {
      .van-tab__text {
        font-weight: 500;
        font-size: 16px;
        color: #1B1B1B;
        line-height: 24px;
      }
    }


  }

}

//.van-tabs__nav .van-tab{
//  color: #1B1B1B;
//}
</style>

<style>
.van-tabs__nav--line .van-tabs__line {
  background: #FC223B;
  width: 15px;
  height: 5px;
}
.van-tabs__track .van-tab__pane {
  height: 80vh;
  overflow: scroll;
  margin-top: 4px;
}
.van-tabs__track .van-tab__pane::-webkit-scrollbar {
  display: none;
}
.innerContent .navTag .van-tabs__nav .van-tab{
  color: #1B1B1B;
}
.innerContent .navTag .van-tabs__nav .van-tab--disabled{
  cursor: pointer;
}
</style>