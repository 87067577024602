<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {enableBuyQuote, enableQuote, getMySaleList, kefu_list} from "@/utils/api";
import {Dialog, Toast} from "vant";
import {isValidJSON} from "@/utils/tool";

export default {
  name: "mySell",
  components: {navigationBar},
  data() {
    return {
      active: 0,
      tabsLi: [
        {text: '全部', list: [], page: 1, limit: 10,loading: false,noMore: false,nums:0,},
        {text: '出价中', list: [], page: 1, limit: 10,loading: false,noMore: false,nums:0,},
        {text: '待取货', list: [], page: 1, limit: 10,loading: false,noMore: false,nums:0,},
        {text: '已完成', list: [], page: 1, limit: 10,loading: false,noMore: false,nums:0,},
        {text: '已取消', list: [], page: 1, limit: 10,loading: false,noMore: false,nums:0,},
      ],
      activeObj:{},

    }
  },
  methods: {
    getList() {
      getMySaleList(this.$store.state.token,this.is_sale, this.tabsLi[this.active].page,this.tabsLi[this.active].limit,  ).then(res => {
        if (res.data.status == true) {
          res.data.data.total
          let list = res.data.data.data
          list.forEach(item => {
            item.showPopover = false
            console.log(item.images)
            if (item.images) {
              if (isValidJSON(item.images)) {
                item.showImg = JSON.parse(item.images)[0]
              } else {
                item.showImg = item.images.split(',')[0]
              }
              if (typeof item.showImg == 'string') {
                if (!item.showImg.startsWith('https:')) {
                  item.showImg = this.$store.state.imageHost + item.showImg
                } else {
                  item.showImg = item.showImg
                }
              } else {
                item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
              }
            }
          })
          this.tabsLi[this.active].list = list
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    tabsChange() {
      this.getList()
    },

    load() {
      this.tabsLi[this.active].loading = true
      if (this.tabsLi[this.active].noMore) {
        return
      }
      let page = this.tabsLi[this.active].page
      let limit = this.tabsLi[this.active].limit += 10
      getMySaleList(this.$store.state.token,this.is_sale, page ,limit).then(res => {
        if (res.data.status == true) {
          console.log(res.data.data.data)
          let maxlength = res.data.data.total
          let list = res.data.data.data
          // '状态： 1出价中 2待取货 3已完成 4已取消    6商家已拒绝',
          list.forEach(item => {
            item.showPopover = false
            console.log(item.images)
            if (item.images) {
              if (isValidJSON(item.images)) {
                item.showImg = JSON.parse(item.images)[0]
              } else {
                item.showImg = item.images.split(',')[0]
              }
              if (typeof item.showImg == 'string') {
                if (!item.showImg.startsWith('https:')) {
                  item.showImg = this.$store.state.imageHost + item.showImg
                } else {
                  item.showImg = item.showImg
                }
              } else {
                item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
              }
            }
          })
          this.tabsLi[this.active].list = list
          if (list.length >= maxlength) {
            this.tabsLi[this.active].noMore = true
            this.tabsLi[this.active].loading = false
          }
          this.tabsLi[this.active].loading = false
          this.tabsLi[this.active].maxlength = maxlength
        } else {
          Toast(res.data.msg)
        }
      })



    },

    rejectBtn(item){
      Dialog.confirm({
        title: '确定取消交易吗',
      })
          .then(() => {
            // on confirm
            console.log('rejectBtn拒绝')
            enableQuote(this.$store.state.token,item.quote_id,item.product_id,2).then(res=>{
              if(res.data.status){
                item.status = 6
                this.getList()
              }
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    agreeBtn(item){
      console.log('agreeBtn同意')
      enableQuote(this.$store.state.token,item.quote_id,item.product_id,1).then(res=>{
        if(res.data.status){
          item.status = 2
          this.getList()
        }
      })
    },
    completeBtn(item){
      Dialog.confirm({
        title: '确定完成交易吗',
      }).then(()=>{
        enableBuyQuote(this.$store.state.token,item.quote_id,item.product_id,1).then(res=>{
          if(res.data.status){
            item.status = 2
            this.getList()
          }
        })
      }).catch(()=>{
        //
      })
    },
    contactBtn(itemc){
      kefu_list(this.$store.state.token,itemc.uid,3).then(res=>{
        if(res.data.status == true){
          let obj = res.data.data
          console.log(obj)
          let list = obj.filter(item=>{return item.type == 3})
          let messageObj = {}
          if(list.find(item=>{return item.anotherid == itemc.uid&&item.userid == this.$store.state.user.id})){
            messageObj = list.find(item=>{return item.anotherid == itemc.uid&&item.userid == this.$store.state.user.id})
          }else{
            if(list.find(item=>{return item.anotherid == this.$store.state.user.id &&item.userid == itemc.uid})){
              messageObj = list.find(item=>{return item.anotherid == this.$store.state.user.id &&item.userid == itemc.uid})
              messageObj.anotherid =  itemc.id
              messageObj.userid = this.$store.state.user.id
            }
          }
          this.$store.commit('updataChatDetail',messageObj)
          console.log(messageObj)
          this.$router.push({
            name: 'chat_details',
          })

        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    deleteBtn(){
      console.log('rejectBtn删除')
    },


    popoverselect(action, index) {
      console.log(action, index)
    },
    popoveropen(item) {
      console.log(item)
      this.activeObj = item
    },
    popoverclose() {
      this.activeObj = {}
    },

    godetails(item){
      console.log(item)
      if(this.active === 2){
        this.$router.push({
          path:'/sellDetails',
          query:{id:item.quote_id},
        })
      }else{
        this.$router.push({
          path:'/idleDetails',
          query:{id:item.product_id}
        })
      }

    },



  },
  computed: {
    is_sale() {
      // 1出价中 2待取货 3已完成 4已取消
      let flag = this.active
      if(this.active == 0){ flag = ''}
      return flag
    },
    disabled() {
      return (this.active!=-1?this.tabsLi[this.active].loading:true) || (this.active!=-1?this.tabsLi[this.active].noMore:false)
    },
    noMore() {
      return this.tabsLi[this.active].list.length >= this.tabsLi[this.active >= 0 ? this.active : 0].maxlength
    },
    actions(){
      let list = [{text: '选项一'}, {text: '选项二'}, {text: '选项三'}]
      if(this.activeObj.status == 2){
        // 1出价中 2待取货 3已完成 4已取消
        list = [{text: '取消交易',type:'close'}]
      }
      if(this.activeObj.status == 3){
        // 1出价中 2待取货 3已完成 4已取消
        list = [{text: '删除订单',type:'delete'}]
      }
      return list
    }


  },
  mounted() {
    // this.getList()
  },
}
</script>

<template>
  <div class="container mySell">
    <div class="area">
      <navigationBar :title="'我卖出的'" class="navigationBar"></navigationBar>
      <van-tabs v-model="active" animated class="vanTabs" @change="tabsChange">
        <!--        <van-tab v-for="(item,index) in tabsLi" :key="index" :title="item.text">-->
        <!--          <div class="ul" v-infinite-scroll="load"-->
        <!--               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">-->

        <!--            <p v-if="tabsLi[index].loading">加载中...</p>-->
        <!--            <p v-if="tabsLi[index].noMore">没有更多了</p>-->
        <!--          </div>-->
        <!--        </van-tab>-->


        <van-tab
            v-for="(item, index) in tabsLi"
            :title="item.name"
            :key="index"
        >
          <template #title>
            <div class="navTitle">
            {{ item.text }}<van-badge :content="item.nums" class="badge" v-if="item.nums>0"/>
           </div>
          </template>
          <div class="ul" v-infinite-scroll="load"
               :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">
            <div v-for="(itemc,indexcc) in tabsLi[index].list" :key="indexcc+'itemc'" class="li">
              <div class="item" @click.stop="godetails(itemc)">
                <div class="center">
                  <div class="left">
                    <img :src="itemc.showImg" alt="" class="showimg">
                  </div>

                  <div class="right">
                    <div class="name">{{ itemc.product_name }}</div>
                    <div class="centerInn">
                      <div class="goodsPrice"><div class="price"><span class="text">物品金额</span>￥<span class="nums">{{itemc.price}}</span></div></div>
                    </div>
                    <div class="price"><span class="text">出价金额</span>￥<span class="nums">{{itemc.price}}</span></div>
                  </div>
                </div>
                <div class="footer">
                  <div class="left" v-if="itemc.status == 3">
<!--                    <div class="btn">删除</div>-->
                    <van-popover
                        v-model="itemc.showPopover"
                        trigger="click"
                        theme="dark"
                        :actions="actions"
                        placement="top-start"
                        @select="popoverselect"
                        @open="popoveropen(itemc)"
                        @close="popoverclose(itemc)"
                    >
                      <template #reference>
                        <span class="iconfont icon-Frame20"></span>
                      </template>
                    </van-popover>
                  </div>
                  <div class="right">
                    <div class="btn" v-if="itemc.status == 1" @click.stop="rejectBtn(itemc)">拒绝</div>
                    <div class="btn last" v-if="itemc.status == 1" @click.stop="agreeBtn(itemc)">同意</div>
                    <div class="btn lastAo" v-if="itemc.status == 2" @click.stop="completeBtn(itemc)">订单完成</div>
                    <div class="btn last" v-if="itemc.status == 2" @click.stop="contactBtn(itemc)">联系买家</div>
<!--                    <div class="btn last" @click.stop="contactBtn(itemc)">联系买家</div>-->
                    <div class="btn completeLast" v-if="itemc.status == 3" @click.stop="contactBtn(itemc)">联系买家</div>
                    <div class="btn" v-if="itemc.status == 4||itemc.status == 6" @click.stop="deleteBtn(itemc)">删除订单</div>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="tabsLi[index].loading">加载中...</p>
            <p v-if="tabsLi[index].noMore">没有更多了</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<style scoped lang="scss">

.container {
  background: #f4f6fa;
  height: 100vh;
}
.container::-webkit-scrollbar{
  display: none;
}
.navTitle{
  position: relative;
  .badge{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%,-10%);
  }
}
.area {
  height: 100vh;
}

.navigationBar {
  background: #fff;
}

.vanTabs .van-tabs__wrap {
  background: #fff;
}

.vanTabs {

  .ul {
    height: 100vh;
    overflow: scroll;

    .li {

      .item {

        border-radius: 10px;
        background: #ffffff;
        margin: 10px 16px;
        padding: 10px;

        .top {
          text-align: left;

          .avatar {
            width: 20px;
            height: 20px;
            object-fit: cover;
            vertical-align: middle;
          }

          .nickname {
            color: #999999;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle;
            display: inline-block;
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
        }

        .center {
          display: flex;

          .left {
            width: 74px;
            height: 74px;

            .showimg {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
          }

          .right {
            flex: 1;
            text-align: left;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .name{
              display: -webkit-box;
              /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
              -webkit-box-orient:vertical;
              /*要显示的行数*/
              -webkit-line-clamp:2;
              /* 溢出部分隐藏 */
              overflow:hidden;
              color: #1b1b1b;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px
            }
            .price{
              font-size: 12px;
              color: #fd2a39;
              font-weight: 400;
              line-height: 24px;

              .nums{
                font-weight: 500;
                font-size: 18px;
              }
              .text{
                color: #1b1b1b;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }
            }
            .goodsPrice{

              .price{
                color: #1b1b1b;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                .nums{
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .footer {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          .left{
            text-align: left;
            .btn{

            }
          }
          .right{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .btn{
              margin-left: 10px;
            }
          }
          .btn{
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            padding: 6px 10px;
            border-radius: 25px;
            border: 0.5px solid #999999;
            color: #1b1b1b;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
          .last{
            background: #fd2a39;
            border: none;
            place-content: center;
            place-items: center;
            gap: 4px;
            padding: 6px 24px;
            border-radius: 25px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .ul::-webkit-scrollbar{
    display: none;
  }
}
</style>
<style>

.mySell .van-tab {
  flex: none;
  width: 20%;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.mySell .van-tab--active {
  text-align: center;
  color: #1b1b1b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.mySell .van-tabs--line .van-tabs__wrap {
  padding-bottom: 10px;
}
.mySell .van-tab__text--ellipsis{
  overflow: visible;
}
</style>