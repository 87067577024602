<script>
export default {
  name: "cardItem",
  props:{
    item:[Object,Number]
  },
  methods:{
    godetails(item){
      this.$emit('godetailsSon',item)
    }
  },
}
</script>

<template>
  <div class="card" @click="godetails(item)">
    <div class="title">
      <div class="leftText">{{item.position}}</div>
      <div class="rightSalary">{{ item.salary_range }}</div>
    </div>
    <div class="name">
      <div class="nameText">{{ item.company.name }}</div>
      <div class="is_attestation" v-if="item.company.id">已认证</div>
    </div>
    <div class="address" v-if="item.city_name||item.district_name">
      <span class="iconfont icon-dingwei"></span>
      <span class="address_text" >{{ item.city_name }} {{ item.district_name }}</span>
    </div>
    <div class="tagScroll">
      <div class="ul">
        <div class="li" v-for="(item2,index2) in item.benefitsLi" :key="index2">
          {{ item2 }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card{
  border-radius: 10px;
  background: #ffffff;
  margin: 10px;
  padding: 10px;

  .title{
    display: flex;
    justify-content: space-between;
    .leftText{
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1b1b1b;
    }
    .rightSalary{
      color: #fc223b;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      width: 40%;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .name{
    margin-top: 10px;
    display: flex;
    align-items: center;
    .nameText{
      color: #666666;
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-right: 10px;
      max-width: 80%;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .is_attestation{
      width: fit-content;
      height: 18px;
      background: #ffffff;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      border: 1px solid #0393f5;
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      padding: 2px 6px;
      border-radius: 5px;
      color: #0393f5;
    }
  }
  .address{
    margin-top: 6px;
    text-align: left;
    .iconfont{
      font-size: 18px;
      color: #4891ee;
      vertical-align: middle;
      margin-right: 5px;
    }
    .address_text{
      color: #666666;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
  }
  .tagScroll{
    margin-top: 6px;
    .ul{
      text-align: left;
      white-space: nowrap;
      overflow-x: scroll;
      .li{
        width: fit-content;
        height: fit-content;
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        padding: 4px 12px;
        border-radius: 4px;
        background: #f6f6f6;
        color: #666666;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        margin-right: 6px;
      }
    }
    .ul::-webkit-scrollbar{
      display: none;
    }
  }
}
</style>