<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {create, getNowTime, getuserShip, sub_verification_barrel} from "@/utils/api";
import {Toast} from "vant";
import chooseTime from "@/components/common/choose_time/choose_time.vue";

export default {
  name: "createOrderSecond",
  components: {chooseTime, navigationBar},
  data() {
    return {
      is_VIP:false,
      packaging:1,
      list: [],
      myself: 0,//自有空桶
      isAll: false,
      allPrice: {
        nums: 0,
        yuan: 0,
        mao: 0
      },//总价格
      addresst: {},//用户地址
      payFlag: false,//支付按钮
      shipTime: '',//发送给服务器的时间
      shipTimeshow: '立即配送',//前台展示
      timeShow: false,//时间弹出框
      serverTime: 0,//服务器时间
      order_type: 1,//订单类型
      remark: '',//备注
      remarkPop:false,
      textareaRemark:'',
    }
  },
  watch:{
    amount(n) {
      // console.log((n+this.packaging).toFixed(2).split(".")[0])
      //
      console.log(n)
      this.allPrice.yuan = (this.chaoshi?n+this.packaging:n).toFixed(2).split(".")[0]
      this.allPrice.mao = (this.chaoshi?n+this.packaging:n).toFixed(2).split(".")[1]
    },
    list(n){
      n.forEach(item=>{
          item.price = item.nums*item.products.price
          item.yuan = (item.nums*item.products.price).toFixed(2).split(".")[0]
          item.mao = (item.nums*item.products.price).toFixed(2).split(".")[1]
          item.mktprice = (item.nums*item.products.mktprice).toFixed(2)
        // item.mao = item.price.split('.')[1]
      })
      if(n.every(item=>{return item.products.goods_type_id==6})){
        this.order_type = '12'
      }else{
        this.order_type = '1'
      }
    },
  },
  computed: {
    chaoshi() {
      return this.list.every(item => {
        return item.type == 5
      })
    },
    shuizhan() {
      return this.list.every(item => {
        return item.type == 1
      })
    },
    amount() {
      if (this.$store.state.is_vip.is_vip) {
        return this.list.reduce((total, item) =>
                total += item.products.vip_price * item.nums
            , 0)
      } else {
        return this.list.reduce((total, item) =>
                total += item.products.price * item.nums
            , 0)
      }

    },
  },
  mounted() {

    if (this.$route.params.list) {
      this.list = this.$route.params.list;

      this.allPrice = this.$route.params.allPrice;
    }else{
      this.list = this.$store.state.waterOrderLi

    }
    console.log(this.list)
    this.initialize()

    this.ininTime()

    // new Date().getHours()
    // console.log(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate())
  },
  beforeRouteLeave(to,from,next){
    if(["buyPail"].includes(to.name)){
      from.meta.keepAlive = true;
    }else{
      from.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    async ininTime() {
      const {data} = await getNowTime()
      this.serverTime = data.data
      console.log(this.serverTime)
      const now = new Date(data.data * 1000)
      const last = new Date((data.data * 1000) + 3600000)
      let y = now.getFullYear()
      let m = now.getMonth() + 1
      let d = now.getDate()
      let hh = now.getHours()
      let mm = now.getMinutes()
      let hh1 = last.getHours()
      let mm2 = last.getMinutes()
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      hh = hh < 10 ? '0' + hh : hh
      mm = mm < 10 ? '0' + mm : mm
      hh1 = hh1 < 10 ? '0' + hh1 : hh1
      mm2 = mm2 < 10 ? '0' + mm2 : mm2
      this.shipTime = y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ',' + y + '-' + m + '-' + d + ' ' + hh1 + ':' + mm2
      this.shipTimeshow = '立即配送'
    },
    chakan() {
      this.isAll = !this.isAll
    },
    GetDateStr(AddDayCount, num) {
      let dd = new Date(this.serverTime * 1000);
      dd.setDate(dd.getDate() + AddDayCount); // 获取AddDayCount天后的日期
      let m = dd.getMonth() + 1; // 获取当前月份的日期
      let y = dd.getFullYear()
      m = m.toString();
      if (m.length == 1) {
        m = '0' + m;
      }
      let d = dd.getDate();
      d = d.toString();
      if (d.length == 1) {
        d = '0' + d;
      }
      let r = m + '月' + d + '日'

      if (num == 1) {
        return r
      } else {
        return y + '-' + m + '-' + d
      }
    },
    childMessage(obj) {
      // if(obj.index == 0){
      // this.ininTime()
      // }else{
      this.shipTimeshow = obj.item.timeshow
      this.shipTime = obj.item.time
      // this.GetDateStr()
      // }
      this.timeShow = false
    },
    async initialize() {
      if(this.$store.state.user.addId){
        console.log(this.$store.state.user)
        this.addresst = {
          name:this.$store.state.user.ship_name,
          ship_address_ds:this.$store.state.user.ship_address_ds,
          id:this.$store.state.user.addId,
        }
        this.addresst = this.$store.state.shipingAddressList[0]
        this.addresst.ship_address_ds = this.addresst.province_name+this.addresst.city_name+this.addresst.district_name+this.addresst.address
        console.log(this.$store.state.shipingAddressList[0].ship_name,)
        console.log(this.$store.state.shipingAddressList)


      }else{
        await getuserShip(this.$store.state.token).then(async res => {
          if (res.data.status == true) {
            let addressList = res.data.data
            let moren = {}
            if (addressList.length > 0) {
              addressList.forEach((item) => {
                if (item.is_def == 1) {
                  item.chekc = true
                  moren = item
                } else {
                  item.chekc = false
                  if (item.chekc) {
                    moren = item
                  }
                }
              })
              if (!moren.address) {
                moren = addressList[0]
                addressList[0].chekc = true
              }
              this.addresst = moren
              console.log(this.addresst)
            }
            this.$store.commit('updateShipingAddress', res.data.data)
          } else {
            Toast(res.data.msg)
          }
        })
      }

    },
    goAddress() {
      console.log('选择地址')
      this.$router.push({
        path:'/userAddress',
        query:{id:this.addresst.id},
      })
    },
    openTime() {
      this.timeShow = true
    },
    openRemark() {
      // console.log('打开备注')
      this.remarkPop = true
      this.textareaRemark = this.remark
    },
    buyTOng() {
      this.$router.push(({name:'buyPail'}))
    },
    okRemark(){
      this.remarkPop = false
      this.remark = this.textareaRemark
      this.textareaRemark = ''
    },
    submit() {
      console.log('去付款')
      if (this.payFlag) return;
      let mKT = 0
      let cart_ids = []

      this.list.forEach(r => {
        if (r.products.goods_type_id == 1) {
          mKT += r.nums
        }
      })
      this.list.forEach(r => {
        cart_ids.push(r.id)
      })
      console.log(this.addresst)
      if (!this.addresst.id) {
        Toast('请您选择收货地址')
        return;
      }

      if (this.shipTime == '') {
        Toast('请选择送达时间')
        return;
      }
      if ((this.$store.state.user.my_kt_ktpledge >= 0 || this.$store.state.user.my_kt_ktpledge <= 0) && mKT > this.$store.state.user.kt_pledge + this.$store.state.user.my_kt_ktpledge) {
        Toast('空桶数量不足');
        return;
      }
      if (this.list.some(e => {
        return e.products.goods_type_id == 1
      })) {
        if (this.shuizhan && this.$store.state.user.my_kt_status == 0) {
          this.sub_verification_barrel()
        }
      }

      let ship = {}
          ship['name'] = this.addresst.name,
          ship['yx'] = '39.932246,116.453546',
          ship['ds'] = this.addresst.ship_address_ds,
          ship['address'] = this.addresst.address,
          ship['mobile'] = this.addresst.mobile,
          ship['time'] = this.shipTime,
          ship['remark'] = this.remark,
          ship['id'] = this.addresst.id
      console.log(ship, this.addresst)
      create(this.$store.state.token, this.order_type, cart_ids, JSON.stringify(ship)).then(res => {
        console.log(res.data.data)
        if(res.data.status){
          this.$store.commit('updateWaterOrder',{})
          this.$router.push({
            path:"/pay",
            query:{data:JSON.stringify(res.data.data),backStep:2,type:"u_order_songsui"}
          })
        }else{
          Toast(res.data.msg)
        }
      })
    },
    sub_verification_barrel() {
      sub_verification_barrel(this.$store.state.token, Number(this.myself)).then(res => {
        console.log(res)
      })
    },
  },

}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'创建订单'" class="navigation"></navigationBar>
      <div class="card">
        <div class="address" @click="goAddress">
          <div class="left" v-if="!addresst.id">请选择地址</div>
          <div class="leftT" v-else>{{ addresst.ship_address_ds }}</div>
          <span class="iconfont icon-Frame-4"></span>
        </div>
        <div class="username" v-if="addresst.name">
          {{ addresst.name }} {{ addresst.mobile }}
        </div>
        <div class="time" @click="openTime">
          <div class="left">选择时间</div>
          <div :class="chaoshi?'chaoshiTime':''">
            <span class="times">{{ shipTimeshow }}</span>
            <span class="iconfont icon-Frame-4"></span>
          </div>
        </div>
        <div class="tip" v-if="shuizhan">送水服务营业时间 早8：00一晚8：00</div>
      </div>
      <div class="card">
        <div class="remark" @click="openRemark">
          <div class="leftText">订单备注</div>
          <input v-model="remark" type="text" class="rightRemarks" placeholder="请备注" readonly>
        </div>
      </div>
      <div class="card" v-if="shuizhan&&$store.state.user.my_kt_status==0">
        <div class="ziyou" >
          <div class="ziyouTop">
            <div class="left">自有空桶数量</div>
            <div class="right">
              <input type="number" class="ziyouInput" oninput="if(value.length>4)value=value.slice(0,4)" maxlength="5"
                     v-model="myself">
              桶
            </div>
          </div>
          <div class="tip">
            如：家中有空桶，请填写空桶数量，可抵押空桶押金
          </div>
        </div>

      </div>
      <div class="buy" @click="buyTOng" v-if="shuizhan&&$store.state.user.my_kt_status==0">
        <div class="left">购买空桶</div>
        <span class="iconfont icon-jiantou1"></span>
      </div>

      <van-popup v-model="timeShow" round closeable position="bottom">
        <chooseTime :white="'white'" :type="'song'" :color="'#0085FF'" @childMessage="childMessage"></chooseTime>
      </van-popup>
      <div class="commodityInfor">
        <div class="title commodTitle">
          订单信息
        </div>
        <div v-for="(item,index) in list" :key="index">
          <div class="commodity" v-if="isAll?true:index<3">
            <div class="leftBox">
              <div class="imageBox">
                <img :src="$store.state.imageHost+item.products.images" alt="" class="img">
              </div>
              <div class="textBox">
                <div class="brand">
                  <div class='brandBox'>品牌</div>
                  <div class='text'>{{ item.products.brand.name }}</div>
                </div>
                <div class="title">
                  {{ item.products.name }}
                </div>
                <div class="specifications oneLinesOmut">
                  规格：{{ item.products.specifications }}
                </div>
                <div class="univalence">
                  单价：￥{{item.products.price}}
                </div>
              </div>
            </div>
            <div class="priceBox" v-if="shuizhan">
              <div class="nums">
                x{{ item.nums }}
              </div>
              <div class="oldPirce">￥{{ item.mktprice }}</div>
              <div class=" price">
                <span class="unit">￥</span><span class="yuan">{{ item.yuan }}</span>.{{ item.mao }}
              </div>
            </div>
            <div class="priceBox" v-if="chaoshi">
              <div class="nums">
                x{{ item.nums }}
              </div>
              <div v-if="!is_VIP">
                <div class="priceinner">
                  <div class=" price">
                    <span class="unit">￥</span><span class="yuan">{{ item.yuan }}</span>.{{ item.mao }}
                  </div>
                  <div class="oldPirce">￥{{ item.mktprice }}</div>
                </div>
                <div class="vipPrice">
                  <div class="price">
                    ￥{{ item.products.vip_price }}
                  </div>
                  <div class="tag">会员价</div>
                </div>
              </div>
              <div v-else>
                <div class="priceinner">
                  <div class=" price">
                    <span class="unit">￥</span><span class="yuan">24</span>.00
                  </div>
                  <div class="tag">会员价</div>
                </div>
                <div class="vipPrice">
                  <div class="price">
                    ￥{{ item.yuan }}.{{ item.mao }}
                  </div>
                  <div class="oldPirce">￥{{ item.mktprice }}</div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div v-if="list.length>3">
          <div class="detailsShowBtn" v-if="!isAll" @click="chakan" style="padding-bottom: 10px;">展开更多</div>
          <div class="detailsShowBtn" v-if="isAll" @click="chakan" style="padding-bottom: 10px;">点击收缩</div>
        </div>
      </div>

      <div class="bottomFlexd" :class="shuizhan?'blueColor':''">
        <div class="left">
          <div class="price">
            <span class="unit">￥</span> <span class="yuan">{{ allPrice.yuan }}</span>.{{ allPrice.mao }}
          </div>
          <div class="text">
            免费配送￥0 {{chaoshi?`| 打包费${packaging}元`:``}}
          </div>
        </div>
        <div class="right" @click="submit">
          去付款
        </div>
      </div>

      <van-popup v-model="remarkPop" round closeable position="bottom" class="remarkPop">
        <div class="title">备注</div>
        <div class="remark">
          <textarea name="" id="" cols="30" rows="10" class="textarea" v-model="textareaRemark" maxlength="50"></textarea>
        </div>
        <div class="button" @click="okRemark">确定</div>
      </van-popup>

    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #F4F6FA;
}

.navigation {
  background: #fff;
}

.card {
  border-radius: 10px;
  background: #FFFFFF;
  margin: 10px 16px;
  padding: 10px;
  position: relative;
  z-index: 5;

  .address, .time {
    display: flex;
    justify-content: space-between;

    .left {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 24px;
      text-align: left;
    }

    .iconfont {
      font-size: 20px;
      color: #1B1B1B;
    }
  }

  .time {
    margin-top: 10px;

    .times {
      font-weight: 400;
      font-size: 14px;
      color: #0085FF;
      line-height: 24px;
    }
  }
  .chaoshiTime{
    display: flex;
    .times{
      color: #FC223B;
      align-items: center;
      vertical-align: middle;
    }
  }

  .tip {
    font-weight: 400;
    font-size: 12px;
    color: #0085FF;
    line-height: 24px;
    text-align: left;
    margin-top: 4px;
  }
}

.rightRemarks {
  background: #fff;
  border: none;
  font-size: 14px;
  margin-left: 10px;
  width: 80%;
}

.rightRemarks::placeholder {
  color: #c7c7c7;
}

.remark {
  display: flex;

  .leftText {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    text-align: left;
  }

}

.ziyou {

  .ziyouTop {
    display: flex;
    justify-content: space-between;

    .left {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 24px;
    }

    .right {
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
    }

    .ziyouInput {
      background: #F1F6FD;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #286CFB;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #286CFB;
      line-height: 24px;
      font-style: normal;
      display: inline-block;
      width: 40px;
    }
  }
}

.buy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 5px 10px;
  background: #286CFB;
  border-radius: 0 0 10px 10px;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  margin: -15px 16px 10px 16px;

  .left {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
  }

  .iconfont {
    transform: rotate(90deg);
    font-size: 20px;
  }
}

.commodityInfor {
  background-color: white;
  margin: 10px 16px 0 16px;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  padding-bottom: 2px;
  position: relative;
  z-index: 2;

  .commodTitle {
    border-bottom: 1px solid rgba(153, 153, 153, .15);
    padding-bottom: 10px;
    text-align: left;
  }

  .commodity {
    display: flex;
    padding: 10px 0 8px 0;

    .leftBox {
      display: flex;
      width: 80%;

      .imageBox {
        width: 23%;
        height: auto;

        .img {
          width: 50px;
          height: 50px;
          border-radius: 16px;
          background: #f9f9f7;
        }
      }

      .textBox {
        width: 70%;
        text-align: left;

        .brand {
          display: flex;
          align-items: center;

          .brandBox {
            background: #E8E8E8;
            border-radius: 4px 4px 4px 4px;
            font-weight: 500;
            font-size: 12px;
            color: #1B1B1B;
            line-height: 18px;
            padding: 0 4px;
          }

          .text {
            font-weight: 500;
            font-size: 13px;
            color: #1B1B1B;
            line-height: 18px;
            margin-left: 5px;
          }
        }

        .title {
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #1b1b1b;
          font-weight: 500;
        }

        .specifications {
          font-size: 14px;
          color: #7f7f7f;
        }
        .univalence{
          font-size: 14px;
          color: #7f7f7f;
        }
      }
    }
  }

  .detailsShowBtn {
    text-align: center;
    font-size: 14px;
    color: #666666;
  }

  .priceBox {
    width: 20%;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .priceinner{
      display: flex;
      align-items: baseline;
      .price{
        //margin-right: 10px;
      }

    }
    .vipPrice{
     display: flex;
      justify-content: space-between;
      align-items: baseline;
      .price{
        color: #392723;
        margin-right: 5px;
      }

    }
    .tag{
      color: #392723;
      font-size: 12px;
      white-space: nowrap;
    }
    .nums {
      font-size: 13px;
    }

    .oldPirce {
      font-weight: 400;
      font-size: 12px;
      color: #7F7F7F;
      line-height: 18px;
      text-align: left;
      text-decoration-line: line-through;
    }
  }

  .priceBox .unit {
    color: #F74440;
  }

  .priceBox .price {
    font-size: 14px;
    font-weight: 600;
    max-width: 300%;
    color: #F74440;

    .yuan {
      font-size: 20px;
    }
  }

  .title {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #1b1b1b;
  }

  .textBox .title {
    font-weight: 600;
  }
}

.bottomFlexd {
  display: flex;
  padding: 10px 0;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .left {
    margin-left: 16px;
    text-align: left;
    .price {
      color: #FD2A39;

      .unit {
        font-size: 14px;
      }

      .yuan {
        font-size: 24px;
      }
    }
    .text{
      font-weight: 400;
      font-size: 11px;
      color: #7F7F7F;
    }
  }

  .right {
    margin-right: 16px;
    background-color: red;
    border-radius: 32px 32px 32px 32px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
    padding: 10px 31px;
  }
}
.remarkPop{
  background-color: #fff!important;
  .title{
    margin: 14px 0;
    font-size: 16px;
    color: #1B1B1B;
    font-weight: 500;
  }
  .remark{
    .textarea{
      width: 90%;
      margin: 0 auto;
      //background: #f6f6f6;
      background-color: #f6f6f6;
      border-radius: 10px;
      padding: 10px;
      font-size: 14px;
      border: none;
      margin-bottom: 100px;
    }
  }
  .button{
    color: white;
    background-color: #398ae9;
    border-radius: 10px;
    margin: 10px auto;
    text-align: center;
    font-size: 16px;
    padding: 13px 0;
    position: absolute;
    transform: translate(-50%,0);
    bottom: 0;
    left: 50%;
    width: 90%;
  }
}
.blueColor {
  .price {
    color: #0085FF !important;
  }

  .right {
    background-color: #0085FF !important;
  }
}

.username {
  text-align: left;
}
</style>