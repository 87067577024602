<template>
    <div class="container">
        <navigationBar :title="'装修质保'" class="navigation"></navigationBar>
        <div class="area">
            <div class="topNav">
                <div class="btn" :class="chooseIndex == 1 ? 'chooseInde' : ''" @click='chooseIndexFun(1)'>装修施工</div>
                <div class="btn" :class="chooseIndex == 2 ? 'chooseInde' : ''" @click='chooseIndexFun(2)'>装修设计</div>
            </div>
            <div v-if="chooseIndex == 1">
                <div class="innerarea">
                    <div class="line">
                        <div class="lable">合同编号</div>
                        <div class="right">
                            BJ061646406464
                        </div>
                    </div>
                </div>
                
                <div class="innerarea">
                    <div class="line border_bottom">
                        <div class="lable">甲方名称</div>
                        <div class="right">
                            许三多
                        </div>
                    </div>
                    <div class="line">
                        <div class="lable">品牌名称</div>
                        <div class="right">
                            146 5555 5555
                        </div>
                    </div>
                </div>


                <div class="innerarea">
                    <div class="line border_bottom">
                        <div class="lable">租赁期限</div>
                        <div class="right">
                            10年
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">装修面积</div>
                        <div class="right">
                            10m²
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">行业属性</div>
                        <div class="right">
                            10454
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">租赁合同</div>
                        <div class="right">
                            10年
                        </div>
                    </div>
                    <div class="line">
                        <div class="lable">房产证明</div>
                        <div class="right">
                            查看
                            <van-icon name="arrow"/>
                        </div>
                    </div>
                </div>
                <div class="innerarea">
                    <div class="line border_bottom">
                        <div class="lable">装修地址</div>
                        <div class="right">
                            10年
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">施工单位</div>
                        <div class="right">
                            10m²
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">甲方代表</div>
                        <div class="right">
                            10454
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">联系电话（甲方代表）</div>
                        <div class="right">
                            10年
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">乙方代表</div>
                        <div class="right">
                            10年
                        </div>
                    </div>
                    <div class="line">
                        <div class="lable">联系电话（乙方代表）</div>
                        <div class="right">
                            查看
                            <van-icon name="arrow"/>
                        </div>
                        
                    </div>
                </div>
                <div class="innerarea">
                    <div class="line border_bottom">
                        <div class="lable">合同期限</div>
                        <div class="right">
                            10年
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">竣工验收</div>
                        <div class="right">
                            10m²
                        </div>
                    </div>
                    <div class="line border_bottom">
                        <div class="lable">施工延期</div>
                        <div class="right">
                            10454
                        </div>
                    </div>
                    <div class="line">
                        <div class="lable">施工变更</div>
                        <div class="right">
                            查看
                            <van-icon name="arrow"/>
                        </div>
                    </div>
                </div>
                <div class="file_ul">
                    <div class="lineLi">
                        <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    </div>
                    <div class="lineLi">
                        <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    </div>
                    <div class="lineLi">
                        <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    <div class="li">
                        <div class="li_inner">
                            <img class="img" src="https://picture.bjbjkj.top/small_program_image/userProtocol.png" alt="">
                            <div class="text">拆除合同</div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div v-if="chooseIndex == 2">

                <div class="innerarea">
                    <div class="line border_bottom">
                        <div class="lable">设计单位</div>
                        <div class="right">
                            单位
                        </div>

                    </div>
                    <div class="line border_bottom">
                        <div class="lable">联系电话</div>
                        <div class="right">
                            146 5555 5555
                        </div>
                    </div>
                    <div class="line">
                        <div class="lable">主案设计</div>
                        <div class="right">
                            名字
                        </div>
                    </div>
                </div>
                <div class="innerarea">
                    <div class="img_ul">
                        <div class="img_li" v-for="(item, index) in img_li" :key="index">
                            <img class="img" :src="item.image" alt="">
                            <div class="text">{{ item.text }}</div>
                        </div>
                    </div>
                </div>
            </div>





            <div style="width: 100%;height: 100px;"></div>
        </div>
    </div>
</template>

<script>
import navigationBar from '../../../navigation-bar/navigation-bar.vue';
export default {
    components: {
        navigationBar
    },
    props: {},
    data() {
        return {
            chooseIndex: 1,
            img_li: [
                { text: '施工图', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
                { text: '效果图', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
                { text: '全景图', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
                { text: '数字化审图', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
                { text: '施工变更', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
                { text: '效果变更', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
                { text: '全景变更', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
                { text: '数字化变更', image: 'https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg' },
            ],

            file_Li:[
                {text:'',},
                {text:'施工合同',},
                {text:'施工预算',},
                {text:'施工交底',},
                {text:'隐检验收',},
                {text:'竣工验收',},
                {text:'工程结算',},
                {text:'施工质保',},
                {text:'客户评价',},
            ],
        };
    },
    watch: {},
    computed: {},
    methods: {
        chooseIndexFun(e) {
            if (this.chooseIndex == e) return;
            this.chooseIndex = e
        },
    },
    created() { },
    mounted() {
        console.log(this.$route.query);
    }
};
</script>
<style lang="scss" scoped>
.container {
    background: #F4F6FA;
    margin-bottom: 40px;
    padding-bottom: 40px;
}

.area {
    padding: 0 16px;

    .topNav {
        margin: 10px auto;
        display: flex;
        border: 1px solid rgba(153, 153, 153, 0.15);
        border-radius: 10px 10px 10px 10px;

        .btn {
            width: 49%;
            font-size: 13px;
            color: #666666;
            padding: 11px;
        }

        .chooseInde {
            background: #FF824B;
            border-radius: 10px 10px 10px 10px;
            color: #fff;
        }
    }
}

.innerarea {
    margin-top: 10px;

    .line {
        display: flex;
        padding-bottom: 10px;
        align-items: center;
        justify-content: space-between;
        margin-left: 8px;
        margin-right: 8px;
        padding: 10px 0;

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #1B1B1B;
            font-size: 14px;
            // .iconfont{
            //     // color: red;
            // }
        }

        .lable {
            color: #666666;
            font-size: 14px;
            line-height: 24px;
        }

        .input {}
    }

    .border_bottom {
        border-bottom: 1px solid rgba(153, 153, 153, 0.15);
    }
}

.img_ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 10px 0;

    .img_li {
        width: 25%;
        margin-top: 10px;

        .img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
        }
    }
}
.file_ul{
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: baseline;
    text-align: left;
    .lineLi{
        display: flex;
        justify-content: space-between
    }
    .li{
        // margin-right: 15px; 
        margin-top: 10px;
        display: inline-block;
        background: #FFFCFA;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #FC223B;
        color: #FC223B;
        font-size: 13px;
        padding: 6px 10px;
        .li_inner{
            display: flex;
        }
        .img{
            // 
            width: 17.93px;
            margin-right: 8px;
        }
    }
}
</style>