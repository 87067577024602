<script>
export default {
  name: "sukPop",
  props: {
    goods: [Object],
    num:[Number,String],
    chaoshi:[Boolean],
  },
  watch:{
    num(n){
      this.nums = n
    },
    nums(n){
      this.nums = n
      console.log(n,'ssssssssssssssssssssssssssssssssssss')
    },
  },
  data(){
    return{
      nums:this.num,
    }
  },
  methods:{
    add(){

      this.nums+=1
    },
    unadd(){
      if(this.nums<=1)return;
      this.nums-=1
    },
    submit(){
      let obj = {
        id:this.goods.id,
        goods_type_id:this.goods.goods_type_id,
        nums:this.nums,
      }
      this.$emit('addCarc',obj)
    },
  },
  mounted() {
    this.nums = this.goods.sell_num
  }
}
</script>

<template>
  <div class="carPop">
    <div class="top">
      <div class="leftImg"><img :src="$store.state.imageHost+goods.images" class="image" alt=""></div>
      <div class="right">
        <div>
          <div class="brand">
            <div class="brandBox">品牌</div>
            {{ goods.brand.name }}
          </div>
          <div class="name">{{ goods.name }}</div>
        </div>
        <div class="price">
          <div class="nowPrice">
            <span class="unit">￥</span><span class="yuan">{{ goods.yuan }}</span>.<span
              class="mao">{{ goods.mao }}</span>
          </div>
          <div class="oldPrice">￥{{ goods.mktprice }}</div>
        </div>
      </div>
    </div>
    <div class="bttom">
      <div class="specification">规格：{{goods.specifications}}</div>
      <div class="selectNums">
        <div class="left">选择数量：</div>
        <div class="rightAdd" :class="chaoshi?'chaoshiAdd':''">
          <div class="unadd" v-if="nums>0" @click.stop="unadd()"></div>
          <div class="input" v-if="nums>0">{{ nums }}</div>
          <div class="add" @click.stop="add()"></div>
        </div>
      </div>
      <button class="button" :class="chaoshi?'chaoshiButtom':''" @click="submit">确定</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rightAdd {
  display: flex;

  .unadd,
  .add {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #398ae9;
    position: relative;
  }

  .add::after,
  .unadd:after {
    content: "";
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 10%;
    background-color: white;
    top: 50%;
    left: 50%;
    border-radius: 100px;
  }

  .add::before {
    content: "";
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10%;
    height: 50%;
    background-color: white;
    top: 50%;
    left: 50%;
    border-radius: 100px;
  }

  .input {
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: #1B1B1B;
    text-align: center;
    margin: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    overflow: scroll;
  }
  .input::-webkit-scrollbar{
    display: none;
  }

  .addBox {
    background: #0085FF;
    border-radius: 5px 5px 5px 5px;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 24px;
    text-align: center;
    padding: 0 10px;
  }
}
.carPop {
  padding: 16px;
  background: #fff;

  .top {
    display: flex;

    .leftImg {
      width: 94px;
      height: 94px;
      border-radius: 10px;
      overflow: hidden;
      margin-right: 10px;

      .image {
        width: 94px;
        height: 94px;
        object-fit: cover;
        border-radius: 10px;

      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      text-align: left;
      .brand {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;

        .brandBox {
          background: #E8E8E8;
          border-radius: 4px;
          margin-right: 5px;
          font-weight: 500;
          font-size: 12px;
          color: #1B1B1B;
          line-height: 18px;
          padding: 0 4px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1B1B1B;
        line-height: 24px;
      }

      .price {
        color: #FD2A39;
        display: flex;
        align-items: baseline;

        .unit {
          font-size: 14px;
        }

        .yuan {
          font-size: 20px;
        }

        .mao {
          font-size: 14px;
        }

        .oldPrice {
          font-weight: 400;
          font-size: 12px;
          color: #7F7F7F;
          line-height: 18px;
          text-align: left;
          font-style: normal;
          text-decoration-line: line-through;
        }
      }
    }
  }
  .bttom{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 10px;
    .specification{
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
    }
    .selectNums{
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
    }
    .button{
      background: #398ae9;
      border-radius: 32px 32px 32px 32px;
      border: none;
      margin-top: 20px;
      padding: 8px;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 24px;
    }
  }
}
.chaoshiAdd{
  .unadd{
    background: #FC223B!important;
  }
  .add{
    background: #FC223B!important;
  }
}
.chaoshiButtom{
  background: #FC223B!important;
}
</style>