<script>


import {industry_list} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "expect_job",
  data(){
    return{
      tabList:[],
      chooseNav:0,
      activeSidebar:0,
      rightChooseId:this.invalidObj,
    }
  },
  props:{
    invalidObj:[Object],
    home:[String]
  },
  components: {},
  methods:{
    close(){
      this.$emit('closePop', false);
    },
    getindustry_list(){
      industry_list().then(res => {
        if(res.data.status){
          this.tabList = res.data.data
          this.tabList.forEach(item=>{
            let list = item.data.map(item2=>{return item2.data})
            // console.log(item.data.map(item2=>{return {name:item2,list}}),'item.data')
            item.children = item.data.map(item2=>{return {name:item2.industry_name,list}})
          })
          console.log(this.tabList)

        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    chooseTop(index){
      if(this.chooseNav==index)return;
      if(this.home) return;
      this.chooseNav=index;
      this.activeSidebar=0;
    },
    chooseLeft(index){
      if(this.activeSidebar==index)return;
      this.activeSidebar = index
    },
    chooseRight(item){
      if(item == this.rightChooseId)return;
      this.rightChooseId = item
      console.log(item)
      let obj = item
      item.chooseNav = this.chooseNav+1
      this.$emit('choosePosition', obj);
      if(this.home){
        this.$emit('chooseTop', this.chooseNav+1);
      }
    },
  },
  computed:{

  },
  mounted() {
    if(this.home == 'recruitment'){
      this.chooseNav = 1
    }
    if(this.home == 'home'){
      this.chooseNav = 0
    }
    this.getindustry_list()
    console.log(this.rightList)

  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <div class="top">
      <div class="backBtn" @click="close">
        <span class="iconfont icon-Frame-4"></span>
      </div>
    </div>
    <div class="inner">
      <div class="topNav" v-if="tabList.length>0">
        <div class="itemNav" v-for="(item,index) in tabList" :key="index" :class="index == chooseNav?'chooseIndex':''" @click="chooseTop(index)">
          <span class="iconfont icon-shangpugangwei-weixuan" v-if="index == 1"></span>
          <span class="iconfont icon-bangonggangwei-xuanzhong" v-if="index == 0"></span>
          {{item.industry_name}}
        </div>
      </div>
      <div class="body" v-if="tabList.length>0">
<!--        <div class="left">-->
<!--          <div class="ul">-->
<!--            <div class="li" v-for="(item,index) in tabList[chooseNav].data" :key="index" :class="index == activeSidebar?'chooseSide':''" @click="chooseLeft(index)">-->
<!--              {{item.industry_name}}-->
<!--              <div class="line" v-if="index == activeSidebar"></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <div class="ul">-->
<!--            <div class="li" v-for="(item,index) in tabList[chooseNav].children" :key="index">-->
<!--              <van-divider-->
<!--                  :style="{ color: '#999999',fontSize:'15px', borderColor: '#999999', padding: '0 16px' }"-->
<!--              >-->
<!--                {{ item.name }}-->
<!--              </van-divider>-->
<!--              <div class="inUl">-->
<!--                <div class="inLi" v-for="(item2,index2) in item.list[index]" :key="index2">-->
<!--                  {{item2.industry_name}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


        <div class="left">
          <div class="ul">
            <div class="li" v-for="(item,index) in tabList[chooseNav].data" :key="index" :class="index == activeSidebar?'chooseSide':''" @click="chooseLeft(index)">
              {{item.industry_name}}
              <div class="line" v-if="index == activeSidebar"></div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="ul">
            <div class="li" v-for="(item,index) in tabList[chooseNav].data[activeSidebar].data" :key="index" :class="item.id == rightChooseId.id?'chooseIndex':''" @click="chooseRight(item)">
<!--              <div class="inUl">-->
<!--                <div class="inLi">-->
                  {{item.industry_name}}
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.top{
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0;
  .backBtn{
    width: 10%;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
      font-size: 36px;
    }
  }
  .title{
    width: 85%;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-end;
  }
}

.inner{

  .topNav{
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    .itemNav{
      border-radius: 10px;
      background: #f6f6f6;
      width: 45%;
      padding: 10px 0;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      border: 1px solid #f6f6f6;
      vertical-align: middle;
      box-sizing: border-box;
      .iconfont{
        font-size: 24px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }

  }

  .body{
    display: flex;
    margin-top: 10px;
    .left{
      width: 40%;
      .ul{
        .li{
          height: 52px;
          padding: 0 20px;
          display: flex;
          place-content: center;
          place-items: center;
          gap: 10px;
          flex-shrink: 0;
          background: #fafafa;
          color: #666666;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          position: relative;
          text-align: left;
          .line{
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background: #0393f5;
          }
        }
      }
    }
    .right{
      width: 70%;
      height: 85vh;
      padding-right: 18px;

      .ul{
        text-align: left;
        display: flex;
        flex-wrap: wrap;

        .li{
          display: flex;
          border-radius: 10px;
          border: 1px solid #f6f6f6;
          box-sizing: border-box;
          text-align: center;
          color: #1b1b1b;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 0px;
          width: 40%;
          margin-right: 10px;
          margin-top: 10px;
          margin-left: 10px;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        }
      }
      .inUl{
        text-align: left;
        .inLi{
          display: inline-block;
          border-radius: 10px;
          border: 1px solid #f6f6f6;
          box-sizing: border-box;
          text-align: center;
          color: #1b1b1b;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 15px;
          width: 40%;
          white-space: nowrap;
          margin-right: 10px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }
  }
}
.chooseIndex{
  border: 1px solid #0393f5!important;
  box-sizing: border-box;
  background: #ebf7ff!important;
  color: #0393f5!important;
}
.chooseSide{
  background: #ffffff!important;
  color: #0393f5!important;
  font-size: 14px!important;
}
</style>