!<template>
  <div class="container">
    <div class="area">
      <img
        src="https://picture.bjbjkj.top//img/shareBackground.png"
        class="top-background-Img"
        alt=""
      />
      <navigationBar
        :title="'分享中心'"
        :backBtn="false"
        class="navigation"
      ></navigationBar>
      <div class="inner">
        <div class="topBox">
          <div class="blur"></div>
          <div class="littleBox">
            <div class="title">我的分享</div>
            <div class="body">
              <span class="nums">{{ userInfo.fx_renshus }}</span
              >人
            </div>
          </div>

          <div class="littleBox">
            <div class="title">返现累计</div>
            <div class="body"><span class="nums">0</span>元</div>
          </div>

          <div class="littleBox" @click="goMoney">
            <div class="title">
              我的钱包<span class="iconfont icon-jiantou"></span>
            </div>
            <div class="body">
              <span class="nums">{{ myBlance }}</span
              >元
            </div>
          </div>
        </div>
        <div class="hengfu"></div>
        <div class="body shareBody">
          <van-tabs v-model="tabActive">
            <van-tab
              v-for="(item, index) in shareTabList"
              :title="item"
              :key="index"
            >
              <template #title>{{ item }}</template>

              <div class="shareRecord" v-if="tabActive == 0">
                <div class="titleBox">
                  <div class="fenxiangTitle">用户名</div>
                  <div class="fenxiangTitle">注册时间</div>
                </div>
                <div class="recordLi">
                  <div
                    class="item"
                    v-for="(item, index) in shareList"
                    :key="index"
                  >
                    <div class="every">{{ item.nickname }}</div>
                    <div class="every">{{ item.ctime }}</div>
                  </div>
                  <div class="item" v-if="shareList.length <= 0">
                    <div class="every">暂无</div>
                    <div class="every">暂无</div>
                  </div>
                </div>
              </div>
              <div class="shareRecord" v-if="tabActive == 1">
                <div class="titleBox">
                  <div class="fenxiangTitle">返现金额</div>
                  <div class="fenxiangTitle">返现时间</div>
                </div>
                <div class="recordLi">
                  <div
                    class="item"
                    v-for="(item, index) in shareList"
                    :key="index"
                  >
                    <div class="every">{{ item.nickname }}</div>
                    <div class="every">{{ item.ctime }}</div>
                  </div>
                  <div class="item" v-if="shareList.length <= 0">
                    <div class="every">暂无</div>
                    <div class="every">暂无</div>
                  </div>
                </div>
              </div>
              <div class="paimingBox" v-if="tabActive == 2">
                <div class="paimingTitle">
                  <div
                    class="paimingTitleEvery"
                    v-for="(item, index) in xiaofeiTitle"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
                <div class="paimingBody">
                  <div
                    class="item"
                    v-for="(item, index) in tixianList"
                    :key="index"
                  >
                    <div class="name every">{{ item.nickname }}</div>
                    <div class="every">{{ item.fx_renshus }}</div>
                    <div class="every">{{ item.fx_renshu }}</div>
                    <div class="every">{{ index + 1 }}</div>
                  </div>
                  <div class="nonelist" v-if="tixianList.length <= 0">
                    暂无数据
                  </div>
                </div>
              </div>
              <div class="paimingBox" v-if="tabActive == 3">
                <div class="paimingTitle">
                  <div
                    class="paimingTitleEvery"
                    v-for="(item, index) in paimingTtitle"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
                <div class="paimingBody">
                  <div class="slefItem item">
                    <div class="name every">名称</div>
                    <div class="every">分享累计</div>
                    <div class="every">本月</div>
                    <div class="every">排名</div>
                  </div>
                  <div
                    class="item"
                    v-for="(item, index) in paimingList"
                    :key="index"
                  >
                    <div class="name every">{{ item.nickname }}</div>
                    <div class="every">{{ item.fx_renshus }}</div>
                    <div class="every">{{ item.fx_renshu }}</div>
                    <div class="every">{{ index + 1 }}</div>
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>

      <van-popup v-model="$store.state.sharePopFlag" @close="closePop">
        <div class="popBox">
          <img
            src="https://picture.bjbjkj.top//img/shareOpen.png"
            alt=""
            class="topImg"
          />
          <div class="outTitle">
            <div class="topTitle">分享返利规则</div>
          </div>
          <div class="ul">
            <div class="li" v-for="(item, index) in sharePopList" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="button shareBtn" @click="goShare">去分享</div>
          <img class="shareBackdrop leftBackImg" src="https://picture.bjbjkj.top//img/shareBackdrop2.png" alt="" />
          <img class="shareBackdrop rightBackImg" src="https://picture.bjbjkj.top//img/shareBackdrop.png" alt="" />
        </div>
      </van-popup>
      <tabbar :active="2"></tabbar>

    </div>
    <!-- <van-popup v-model="show">内容</van-popup> -->

  </div>
</template>

<script>
import tabbar from "@/components/common/tabbar/home_tabbar.vue";
import navigationBar from "../../navigation-bar/navigation-bar.vue";

export default {
  components: {
    tabbar,
    navigationBar,
  },
  props: {},
  data() {
    return {
      sharePopList: [
        "本地分享100人以上，分享人可获得被分享人消费的1%",
        "全平台所有服务均可享受分享返利",
        "本地分享100人以下，分享人可获得被分享人消费的5‰",
        "被分享人在淘金券购买的领券金额全部返利给分享人",
        "分享人每周五可在分享中心或我的一键提现",
      ],
      shareTabList: ["分享记录", "返现记录", "提现记录", "分享排名"],
      paimingTtitle: ["用户名", "分享累计", "本月分享", "本月排名"],
      xiaofeiTitle: ["提现时间", "提现金额", "提现账户", "提现状态"],
      paimingList: [],
      tixianList: [],
      shareList: [
        { nickname: "hh", ctime: "15:12:00" },
        { nickname: "hh", ctime: "15:12:00" },
      ],
      myBlance: 10,
      userInfo: {
        fx_renshus: 5,
      },
      tabActive: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    goShare() {
      console.log("分享");
    },
    closePop() {
      this.$store.commit("updateShare", false);
    },
    goMoney(){
      console.log('我的钱包')
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.area {
  position: relative;
}

.navigation {
  background: transparent;
  position: relative;
  z-index: 2;
  color: #fff;
}
.top-background-Img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  max-height: 160px;
}

.popBox {
  position: relative;
  padding-top: 36px;
  border-radius: 10px;
  // overflow: scroll;
  padding-bottom: 10px;
  // height: 100px;
  // overflow: initial;
  // background: red;
  .topImg {
    position: absolute;
    width: 225px;
    height: 171px;
    top: 0;
    left: 50%;
    // float: left;

    transform: translate(-50%, -85%);
  }
  .outTitle {
    text-align: center;
    .topTitle {
      color: #ffc165;
      letter-spacing: 5px;
      font-size: 16px;
      border: 1px solid #ffc165;
      display: inline-block;
      padding: 4px 12px;
      border-radius: 25px;
      position: relative;
    }
    .topTitle::after,
    .topTitle::before {
      display: block;
      content: "";
      position: absolute;
      width: 43px;
      height: 4px;
      top: 50%;
      transform: translate(-0%, -50%);
      border-radius: 10px;
    }
    .topTitle::after {
      right: -35%;
      background: linear-gradient(
        90deg,
        rgba(254, 208, 89, 1),
        rgba(252, 229, 155, 0)
      );
    }
    .topTitle::before {
      left: -35%;
      background: linear-gradient(
        270deg,
        rgba(254, 208, 89, 1),
        rgba(252, 229, 155, 0)
      );
    }
  }
  .ul {
    margin-top: 5px;
    padding: 0 20px;
    .li {
      font-size: 14px;
      color: #1b1b1b;
      text-align: left;
    }
  }
  .shareBtn {
    background-color: #fed059;
    color: white;
    border-radius: 25px;
    margin: 10px 20px 10px 20px;
    position: relative;
    z-index: 2;
    padding: 10px;
  }
  .shareBackdrop {
    position: absolute;
    top: 0;

    z-index: 1;
  }
  .rightBackImg {
    right: 0;
    width: 156px;
    height: 165px;
  }
  .leftBackImg {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 60%;
  }
}
.van-popup {
  border-radius: 20px;
  width: 70%;
  // overflow: inherit;
  overflow-y: initial !important;
  // overflow-x: inherit!important;
  /* max-height: 80vh; */
}

.inner {
  position: relative;
  z-index: 2;
  .topBox {
    border-radius: 15px 15px 15px 15px;
    border: 2px solid rgb(165, 201, 255);
    margin: 10px 16px;
    position: relative;
    z-index: 22;
    overflow: hidden;
    padding: 10px;
    .blur {
      width: 100%;
      height: 100%;
      backdrop-filter: blur(4px);
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(186, 213, 255, 0.62);
    }
    .littleBox {
      position: relative;
      z-index: 2;
      display: inline-block;
      width: 33%;
      text-align: center;
      color: white;
      .title {
        font-size: 14px;
      }
      .body {
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin-top: 15px;

        .nums {
          display: inline-block;
          font-size: 26px;
          font-weight: 500;
          max-width: 80%;
          overflow: hidden;
          line-height: 30px;
          text-overflow: ellipsis;
        }
        .van-tab {
          color: red !important;
        }
      }
    }
  }
  .paimingBox {
    box-shadow: 0 0 2px 0 rgba(102, 102, 102, 0.25);
    border-radius: 10px;
    margin: 10px 8px 0 10px;
    overflow: hidden;
    //margin-top: 10px;
    padding: 4px 0;
    .paimingTitle {
      display: flex;
      margin: 0 auto;
      background-color: white;
      justify-content: space-around;
      .paimingTitleEvery {
        width: 25%;
        text-align: center;
        margin: 10px 0;
        font-weight: 400;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 16px;
      }
    }
    .paimingBody {
      .item {
        display: flex;
        padding: 6px 0;
        border-bottom: 1px solid red;
        .every {
          width: 25%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          color: #1b1b1b;
        }
      }
      .slefItem {
        background: #fff5f0;
      }
      .item:nth-last-of-type(1) {
        border-bottom: 1px solid transparent;
      }
    }
  }
  .shareRecord {
    box-shadow: 0 0 2px 0 rgba(102, 102, 102, 0.25);
    margin: 10px 8px 0 8px;
    padding: 6px;
    border-radius: 10px;
    .titleBox {
      display: flex;
      justify-content: space-between;
      position: relative;
      border-bottom: 1px solid rgba(153, 153, 153, 0.15);
      padding-bottom: 10px;
      .fenxiangTitle {
        width: 49%;
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
      }
    }
    .titleBox::after {
      position: absolute;
      content: "";
      display: block;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 1px;
      height: 12px;
      background: #cccccc;
    }
    .recordLi {
      .item {
        display: flex;
        justify-content: space-between;
        margin: 6px 0 0 0;
        .every {
          width: 49%;
          font-weight: 400;
          font-size: 14px;
          color: #1b1b1b;
          line-height: 24px;
        }
      }
    }
  }
}

.nonelist {
  color: #cccccc;
  font-size: 14px;
}

@media (min-width: 768px) {
  .van-popup {
    width: 650px !important;
  }
}
</style>


<style>
</style>