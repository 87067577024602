<template>
  <div>
    <navigationBar :title="navigator_title" class="navigation"></navigationBar>
    <div class="container">
      <div class="area">
        <div class="ul" v-infinite-scroll="load"
             :infinite-scroll-disabled="disabled" :infinite-scroll-immediate="false">
          <div class="li" v-for="(i,index) in list" :key="index">
            <div class="setting" v-if="type == 1">
              <div class="top">
                <div class="img">
                  <img
                      class="leftImg"
                      :src="i.showImg"
                      alt=""
                  />
                </div>
                <div class="rightInfo">
                  <div class="title">
                    <div class="leText">{{ i.items.title }}</div>
                    <div class="rigType"> {{i.status==2?'已完成':
                        i.status==3?'已取消':
                            (i.pay_status==1)?'未支付':
                                i.pay_status==6&&i.items.status==0?'勘察员未接单':
                                    i.pay_status==6&&i.items.status==1?'勘察员已接单':
                                        i.pay_status==2&&i.items.maintenance_status==1?'已付款':
                                            i.pay_status==6&&i.items.maintenance_status==1?'材料待付款':
                                                i.items.maintenance_status==2&&i.maintenance_user_status==1?'等待维修':
                                                    i.items.maintenance_status==2&&i.maintenance_user_status==2?'维修中':
                                                        i.items.maintenance_status==3&&i.confirm==1?'待验收':
                                                            i.confirm==2&&i.is_comment==1?'待评价':'等待维修'
                      }}</div>
                  </div>
                  <div class="orderNumber">订单编号：{{  i.order_id  }}</div>
                </div>
              </div>
              <div class="bott">
                <div class="time">勘察时间：{{ i.time }}</div>
                <div class="address">维修地址：{{ i.ship_address_ds }}{{ i.ship_address }}</div>
              </div>
            </div>
            <div class="history" v-if="type == 2">
              <div class="titleBox">
                <div class="left">
                  <div class="title">{{ i.items.title }}</div>
                  <div class="status" v-if="i.items.maintenance_type==1">质保外</div>
                  <div class="status" v-if="i.items.maintenance_type==2">快修质保</div>
                  <div class="status" v-if="i.items.maintenance_type==3">装修质保</div>
                </div>
                <div class="price">{{ i.goods_amount != 0.00 ? '￥' + i.goods_amount : '' }}</div>
              </div>
              <div class="body">
                <div class="time"> {{ i.items.status == 2 ? '维修时间' : '勘察时间' }}: {{ i.time }}</div>
                <div class="address">维修地址： {{ i.ship_address_ds }}{{ i.ship_address }}</div>
                <div class="orderNums">订单编号：{{ i.order_id }}</div>
              </div>
            </div>
            <div class="bottomBtn">
              <div class="btn" v-if="i.pay_status==6&&i.items.status<2&&i.status==1" @click="cancelOrder(i)">取消订单</div>
<!--              <div class="btn">修改订单</div>-->
              <div class="btn last" @click="checkOrder(i)">查看订单</div>
            </div>
          </div>
          <p v-if="loadingSc">加载中...</p>
          <p v-if="noMore">没有更多了</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigationBar from "../../../navigation-bar/navigation-bar.vue";
import {cancel_maintenanc_order, get_no_order, maintenance_order_list} from "@/utils/api";
import {Toast,Dialog} from "vant";
export default {
  components: {
    navigationBar,
  },
  props: {},
  data() {
    return {
      navigator_title: "1",
      type: 2,
      list: [],
      loadingSc: false,
      noMore: false,
      page: 1,
      limit: 10,
      maxlength: 0
    };
  },
  watch: {
    list(n) {
      console.log(n)
    },
  },
  computed: {
    disabled() {
      return this.loadingSc || this.noMore
    },
  },
  methods: {
    load() {
      console.log('1234556加载', this.type)
      this.loadingSc = true
      if (this.type == 1) {
        get_no_order(this.$store.state.token, this.page, this.limit).then(res => {
          if (res.data.status == true) {
            this.maxlength = res.data.data.count
            if (this.list.length >= res.data.data.count) {
              this.noMore = true
              this.loadingSc = false
              return
            }
            let list = res.data.data.data
            if(list.length<=0){
              this.noMore = true
              this.loadingSc = false
              return;
            }
            list.forEach(item => {
              console.log(item)
              if (item.items) {
                let time = ''
                if (item.is_up_dizhi_status == 1) {
                  time = item.items.status == 2 ? item.service_time : item.yvyue_time
                } else if (item.is_up_dizhi_status == 2) {
                  time = item.user_weixin_order_times
                }
                item.time = time.split(',')[0] + '-' + time.split(',')[1].split(' ')[1]

                if(item.items.files_user){
                  item.showImg = this.$store.state.imageHost+JSON.parse(item.items.files_user).find(e=>{return e.fileType=='image'}).tempFilePath
                }else{
                  item.showImg = 'https://picture.bjbjkj.top/touxiang/7fc545aedbedcaf7f6ef03cea3ef206.png'
                }

              }
            })
            this.list = this.list.concat(list)
            this.loadingSc = false
            this.page += 1

          }else{
            Toast(res.data.msg)
          }
        })
      } else if (this.type == 2) {
        maintenance_order_list(this.$store.state.token, this.page, this.limit).then(res => {
          if (res.data.status == true) {
            this.maxlength = res.data.data.count
            if (this.list.length >= res.data.data.count) {
              this.noMore = true
              this.loadingSc = false
              return
            }
            let list = res.data.data.list
            list.forEach(item => {
              if (item.items) {
                let time = ''
                if (item.is_up_dizhi_status == 1) {
                  time = item.items.status == 2 ? item.service_time : item.yvyue_time
                } else if (item.is_up_dizhi_status == 2) {
                  time = item.user_weixin_order_times
                }
                item.time = time.split(',')[0] + '-' + time.split(',')[1].split(' ')[1]
                console.log(item.time)
              }
            })
            this.list = this.list.concat(list)
            this.loadingSc = false
            this.page += 1

          }else{
            Toast(res.data.msg)
          }
        })
      }

    },
    // 记录
    getList() {
      maintenance_order_list(this.$store.state.token, this.page, this.limit).then(res => {
        if (res.data.status == true) {
          console.log(res.data.data.list)
          this.maxlength = res.data.data.count
          let list = res.data.data.list
          this.list = this.list.concat(list)
        }
      })

    },
    // 正在快修
    weiwancList() {
    },

    // 取消订单
    cancelOrder(item){
      Dialog.confirm({
        message: '确定取消订单吗？',
      }).then(() => {
        cancel_maintenanc_order(this.$store.state.token,item.order_id).then((res=>{
          if(res.data.status==true){
            console.log(res.data)
            item.status = 3
            Toast('取消成功')
          }else{
            Toast(res.data.msg)
          }
        }))
      });

    },

    checkOrder(item){
        let obj = item
      console.log(obj,'==========进入')
        let fourStepFlag = 0
        if(obj.status==2){
          fourStepFlag = 3
          let obj1 = {
            item:JSON.stringify(obj),
            index:fourStepFlag,
            identity:1,
          }
          this.$router.push({
            path: 'service_page',
            query: obj1
          })
          return
        }

        if(obj.items.status!=2){
          fourStepFlag = 0
        }
        if(obj.items.status==2){
          fourStepFlag = 1
        }
        if(obj.items.maintenance_status==3){
          fourStepFlag = 2
        }
        if(obj.confirm==2){
          fourStepFlag = 3
        }
      let obj1 = {
        item:JSON.stringify(obj),
        index:fourStepFlag,
        identity:1,
      }
      this.$router.push({
        path: 'service_page',
        query: obj1
      })
    },
  },
  created() {
  },
  mounted() {
    if (this.$route.query.title) {
      this.navigator_title = this.$route.query.title;
      console.log(this.$route.query.title)
      this.type = this.$route.query.type;
      if (this.type == 2) {//记录
        // this.getList()
      } else {//正在快修
        this.weiwancList()
      }

    }
  },
};
</script>
<style lang="scss" scoped>
.area {
  background: #f4f6fa;
  min-height: 92vh;

  .ul {
    height: 90vh;
    overflow: scroll;

    .li {
      margin: 10px;
      background: white;
      border-radius: 10px;
      padding: 10px;
      text-align: left;

      .setting {
        .top {
          display: flex;
          justify-content: space-between;

          .img {
            width: 20%;
            border-radius: 10px 10px 10px 10px;
            //background: red;
            display: flex;
            min-width: 70px;
            margin-right: 10px;

            .leftImg {
              width: 70px;
              height: 70px;
              border-radius: 10px 10px 10px 10px;
              //background: red;
              object-fit: cover;
            }
          }

          .rightInfo {
            width: 78%;

            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .leText {
                font-weight: 400;
                font-size: 16px;
                color: #1b1b1b;
                line-height: 24px;
                width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .rigType {
                font-size: 13px;
                color: #FC223B;
                line-height: 24px;
                text-align: right;
                min-width: 40%;
                max-width: 50%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .orderNumber {
              font-weight: 400;
              font-size: 12px;
              color: #666666;
              line-height: 24px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .bott {
          margin-top: 4px;

          .time,
          .address {
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 24px;
          }
        }
      }

      .history {
        .titleBox {
          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            width: 70%;

            .title {
              color: #FC223B;
              max-width: 70%;
              font-size: 16px;
              overflow: hidden;
              white-space: nowrap;
              overflow: hidden;
              margin-right: 10px;
              text-overflow: ellipsis;
            }

            .status {
              color: #ffffff;
              background: #FC223B;
              font-size: 12px;
              border-radius: 50px;
              padding: 0 5px;
              white-space: normal;
              min-width: 50px;
              text-align: center;
            }
          }

          .price {
            min-width: 55px;
            width: 20%;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .body {
          margin-right: 4px;

          .time, .address, .orderNums {
            color: #666666;
            font-size: 14px;
          }
        }
      }

      .bottomBtn {
        margin-top: 4px;
        text-align: right;

        .btn {
          display: inline-block;
          font-weight: 500;
          font-size: 14px;
          color: #FC223B;
          line-height: 24px;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #FC223B;
          padding: 8px 16px;
          margin-left: 10px;
        }

        .last {
          background: #FC223B;
          color: #ffffff;
        }
      }
    }
  }
}
</style>