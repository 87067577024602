<script>
import {get_work_list, getNowTime} from "@/utils/api";
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Toast} from "vant";

export default {
  name: "workRecords",
  components: {NavigationBar},
  data() {
    return {
      serverTime: '',
      nowMonth: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      lastDay: -1,
      list: [],
      xiuxiDay: 0,
      nowDayN: new Date().getDate(),
      topBoxIndex: 0,
      nowDay: (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1) + '.' + new Date().getDate() + '',
      week: '',
      weekDay: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    }
  },
  async mounted() {
    const {data} = await getNowTime()
    console.log(data.data)
    this.serverTime = data.data
    this.lastDay = this.getLastDay(this.year, new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1)
    this.nowMonth = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1;
    this.year = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getFullYear();
    this.nowDayN = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getDate();
    this.nowDay = (new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1 < 10 ? '0' + (new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1) : new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getMonth() + 1) + '.' + new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getDate() + ''
    this.week = new Date((data.data ? data.data : (new Date().getTime() / 1000)) * 1000).getDay()
    this.getList(this.year + '-' + this.nowMonth + '-' + 1, this.year + '-' + this.nowMonth + '-' + this.lastDay)
  },
  methods: {
    getLastDay(year, month) {
      // console.log(year,month);
      const date1 = new Date(year, month, 0)
      return date1.getDate()
    },
    getList(star, end) {
      get_work_list(this.$store.state.token, star, end).then(res => {
        if (res.data.status == true) {
          let list = res.data.data
          list.forEach(item => {
            if (item.come_work_time) {
              item.come_work_ime1 = item.come_work_time.split(' ')[0] + ' ' + item.come_work_time.split(' ')[1].split(':')[0] + ':' + item.come_work_time.split(' ')[1].split(':')[1]
              item.month = item.come_work_time.split(' ')[0].split('-')[1] + '/' + item.come_work_time.split(' ')[0].split('-')[2]
            } else {
              item.come_work_time1 = '未打卡'
            }
            if (item.go_work_time) {
              item.go_work_time1 = item.go_work_time.split(' ')[0] + ' ' + item.go_work_time.split(' ')[1].split(':')[0] + ':' + item.go_work_time.split(' ')[1].split(':')[1]
              item.month = item.go_work_time.split(' ')[0].split('-')[1] + '/' + item.go_work_time.split(' ')[0].split('-')[2]
            } else {
              item.go_work_time1 = '未打卡'
            }
          })
          this.list = list
          if (this.year == new Date(this.serverTime * 1000).getFullYear() && this.nowMonth == new Date(this.serverTime * 1000).getMonth() + 1) {
            this.xiuxiDay = this.getLastDay(new Date(this.serverTime * 1000).getFullYear(), new Date(this.serverTime * 1000).getMonth() + 1) - this.list.length
          } else {
            this.xiuxiDay = this.lastDay - this.list.length
          }
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    lastMonth() {
      this.nowMonth -= 1
      if (this.nowMonth <= 0) {
        this.nowMonth = 12
        this.year -= 1
      }

      this.lastDay = this.getLastDay(this.year, this.nowMonth)
      let star = this.year + '-' + this.nowMonth + '-' + 1
      let end = this.year + '-' + this.nowMonth + '-' + this.lastDay
      this.getList(star, end)
    },
    nextMonth() {
      if (this.year <= new Date(this.serverTime * 1000).getFullYear()) {
        if (this.year == new Date(this.serverTime * 1000).getFullYear() && this.nowMonth >= (new Date(this.serverTime * 1000).getMonth() + 1)) {
          return
        }
        this.nowMonth += 1
        if (this.nowMonth >= 13) {
          this.nowMonth = 1
          this.year += 1

        }
        this.lastDay = this.getLastDay(this.year, this.nowMonth)
        let star = this.year + '-' + this.nowMonth + '-' + 1
        let end = this.year + '-' + this.nowMonth + '-' + this.lastDay
        this.getList(star, end)
      }

    },
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'打卡记录'"></navigationBar>
      <div class="topNav">
        <div class="left">
          <div class="edate">{{ nowDay }}</div>
          <div class="eweek">{{ weekDay[week] }}</div>
        </div>
        <div class="right">
          <div class="monthBox topBox">
            <div class="monthLeft monthSon" @click = 'lastMonth'>
              <van-icon name="arrow-left" class="icon"/>
            </div>
            <div class="monthCenter monthSon">{{ nowMonth }}</div>
            <div class="monthRight monthSon" @click = 'nextMonth'>
              <van-icon name="arrow" class="icon"/>
            </div>
          </div>
        </div>
      </div>
      <div class="topNav centerNav">
        <div class="left">
          <div class="sumBox topBox">
            <div class="sumLeft y">月出工{{ list.length }}天</div>
            <div class="sumRight">月休息{{ xiuxiDay }}天</div>
          </div>
        </div>
      </div>
      <div class="listBox">
        <div class="listEvery" v-for="(item,index) in list" :key="index">
          <div style="display: flex;margin-left: 10rpx;align-items: center;">
            <div class="left">
              <span class="iconfont icon-rili" style="color: #398ae9;font-size: 50rpx;"></span>
            </div>
            <div class="center">
              <div class="leftBox">上班:{{ item.go_work_time1 }}</div>
              <div class="rightBox">下班:{{ item.come_work_time1 }}</div>
            </div>
          </div>
          <div class="right">{{ item.month }}</div>
        </div>
        <div class="none" v-if="list.length<=0">
          暂无数据
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.topNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;

  .left {
    width: 60%;
    text-align: left;

    .edate {
      color: #3E9DFC;
      font-size: 20px;
      //margin-left: 14px;
    }

    .eweek {
      color: #1B1B1B;
      font-size: 16px;
      margin-top: 4px;
      //margin-left: 28rpx;
    }
  }

  .right {
    width: 30%;
    display: flex;
    justify-content: flex-end;

    .topBox {
      border: 1px solid #3E9DFC;
      width: 90%;
      display: flex;
      overflow: hidden;
      background: rgba(227, 250, 255, 0.5);
    }

    .monthBox {
      justify-content: space-between;
      background-color: #398ae9;
      overflow: hidden;
      border-radius: 5px;
      padding: 1px;
    }

    .monthSon {
      background-color: #f1f6fd;
      font-size: 14px;
      text-align: center;

      .icon {
        color: #398ae9;
      }
    }

    .monthCenter {
      color: white;
      background-color: transparent;
      display: flex;
      align-items: center;
    }

    .monthLeft, .monthRight {
      width: 26px;
      height: 26px;
      line-height: 26px;
      border-radius: 5px;
    }
  }
}

.centerNav {
  margin-top: 25px;
  justify-content: center;

  .topBox {
    display: flex;
    border: 1px solid #3E9DFC;
    background: rgba(227, 250, 255, 0.5);
    border-radius: 50px;

    .sumLeft, .sumRight {
      width: 50%;
      text-align: center;
      font-size: 14px;
      padding: 4px 0;
      border-radius: 50px;
      color: rgba(62, 157, 252, 0.5);
    }

    .y {
      background-color: #FFFFFF;
      color: #3E9DFC;
    }
  }
}

.listBox {
  width: 100%;
  margin: 0 auto;
  background-color: white;

  .listEvery {
    //width: 90%;
    margin: 10px 16px;
    box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    position: relative;

    .right {
      font-size: 13px;
      color: #666666;
    }

    .center {
      margin-left: 10px;
      text-align: left;

      .leftBox, .rightBox {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}
</style>