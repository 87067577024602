<template>
  <div>
    <div class="container">
      <div class="area">
        <navigationBar
          :title="'购物车'"
          :backBtn="false"
          class="navigation"
        ></navigationBar>
        <div class="innerArea">
          <van-dropdown-menu>
            <van-dropdown-item v-model="value1" :options="option1" />
          </van-dropdown-menu>
        </div>

        <div class="body">
          <div class="out" v-for="(item, index) in list" :key="index">
            <div class="title">
              <div class="leftCheck">
                <van-checkbox
                  v-model="item.checked"
                  class="radioGroup"
                  @change="outChange(item)"
                >
                  <template #icon="props">
                    <div
                      class="radio"
                      :class="props.checked ? 'choose' : ''"
                    ></div>
                  </template>
                  <template #default> </template>
                </van-checkbox>
              </div>
              <div class="right">
                <img
                  class="img"
                  src="https://picture.bjbjkj.top/tp202407061037103710449543.png"
                  alt=""
                />
                <div class="name">北京望京SOHO 辅材2店</div>
                <div class="iconfont icon-Frame-4"></div>
              </div>
            </div>
            <div
              class="inne"
              v-for="(item2, index2) in item.list"
              :key="index2"
            >
              <div class="leftCheck">
                <van-checkbox
                  v-model="item2.checked"
                  class="radioGroup"
                  @change="innerChange(item2, item2.checked, item, index)"
                >
                  <template #icon="props">
                    <div
                      class="radio"
                      :class="props.checked ? 'choose' : ''"
                    ></div>
                  </template>
                  <template #default> </template>
                </van-checkbox>
              </div>
              <div class="rightInfo">
                <goods
                  :width="75"
                  :brand="'pp' + index2"
                  :item2="item2"
                  :name="'name' + index2"
                  :size="'size' + index"
                  :weight="'weight' + index"
                ></goods>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomSubmitLine" :style="'bottom: ' + bottom + 'px;'">
          <div class="inner" style="position: relative">
            <van-submit-bar
                button-color="#FC223B"
                :price="allPrice"
                button-text="提交订单"
                @submit="onSubmit()"
            >
              <van-checkbox v-model="allFlag" class="radioGroup">
                <template #icon="props">
                  <div class="radio" :class="props.checked ? 'choose' : ''"></div>
                </template>
                <template #default>全选</template>
              </van-checkbox>
            </van-submit-bar>
          </div>
        </div>

        <tabbar :active="1" ref="tabbar"></tabbar>
      </div>

    </div>
  </div>
</template>

<script>
import tabbar from "@/components/common/tabbar/build_tabbar.vue";
import navigationBar from "../../navigation-bar/navigation-bar.vue";
import Vue from "vue";
import { SubmitBar } from "vant";
import goods from "./goods/goods.vue";
Vue.use(SubmitBar);
export default {
  components: {
    tabbar,
    navigationBar,
    goods,
  },
  props: {},

  data() {
    return {
      option1: [
        { text: "全部", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      value1: 0,
      checked: false,
      list: [],
      list2: [
        {
          checked: true,
          cart_nums: 0,
          price: "5.00",
          yuan: '5',
          mao:'.00',
          type_text: "水",
        },
        {
          checked: true,
          cart_nums: 1,
          price: "2.40",
          yuan: '2',
          mao:'.40',
          type_text: "水",
        },
        {
          checked: true,
          cart_nums: 2,
          price: "22.99",
          yuan: '22',
          mao: '.99',
          type_text: "超市",
        },
        {
          checked: true,
          cart_nums: 0,
          price: "18.00",
          yuan: '18',
          mao: '.00',
          type_text: "建材",
        },
        {
          checked: true,
          cart_nums: 0,
          price: "20.00",
          yuan: '20',
          mao: '.00',
          type_text: "建材",
        },
        {
          checked: true,
          cart_nums: 0,
          price: "34.90",
          yuan: '34',
          mao: '.90',
          type_text: "建材",
        },
      ],
      abclist: [
        {
          checked: true,
          list: [
            { checked: true, cart_nums: 0, price: "5.00", yuan: 5 },
            { checked: true, cart_nums: 1, price: "2.40", yuan: 2 },
          ],
        },
        {
          checked: true,
          list: [{ checked: true, cart_nums: 2, price: "22.99", yuan: 22 }],
        },
        {
          checked: true,
          list: [
            { checked: true, cart_nums: 0, price: "18.00", yuan: 18 },
            { checked: true, cart_nums: 0, price: "20.00", yuan: 20 },
            { checked: true, cart_nums: 0, price: "34.90", yuan: 34 },
          ],
        },
      ],
      bottom: 0,
      allPrice:0.
    };
  },
  watch: {
    list: {
      handler() {
        let allPrice = 0
        this.list2.forEach(item=>{
          allPrice+=Number(item.price)*100*item.cart_nums
        })
        this.allPrice = allPrice
      },
      deep: true,
    },
  },
  computed: {

    allFlag() {
      return this.list.every((item) => item.checked);
    },
  },
  methods: {
    customGroup(array, callBack) {
      const arr = Array.from([...array]);
      const tempObj = {};
      arr.map((mp) => (tempObj[callBack(mp)] = []));
      Object.keys(tempObj).forEach(
        (key) =>
          (tempObj[key] = [
            ...tempObj[key],
            ...arr.filter((el) => callBack(el) === key),
          ])
      );
      return { ...tempObj };
    },
    // var arr1 = [1, [2], [3, 4], [5, [6, [7]]]];
    // console.log(flat(arr1));
    onSubmit() {
      console.log(12345);
    },

    innerChange(item, checked, list) {
      item.checked = checked;
      list.checked = list.list.every((item2) => item2.checked);
      // console.log(this.list);
    },
    outChange(item) {
      if (!item.list.some((item2) => item2.checked == true)) {
        item.list.forEach((element) => {
          element.checked = false;
        });
      }
      if (item.checked && !item.list.every((item2) => item2.checked)) {
        item.list.forEach((element) => {
          element.checked = true;
        });
      }
      // console.log(item.checked,item.list.some(item2=>item2.checked),item.list.every(item2=>item2.checked));

      if (
        !item.checked &&
        item.list.some((item2) => item2.checked) &&
        item.list.every((item2) => item2.checked)
      ) {
        item.list.forEach((element) => {
          element.checked = false;
        });
      }
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.bottom = this.$refs.tabbar.$refs.buildTabbar.offsetHeight;
    });
    this.list = Object.entries(
      this.customGroup(this.list2, ({ type_text }) => type_text)
    );
    let list = []
    // let option1 = [
    //     { text: '全部商品', value: 0 },
    // ]
    this.list.forEach((item) => {
      console.log(item);
      list.push({
        title:item[0],
        list:item[1],
        checked:true,
      })
      // option1.push({
      //   text: item[0],
      //   value: item[1].find((itema) => {
      //     return itema.type_text;
      //   }),
      // });
      // item.push({
      //   list: Object.entries(
      //     this.customGroup(item[1], ({ seller_name }) => seller_name)
      //   ),
      //   seller: {},
      //   type: item[1].find((itema) => {
      //     return itema.type_text;
      //   }),
      // });
    });
    this.list = list
  },
};
</script>
<style lang="scss" scoped>
.body {
  padding-bottom: 100px;
  .out {
    background: #fff;
    border-radius: 20px 20px 20px 20px;
    padding: 16px 15px;
    margin-bottom: 10px;
    .title {
      display: flex;
      align-items: center;
      width: 100%;

      .right {
        display: flex;
        align-items: center;
        flex: 1;
        .img {
          width: 24px;
          height: 24px;
          border-radius: 5px;
          margin-right: 10px;
        }
        .name {
          font-weight: 500;
          font-size: 16px;
          color: #1b1b1b;
          line-height: 24px;
          text-align: left;
          width: 100%;
        }
        .iconfont {
          font-size: 24px;
        }
      }
    }
    .inne {
      width: 100%;
      border-radius: 10px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      .rightInfo {
        flex: 1;
      }
    }
  }
}
.container {
  background: #fafafa;
}
.area {
}
.innerArea {
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 20px;
}
.radioGroup {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .radio {
    border: 1px solid #ff9900;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    position: relative;
  }
  .radio::after {
    display: block;
    position: absolute;
    content: "";
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 80%;
    height: 80%;
    background: #ff9900;
    border-radius: 50%;
    opacity: 0.15;
  }
  .choose:after {
    opacity: 1;
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    color: #1b1b1b;
    line-height: 20px;
    text-align: left;
  }
  .van-radio {
    margin-right: 10px;
  }
}
.bottomSubmitLine {
  max-width: 750px;
  position: fixed;
  width: 100%;
  background: red;
  left: 50%;
  transform: translate(-50%,0);
  .inner {
    width: 100%;
    position: relative;
  }
}
</style>
<style>
.innerArea::v-deep(van-dropdown-menu__item) {
  display: inline-block !important;
  background-color: white;
  background-color: red;

  /* box-shadow: 0rpx 0rpx 4rpx 0rpx rgba(102, 102, 102, 0.25); */
  text-align: center;
  /* border-radius: 92rpx; */
}
.innerArea >>> .van-dropdown-menu__item {
  display: inline-block !important;
  background-color: white;
  background-color: red;
  /* box-shadow: 0rpx 0rpx 4rpx 0rpx rgba(102, 102, 102, 0.25); */
  text-align: center;
  /* border-radius: 92rpx; */
}
.van-dropdown-menu__item {
  /* background-color: white; */
  /* background-color: red; */
  /* box-shadow: 0px 0px 2px 0rpx rgba(102, 102, 102, 0.25); */
  /* text-align: center; */
  /* text-align: left; */
}
.van-dropdown-menu {
  text-align: left;
}
.van-dropdown-menu__bar {
  display: inline-block !important;
  height: auto !important;
  /* display: block!important; */
  /* height: auto!important; */
  /* background-color: #FAFAFA!important; */
  /* padding: 5px 10px; */
  border-radius: 46px;
}
.van-dropdown-menu__title {
  padding: 5px 25px 5px 10px !important;
  border-radius: 10px;
}
.van-dropdown-menu .van-dropdown-item {
  padding: 0 16px;
  transform: translate(-50%, 0);
  left: 50%;
  width: 95%;
  max-width: 720px;
}
.van-dropdown-menu__title::after {
  right: 10px !important;
}
.van-dropdown-item__content {
  border-radius: 0 0 20px 20px;
}
.van-ellipsis {
  font-weight: 400;
  font-size: 14px;
  color: #1b1b1b;
  line-height: 24px;
}
.van-submit-bar {
  position: absolute !important;
}
</style>