<script>
import invite_tabbar from "@/components/common/tabbar/invite_tabbar.vue";
import {Dialog, Toast} from "vant";
import Bus from "@/utils/Bus";
import {authentication_detail, change_invite, Company_list} from "@/utils/api";

export default {
  name: "invite_mine",
  components: {invite_tabbar},
  data() {
    return {
      commonFun: [
        {
          img: 'https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png',
          name: '在线简历',
          src:'/online_resume',
        }, {
          img: 'https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png',
          name: '附件简历',
          src:'/Attached',
        }, {
          img: 'https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png',
          name: '实名认证',
          src:'/invite_certification'
        }, {
          img: 'https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png',
          name: '我的收藏',
          src: 'invite_collect',
        },
        {
          img: 'https://picture.bjbjkj.top/img/invite_switch_1.png',
          name: '切换身份',
          src: '',
          switch:true,
        },
      ],
      commonFun2: [
        {
          img: 'https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png',
          name: '我的发布',
          src:'/invite_Corporation_my_publish',
        }, {
          img: 'https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png',
          name: '企业管理',
          src:'/invite_corporation_manage',
        }, {
          img: 'https://m.360buyimg.com/babel/jfs/t1/189590/5/43143/7643/660157ebF638a43d9/b2843ee17c84c1b4.png',
          name: '人才管理',
          src:'/invite_corporation_talents'
        },
        {
          img: 'https://picture.bjbjkj.top/img/invite_switch_1.png',
          name: '切换身份',
          src: '',
          switch:true,
        },
      ],
      bodyFunLi: [
        {
          name: '公司主页',
          id: 2,
        },
        {
          name: '常用语',
          id: 1,
        },{
          name: '用户协议',
          id: 10,
        }, {
          name: '隐私政策',
          id: 11,
        }, {
          name: '意见反馈',
          id: 3,
        }, {
          name: '关于我们',
          id: 4,
        },
      ],

      is_Individual:false,
      identity:1,//1  求职者
    }
  },
  methods: {
    checkCorporation(){
      console.log('切换公司',)
      this.$router.push('invite_corporation_list')
    },
    gologin() {
      this.$router.push({name: '登录'})
    },
    chooseFun(item){
      if(item.id == 10||item.id == 11){
        window.open(this.$router.resolve({path: '/agreement',query:{id:item.id}}).href, '_blank');
      }
      if(item.id == 3){
        this.$router.push({path: '/problem_feedback'})
      }
      if(item.id == 4){
        this.$router.push({path: '/aboutUs'})
      }
      if(item.id == 1){
        this.$router.push({path: '/frequently'})
      }
      if(item.id == 2){
        if(this.$store.state.nowCompany.id){
          this.$router.push({
            path:'/invite_corporation_home_page',
            query:{item:this.$store.state.nowCompany.id}
          })
        }else{
          Toast('请选择公司')
        }

      }
    },
    chooseCommon(item){

      if(item.src){
        this.$router.push(item.src)
      }
      if(item.argument){
        this.$router.push({path:'/idle_certification',query:{type:'personage'}})
      }
      if(item.switch){
        // 判断身份
        console.log(this.$store.state.user.job_standing)
        if(this.$store.state.user.job_standing == 1){
          Dialog.alert({
            title: '确定切换为招聘者吗？',
            showCancelButton:true,
            confirmButtonText:'确定',
          }).then(async () => {
            let user_ = await authentication_detail(this.$store.state.token,this.$store.state.user.id)
            if(user_.data.data){
              let com = await Company_list(this.$store.state.token)
              console.log(com.data.data.count)
              if(com.data.data.count == 0){
                //没有公司认证
                Dialog.alert({
                  title: '您还未认证，请认证',
                  showCancelButton:true,
                  confirmButtonText:'确定',
                }).then(()=>{
                  this.$router.push('invite_Company_certification')
                }).catch(()=>{
                  //
                })
              }else{
                console.log(com.data.data.data.find(item=>{return item.acquiesce == 1}))
                if(com.data.data.data.find(item=>{return item.acquiesce == 1})){
                  this.$store.commit('updatenowCompany',com.data.data.data.find(item=>{return item.acquiesce == 1}))
                }
                change_invite(this.$store.state.token).then(res=>{
                  console.log(res)
                  Bus.$emit("updataUserInfo", '1');
                }).catch(err=>{
                  console.log(err)
                })
              }
            }else{
              // 没有个人认证
                    Dialog.alert({
                      title: '您还未认证，请认证',
                      showCancelButton:true,
                      confirmButtonText:'确定',
                    }).then(()=>{
                      this.$router.push({path:'/invite_certification'})
                    }).catch(()=>{
                      //
                    })
            }
          }).catch(()=>{
            // on catch
          });
        }else{
          Dialog.alert({
            title: '确定切换为求职者吗？',
            showCancelButton:true,
            confirmButtonText:'确定',
          }).then(()=>{
            change_invite(this.$store.state.token).then(res=>{
              console.log(res)
              Bus.$emit("updataUserInfo", '2');
            }).catch(err=>{
              console.log(err)
            })
          }).catch(()=>{
            //
          })
        }
      }
    },
    invite_switch(){
      Dialog.alert({
        title: '确定切换为招聘者吗？',
        showCancelButton:true,
        confirmButtonText:'确定',
      }).then(() => {
        // on close
      }).catch(()=>{
        // on catch
      });
    },
},
  async mounted() {
    Bus.$emit("updataUserInfo", '1');
    let authentication = await authentication_detail(this.$store.state.token,this.$store.state.user.id)

    if(authentication.data.status == true){
      //true   (this.name&&this.frontUpload&&this.contraryUpload&&this.standing)
      this.is_Individual = true
    }else{
      this.is_Individual = false
    }
    if(this.$store.state.user.job_standing == 2){
      let com = await Company_list(this.$store.state.token)
      if(com.data.data.data.find(item=>{return item.acquiesce == 1})){
        this.$store.commit('updatenowCompany',com.data.data.data.find(item=>{return item.acquiesce == 1}))
      }
    }
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <div class="topNav">
        <div v-if="$store.state.token"
             style="text-align: right; width: 95%; min-width: 30px;display:flex;align-items: center;justify-content: flex-end"
        >
<!--          <img src="https://picture.bjbjkj.top/img/invite_switch.png" alt="" @click="invite_switch" class="invite_switch">-->
        </div>
        <div class="topInfo" :class="!$store.state.token?'nologinInfo':''">
          <div class="userInfo">
            <div class="leftImg" v-if="$store.state.token">
              <!-- <img class="avatar" src="https://picture.bjbjkj.top/tp2024062803111511572202305.png" alt="" /> -->
              <el-image
                  v-if="$store.state.user.avatar&&$store.state.user.avatar"
                  class='avatar'
                  :src="$store.state.imageHost+$store.state.user.avatar"
                  :preview-src-list="[$store.state.imageHost+$store.state.user.avatar]">
              </el-image>
            </div>
            <div class="rightInfo" v-if="$store.state.token&&$store.state.user.job_standing!=2">
              <div class="username">{{ $store.state.user.nickname }}</div>
              <!--            <div class="mobile">{{ $store.state.user.mobile }}</div>-->
              <div class="yes" v-if="is_Individual">实名已认证</div>
              <div class="no" v-if="!is_Individual">实名未认证</div>
            </div>

            <div class="rightInfo" v-else>
              <div class="username">{{ $store.state.user.nickname }}</div>
              <!--            <div class="mobile">{{ $store.state.user.mobile }}</div>-->
              <div style="display: flex">
                <div class="corporationName">{{$store.state.nowCompany.name}}</div>
                <div class="yes" v-if="$store.state.nowCompany.id">已认证</div>
                <div class="no" v-if="!$store.state.nowCompany.id">未认证</div>
              </div>
              <div class="lastRight" v-if="$store.state.user.job_standing==2" @click="checkCorporation">
                <span class="iconfont icon-a-Group20093"></span>
                <div class="text">切换公司</div>
              </div>

            </div>
            <div class="noLogin" v-if="!$store.state.token" @click="gologin">
              <img class="avatar" :src="$store.state.imageHost+'/touxiang/7fc545aedbedcaf7f6ef03cea3ef206.png'" alt=""/>
              请登录~
            </div>
          </div>

        </div>
        <invite_tabbar :active="$store.state.user.job_standing == 1?4:2" ref="tabbar" :key="$store.state.user.job_standing == 1?4:2"></invite_tabbar>
      </div>
      <div class="common card">
        <div class="title">常用功能</div>
        <div class="ul">
          <div class="li" v-for="(item,index) in $store.state.user.job_standing == 1?commonFun:commonFun2" :key="index" @click="chooseCommon(item)">
            <div class="item">
              <img :src="item.img" alt="" class="iconImg">
              <div class="text">{{ item.name }}</div>
              <div class="tag" v-if="$store.state.user.job_standing==1&&index== ($store.state.user.job_standing == 1?commonFun.length-1:commonFun2.length-1)">用户</div>
              <div class="tag" v-if="$store.state.user.job_standing==2&&index== ($store.state.user.job_standing == 1?commonFun.length-1:commonFun2.length-1)">老板</div>
            </div>
          </div>
        </div>
      </div>
      <div class="body card">
        <div class="ul">
          <div v-for="(item,index) in bodyFunLi" :key="index" @click="chooseFun(item)">
            <div class="li"  v-if="index!=0||$store.state.user.job_standing==2">
              <div class="line">
                <div class="left">{{ item.name }}</div>
                <span class="iconfont icon-Frame-4"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #f4f6fa;
}

.topNav {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding-top: 40px;
  position: relative;
  background-image: url("https://picture.bjbjkj.top/img/inviteMask.png");
  background-repeat: no-repeat;
  background-size: 100% 120%;
}

.invite_switch {
  width: 31px;
  height: 31px;
  object-fit: cover;
}

.topInfo {
  margin: 0 16px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
  .userInfo {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .rightInfo {
      width: 75%;
      text-align: left;
      padding-left: 10px;

      .username {
        font-weight: 500;
        font-size: 18px;
        color: #1b1b1b;
        line-height: 24px;
      }

      .yes {
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        flex-shrink: 0;
        padding: 2px 6px;
        border-radius: 5px;
        background: #fff4e9;
        color: #ff9f4d;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
      }

      .no {
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        flex-shrink: 0;
        padding: 2px 6px;
        border-radius: 5px;
        background: #c7c7c7;
        color: #ffffff;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
      }
      .corporationName{
        color: #1a1a1a;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mobile {
        font-weight: 400;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 18px;
        margin-top: 6px
      }
      .lastRight{
        position: absolute;
        display: flex;
        flex-direction: column;
        color: #0393f5;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        right: 0px;
        top: 50%;
        align-items: center;
        transform: translate(0,-50%);
        .iconfont{
          transform: rotate(90deg);
          font-size: 24px;
          color: #0393F5;
          height: 24px;
          width: 24px;
        }
      }
    }

    .noLogin {
      .avatar {
        border-radius: 50%;
        width: 54px;
        height: 54px;
        object-fit: cover;
      }

      display: flex;
      align-items: center;
      text-indent: 1em;
      line-height: 40px;
    }

    .leftImg {
      display: flex;
    }
  }

  .avatar {
    width: 54px;
    height: 54px;
    border-radius: 10px;
    object-fit: cover;
    // object-fit: fill; // 填充，撑满
    // object-fit: contain; // 保持原比例缩小
    // object-fit: cover;
    // object-fit: none;
    // object-fit: scale-down;
  }

}

.card {
  border-radius: 10px;
  background: #ffffff;
  margin: 10px 16px;
}

.common {
  padding: 10px;

  .title {
    text-align: left;
    margin-bottom: 10px;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .ul {
    display: flex;

    .li {
      width: 25%;

      .item {
        position: relative;
        .iconImg {
          width: 31px;
          object-fit: cover;
        }

        .text {
          color: #1b1b1b;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
        }
        .tag{
          position: absolute;
          right: 0;
          top: 0;
          width: fit-content;
          height: fit-content;
          display: inline-flex;
          place-content: center;
          place-items: center;
          gap: 10px;
          padding: 0px 4px;
          border-radius: 5px;
          background: #7e6dff;
          color: #ffffff;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }
}

.body {
  .ul {
    text-align: left;

    .li {
      padding: 10px 15px;
      border-bottom: 1px solid #99999926;
      transform: rotate(0.000005deg);

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          color: #333333;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }

        .iconfont {
          font-size: 22px;
          color: #666666;
        }
      }
    }
  }
}
</style>