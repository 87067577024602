<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {_throttle} from "@/utils/tool";
import {getCategoryShopList} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "idle_shop_list",
  components: {NavigationBar},
  data(){
    return{
      choose:{},
      page:0,
      limit:10,
      list:[],
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      oldScrollTop: 0,
    }
  },
  mounted() {
    console.log(this.$route.query)
    this.choose = this.$route.query
    this.qingqiu()

  },

  methods:{
    godetails(item){
      this.$router.push({
        path:'/idle_shop_details',
        query:{item:item.id,type:'shop'},
      })
    },
    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    isValidJSON(text) {
      try {
        JSON.parse(text);
        return true;
      } catch (error) {
        return false;
      }
    },
    qingqiu: _throttle(async function () {
      this.page+=1
      getCategoryShopList(this.choose.id,'',this.limit,this.page).then((res)=>{
        if(res.data.status == true){
          let list = res.data.data.data
          list.forEach(item=>{
            if(item.logo){
              item.showImg = item.logo.split(',')[0]
            }
            item.tagLi = item.tag.split(',')
          })
          this.list = this.list.concat(list)
          this.loadLock = false
          if(this.list.length>=res.data.data.total){
            this.hasMore = false
            return
          }
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },500),
  },
}
</script>

<template>
  <div class="container"  @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="choose.cate_name" class="navigationBar"></navigationBar>
      <div class="ul">
        <div class="li">
          <div class="item" v-for="(item,index) in list" :key="index" @click="godetails(item)">
            <div class="head">
              <div class="left">
                <img :src="$store.state.imageHost+item.showImg" alt="" class="image">
              </div>
              <div class="right">
                <div class="inn">
                  <div class="name">{{item.title}}</div>
                  <div class="info">
                    <div class="text">全北京可服务</div>
                    <div class="text">
                      <div class="left">{{item.cate_name}}</div>
                      <div class="right">浏览{{item.visit_count}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="tagUl">
                <div class="tagLi" v-for="(itemc,indexc) in item.tag_name" :key="indexc">
                  {{itemc}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loader-box">
        <div id="loader" v-if="loadLock"></div>
        <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container{
  height: 100vh;
  background: #f9f9f9;
  .area{
    height: 100vh;
  }
}
.navigationBar{
  background: #fff5e9;
  text-align: left;
}
.ul{

  .li{
    width: 100%;
    .item{
      border-radius: 10px;
      background: #ffffff;
      padding: 10px;
      margin: 10px 16px;
      .head{
        display: flex;
        .left{
          width: 72px;
          .image{
            height: 72px;
            width: 72px;
            object-fit: cover;
            border-radius: 10px;
          }
        }
        .right{
          text-align: left;
          flex: 1;
          padding-left: 10px;
          width: 75%;
          .inn{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            .name{
              width: 100%;
              color: #1a1a1a;
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;

              /* 溢出部分隐藏 */
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .info{
              color: #7f7f7f;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              .text{
                display: flex;
                justify-content: space-between;
                .right{
                  width: 40%;
                  text-align: right;
                }
                .left{
                  width: 60%;
                }
              }
            }
          }
        }
      }
      .footer{
        overflow: scroll;
        .tagUl{
          margin-top: 8px;
          display: flex;
          .tagLi{
            width: fit-content;
            height: fit-content;
            display: inline-flex;
            place-content: center;
            place-items: center;
            gap: 10px;
            padding: 4px 12px;
            border-radius: 4px;
            background: #f6f6f6;
            color: #666666;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            margin-right: 6px;
            white-space: nowrap;
          }
        }
      }
      .footer::-webkit-scrollbar{
        display: none;
      }
    }
  }
}
.loader-box {
  width: 375px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  width: 25px;
  height: 25px;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-bottom: 3px solid #717171;
  transform: rotate(0deg);
  animation: loading 1s linear infinite;
}
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>