<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {vip_order, vipOrder} from "@/utils/api";
import {Toast} from "vant";
import loding from "@/components/common/loding/loding.vue";
import {_throttle} from "@/utils/tool";

export default {
  name: "vip_page",
  components: {
    navigationBar,
    loding
  },
  data() {
    return {
      economize: this.$store.state.is_vip.cut_amount?this.$store.state.is_vip.cut_amount:0,
      privilegelI: [
        {
          title: '免起送费',
          text: '无需凑价免费送',
          type: '商品类覆盖',
          icon: '/img/0c5bf7969b70e2c4a5b129eec033321.png',
        },
        {
          title: '专享减免',
          text: '无门槛立享减免',
          type: '商品类覆盖',
          icon: '/img/544ea2b7659cf57550d54711ef9e684.png',
        },
      ],
      tabsList: [
        {name: '免费配送',},
        {name: '商品优惠',},
        {name: '免起送费',},
      ],
      is_vip: this.$store.state.is_vip?this.$store.state.is_vip.is_vip:false,
      btnText: '立即开通',
      priceList: [{}, {}, {}],
      priceIndex: 0,

      vip_type: 5,
      loding: false,

    }
  },

  computed: {
    activeVip() {
      return this.priceList[this.priceIndex]
    },
    vipTime() {
      let data = new Date(this.$store.state.vipTime * 1000)
      let y = data.getFullYear()
      let m = data.getMonth() + 1
      let d = data.getDate()
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      return this.$store.state.vipTime ? y + '-' + m + '-' + d : 0
    },
    daysNums(){
      if(this.$store.state.is_vip.is_vip){
        let nums = 0
        if((new Date(this.vipTime).getTime() - new Date().getTime())>0){
         return  Math.round(((new Date(this.vipTime).getTime() - new Date().getTime())/1000/60/60/24))
        }else{
          nums = 0
        }

        return nums
      }else{
        return 0
      }
    },
  },
  watch: {
    priceList(n) {
      n.forEach(item => {
        item.month = item.day_count / 30
        // item.daysPrice = (item.price/item.day_count).toFixed(2)
      })
    }
  },
  methods: {
    // getExpirationDate(level) {
    //   const now = new Date();
    //   const daysToAdd = {
    //     '7': '一周',
    //     '30': '月',
    //     '90': '季',
    //     '360': '年',
    //   }[level];
    //   console.log(daysToAdd)
    //   now.setDate(now.getDate() + daysToAdd);
    //   const year = now.getFullYear();
    //   const month = now.toLocaleDateString('zh-CN', { month: 'short' }); // 月份需要根据实际需求进行格式化
    //   const season = Math.floor(month / 3) + 1; // 假设一季度是3个月，根据实际调整
    //   return `${year}年${month} ${season}季`;
    // },
    vip_order() {
      vip_order(this.$store.state.token, this.vip_type).then(res => {
        if (res.data.status == true) {
          console.log(res.data.data.list)
          let list = res.data.data.list

          this.priceList = list
        } else {
          Toast(res.data.msg)
        }

      }).catch(err => {
        console.log(err)
      })
    },
    submit() {
      this.loding = true
      this.create()
    },
    create: _throttle(function () {
      this.loding = false
      vipOrder(this.$store.state.token, this.vip_type, this.activeVip.id, 1, this.activeVip.vip_type).then(res => {
        if (res.data.status == true) {
          console.log(res.data.data)
          this.$router.push({
            name: "pay",
            params: {data: res.data.data, backStep: 2, type: ""}
          })
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }, 2000),
    choose_Vip(index) {
      console.log(index)
      this.priceIndex = index
      // console.log(this.$refs)
      if (index >= 1) {
        this.$refs.innerli[index - 1].scrollIntoView({block: 'end', behavior: 'smooth', inline: "start"})
      } else {
        // this.$refs.innerScroll.scrollLeft = 0
        this.$refs.innerli[0].scrollIntoView({block: 'end', behavior: 'smooth', inline: "start"})
      }
    },
  },
  mounted() {
    this.vip_order()
  },
}
</script>

<template>
  <div class="container vippage">
    <div class="area">
      <navigationBar :title="'会员中心'" class="navigation"></navigationBar>
      <div class="body">
        <loding v-if="loding"></loding>
        <div class="top">
          <div class="left">
            <div class="userinfo">
              <div class="avatarBox"><img :src="$store.state.imageHost+$store.state.user.avatar" alt="" class="avatar">
              </div>
              <div class="info">
                <div class="nickname">
                  <span class="text">{{ $store.state.user.nickname }}</span>
                  <img class="vip" src="../../../../public/image/vip.png" alt="">
                </div>
                <div class="isvip">
                  {{ is_vip ? `有效期至 ${vipTime}` : '暂未开通会员' }}
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="economize">
                <div class="nums">{{ economize }}</div>
                <div class="text">会员已省</div>
              </div>
              <div class="daysNums">
                <div class="nums">{{ daysNums }}</div>
                <div class="text">会员天数</div>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="../../../../public/image/vipBadge.png" alt="" class="vipBadge">
            <div class="btn">
              <span class="text" @click="submit">{{ is_vip ? '立即续费' : '立即开通' }}</span>
            </div>
          </div>
        </div>
        <div class="inner">
          <div class="everyBox">
            <div class="topbox">
              <div class="left">
                <div class="title">选择会员套餐</div>
                <div class="details">解锁专属福利</div>
              </div>
              <!--              <div class="rightBtn">去开通<span class="iconfont icon-Frame-13"></span></div>-->
            </div>
            <div class="priceList" ref="innerScroll">
              <div class="priceItem" ref="innerli" v-for="(item,index) in priceList" :key="index"
                   :class="priceIndex == index?'choosePrice':''" @click="choose_Vip(index)">
                <div class="topbox">
                  <div class="left">
                    <div class="time">{{ item.month }}个月</div>
                    <div class="original_price">￥{{ item.original_price }}</div>
                  </div>
                  <div class="right"></div>
                </div>
                <div class="price">￥<span class="num">{{ item.price }}</span></div>
              </div>
            </div>
            <div class="topbox">
              <div class="left">
                <div class="title">会员专属福利</div>

              </div>
            </div>
            <div class="privilege">
              <div class="every" v-for="(item,index) in privilegelI" :key="index">
                <div class="left">
                  <div class="title">{{ item.title }}</div>
                  <div class="text">{{ item.text }}</div>
                  <div class="type">{{ item.type }}</div>
                </div>
                <div class="right">
                  <img class="icon" :src="$store.state.imageHost+item.icon" alt="">
                </div>
              </div>
            </div>
            <div class="bottomBox bottomFun">
              <div class="inn">
                <div class="btn" @click="submit">
                  {{ is_vip ? '立即续费' : `${activeVip.price}元/月 立即开通` }}
                </div>
                <div class="agreement">开通代表已阅读<a class="agree" href="">《会员服务协议》</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container, .area {
  height: 100vh;
  background: linear-gradient(108deg, #FFF5E1 0%, #FCE9BE 49%, #FCE9BE 100%);
  padding-bottom: 0;
}

.area {
  position: relative;
  min-height: 100vh;
}

.navigation {
  background: transparent;
  position: relative;
  z-index: 2;
}

.body {
  position: relative;
  z-index: 1;

  .top {
    background: linear-gradient(90deg, #FEE1A9 0%, #FFD58E 100%);
    display: flex;
    border-radius: 16px;
    margin: 10px 16px;
    justify-content: space-between;
    padding: 20px 12px 24px 12px;

    .left, .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .left {
      width: 68%;

      .userinfo {
        display: flex;

        .avatarBox {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          .avatar {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .info {
          text-align: left;
          width: 74%;

          .nickname {
            .text {
              display: inline-block;
              vertical-align: middle;
              max-width: 69%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 500;
              font-size: 15px;
              color: #6E451E;
              line-height: 20px;
              text-align: left;
            }

            .vip {
              width: 44px;
              object-fit: cover;
              vertical-align: middle;
              margin-left: 7px;
            }

          }

          .isvip {
            font-weight: 300;
            font-size: 11px;
            color: #68421E;
            line-height: 20px;
            text-align: left;
          }
        }

      }

      .bottom {
        display: flex;

        .economize, .daysNums {
          margin-left: 16px;
          max-width: 45%;

          .nums {
            width: 100%;
            overflow: auto;
            white-space: nowrap;
            font-weight: 500;
            font-size: 18px;
            color: #684623;
            line-height: 20px;
          }

          .text {
            font-weight: 400;
            font-size: 12px;
            color: #A58343;
            line-height: 20px;
            text-align: left;
          }
        }

        .economize::-webkit-scrollbar, .daysNums::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .right {

      .vipBadge {
        width: 108px;
        height: 108px;
      }

      .btn {
        background: #6E451E;
        border-radius: 33px 33px 33px 33px;
        padding: 5px;

        .text {
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: left;
          //background-image: linear-gradient(to top, #fec163,#de4313); /* 线性渐变背景，方向向上 */
          -webkit-background-clip: text; /* 背景被裁剪成文字的前景色 */
          -webkit-text-fill-color: transparent; /* 文字填充颜色变透明*/
          background-image: linear-gradient(358.5398342341906deg, #F6DDBE 0%, #FFEED2 100%);
        }

      }
    }
  }


  .inner {
    background: #ffffff;
    //background: red;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    min-height: 70vh;

    .everyBox {
      .topbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 16px;
        text-align: left;

        .left {
          .title {
            font-weight: 500;
            font-size: 17px;
            color: #1B1B1B;
            line-height: 24px;
            text-align: left;
          }

          .details {
            font-weight: 400;
            font-size: 13px;
            color: #9B9B9B;
            line-height: 24px;
          }
        }

        .rightBtn {
          background: #3B3013;
          border-radius: 29px;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 24px;
          padding: 4px 10px;
          //transform: rotate(90deg);
          display: flex;
          align-items: center;

          .iconfont {
            display: block;
            color: #fff;
            transform: rotate(90deg);
          }
        }
      }

      .priceList {
        display: flex;
        margin-left: 16px;
        overflow-x: scroll;

        .priceItem {
          background: #FFF9EE;
          border-radius: 10px 10px 10px 10px;
          margin-right: 10px;
          min-width: 108px;
          padding: 12px;

          .topbox {
            margin: 0;

            .time {
              color: #666666;
              font-size: 16px;
            }
          }

          .original_price {
            font-weight: 400;
            font-size: 11px;
            color: #A2A5AC;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-decoration-line: line-through;
          }

          .price {
            text-align: left;
            font-weight: bold;
            font-size: 20px;
            color: #666666;
            line-height: 24px;
            font-style: normal;
            margin-top: 18px;

            .num {
              font-size: 20px;
              color: #666666;
            }
          }
        }
      }

      .priceList::-webkit-scrollbar {
        display: none;
      }

      .choosePrice {
        border: 1px solid #FEBB0E;

        .time {
          color: #B6771A !important;
        }

        .price {
          color: #B6771A !important;

          .num {
            color: #B6771A !important;
            font-size: 28px !important;
          }
        }
      }
    }

    .privilege {
      display: flex;
      margin: 0 0px 0 16px;
      white-space: nowrap;
      overflow-x: scroll;

      .every {
        //width: 50%;
        display: flex;
        align-items: center;
        background: #FCE8C7;
        box-shadow: inset 2px 2px 4px 0px rgba(255, 255, 255, 0.4);
        border-radius: 10px 10px 10px 10px;
        margin: 0 3px;
        padding: 10px;
      }

      .every:nth-last-of-type(1) {
        margin-right: 16px;
      }

      .left {
        margin-right: 14px;

        .title {
          font-weight: 500;
          font-size: 15px;
          color: #492F0A;
          line-height: 24px;
          text-align: left;
        }

        .text {
          font-weight: 400;
          font-size: 12px;
          color: #642C10;
          line-height: 18px;
          text-align: left;
        }

        .type {
          font-weight: 400;
          font-size: 10px;
          color: #BA9A7D;
          line-height: 15px;
          text-align: left;
        }
      }

      .right {
        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    .privilege::-webkit-scrollbar {
      display: none;
    }
  }
}

.bottomBox {
  position: fixed;
  bottom: 4%;
  width: 100%;

  .inn {
    margin: 0 16px;
  }

  .btn {
    background: linear-gradient(90deg, #FCDEB4 0%, #FDC18D 100%);
    box-shadow: inset 0px 4px 4px 0px rgba(255, 255, 255, 0.58);
    border-radius: 29px 29px 29px 29px;
    border: 1px solid #FFDCBE;
    color: #642C10;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 11px;
  }

  .agreement {
    font-weight: 400;
    font-size: 11px;
    color: #A2A5AC;
    line-height: 16px;
    margin-top: 10px;

    .agree {
      color: #FC223B;
    }
  }
}

</style>
<style>
.vippage .van-tab {
  font-weight: 500;
  font-size: 17px;
  color: #999999;
  line-height: 24px;
}

.vippage .van-tab--active {
  color: #3B3013;
}

.vippage .van-tabs__nav--line .van-tabs__line {
  background: #3B3013;
  width: 22px;
  height: 5px;
}
</style>