<script>

export default {
  name: "filtrate_corporation",
  props:{
    invalidObj:[Array],
    liste:[Array],
  },
  data(){
    return{
      list:this.liste,
      address:this.invalidObj,
      pop_show:false,
      pop_type:'',
      address_li: this.$store.state.allAddress,
    }
  },
  methods:{
    close(){
      this.$emit('closePop', false);
    },

    closePop(){
      this.pop_show = false
    },
    save(){
      if (this.pop_type == 'Working_city') {
        this.address = this.$refs.Working_city.getValues()
      }
      this.$emit('saveAddress',this.address)
      this.closePop()
    },
    openPop(e){
      this.pop_show = true
      this.pop_type = e
    },
    chooseIndex(item,idex){
      // if(item.id == ''){
      console.log(this.list[idex].list.every(item2=>{return !item2.choose}))
        if(this.list[idex].list.every(item2=>{return !item2.choose}))return
        this.list[idex].list.forEach(item=>{
          item.choose = false
        })
      // }

      item.choose = !item.choose
    },
    clear(){
      this.list.forEach(item=>{
        item.list.forEach(item2=>{
          item2.choose = false
        })
      })
    },
    submit2(){
      this.$emit('choosePosition', this.list);
    },

  },
  mounted() {
    // console.log(this.liste)
  },
  watch:{
    address(e){
      console.log(e,this.address_li,this.address_li.findIndex(item=>{return item.id == e[0].id}))
    },
    list:{
      handler(e){
        e.forEach(item=>{
          let list = item.list.slice(1,item.list.length)
          item.list[0].choose = list.every(item2=>{return !item2.choose})
        })
      },
      deep:true,
    },
  },
}
</script>

<template>
  <div class="container">
    <div class="area">
      <div class="top">
        <div class="backBtn" @click="close">
          <span class="iconfont icon-Frame-4"></span>
        </div>

      </div>
      <div class="addressBOX">
        <div class="title">筛选城市</div>
        <div class="bottomText" :class="address[0]?'':'placeholder'" @click="openPop('Working_city')">
          <div class="text">{{address[0]?address[0].name+' '+address[2].name:'筛选城市、区'}}</div>
          <span class="iconfont icon-Frame-4"></span>
        </div>
      </div>
      <div class="infoLi">
        <div class="ul">
          <div class="li" v-for="(item,index) in list" :key="index">
            <div class="title">{{item.type}}</div>
            <div class="inList">
              <div class="ilInItem" v-for="(item2,index2) in item.list" :key="index2" @click="chooseIndex(item2,index)" :class="item2.choose?'yes':''">
                {{item2.job_name}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBtn">
        <div class="left Btn" @click="clear">清除</div>
        <div class="right Btn" @click="submit2">确定</div>
      </div>

      <van-popup v-model="pop_show" position="bottom">
        <div class="pop_inner">
          <van-picker
              v-if="pop_type == 'Working_city'"
              ref="Working_city"
              value-key="name"
              :columns="address_li"
          />
        </div>
        <div class="pop_bottom">
          <div class="close btn" @click="closePop">取消</div>
          <div class="save btn" @click="save">确定</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pop_bottom {
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-around;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .save {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #05c160;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}

.area{
  position: relative;
  height: 100vh;
}
.top{
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0;
  .backBtn{
    width: 10%;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
      font-size: 36px;
    }
  }
  .title{
    width: 85%;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
.addressBOX{
  text-align: left;
  margin: 0 16px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f6f6f6;
  .title{
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .bottomText{
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    .iconfont{
      font-size: 24px;
      color: #666666;
    }
    .text{
      width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.placeholder{
  color: #c9c9c9!important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.infoLi{
  padding: 0 16px 100px 16px;
  .ul{

    .li{
      margin-top: 20px;
      text-align: left;
      .title{
        color: #1b1b1b;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 10px;
      }
      .inList{

        .ilInItem{
          display: inline-block;
          width: 30%;
          text-align: center;
          border-radius: 10px;
          border: 1px solid #f6f6f6;
          box-sizing: border-box;
          padding: 10px 0;
          margin-bottom: 10px;
          margin-right: 3%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #1b1b1b;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .yes{
          border: 1px solid #0393f5;
          background: #ebf7ff;
          color: #0393f5;
        }
      }
    }
  }
}
.bottomBtn{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  display: flex;
  padding: 10px 0;
  .left{
    margin-right: 10px;
    margin-left: 16px;
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 12px 40px;
    border-radius: 12px;
    background: #f6f6f6;
    text-align: center;
    color: #666666;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .right{
    margin-right: 16px;
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 12px 13px;
    border-radius: 12px;
    background: #0393f5;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
  }
}
</style>