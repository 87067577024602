<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {_throttle} from "@/utils/tool";
import {getCategoryShopList} from "@/utils/api";

export default {
  name: "idle_homeGoodsList",
  components: {navigationBar},
  data() {
    return {
      obj: {},
      page: 0,
      limit: 10,
      list: [],
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      oldScrollTop: 0,
    }
  },
  mounted() {
    this.obj = JSON.parse(this.$route.query.item)
    console.log(this.obj)
    this.qingqiu()
  },
  methods: {
    getBoxHeight(leftList, rightList, _this) {
      //获取左右两边高度
      return new Promise((resolve) => {
        _this.$nextTick(() => {
          _this.leftList = leftList;
          _this.rightList = rightList;
          _this.leftHeight = _this.$refs.left.offsetHeight;
          _this.rightHeight = _this.$refs.right.offsetHeight;
          // console.log("左边", _this.leftHeight, "右边", _this.rightHeight);
        });
        resolve();
      });
    },

    getList() {
      getCategoryShopList(this.obj.id,'',this.limit,this.page).then(async (res)=>{
        console.log(res.data.data.data)
        if(res.data.status){
          let list = res.data.data.data
          list.forEach(item=>{
            item.showImg = item.img.split(',')[0]
          })
          this.list = this.list.concat(list)
          if(list.length>=res.data.data.total){
            this.hasMore = false
          }
          this.loadLock = false
        }

      })
    },
    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    qingqiu: _throttle(async function () {
      this.page += 1
      this.getList()
    }, 500),


    godetails(item){
      this.$router.push({
        path:'/idle_shop_details',
        query:{item:item.id,type:'shop'},
      })
    },
  },

}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
<!--      办公转租-->
      <navigationBar :title="this.obj.cate_name"></navigationBar>

      <div class="ul">
        <div class="li" v-for="(item,index) in list" :key="index" @click="godetails(item)">
          <div class="item">
            <div class="left">
              <img :src="$store.state.imageHost+item.showImg" alt="" class="image">
            </div>
            <div class="rightOut">
              <div class="right">
                <div class="name">{{item.title}}</div>
                <div class="measure">{{item.measure}}㎡</div>
                <div class="tag_name">
                  <div class="item2Li" v-for="(item2,index2) in item.tag_name" :key="index2">{{item2}}</div>
                </div>
                <div class="price">
                  <span class="priceNum">{{item.rent}}</span>   <span class="priceUnit">元/月</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  height: 100vh;

  .area {
    height: 100vh;
  }
}
.ul{
  width: 100%;
  .li{
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 2px #66666640;
    margin: 10px 16px;
    padding: 10px;
    .item{
      display: flex;
      overflow: hidden;
      .left{
        width: 38%;
        height: 96px;
        border-radius: 10px;
        overflow: hidden;
        min-width: 128px;
        .image{
          width: 128px;
          height: 96px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
      .rightOut{
        padding-left: 10px;
        text-align: left;
        width: 62%;
      }
      .right{
        width: 100%;
        .name{
          color: #1b1b1b;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px
        }
        .measure{
          color: #666666;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }
        .tag_name{

          white-space: nowrap;
          overflow: scroll;
          width: 100%;
          .item2Li{
            display: inline-block;
            margin-right: 10px;
            border-radius: 15px;
            background: #f2f2f2;
            text-align: center;
            color: #8b8b8b;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            padding: 1px 8px;

          }
        }
        .tag_name::-webkit-scrollbar{
          display: none;
        }
        .price{
          color: #fd2a39;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          .priceUnit{
            font-size: 12px;
          }
        }

      }
    }
  }
}
</style>