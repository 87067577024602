<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getCategoryShopList} from "@/utils/api";
import {Toast} from "vant";
import {_throttle} from "@/utils/tool";

export default {
  name: "idle_house_li",
  components: {NavigationBar},
  data(){
    return{
      obj:{},
      page:0,
      limit:10,
      list:[],
      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      oldScrollTop: 0,
    }
  },
  methods:{
    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    isValidJSON(text) {
      try {
        JSON.parse(text);
        return true;
      } catch (error) {
        return false;
      }
    },
    qingqiu: _throttle(async function () {
      this.page+=1
      getCategoryShopList(this.obj.id,'',this.limit,this.page).then((res)=>{
        if(res.data.status == true){
          console.log(res.data.data)
          let list = res.data.data.data
          list.forEach(item=>{
            if(this.isValidJSON(item.img)){
              item.showImg = JSON.parse(item.img)[0]
            }
            item.tagLi = item.tag.split(',')
          })
          this.list = this.list.concat(list)
          this.loadLock = false
          if(this.list.length>=res.data.data.total){
            this.hasMore = false
            return
          }
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },500),


    godetails(item){
      console.log(item)
      this.$router.push({
        path:'/idle_house_details',
        query:{item:item.id}
      })
    },
  },


  mounted() {
    this.obj = JSON.parse(this.$route.query.item)
    this.qingqiu()
  }
}
</script>

<template>
<div class="container" @scroll="containerScroll">
  <div class="area">
    <navigationBar :title="obj.cate_name" class="navigationBar"></navigationBar>
    <div class="topImg">
      <div class="imgBox">
        <img src="https://picture.bjbjkj.top/img/idle_house_li_top.png" alt="" class="image">
      </div>
    </div>
    <div class="houseBody">
      <div class="ul">
        <div class="liOut">
          <div class="li" v-for="(item,index) in list" :key="index" @click.stop="godetails(item)">
            <div class="leftImg">
              <img :src="$store.state.imageHost+item.showImg" alt="">
              <!--            <image lazy-load	src="{{globalData.imgHost}}{{item.tImage}}" mode="aspectFill" class="image" lazy-load	/>-->
              <div class="imgtag">{{item.type == 1?'商铺转让':item.type == 2?'商铺租赁':item.type == 3?'写字楼租赁':item.type == 4?'住宅租赁':''}}</div>
            </div>
            <div class="rightBox">
              <div class="title">{{item.title}}</div>
              <div class="areac">{{item.measure}}㎡</div>
                <div class="tag">
                  <div class="tagIi" v-for="(item2,index2) in item.tagLi" :key="index2">
                    <div class="tagItem" v-if="item2">{{item2}}</div>
                  </div>
                </div>
              <div class="price">
                <div class="leftPrice">
                  <span class="priceNum">{{item.rent}}</span>   <span class="priceUnit">元/月</span></div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="loader-box">
        <div id="loader" v-if="loadLock"></div>
        <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  height: 100vh;
  min-height: 100vh;
  .area{
    min-height: 100vh;
  }
}
.topImg{
  width: 100%;
  max-height: 186px;
  height: 186px;
  .imgBox{
    margin: 10px 16px;
    max-height:186px;
    .image{
      width: 100%;
      height: 100%;
      max-height:186px;
      object-fit: cover;
    }
  }

}
.houseBody{

  .ul{
    margin: 10px 16px;
    .liOut{
      width: 100%;
    }
    .li{
      margin: 10px 0;
      border-radius: 10px;
      background: #ffffff;
      box-shadow: 0px 0px 2px 0px rgba(102,102,102,0.25);
      padding: 10px;
      display: flex;
      .leftImg{
        //width: 30%;
        border-radius: 10px;
        width: 128px;
        min-width: 128px ;
        height: 96px;
        /* border: 1px solid red; */
        position: relative;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .rightBox{
        max-width: 60%;
        flex: 1;
        text-align: left;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title{
          display: -webkit-box;
          /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
          -webkit-box-orient:vertical;
          /*要显示的行数*/
          -webkit-line-clamp:2;
          /* 溢出部分隐藏 */
          overflow:hidden;
          color: #1b1b1b;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        .areac{
          color: #666666;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }
          .tag{
            max-width: 100%;
            overflow: scroll;
            /* padding-bottom: 15rpx; */
            margin-bottom: 2px;
            white-space: nowrap;

            .tagIi{
              display: inline-block;
              .tagItem{
                border-radius: 15px;
                background: #f2f2f2;
                color: #8b8b8b;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                padding: 1px 8px;
              }
            }

          }
          .tag::-webkit-scrollbar{
            display: none;
          }


        .price{
          display: flex;
          align-items: center;
          .leftPrice{
            margin-right: 10px;
            width: 90%;
            white-space: nowrap;
            overflow: scroll;
            display: flex;
            align-items: baseline;
            color: #f74440;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            .priceUnit{
              font-size: 12px;
            }
          }
          .leftPrice::-webkit-scrollbar{
            display: none;
          }
        }
        .price::-webkit-scrollbar{
          display: none;
        }

      }
    }
  }
  .loader-box {
    width: 375px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #loader {
    width: 25px;
    height: 25px;
    border: 3px solid #ddd;
    border-radius: 50%;
    border-bottom: 3px solid #717171;
    transform: rotate(0deg);
    animation: loading 1s linear infinite;
  }
  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

</style>