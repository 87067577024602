<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {createResume, deleteResume, resumelist, upload_files} from "@/utils/api";
import {Dialog, Toast} from "vant";
import Compressor from "compressorjs";

export default {
  name: "Attached",
  components: {navigationBar},
  data(){
    return{
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["pdf", "doc", "docx"],
      list:[],
      attachedLimit:99,
      attachedFiles:[],
      sssssss:'',
    }
  },
  methods:{
    getList(){
      resumelist(this.$store.state.token,).then(res=>{
        console.log(res)
        if(res.data.status == true){
          console.log(res.data.data)
          if (res.data.status == true){
            let list = res.data.data
            this.list = list
          }else{
            Toast(res.data.msg)
          }
        }else{
          Toast(res.data.msg)
        }
      })
    },
    createResumeFun(src,name){
      createResume(this.$store.state.token,src,name).then(res=>{
        if(res.data.status == true){
          this.$message.success("上传成功");
          this.getList()
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },



    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)
        }


      })
    },
    async beforeUpload(file) {
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.createResumeFun(res.data.data.saveurl,res.data.data.saveurl.split(',')[0])
            this.handleClose(0)
          }
        })
    },
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange(file, infomationFiles) {
      this.avatarFiles = infomationFiles;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.avatarFiles.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },

    deleteAttached(item){
      Dialog.alert({
        title: `确定删除吗？`,
        showCancelButton:true,
      }).then(() => {
        // on close
        deleteResume(this.$store.state.token,item.id).then(async res=>{
          if(res.data.status == true){
           await this.getList()
            await Toast('删除成功')
          }else{
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }).catch(()=>{
        // catch
      });
    },
    checkAcctched(item){
      console.log(item)
      this.sssssss = "https://view.officeapps.live.com/op/view.aspx?src="+(this.$store.state.imageHost+ item.file_url)
      // console.log("https://view.officeapps.live.com/op/view.aspx?src="+(this.$store.state.imageHost+ item.file_url))
    },


  },
  mounted() {
    this.getList()
  }
}
</script>

<template>
 <div class="container">
   <div class="area">
     <navigationBar :title="'附件简历'" class="navigationBar" ></navigationBar>

     <div class="">
       <el-upload
           class="attached-uploader"
           action
           :http-request="uploadFile"
           ref="upload"
           :limit="attachedLimit"
           :on-remove="handleRemove"
           :file-list="attachedFiles"
           :on-exceed="handleExceed"
           :before-upload="beforeUpload"
           :show-file-list="false"
           :headers="headers"
           :on-change="fileChange"
           multiple
           name="attached"
       >
         <div class="upload__text"><span class="iconfont icon-Frame27"></span><span class="text">上传您的简历</span></div>
       </el-upload>
     </div>
     <div class="Tips">
       上传小于5MB的.pdf、.doc、(或).docx文件
     </div>
     <div class="list">
       <div class="title">您的简历清单</div>
       <div class="ul" >
         <div class="li" v-if="list.length>0">
           <div class="itemLi" v-for="(item,index) in list" :key="index">
             <div class="left" @click="checkAcctched(item)">{{item.file_name}}</div>
             <span class="iconfont icon-guanbi1" @click="deleteAttached(item)"></span>
           </div>
         </div>
         <div class="noList" v-if="list.length<=0">
           <van-divider
               :style="{ color: '#999999', borderColor: '#DDDDDD', padding: '0 16px',fontSize:'14px' }"
           >
             暂未上传简历
           </van-divider>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>

<style scoped lang="scss">
.area{
  height: 100vh;
}
.attached-uploader{
  padding: 10px 16px;
}
.upload__text{
  color: #1b1b1b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
  .iconfont{
    vertical-align: middle;
    margin-right: 6px;
    font-size: 24px;
  }
}
.Tips{
  color: #666666;
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  padding-left: 16px;
}
.list{
  margin-top: 25px;
  text-align: left;
  padding: 0 16px;
  .title{
    color: #1b1b1b;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
  }
  .ul{
    height: 500px;
    overflow-y: scroll;
    .li{
      margin: 10px 0;
      .itemLi{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .left{
          color: #1b1b1b;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          width: 90%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .iconfont{
          width: 10%;
          font-size: 20px;
          color: #666666;
          text-align: right;
        }
      }
    }
    .noList{
      padding-top: 100px;
    }
  }
  .ul::-webkit-scrollbar{
    display: none;
  }
}
</style>
<style>
.attached-uploader .el-upload{
  height: 72px;
  border-radius: 10px;
  background: #f6f6f6;
  width: 100%;
  border: 1px dashed transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>