<template>
  <div class="house_list">
    <!-- <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      offset="50"
      @load="scrollGet"
      :immediate-check="false"
    > -->
    <div class="item" v-for="(item, index) in list" :key="index">
      <div class="leftImg">
        <img class="img" :src="item.img" alt=""/>
        <div class="tag">{{ item.tag }}</div>
      </div>

      <div class="rightInfo">
        <div class="inner">
          <div class="name">{{ item.name }}</div>
          <div class="address">朝阳区 白西社区</div>
          <div class="house_tag">
            <div class="li" v-for="(item, index) in tagList" :key="index">
              {{ item.name }}
            </div>
          </div>
          <div class="priceBox">
            <div class="yuan">{{ item.price }}</div>
            元/月
          </div>
        </div>
      </div>
    </div>
    <!-- </van-list> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    list: {
      type: [Array],
      required: true,
      default() {
        return [
          {
            name: "碑林",
            img: "https://picture.bjbjkj.top/tp202407060300150052350336.png",
          },
          {
            name: "300m²临街店铺出租300m²超级搜索",
            img: "https://picture.bjbjkj.top/tp2024070601521352037796144.png",
          },
          {
            name: "三里屯",
            img: "https://picture.bjbjkj.top/tp2024070601461346329228381.png",
          },
        ];
      },
    },
    deleteTodo: Function,
    updataTodo: Function,
  },
  data() {
    return {
      tagList: [
        {
          id: 1,
          name: "客梯",
          ctime: "2024-06-03 13:24:46",
          sort: 99,
          icon: "keti",
          check: false,
        },
        {
          id: 2,
          name: "货梯",
          ctime: "2024-06-03 13:24:51",
          sort: 98,
          icon: "huoti",
          check: false,
        },
        {
          id: 3,
          name: "扶梯",
          ctime: "2024-06-03 13:25:02",
          sort: 97,
          icon: "shoufuti",
          check: false,
        },
        {
          id: 4,
          name: "暖气",
          ctime: "2024-06-03 13:25:11",
          sort: 96,
          icon: "nuanqi",
          check: false,
        },
        {
          id: 5,
          name: "空调",
          ctime: "2024-06-03 13:26:58",
          sort: 95,
          icon: "kongtiao",
          check: false,
        },
        {
          id: 6,
          name: "可明火",
          ctime: "2024-06-03 13:27:36",
          sort: 94,
          icon: "keminghuo",
          check: false,
        },
        {
          id: 7,
          name: "燃气",
          ctime: "2024-06-03 13:27:18",
          sort: 93,
          icon: "ranqi",
          check: false,
        },
        {
          id: 8,
          name: "网络",
          ctime: "2024-06-03 13:27:44",
          sort: 92,
          icon: "WIFI",
          check: false,
        },
        {
          id: 9,
          name: "停车位",
          ctime: "2024-06-03 13:27:49",
          sort: 91,
          icon: "tingchechang",
          check: false,
        },
        {
          id: 10,
          name: "上水",
          ctime: "2024-06-03 13:28:27",
          sort: 90,
          icon: "shangshui",
          check: false,
        },
        {
          id: 11,
          name: "下水",
          ctime: "2024-06-03 13:28:33",
          sort: 89,
          icon: "xiashuidao",
          check: false,
        },
        {
          id: 12,
          name: "排烟",
          ctime: "2024-06-03 13:28:44",
          sort: 88,
          icon: "paiyan",
          check: false,
        },
        {
          id: 13,
          name: "排污",
          ctime: "2024-06-03 13:29:03",
          sort: 87,
          icon: "paiwu",
          check: false,
        },
        {
          id: 14,
          name: "380v",
          ctime: "2024-06-03 13:29:06",
          sort: 86,
          icon: "shandian",
          check: false,
        },
      ],

      //   list22: this.list,
      //   loading: false,
      //   finished: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    scrollGet(event) {
      console.log(event);
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求

      //   console.log('触底');
      //   setTimeout(() => {
      //     for (let i = 0; i < 10; i++) {
      //         console.log(i);
      //         // console.log(this.list22);
      //       this.list22.push(this.list22.length + 1);
      //     //   this.list22 = this.list22.concat(this.list22)
      //     }

      //     // 加载状态结束
      //     this.loading = false;
      //     // console.log(this.list22);
      //     // 数据全部加载完成
      //     if (this.list22.length >= 40) {
      //       this.finished = true;
      //     }
      //   }, 1000);
    },
  },
  created() {
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.house_list {
  .item {
    display: flex;
    box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;

    .leftImg {
      width: 40%;
      min-width: 128px;
      max-width: 200px;
      position: relative;
      display: flex;
      border-radius: 8px;
      overflow: hidden;
      height: 96px;

      .img {
        width: 100%;
        height: 96px;
        // object-fit: fill; // 填充，撑满
        // object-fit: contain; // 保持原比例缩小
        object-fit: cover;
        // object-fit: none;
        // object-fit: scale-down;
      }

      .tag {
        position: absolute;
        left: 0;
        top: 0;
        background: #3ccfc7;
        border-radius: 8px 0px 5px 0px;
        font-weight: 400;
        font-size: 11px;
        color: #ffffff;
        line-height: 20px;
        padding: 0 6px;
      }
    }

    .rightInfo {
      flex: 1;
      padding: 0 10px;
      overflow: hidden;

      .inner {
        width: 100%;

        .name {
          font-weight: 400;
          font-size: 16px;
          color: #1b1b1b;
          line-height: 24px;
          text-align: left;
        }

        .address {
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          line-height: 24px;
          text-align: left;
        }

        .house_tag {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          line-height: 16px;
          box-sizing: border-box;
          overflow-x: scroll;

          .li {
            display: inline-block;
            font-weight: 400;
            font-size: 10px;
            color: #8b8b8b;
            line-height: 16px;
            text-align: center;
            background: #f2f2f2;
            border-radius: 15px 15px 15px 15px;
            padding: 0 8px;
          }
        }

        /* 垂直滚动条隐藏 */
        .house_tag::-webkit-scrollbar {
          width: 0;
          height: 0px;
          color: transparent;
        }

        /* 水平滚动条的样式 */
        .house_tag::-webkit-scrollbar-track-piece {
          background-color: #f8f8f8; /* 滚动条轨道的背景颜色 */
        }

        /* 滚动条的样式 */
        .house_tag::-webkit-scrollbar-thumb {
          background-color: #c1c1c1; /* 滚动条的颜色 */
          border-radius: 10px; /* 滚动条的圆角大小 */
          border: 2px solid #ffffff; /* 滚动条边框 */
        }

        /* 滚动条在悬停或者点击时的样式 */
        .house_tag::-webkit-scrollbar-thumb:hover {
          background-color: #a8a8a8;
        }

        .priceBox {
          display: flex;
          margin-top: 5px;
          font-size: 12px;
          color: #f74440;
          align-items: baseline;

          .yuan {
            font-size: 18px;
          }
        }

        .name,
        .address,
        .priceBox {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>