<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {create, get_user_kt, getKongtong, getNowTime, user_ktyj} from "@/utils/api";
import Bus from "@/utils/Bus";
import {Toast} from "vant";

export default {
  name: "exitPail",
  components: {
    navigationBar
  },
  data() {
    return {
      timeIndex: 0,
      nowHour: 0,
      nowSend: false,
      shipAddress: '',
      userName: '',
      mobile: '',
      time: '',
      maxlength: 4,
      maxIndex: 4,
      click: false,
      yutong: this.$store.state.user.kt_pledge,
      allTong: 0,
      allTongPrice: 0,
      yuTongPrice: 0,
      tuiTongPrice: 0,
      totalNum: 0,
      ktPrcie: 0,
      dataIndex: 0,
      listTitle: [
        '退桶日期',
        '退桶数量',
        '审核状态',
      ],
      totalListText: [
        '桶押金数量',
        '押金总额',
        '退桶数量',
        '退桶金额',
        '余桶数量',
        '押金总额',
      ],
      listQuitList: [],
      dateList:[{text:'今天',time:new Date().getMonth() + 1+'月'+new Date().getDate()+'日'}],
      timeList:[
        {text:'立即退桶',time:'',diab:99,disable:99},
        {text:'预约',time:'08:00-09:00',diab:7,disable:7},
        {text:'预约',time:'09:00-10:00',diab:8,disable:8},
        {text:'预约',time:'10:00-11:00',diab:9,disable:9},
        {text:'预约',time:'11:00-12:00',diab:10,disable:10},
        {text:'预约',time:'13:00-14:00',diab:12,disable:12},
        {text:'预约',time:'14:00-15:00',diab:13,disable:13},
        {text:'预约',time:'15:00-16:00',diab:14,disable:14},
        {text:'预约',time:'16:00-17:00',diab:15,disable:15},
        {text:'预约',time:'17:00-18:00',diab:16,disable:16},
        {text:'预约',time:'18:00-19:00',diab:17,disable:17},
        {text:'预约',time:'19:00-20:00',diab:18,disable:18},
      ],
      show: false,
      addId:-1,
    }
  },
  methods: {
    get_user_kt() {
      get_user_kt(this.$store.state.token).then(res => {
        if (res.data.status == true) {
          console.log((res.data.data.nums), this.yutong)
          this.allTong = res.data.data.nums ? res.data.data.nums : 0
          this.allTongPrice = (this.ktPrcie * res.data.data.nums).toFixed(2)
          this.tuiTongPrice = ((res.data.data.nums - this.yutong) * this.ktPrcie)
          this.yuTongPrice = (this.ktPrcie * this.yutong).toFixed(2)
        }
      })
    },
    getNowDate(e,time) {
      if(e==1){
        var myDate = new Date;
      }else{
        var myDate = new Date(time);
      }
      var year = myDate.getFullYear(); //获取当前年
      var mon = myDate.getMonth() + 1; //获取当前月
      var date = myDate.getDate(); //获取当前日
      var hours = myDate.getHours(); //获取当前小时
      var now =  hours ;
      if(e==1){
        return now;
      }else{
        return year+'.'+mon+'.'+date
      }
    },
    nowSendF(){
      let newArr = this.timeList.slice(1);
      console.log(this.nowHour)
      if(this.nowHour>=8&&this.nowHour<20){
        this.timeIndex = 0
      }else{
        this.timeIndex = -1
      }
      let flag = newArr.some(e=>{
        return e.disable>this.nowHour})
        this.nowSend = flag
    },
    unadd(){
      if(this.totalNum>0){
        this.totalNum-=1
      }
    },
    add(){
      if(this.totalNum>=this.$store.state.user.kt_pledge)return;
      this.totalNum +=1
    },
    goAddress(){
      this.$router.push({
        path:'/userAddress',
        query:{id:this.addId},
      })
    },
    showPopup(){
      this.show = true
    },
    onClose(){
      this.show = false
    },
    selectDate(){},
    selectTime(item, index){
      let list = this.timeList
      if (this.dataIndex != 0 && index == 0) return;
      if (this.nowHour <= 6) {
        if (index == 0) {
          return
        }
      }
      if (this.nowHour >= list[index].disable) {
        return
      }
      let data = this.GetDateStr(this.dataIndex, 0)
      let star
      let end
      this.timeIndex = index
      console.log(this.timeIndex)
      if (this.dataIndex == 0) {
        let myDate = new Date(this.serverTime * 1000);
        let hours = myDate.getHours(); //获取当前小时
        let minutes = myDate.getMinutes(); //获取当前分钟
        hours = hours < 10 ? ('0' + hours) : hours;
        minutes = minutes < 10 ? ('0' + minutes) : minutes;
        star = hours + ':' + minutes
        end = (hours + 1) + ':' + minutes
        if (this.nowHour == 12) {
          end = (hours + 2) + ':' + minutes
        }
      } else {
        star =list[this.timeIndex].time.split('-')[0]
        end = list[this.timeIndex].time.split('-')[1]
      }
      let obj = {
        time: data + ' ' + star + ',' + data + ' ' + end,
        timeshow: this.dateList[this.dataIndex].text + list[this.timeIndex].time,
      }
      console.log(obj,index)
      this.time = obj.timeshow
      this.axiosTime = obj.time
      this.show = false
    },

    // 获取今天明天后天
    GetDateStr(AddDayCount, num) {
      var dd = new Date();
      dd.setDate(dd.getDate() + AddDayCount); // 获取AddDayCount天后的日期
      var m = dd.getMonth() + 1; // 获取当前月份的日期
      var y = dd.getFullYear()
      m = m.toString();
      if (m.length == 1) {
        m = '0' + m;
      }
      var d = dd.getDate();
      d = d.toString();
      if (d.length == 1) {
        d = '0' + d;
      }
      let r = m + '月' + d + '日'

      if (num == 1) {
        return r
      } else {
        return y + '-' + m + '-' + d
      }
    },


    submit(){
      console.log(this.totalNum)
      if(this.totalNum<=0){Toast('请选择数量');return;}
      if(!this.time){Toast('请选择时间');return;}
      if(!this.addId){Toast('请选择地址');return}
      this.addresst = this.$store.state.shipingAddressList[0]
      this.addresst.ship_address_ds = this.addresst.province_name+this.addresst.city_name+this.addresst.district_name+this.addresst.address
      let ship = {}
          ship['name'] = this.addresst.name,
          ship['yx'] = '39.932246,116.453546',
          ship['ds'] = this.addresst.ship_address_ds,
          ship['address'] = this.addresst.address,
          ship['mobile'] = this.addresst.mobile,
          ship['time'] = this.axiosTime,
          ship['remark'] = '',
          ship['id'] = this.addresst.id
      let data = {
        token:this.$store.state.token,
        cart_ids:this.totalNum,
        ship:JSON.stringify(ship),
        order_type:11,
      }
      create(this.$store.state.token, data.order_type, data.cart_ids, JSON.stringify(ship)).then(res => {
        console.log(res.data.data)
        if(res.data.status){
          let msg={type:'u_order_bottled'}
          this.$store.state.websocket.send(JSON.stringify(msg),)
          this.$router.go(-1)
        }else{
          Toast(res.data.msg)
        }
      })

    },

  },
  async mounted() {
    Bus.$emit("updataUserInfo", '1');
    const {data} = await getNowTime()
    this.serverTime = (data.data * 1000)
    this.ktPrcie = (await getKongtong(this.$store.state.token)).data.data.price
    this.get_user_kt()
    this.nowHour= this.getNowDate(1)+this.dataIndex*24
    this.nowSendF()
    user_ktyj(this.$store.state.token).then(res => {
      let list = res.data.data
      list.forEach(item => {
        item.yvyue_time = this.getNowDate(0, (item.ctime * 1000))
        if (item.status == 1) {
          item.status_text = '审核中'
        }
        if (item.status == 2) {
          item.status_text = '已同意'
        }
        if (item.status == 3) {
          item.status_text = '已拒绝'
        }
      })
      this.listQuitList = list
    })
    if (this.$store.state.token) {
      Bus.$on("sendUserInfo", (msg) => {
        console.log(msg)
        this.ys =  '39.932246,116.453546',
        this.mobile = this.$store.state.shipingAddressList[0].mobile
        this.addId = this.$store.state.shipingAddressList[0].id
        this.userName = this.$store.state.shipingAddressList[0].name
        this.shipAddress = this.$store.state.shipingAddressList[0].province_name+this.$store.state.shipingAddressList[0].city_name+this.$store.state.shipingAddressList[0].district_name+this.$store.state.shipingAddressList[0].details_name
      });
    }




  },
  computed:{
    totalPrice(){
      return Number(this.totalNum *this.ktPrcie).toFixed(2)
    },
  },





  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'空桶押金'"></navigationBar>
      <div class="top topl">
        <div class="head">
          <div class="every">
            <div class="evertHead">空桶数量</div>
            <div class="evertBody">{{ allTong }}桶</div>
          </div>

          <div class="every">
            <div class="evertHead">押金总额</div>
            <div class="evertBody">{{ allTongPrice }}元</div>
          </div>

          <div class="every">
            <div class="evertHead">退桶数量</div>
            <div class="evertBody">{{ allTong - yutong > 0 ? allTong - yutong : 0 }}桶</div>
          </div>

          <div class="every">
            <div class="evertHead">退桶金额</div>
            <div class="evertBody">{{ tuiTongPrice > 0 ? tuiTongPrice : 0 }}元</div>
          </div>

          <div class="every">
            <div class="evertHead">余桶数量</div>
            <div class="evertBody">{{ yutong }}桶</div>
          </div>
        </div>
        <div class="bottom">
          <div class="text">押金余额</div>
          <div class="text">{{ yuTongPrice }}元</div>
        </div>
      </div>
      <div class="topTitle">
        空桶押金退回记录
      </div>
      <div class="top">

        <div class="listTitle">
          <div class="listTitleEvery" v-for="(item,index) in listTitle" :key="index">
            {{ item }}
          </div>
        </div>
        <div class="listQuitList">
          <div class="listQuitListEvery" v-for="(item,index) in listQuitList" :key="index">
            <!--          v-if="{{index<maxIndex||maxIndex==-1}}"-->
            <div class="time every">
              {{ item.yvyue_time }}
            </div>
            <div class="nums every">
              {{ item.num }}桶
            </div>
            <div class="price every">
              {{ item.status_text }}
            </div>
          </div>
          <div v-if="listQuitList.length>maxlength&&maxIndex!=-1" class="more" @click="more">
            查看更多
          </div>
          <div class="more" v-if="maxIndex==-1" @click="shouqi">
            收起
          </div>
        </div>
      </div>

      <div class="shenqingTitle">
        申请退空桶押金
      </div>


      <div class="infoBox">
        <div class="ktNum inforEvery">
          <div class="left">
            <div class="infoTitle">
              退桶数量<span class="text"></span>
            </div>
          </div>
          <div class="right">
            <div class="addBox">
              <div class="unadd" @click="unadd"></div>
              <div class="input">{{ totalNum }}</div>
              <div class="add" @click="add"></div>
            </div>
          </div>
        </div>
        <div class="ktPrice inforEvery">
          <div class="left">
            <div class="infoTitle">
              退桶金额
            </div>
          </div>
          <div class="right">
            ￥{{ totalPrice }}元
          </div>
        </div>
        <div class="userAddress inforEvery" @click.prevent="goAddress">
          <div class="left">
            <div class="infoTitle">
              回收退桶地址
            </div>
          </div>
          <div class="right">
            <span class="addressInput" :class="shipAddress?'':'placeholder'">{{ shipAddress?shipAddress:'请输入地址' }}</span>
<!--            <input class="addressInput"  v-model="shipAddress" disabled type="text" placeholder="请输入地址"/>-->
            <div class="img">
              <van-icon style="font-size: 28rpx;  vertical-align: middle; color: #7f7f7f; " name="arrow"/>
            </div>
          </div>
        </div>
        <div class="ktTime inforEvery" @click="showPopup">
          <div class="left">
            <!-- <div class="img">
              <image class="image" src="/images/time.png" mode="widthFix"/>
            </div> -->
            <div class="infoTitle">
              回收空桶时间
            </div>
          </div>
          <div class="right">
            <span class="addressInput" :class="time?'':'placeholder'">{{ time?time:'请选择' }}</span>
            <div class="img">
              <van-icon style="font-size: 28rpx;  vertical-align: middle; color: #7f7f7f; " name="arrow"/>
            </div>
          </div>
        </div>
        <van-popup v-model="show" position="bottom" custom-style=" background-color:##FFFFFF;" @close="onClose"
                   round>
          <div class="timeselectBox">
            <div class="topBox">
              选择时间
            </div>
            <div class="timeBody">
              <div class="popupHead">
                <div class="dateBox">
                  <div class="dateEvery" :class="dataIndex==index?'selectDate':''" v-for="(item,index) in dateList"
                       :key="index" @click="selectDate">
                    <div class="selectLine" v-if="dataIndex==index"></div>
                    <div class="dateBottom">
                      {{ item.time }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="timeInBox">
                <div class="select"
                     :class=" nowHour>=item.disable?'noSelect':''+timeIndex==index&&nowSend?'selectTime':''+index==0?'first':''"
                     v-for="(item,index) in timeList" :key="index" @click="selectTime(item, index)">
                  <div class="timeButtonTop" v-if="index == 0">
                    {{ item.text }}
                  </div>
                  <div class="timeButtonUp" v-if="item.time">
                    {{ item.time }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-popup>


        <div class="userName inforEvery" @click="goAddress">
          <div class="left">
            <div class="infoTitle">
              用户名称
            </div>
          </div>
          <div class="right">
            <span class="addressInput" :class="userName?'':'placeholder'">{{ userName?userName:'请输入用户名称' }}</span>
<!--            <input class="addressInput" disabled="true" style="text-align: right;font-size:28rpx ;" type="text"-->
<!--                   placeholder="请输入用户名称" v-model="userName" bindinput="userNameChange"/>-->
            <div class="img">
              <van-icon style="font-size: 28rpx;  vertical-align: middle; color: #7f7f7f; " name="arrow"/>
            </div>
          </div>
        </div>
        <div class="userMobile inforEvery" @click="goAddress">
          <div class="left">
            <div class="infoTitle">
              用户电话
            </div>
          </div>
          <div class="right">
            <span class="addressInput" :class="mobile?'':'placeholder'">{{ mobile?mobile:'请输入用户电话' }}</span>

<!--            <input class="addressInput" v-else style="text-align: right;font-size:28rpx ;" type="text"-->
<!--                   placeholder="请输入用户电话" bindinput="mobile" bindchange='mobileChange' disabled="true"-->
<!--                   v-model="mobile"/>-->
            <div class="img">
              <van-icon style="font-size: 28rpx;  vertical-align: middle; color: #7f7f7f; " name="arrow"/>
            </div>
          </div>
        </div>


      </div>

      <div class="bottomBox">
        <div class="btn" @click="submit">
          提交申请
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bottomBox{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  .btn{
    background-color: #286CFB;
    width: 85%;
    margin: 0 auto;
    padding: 14px;
    color: #fff;
    border-radius: 10px;
  }
}
.timeselectBox{
  background-color: #fff!important;
  width: 100%;
  margin-bottom: 15px;
  .topBox{
    width: 100%;
    padding: 14px 16px;
    font-size: 18px;
    color: #1A1A1A;
    border-bottom: 1rpx solid rgba(153, 153, 153,.15);
  }
  .timeBody{
    display: flex;
    max-height: 48vh;
    .popupHead{
      width: 25%;
      overflow: scroll;
      .dateBox {
        display: flex;
        color: white;
        width: 100%;
        margin: 0 auto;
        flex-direction: column;
        .dateEvery {
          width: 100%;
          border-bottom: none;
          text-align: center;
          padding: 15px 0;
          position: relative;
          .dateBottom {
            font-size: 14px;
            color: #1b1b1b;
          }

        }
        .selectLine{
          position: absolute;
          width: 4px;
          height: 16px;
          background-color: #0085FF;
          border-radius: 10px;
          transform: translate(0,-50%);
          top: 50%;

        }
        .selectDate{
          color: #0085FF!important;
          .dateBottom {
            font-size: 14px;
            color: #0085FF!important;
          }
        }
      }
    }

    .timeInBox{
      overflow: scroll;
      width: 75%;
      background-color: #FFFFFF;
      padding: 0;
      .select {
        text-align: left;
        width: 100%;

        padding: 15px 0;
        border-bottom: 1px solid rgba(153, 153, 153,.15);
        .timeButtonUp {
          font-size: 16px;
          padding: 0 0 5px 0;
          color: #666666;
        }
      }
      .noSelect{
        .timeButtonUp {
          color: #999999;
        }
      }
      .selectTime .timeButtonTop, .selectTime .timeButtonUp {
        color: #0085FF;
      }
    }
  }
}
.top {
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  margin: 10px 16px 0px 16px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;

  .head {
    display: flex;
  }

  .bottom {
    background-color: #286CFB;
    display: flex;
    color: white;
    justify-content: space-between;
    padding: 8px 10px;
    font-size: 14px;
  }

  .every {
    width: 20%;
    text-align: center;
    margin: 10px 0;
  }

  .evertHead {
    margin-bottom: 4px;
    font-size: 14px;
    color: #1b1b1b;
  }

  .evertBody {
    font-size: 18px;
    white-space: nowrap;
    overflow: auto;
  }

  .evertBody::-webkit-scrollbar {
    display: none;
  }


  .listTitle {
    display: flex;
    background-color: #286CFB;
    color: white;
    justify-content: space-around;
  }

  .listTitleEvery {
    width: 33%;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 400;
    color: white;
  }

  .listQuitList {
    max-height: 225px;
    overflow: auto;

    .listQuitListEvery {
      display: flex;
      padding: 10px 0;
      position: relative;
      justify-content: space-around;

      .every {
        width: 33%;
        text-align: center;
        color: #1b1b1b;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .listQuitListEvery::after {
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      background-color: #E8E8E8;
      transform: translate(-50%, 0%);
      left: 50%;
      bottom: 0;
    }
  }
}

.topl {
  background: linear-gradient(180deg, #E7EEFD 0%, #FFFFFF 100%);
}

.topTitle {
  font-weight: 500;
  font-size: 16px;
  margin: 0 16px;
  color: #1b1b1b;
  margin-top: 20px;
  text-align: left;
}

.shenqingTitle {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 16px 10px 16px;
  text-align: left;
}

.infoBox {
  background-color: white;
  box-sizing: border-box;
  margin: 0 32rpx;
  border-radius: 20rpx;
  box-shadow: 0px 0px 4rpx 0px rgba(102, 102, 102, 0.25);

  .inforEvery {
    box-sizing: border-box;
    border-bottom: 1px solid #f6f6f6;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;

    .infoTitle {
      font-size: 14px;
      margin-left: 5px;
      color: #666666;
    }
    .left {
      display: flex;
      align-items: center;
    }

    .img {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .iconfont {
        font-size: 14px;
        vertical-align: middle;
        color: #666;
      }
    }
  }

  .inforEvery:nth-last-of-type(1) {
    border-bottom: none;
  }
}

.addBox {
  display: flex;
  justify-content: flex-end;
}

.addBox .unadd, .addBox .add {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #286CFB;
  position: relative;
}

.addBox .input {
  height: 25px;
  width: 25px;
  line-height: 25px;
  margin: 0 9px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 500;
}

.addBox .unadd, .addBox .add {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #286CFB;
  position: relative;
}
.addBox .add::after,.addBox .unadd::after{
  content: '';
  display: block;
  position: absolute;
  transform: translate(-50%,-50%);
  width: 50%;
  height: 10%;
  background-color: white;
  top: 50%;
  left: 50%;
  border-radius: 10rpx;

}
.addBox .add::before{
  content: '';
  display: block;
  position: absolute;
  transform: translate(-50%,-50%);
  width: 10%;
  height: 50%;
  background-color: white;
  top: 50%;
  left: 50%;
  border-radius: 10rpx;
}
.addBox .input{
  height:24px;
  width:24px;
  line-height: 24px;
  margin: 0 6px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 500;
}
.ktPrice .right {
  font-size: 15px;
  font-weight: 400;
  color: #F74440;
}

.userAddress .right, .ktTime .right, .userName .right, .userMobile .right {
  display: flex;
  font-size: 14px;
  flex: 1;
  justify-content: flex-end;
}

.userAddress .right .addressInput {
  text-align: right;
  font-size: 14px;
  color: #1b1b1b;
  width: 100%;
}

input:disabled {
  border-color: transparent;
  background-color: transparent;
}
.placeholder{
  color: var(--999999, #999999);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
</style>