// import Login from ''
import tabbar_home from '@/components/common/home_tabbar_page/tabbar_home.vue';
import tabbar_car from '@/components/common/home_tabbar_page/tabbar_car.vue';
import tabbar_mine from '@/components/common/home_tabbar_page/tabbar_mine.vue';
import tabbar_message from '@/components/common/home_tabbar_page/tabbar_message.vue';
import tabbar_share from '@/components/common/home_tabbar_page/tabbar_share.vue';
import sign_in from '@/components/pages/sign_in.vue';
import home_login from "@/components/pages/home_login.vue";
//问题反馈
import problem_feedback from "@/components/pages/problem_feedback.vue";


//tabbar 我的-用户信息
import userInfo from "@/components/common/home_tabbar_page/mine/userInfo.vue";
import order_list from "@/components/common/home_tabbar_page/order/order_list.vue";
import exitPail from "@/components/common/home_tabbar_page/mine/exitPail.vue";
import myWallet from "@/components/common/home_tabbar_page/mine/myWallet.vue";
import userAddress from "@/components/common/home_tabbar_page/mine/userAddress.vue";
import invoiceManagement from "@/components/common/home_tabbar_page/mine/invoiceManagement.vue";
import setDetails from "@/components/common/home_tabbar_page/mine/setDetails.vue";
import agreement from "@/components/common/home_tabbar_page/agreement/agreement.vue";
import addAddress from "@/components/common/home_tabbar_page/mine/addAddress.vue";



//tabbar 消息列表-聊天详情
import chat_details from "@/components/common/home_tabbar_page/chat_details/chat_details.vue";


// vip页面
import vip_page from "@/components/common/vip/vip_page.vue";


// 室内快修
import service_page from '@/components/common/service/service_page.vue';
import my_acceptance from "@/components/common/service/my_acceptance.vue"
import fitment_details from "@/components/common/service/fitment_details/fitment_details.vue"
import service_order from '@/components/common/service/service_order/service_order.vue';

//水站
import water from '@/components/common/water/water_home.vue';
import water_coupon from "@/components/common/water/water_coupon.vue";
import one_key_send_water from "@/components/common/water/one_key_send_water.vue";
import buyPail from "@/components/common/water/buyPail.vue";
import water_details from "@/components/common/water/water_details.vue";
import createOrderFirst from "@/components/pages/createOrder/createOrderFirst.vue";
import createOrderSecond from "@/components/pages/createOrder/createOrderSecond.vue";

//超市
import supmarket_home from '@/components/common/supmarket/supmarket_home.vue';


//房产 h
import house_home from '@/components/common/house/house_home.vue';
import house_mine from '@/components/common/house/house_mine.vue';
import house_publish from '@/components/common/house/house_publish.vue';


// 建材市场
import materials_home from '@/components/common/building_materials/materials_home.vue';
import materials_car from '@/components/common/building_materials/materials_car.vue';
import materials_order from '@/components/common/building_materials/materials_order.vue';


//员工端
import employee_home from "@/components/common/employee/home.vue"
import employee_order_details from "@/components/common/employee/details/order_details.vue";
import workRecords from "@/components/common/employee/workRecords/workRecords.vue";
import performance from "@/components/common/employee/performance/performance.vue";
import inventory from "@/components/common/employee/inventory/inventory.vue";
import service_detail from "@/components/common/employee/details/service_detail.vue";


//支付
import pay from "@/components/pages/pay/pay.vue";



// 闲置转让
import idle_home from "@/components/common/idleTransfer/tabbar/home.vue"
import liberty from "@/components/common/idleTransfer/tabbar/liberty.vue";
import publish from "@/components/common/idleTransfer/tabbar/publish.vue";
import idel_message from "@/components/common/idleTransfer/tabbar/message.vue";
import idel_mine from "@/components/common/idleTransfer/tabbar/mine.vue";
import idleDetails from "@/components/common/idleTransfer/idleDetails/idleDetails.vue";
import idle_certification from "@/components/common/idleTransfer/certification/idle_certification.vue";
import myRelease from "@/components/common/idleTransfer/myRelease/myRelease.vue";
import mySell from "@/components/common/idleTransfer/mySell/mySell.vue";
import myBought from "@/components/common/idleTransfer/myBought/myBought.vue";
import myCollect from "@/components/common/idleTransfer/myCollect/myCollect.vue";
import sellDetails from "@/components/common/idleTransfer/mySell/sellDetails/sellDetails.vue";
import FAQ from "@/components/common/idleTransfer/FAQ/FAQ.vue";
import FAQdetails from "@/components/common/idleTransfer/FAQ/FAQdetails/FAQdetails.vue";
import recycleCheckIn from "@/components/common/idleTransfer/recycleCheckIn/recycleCheckIn.vue";
import MoveCheckIn from "@/components/common/idleTransfer/MoveCheckIn/MoveCheckIn.vue";
import ShopTransfer from "@/components/common/idleTransfer/ShopTransfer/ShopTransfer.vue";
import idle_house_li from "@/components/common/idleTransfer/idle_house_li/idle_house_li.vue";
import idle_house_details
    from "@/components/common/idleTransfer/idle_house_li/idle_house_details/idle_house_details.vue";
import idle_shop_list from "@/components/common/idleTransfer/idle_shop_list/idle_shop_list.vue";
import idle_shop_details from "@/components/common/idleTransfer/idle_shop_list/idle_shop_details/idle_shop_details.vue";
import Idle_office from "@/components/common/idleTransfer/tabbar/homeGoodsList/Idle_office.vue"
import idle_homeGoodsList from "@/components/common/idleTransfer/tabbar/homeGoodsList/idle_homeGoodsList.vue"
import idle_work_list from "@/components/common/idleTransfer/tabbar/homeGoodsList/WorkerList.vue"



// 求职招聘
import invite_home from "@/components/common/invite_job/tabbar/invite_home.vue";
import store_recruitment from "@/components/common/invite_job/tabbar/store_recruitment.vue";
import invite_message from "@/components/common/invite_job/tabbar/invite_message.vue";
import invite_mine from "@/components/common/invite_job/tabbar/invite_mine.vue";
import Attached from "@/components/common/invite_job/Attached/Attached.vue";
import online_resume from "@/components/common/invite_job/online/online_resume .vue";
import aboutUs from "@/components/pages/aboutUs.vue";
import invite_collect from "@/components/common/invite_job/invite_collect/invite_collect.vue";
import invite_details from "@/components/common/invite_job/invite_details/invite_details.vue";
import invite_certification from "@/components/common/invite_job/invite_certification/invite_certification.vue";
import frequently from "@/components/common/invite_job/frequently/frequently.vue";
import invite_talents from "@/components/common/invite_job/tabbar/talents.vue";
import invite_Company_certification
    from "@/components/common/invite_job/invite_certification/invite_Company_certification.vue";
import invite_Corporation_my_publish from "@/components/common/invite_job/Corporation/my_publishi/my_publishi.vue"
import invite_corporation_manage from "@/components/common/invite_job/Corporation/corporation_manage/corporation_manage.vue"
import invite_corporation_talents from "@/components/common/invite_job/Corporation/talents_manage/talents_manage.vue"
import invite_corporation_list from "@/components/common/invite_job/Corporation/corporation_list.vue"
import invite_corporation_home_page from "@/components/common/invite_job/Corporation/corporation_home_page.vue"
import invite_add_position from "@/components/common/invite_job/Corporation/add_position.vue"
import biographical_notes from "@/components/common/invite_job/Corporation/biographical_notes.vue";






const routes = [
    {
        path: "/home",
        component: tabbar_home,
        name: "首页",
    },
    {path: '*', redirect: '/home'},
    {
        path: "/car",
        component: tabbar_car,
        name: "购物车"
    },
    {
        path: "/share",
        component: tabbar_share,
        name: "分享中心"
    },
    {
        path: "/message",
        component: tabbar_message,
        name: "消息"
    },
    {
        path: "/mine",
        component: tabbar_mine,
        name: "我的",
    },

    {
        path: "/Login",
        component: home_login,
        hide: true, //注解：当有此参数导航栏不会显示此按钮
        name: "登录"
    },
    {
        path: "/sign",
        component: sign_in,
        hide: true, //注解：当有此参数导航栏不会显示此按钮
        name: "注册"
    },
    {
        path: "/service_page",
        component: service_page,
        name: "室内快修"
    },
    {
        path: "/my_acceptance",
        component: my_acceptance,
        name: "我的质保"
    },
    {
        path: "/fitment_details",
        component: fitment_details,
        name: "装修质保"
    },
    {
        path: "/service_order",
        component: service_order,
        name: "快修订单"
    },
    {
        path: "/water",
        component: water,
        name: 'water',
    },
    {
        path: "/supmarket",
        component: supmarket_home,
        name: 'supmarket_home'
    },
    {
        path: "/house_home",
        component: house_home,
        name: 'house_home',

    },
    {
        path: "/house_publish",
        component: house_publish,
        name: 'house_publish'
    },
    {
        path: "/house_mine",
        component: house_mine,
        name: 'house_mine'
    },
    {
        path: "/materials_home",
        component: materials_home,
        name: 'materials_home'
    },
    {
        path: "/materials_car",
        component: materials_car,
        name: 'materials_car'
    },
    {
        path: "/materials_order",
        component: materials_order,
        name: 'materials_order'
    },
    {
        path: "/userInfo",
        component: userInfo,
        name: 'userInfo'
    },
    {
        path: "/order_list",
        component: order_list,
        name: 'order_list'
    },
    {
        path: "/exitPail",
        component: exitPail,
        name: 'exitPail'
    },
    {
        path: "/myWallet",
        component: myWallet,
        name: 'myWallet'
    },
    {
        path: "/userAddress",
        component: userAddress,
        name: 'userAddress'
    },
    {
        path: "/addAddress",
        component: addAddress,
        name: 'addAddress'
    },
    {
        path: "/invoiceManagement",
        component: invoiceManagement,
        name: 'invoiceManagement'
    },
    {
        path: "/water_coupon",
        component: water_coupon,
        name: 'water_coupon'
    },
    {
        path: "/one_key_send_water",
        component: one_key_send_water,
        name: 'one_key_send_water'
    },
    {
        path: "/setDetails",
        component: setDetails,
        name: 'setDetails'
    },
    {
        path: "/vip_page",
        component: vip_page,
        name: 'vip_page'
    },
    {
        path: "/buyPail",
        component: buyPail,
        name: 'buyPail',
    },
    {
        path: "/water_details",
        component: water_details,
        name: 'water_details',
    }, {
        path: "/createOrderFirst",
        component: createOrderFirst,
        name: 'createOrderFirst',
    }, {
        path: "/createOrderSecond",
        component: createOrderSecond,
        name: 'createOrderSecond',
        mate: {
            keepAlive: true
        },

    }, {
        path: "/pay",
        component: pay,
        name: 'pay',
    }, {
        path: "/chat_details",
        component: chat_details,
        name: 'chat_details',
    },
    {
        path: "/employee_home",
        component: employee_home,
        name: 'employee_home',
    },
    {
        path: "/employee_order_details",
        component: employee_order_details,
        name: 'employee_order_details',
    },
    {
        path: "/workRecords",
        component: workRecords,
        name: 'workRecords',
    },
    {
        path: "/performance",
        component: performance,
        name: 'performance',
    },
    {
        path: "/inventory",
        component: inventory,
        name: 'inventory',
    },
    {
        path: "/agreement",
        component: agreement,
        name: 'agreement',
    },
    {
        path: "/idle_home",
        component: idle_home,
        name: 'idle_home',
    },
    {
        path: "/service_detail",
        component: service_detail,
        name: 'service_detail',
    },
    {
        path: "/liberty",
        component: liberty,
        name: 'liberty',
    },
    {
        path: "/publish",
        component: publish,
        name: 'publish',
    },
    {
        path: "/idel_message",
        component: idel_message,
        name: 'idel_message',
    },
    {
        path: "/idel_mine",
        component: idel_mine,
        name: 'idel_mine',
    },
    {
        path: "/idleDetails",
        component: idleDetails,
        name: 'idleDetails',
    },
    {
        path: "/idle_certification",
        component: idle_certification,
        name: 'idle_certification',
    },
    {
        path: "/problem_feedback",
        component: problem_feedback,
        name: 'problem_feedback',
    },
    {
        path: "/myRelease",
        component: myRelease,
        name: 'myRelease',
    },
    {
        path: "/mySell",
        component: mySell,
        name: 'mySell',
    },
    {
        path: "/myBought",
        component: myBought,
        name: 'myBought',
    },
    {
        path: "/myCollect",
        component: myCollect,
        name: 'myCollect',
    },
    {
        path: "/sellDetails",
        component: sellDetails,
        name: 'sellDetails',
    },
    {
        path: "/FAQ",
        component: FAQ,
        name: 'FAQ',
    },
    {
        path: "/FAQdetails",
        component: FAQdetails,
        name: 'FAQdetails',
    },
    {
        path: "/recycleCheckIn",
        component: recycleCheckIn,
        name: 'recycleCheckIn',
    },
    {
        path: "/MoveCheckIn",
        component: MoveCheckIn,
        name: 'MoveCheckIn',
    },
    {
        path: "/ShopTransfer",
        component: ShopTransfer,
        name: 'ShopTransfer',
    },
    {
        path: "/idle_house_li",
        component: idle_house_li,
        name: 'idle_house_li',
    },
    {
        path: "/idle_house_details",
        component: idle_house_details,
        name: 'idle_house_details',
    },

    {
        path: "/invite_home",
        component: invite_home,
        name: 'invite_home',
    },
    {
        path: "/store_recruitment",
        component: store_recruitment,
        name: 'store_recruitment',
    },
    {
        path: "/invite_message",
        component: invite_message,
        name: 'invite_message',
    },
    {
        path: "/invite_mine",
        component: invite_mine,
        name: 'invite_mine',
    },
    {
        path: "/Attached",
        component: Attached,
        name: 'Attached',
    },
    {
        path: "/online_resume",
        component: online_resume,
        name: 'online_resume',
    },
    {
        path: "/aboutUs",
        component: aboutUs,
        name: 'aboutUs',
    },
    {
        path: "/invite_collect",
        component: invite_collect,
        name: 'invite_collect',
    },
    {
        path: "/idle_shop_list",
        component: idle_shop_list,
        name: 'idle_shop_list',
    },
    {
        path: "/idle_shop_details",
        component: idle_shop_details,
        name: 'idle_shop_details',
    },
    {
        path: "/invite_details",
        component: invite_details,
        name: 'invite_details',
    },
    {
        path: "/invite_certification",
        component: invite_certification,
        name: 'invite_certification',
    },
    {
        path: "/frequently",
        component: frequently,
        name: 'frequently',
    },
    {
        path: "/invite_talents",
        component: invite_talents,
        name: 'invite_talents',
    },
    {
        path: "/invite_Company_certification",
        component: invite_Company_certification,
        name: 'invite_Company_certification',
    },
    {
        path: "/invite_Corporation_my_publish",
        component: invite_Corporation_my_publish,
        name: 'invite_Corporation_my_publish',
    },
    {
        path: "/invite_corporation_manage",
        component: invite_corporation_manage,
        name: 'invite_corporation_manage',
    },
    {
        path: "/invite_corporation_talents",
        component: invite_corporation_talents,
        name: 'invite_corporation_talents',
    },
    {
        path: "/invite_corporation_list",
        component: invite_corporation_list,
        name: 'invite_corporation_list',
    },
    {
        path: "/invite_corporation_home_page",
        component: invite_corporation_home_page,
        name: 'invite_corporation_home_page',
    },
    {
        path: "/invite_add_position",
        component: invite_add_position,
        name: 'invite_add_position',
    },{
        path: "/biographical_notes",
        component: biographical_notes,
        name: 'biographical_notes',
    },
    {
        path: "/Idle_office",
        component: Idle_office,
        name: 'Idle_office',
    },
    {
        path: "/idle_homeGoodsList",
        component: idle_homeGoodsList,
        name: 'idle_homeGoodsList',
    },
    {
        path: "/idle_work_list",
        component: idle_work_list,
        name: 'idle_work_list',
    },


]
export default [...routes];