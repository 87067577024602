<template>
  <div style="position: relative;">
    <img
        class="topImg"
        src="https://picture.bjbjkj.top/image/Mask%20group%402x%20%281%29.png"
        alt=""
    />
    <div class="container" ref="container">


      <div class="areaT">
        <navigationBar class="nivaBar">
          <template #search>
            <div class="navBox">
              <div class="centertext">
                百街水站
              </div>
              <div class="right">
                <span class="iconfont icon-kefu1" @click="kefu"></span>
                <span class="iconfont icon-dingdan" @click="goOrderLi"></span>
              </div>
            </div>
          </template>
        </navigationBar>
        <div class="areaOne">
          <div class="function">
            <div class="left" @click="waterCoupon">
              <div class="title">
                <div class="text">百街水票</div>
                <span class="iconfont icon-Frame-4"></span>
              </div>
              <div class="detailsText">桶装水票使用详情</div>
              <div class="line"></div>
            </div>
            <div class="right" @click="sender">
              <div class="title">
                <div class="text">一键送水</div>
                <span class="iconfont icon-Frame-4"></span>
              </div>
              <div class="detailsText">桶装送水一键搞定</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <van-sticky @change="stickchange" @scroll="stickscroll">
        <div class="outoverFlow" ref="sticky" :style="fixedFlag?'background:#fff;margin-top:0px':''">
          <div class="innerScroll" v-if="commodityLList.length>0">
            <div
                class="li"
                v-for="(item, index) in commodityLList"
                :key="index"
                :class="topIndex == index ? 'chooseTop' : ''"
                @click="chooseTop(index)"
            >
              <div class="imgBox">
                <img
                    class="img"
                    :src="$store.state.imageHost+item.image"
                    alt=""
                />
              </div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </van-sticky>
      <div class="area">

        <div class="body">
          <div class="left" :class="fixedFlag?'fixed1':''" :style="'top:'+(this.stickyHieght+10)+'px'">
            <div
                class="li"
                v-for="(item, index) in leftLi"
                :key="index"
                :class="leftIndex == index ? 'chooseLeft' : ''"
                @click="chooseLeft(index)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="left" v-if="fixedFlag"></div>

          <div class="right">
            <div class="top">
              <div class="leftSort" :class="sortType==1?'defaultS':''" @click="defaultS">默认排序 <span
                  class="iconfont icon-a-Frame32741"></span></div>
              <div class="rightSort">
                <div class="sale" :class="sortType==2?'saleS':''" @click="sale">销量</div>
                <div class="priceSort" @click="price" :class="sortType==3||sortType==4?'priceS'+sortType:''">
                  价格
                  <div class="iconfontBox">
                    <span class="iconfont icon-Vector"></span>
                    <span class="iconfont icon-a-Group2009"></span>
                  </div>
                  <!--                  <span class="iconfont icon-a-Group20093"></span>-->
                  <!--                  <svg class="icon" aria-hidden="true">-->
                  <!--&lt;!&ndash;                    <use xlink:href="#icon-a-Group20093"></use>&ndash;&gt;-->
                  <!--                    <use xlink:href="#icon-a-Group2009-11"></use>-->
                  <!--                  </svg>-->
                </div>
              </div>
            </div>
            <div class="ul">
              <div class="li" v-for="(item, index) in goodsLi" :key="index" @click.stop="godetails(item)">
                <div class="leftImgBox">
                  <img :src="$store.state.imageHost+item.images" alt="" class="img"/>
                </div>
                <div class="info">
                  <div class="brand">
                    <div class="brandBox">品牌</div>
                    {{ item.brand.name }}
                  </div>
                  <div class="name">{{ item.name }}</div>
                  <div class="detail">
                    <!--                    <div class="spacl">{{ item.spacl }}</div>-->
                    <div class="spacl">
                      规格:{{ item.goods_type_id == 6 ? item.nums + '张/套餐' : item.specifications }}
                    </div>
                    <div class="line"></div>
                    <div class="sale">已售{{ item.buy_count }}</div>
                  </div>

                  <div class="price_add">
                    <div class="leftPrice">
                      <div class="sellPrice">
                        <div>￥</div>
                        <div class="yuan">{{ item.yuan }}</div>
                        <div>.{{ item.mao }}</div>
                      </div>
                      <div class="lastPrice">￥{{ item.mktprice }}</div>
                    </div>
                    <div class="rightAdd">
                      <div class="unadd" v-if="item.cart_nums>0" @click.stop="unadd(item)"></div>
                      <div class="input" v-if="item.cart_nums>0">{{ item.cart_nums }}</div>
                      <div class="add" @click.stop="add(item)"></div>
                    </div>
                  </div>
                  <!--                  <div class="vipBox">-->
                  <!--                    <div class="vipPrice">￥{{ item.vipPr }}</div>-->
                  <!--                    <div class="">会员价</div>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="li" v-for="(item,index) in ticketLi" :key="index+item.id">
                <div class="leftImgBox">
                  <img :src="$store.state.imageHost+item.water_img" alt="" class="img"/>
                </div>
                <div class="info">
                  <div class="brand">
                    <div class="brandBox">品牌</div>
                    {{ item.brand.name }}
                  </div>
                  <div class="name">{{ item.water_name }}</div>
                  <div class="detail">
                    <div class="spacl">{{ item.spacl }}</div>
                    <div class="spacl">
                      规格:{{ item.goods_type_id == 6 ? item.package_nember + '张/套餐' : item.specifications }}
                    </div>
                    <div class="line"></div>
                    <div class="sale">已售{{ item.buy_count }}</div>
                  </div>

                  <div class="price_add">
                    <div class="leftPrice">
                      <div class="sellPrice">
                        <div>￥</div>
                        <div class="yuan">{{ item.yuan }}</div>
                        <div>.{{ item.mao }}</div>
                      </div>
                      <div class="lastPrice">￥{{ item.costprice }}</div>
                    </div>
                    <div class="rightAdd">
                      <div class="addBox" @click="buy(item)">
                        购买
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="vipBox">-->
                  <!--                    <div class="vipPrice">￥{{ item.vipPr }}</div>-->
                  <!--                    <div class="">会员价</div>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="noGoods" v-if="goodsLi.length==0&&ticketLi.length == 0">
                暂无商品
              </div>
            </div>
          </div>
        </div>
        <van-popup v-model="carshow" round position="bottom">
          <div class="popup">
            <div class="tioline"></div>
            <div class="title">
              <div class="left">购物车</div>
              <div class="right" @click="clearCar">清空</div>
            </div>
            <div class="carBox">
              <div class="item" v-for="(item, index) in cartLi" :key="index">

                <div class="leftImgBox">
                  <img :src="$store.state.imageHost+item.products.images" alt="" class="img">
                </div>
                <div class="rightInfo">
                  <div class="brand">
                    <div class="brandBox">品牌</div>
                    {{ item.products.brand.name }}
                  </div>
                  <div class="name">{{ item.products.name }}</div>
                  <div class="detail">
                    <div class="spacl">
                      规格:{{
                        item.products.goods_type_id == 6 ? item.products.nums + '张/套餐' : item.products.specifications
                      }}
                    </div>
                    <div class="line"></div>
                    <div class="sale">{{ item.sale }}</div>
                  </div>

                  <div class="price_add">
                    <div class="leftPrice">
                      <div class="sellPrice">
                        <div>￥</div>
                        <div class="yuan">{{ item.products.yuan }}</div>
                        <div>.{{ item.products.mao }}</div>
                      </div>
                      <div class="lastPrice">￥{{ item.products.mktprice }}</div>
                    </div>
                    <div class="rightAdd">
                      <div class="unadd" v-if="item.nums>0" @click.stop="unadd1(item)"></div>
                      <div class="input" v-if="item.nums>0">{{ item.nums }}</div>
                      <div class="add" @click.stop="add1(item)"></div>
                    </div>
                  </div>
                  <!--                  <div class="vipBox">-->
                  <!--                    <div class="vipPrice">￥{{ item.vipPr }}</div>-->
                  <!--                    <div class="">会员价</div>-->
                  <!--                  </div>-->
                </div>
                <!--                <svg class="icon" aria-hidden="true">-->
                <!--                  <use xlink:href="#icon-kefu"></use>-->
                <!--                </svg>-->
              </div>
            </div>
            <div class="bottom">
              <div class="bottomBigbox">
                <div class="left">
                  <div class="car">
<!--                    <img-->
<!--                        class="image busPos"-->
<!--                        src="https://picture.bjbjkj.top/img/ca.png"-->
<!--                        alt=""-->
<!--                    />-->
                    <img src="https://picture.bjbjkj.top/img/waterCar.png" alt="" class="image busPos">
                    <div class="nums" v-if="cartNums>0">{{ cartNums }}</div>
                  </div>
                  <div class="price">
                    ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
                  >
                  </div>
                </div>
                <div class="right">
                  <!-- <div class="songshui btnBox">一键送水</div> -->
                  <div class="zhifu btnBox" @click="submit">去支付</div>
                </div>
              </div>
            </div>

          </div>
        </van-popup>

        <div class="bottom" v-if="cartLi.length>=1">
          <div class="bottomBigbox">
            <div class="left">
              <div class="car" @click="opencar">
                <img src="https://picture.bjbjkj.top/img/waterCar.png" alt="" class="image busPos">
                <div class="nums" v-if="cartNums>0">{{ cartNums }}</div>
              </div>
              <div class="price">
                ￥<span>
                  <span class="yuan">{{ allPirce.yuan }}</span
                  ><span class="mao">.{{ allPirce.mao }}</span></span
              >
              </div>
            </div>
            <div class="right">
              <!-- <div class="songshui btnBox">一键送水</div> -->
              <div class="zhifu btnBox" @click="submit">去支付</div>
            </div>
          </div>
        </div>

      </div>
      <loding v-if="loding"></loding>
    </div>
  </div>
</template>

<script>
import navigationBar from "../../navigation-bar/navigation-bar.vue";
import loding from "@/components/common/loding/loding.vue";
import Vue from 'vue';
import {Dialog, Toast} from 'vant';
import {
  addCar,
  cart_del,
  get_cat_goods_list,
  get_cat_package_list,
  getAllCatlist,
  getCartListAll,
  // getFiveCus, kefu_list
} from "@/utils/api";
import {_getChatRoom} from "@/utils/tool"
import buyPail from "@/components/common/water/buyPail.vue";

// 全局注册
Vue.use(Dialog);
export default {
  components: {
    navigationBar,
    loding,
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {},
  data() {
    return {
      loding: false,
      commodityLList: [
        {name: "桶装水", img: ""},
        {name: "一次性包装水", img: ""},
        {name: "瓶装水", img: ""},
        {name: "Logo订制水", img: ""},
      ],
      leftLi: [
        "推荐",
        "活动",
        "热销",
        "恒大",
        "农夫山泉",
        "怡宝",
        "哇哈哈",
        "景田",
        "今麦郎",
      ],
      leftIndex: 0,
      topIndex: 0,
      goodsLi: [
        // {
        //   image:
        //       "https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg",
        //   brand: "农夫山泉",
        //   name: "农夫山泉矿泉水",
        //   spacl: "规格：555ml/瓶",
        //   sale: "已售35",
        //   yuan: "1",
        //   price: "1.80",
        //   mao: "80",
        //   oldPrice: "2.00",
        //   vipPr: "1.50",
        //   cart_nums: 0,
        // },
      ],

      ticketLi: [],
      container: null,
      cartLi: [],
      allPirce: {
        yuan: 0,
        mao: 0,
      },
      fixedFlag: false,
      stickyHieght: 0,
      vipFlag: false,
      carshow: false,
      sortType: 1,
    };
  },
  watch: {
    amount(n) {
      this.allPirce.yuan = n.toFixed(2).split(".")[0]
      this.allPirce.mao = n.toFixed(2).split(".")[1]
    },
    topIndex(n) {
      if (this.commodityLList[n].name != '桶装水') {
        this.ticketLi = []
      }
    },

    // cartNums
    // cartLi: {
    //   // handler(n) {
    //   //   // this.cartNums = n.length
    //   // },
    //   deep: true,
    // },
  },
  computed: {
    buyPail() {
      return buyPail
    },
    amount() {
      if (this.vipFlag) {
        return this.cartLi.reduce((total, item) =>
                total += item.products.price * item.nums
            , 0)
      } else {
        console.log(this.cartLi)
        return this.cartLi.reduce((total, item) =>
                total += item.products.price * item.nums
            , 0)
      }

    },
    cartNums() {
      return  this.cartLi.reduce((total, item) =>
              total += item.nums
          , 0)
    },
  },
  methods: {
    goOrderLi() {
      this.$router.push({
        name: "order_list",
        params: { id:'0', },
      })
    },
    kefu() {
      if (!this.$store.state.token) {
        Toast('您还未登录，请登录')
        return
      }
      //type 2 辅材 2 水站 3 超市 4 快修 5 房产
      _getChatRoom(2, 1).then(res => {
        if (res) {
          this.$router.push({
            name: 'chat_details',
          })
        }
      })
      // window.open(this.$router.resolve({name: 'chat_details'}).href, '_blank');
      // console.log('水站客服')
    },


    godetails(item) {
      this.$router.push({
        path: '/water_details',
        query:{goods:item.id}
      })
    },
    waterCoupon() {
      this.$router.push({name: 'water_coupon'})
    },
    sender() {
      this.$router.push({name: 'one_key_send_water'})
    },
    chooseTop(e) {
      if (this.topIndex == e) return;
      this.topIndex = e;
      this.leftIndex = 0
      this.leftLi = this.commodityLList[e].child
      this.loding = true
      this.updataGoods()
    },
    chooseLeft(e) {
      if (this.leftIndex == e) return;
      this.leftIndex = e;
      this.loding = true

      this.updataGoods()

    },
    opencar() {
      this.carshow = true
    },
    submit() {
      if (this.carshow) {
        this.$router.push({
          name: 'createOrderSecond',
          params: {list: this.cartLi, allPrice: this.allPirce},
        })
        this.$store.commit('updateWaterOrder', this.cartLi)
      } else {
        this.carshow = true
      }
    },
    stickchange(isFixed) {
      this.fixedFlag = isFixed
    },
    stickscroll() {
      // console.log(e)
    },
    add(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      if (item.cart_nums < item.sell_num) {
        nums = item.sell_num
      } else {
        nums = 1
      }
      addCar(this.$store.state.token, item.id, nums, 1, 1).then(res => {
        if (res.data.status == true) {
          item.cart_nums += nums
          console.log(item)
          this.getCart()
        } else {
          console.log(res)
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    unadd(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = (item.cart_nums - 1)
      if ((item.cart_nums <= item.sell_num) && item.sell_num < 1) {
        nums = item.sell_num
      } else {
        nums = (item.cart_nums - 1)
      }
      addCar(this.$store.state.token, item.id, nums, 2, 1).then(res => {
        if (res.data.status == true) {
          item.cart_nums -= 1
          this.getCart()
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)

        // Toast(err.data.data.msg)
      })
      // if (item.cart_nums <= 0) return;
      // if(item.cart_nums<=item.sell_num) {
      //   item.cart_nums -= item.sell_num
      // }else{
      //   item.cart_nums -= 1
      // }
    },
    add1(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      addCar(this.$store.state.token, item.product_id, nums, 1, 1).then(res => {
        if (res.data.status == true) {
          item.nums += nums
          let mapc = Object.fromEntries(this.cartLi.map(({product_id, nums}) => [product_id, nums]));
          this.goodsLi.forEach(it => {
            it.cart_nums = mapc[it.id] ?? 0
          })
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
        // Toast(err.data.msg)
      })
    },
    unadd1(item) {
      if (!this.$store.state.token) {
        Toast('请登录')
        return;
      }
      let nums = 1
      if (item.nums <= item.products.sell_num) {
        nums = item.products.sell_num
      } else {
        nums = 1
      }
      addCar(this.$store.state.token, item.product_id, nums, 2, 1).then(res => {
        if (res.data.status == true) {
          item.nums -= nums
          let mapc = Object.fromEntries(this.cartLi.map(({product_id, nums}) => [product_id, nums]));
          this.goodsLi.forEach(it => {
            it.cart_nums = mapc[it.id] ?? 0
          })
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
        // Toast(err.data.msg)
      })
    },

    clearCar() {
      Dialog.confirm({
        title: '确认清空购物车？',
      })
          .then(() => {
            // on confirm
            if (!this.$store.state.token) return;
            let goods_id = []
            if (this.goodsLi.length == 0) return
            this.cartLi.forEach(item => {
              goods_id.push(item.id)
            })
            goods_id = String(goods_id)

            cart_del(this.$store.state.token, goods_id).then(res => {
              if (res.data.status == true) {
                console.log(res.data.data)
                Toast(res.data.msg)
                this.updataGoods()
                this.carshow = false
                this.cartLi = []
              } else {
                Toast(res.data.msg)
              }
            }).catch(err => {
              console.log(err)
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    defaultS() {
      this.sortType = 1
      this.goodsLi1
      this.goodsLi = this.goodsLi.sort(this.compareAlign('id','inverted'))
      // this.goodsLi.sort(function (a, b) {return a.id - b.id;})
    },
    sale() {
      this.sortType = 2
      let list = JSON.parse(JSON.stringify(this.goodsLi))
      this.goodsLi = list.sort(this.compareAlign('buy_count','inverted'))
    },
    compareAlign(prop,align){
      return function(a,b){
        var value1=a[prop];
        var value2=b[prop];
        if(align=="positive"){//正序
          return new Date(value1)-new Date(value2);
        }else if(align=="inverted"){//倒序
          return new Date(value2)-new Date(value1);
        }
      }
    },
    price() {
      let list = JSON.parse(JSON.stringify(this.goodsLi))
      list.forEach(item=>{
        item.price = Number(item.price)
      })
      if (this.sortType == 3) {
        this.sortType = 4
        this.goodsLi = list.sort(this.compareAlign('price','positive'))
      } else {
        this.sortType = 3
        this.goodsLi = list.sort(this.compareAlign('price','inverted'))
      }
      console.log(this.goodsLi)
    },
    buy(item) {
      this.$router.push({
        name: 'createOrderFirst',
        params: {object: item},
      })
      // updateWaterOrder
    },


    async initList() {
      const {data} = await getAllCatlist(1)
      if (data.status == 1) {
        let list = data.data
        list = JSON.parse(JSON.stringify(list))
        list.forEach(item => {
          item.child.unshift({id: 0, name: '热销'})
        })
        // 头部li
        this.commodityLList = list
        // 左侧li
        this.leftLi = list[this.topIndex].child
        let goods_cat_id = this.leftLi[this.leftIndex].id == 0 ? this.commodityLList[this.topIndex].id : this.leftLi[this.leftIndex].id
        let is_hot = this.leftLi[this.leftIndex].id == 0 ? 1 : 2
        //获取商品
        const goodsData = await get_cat_goods_list(goods_cat_id, is_hot, this.$store.state.token)
        if (goodsData.data.status == true) {
          this.goodsLi = goodsData.data.data.data
          this.goodsLi.forEach((item => {
            item.yuan = item.price.split('.')[0]
            item.mao = item.price.split('.')[1]
          }))
        } else {
          Toast(goodsData.data.msg)
        }

        if (list[this.topIndex].name == '桶装水') {
          //获取水票
          const ticket = await get_cat_package_list(goods_cat_id, is_hot)
          if (ticket.data.status == true) {
            this.ticketLi = ticket.data.data.data
            this.ticketLi.forEach((item => {
              item.yuan = item.price.split('.')[0]
              item.mao = item.price.split('.')[1]
            }))
          } else {
            Toast(ticket.msg)
          }
        }


      } else {
        Toast(data.msg)
      }
    },
    async updataGoods() {
      this.goodsLi = []
      this.ticketLi = []

      let goods_cat_id = this.leftLi[this.leftIndex].id == 0 ? this.commodityLList[this.topIndex].id : this.leftLi[this.leftIndex].id
      let is_hot = this.leftLi[this.leftIndex].id == 0 ? 1 : 2
      const {data} = await get_cat_goods_list(goods_cat_id, is_hot, this.$store.state.token)
      if (data.status == true) {
        this.loding = false
        this.goodsLi = data.data.data
        this.goodsLi.forEach((item => {
          item.yuan = item.price.split('.')[0]
          item.mao = item.price.split('.')[1]
        }))
      } else {
        Toast(data.msg)
      }
      this.loding = false
      if (this.commodityLList[this.topIndex].name == '桶装水') {
        //获取水票
        const ticket = await get_cat_package_list(goods_cat_id, is_hot)
        // res.data.data
        if (ticket.data.status == true) {
          this.ticketLi = ticket.data.data.data
          this.ticketLi.forEach((item => {
            item.yuan = item.price.split('.')[0]
            item.mao = item.price.split('.')[1]
          }))
        } else {
          Toast(ticket.data.msg)
        }
      }

    },
    getCart() {
      if (this.$store.state.token) {
        getCartListAll(this.$store.state.token).then(res => {
          console.log(res.data.data.data)
          if (res.data.status == true) {
            this.cartLi = res.data.data.data.list
            this.cartLi.forEach(item => {
              item.products.yuan = item.products.price.split('.')[0]
              item.products.mao = item.products.price.split('.')[1]
            })
            console.log(this.cartLi)
          }
        })
      }
    },
  },
  created() {
  },
  mounted() {
    // console.error(this.$store.state.user)
    this.container = this.$refs.container;
    this.stickyHieght = this.$refs.sticky.offsetHeight
    // this.getAllCatlist()
    this.initList()

    this.getCart()
  },
};
</script>
<style lang="scss" scoped>
.noGoods {
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  color: #666666;
}

.popup {
  position: relative;
  padding: 20px 16px 0 16px;
  background: #fff;

  .carBox {
    max-height: 50vh;
    overflow: scroll;
    margin-top: 10px;
    padding-bottom: 70px;

    .item {
      display: flex;
    }

    .leftImgBox {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      overflow: hidden;

      .img {
        width: 100%;
        object-fit: cover;
      }
    }

    .rightInfo {
      flex: 1;
      padding-left: 10px;
      padding-bottom: 10px;

      .brand {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        text-overflow: ellipsis;
        align-items: center;
        font-size: 13px;
        color: #1B1B1B;
        line-height: 18px;

        .brandBox {
          font-weight: 500;
          font-size: 12px;
          color: #1b1b1b;
          line-height: 18px;
          padding: 0 4px;
          background: #e8e8e8;
          border-radius: 4px 4px 4px 4px;
          margin-right: 5px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #1b1b1b;
        line-height: 24px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        text-align: left;
      }

      .detail {
        display: flex;
        font-weight: 400;
        font-size: 12px;
        color: #7f7f7f;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;

        .line {
          margin: 0 6px;
          width: 1px;
          height: 9px;
          background: #dddddd;
        }
      }

      .price_add {
        display: flex;
        justify-content: space-between;
        margin-right: 16px;
        align-items: flex-end;
        font-weight: 500;

        .leftPrice {
          display: flex;
          //align-items: baseline;
          align-items: flex-end;

          .sellPrice {
            display: flex;
            font-size: 14px;
            color: #fd2a39;
            line-height: 18px;
            align-items: baseline;

            .yuan {

              font-size: 20px !important;
            }
          }

          .lastPrice {
            text-decoration-line: line-through;
            line-height: 18px;
            color: #7f7f7f;
            font-size: 12px;
          }
        }

        .rightAdd {
          display: flex;

          .unadd,
          .add {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #398ae9;
            position: relative;
          }

          .add::after,
          .unadd:after {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 10%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .add::before {
            content: "";
            display: block;
            position: absolute;
            transform: translate(-50%, -50%);
            width: 10%;
            height: 50%;
            background-color: white;
            top: 50%;
            left: 50%;
            border-radius: 100px;
          }

          .input {
            height: 22px;
            width: 22px;
            line-height: 22px;
            margin: 0 5px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #1b1b1b;
            white-space: nowrap;
            overflow: scroll;
          }
          .input::-webkit-scrollbar{
            display: none;
          }

          .addBox {
            background: #0085FF;
            border-radius: 5px 5px 5px 5px;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
            padding: 0 10px;
          }
        }
      }

      .vipBox {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        color: #392723;
        line-height: 18px;

        .vipPrice {
          margin-right: 5px;
        }
      }
    }
  }

  .carBox::-webkit-scrollbar {
    display: none;
  }

  .tioline {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 10%;
    height: 5px;
    background: #0085ff;
    transform: translate(-50%, -0%);
    border-radius: 25px;
  }

  .title {
    display: flex;
    justify-content: space-between;
  }
}

.fixed1 {
  position: fixed;
}

.nivaBar {
  position: relative;
  z-index: 2;
  color: #fff;

  .navBox {
    width: 85%;
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 18px;

    .right {
      position: absolute;
      right: 0;

      .iconfont {
        font-size: 24px;
        margin-left: 10px;
      }
    }
  }
}

.topImg {
  position: absolute;
  top: 0;
  right: 0;
  height: 278px;
  width: 100%;
}

.areaOne {
  margin: 0 16px;

  .function {
    display: flex;
    justify-content: space-between;

    .left,
    .right {
      width: calc(45% - 10px);
      background-repeat: no-repeat;
      background-size: 35%;
      padding: 10px 10px 14px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: white;
      background-position: 100% 100%;
      overflow: hidden;
      position: relative;
      border-radius: 10px;

      .title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #3263ac;

        .iconfont {
          font-size: 25px;
        }
      }

      .detailsText {
        font-size: 12px;
        text-align: left;
        color: #83a2ca;
        line-height: 18px;
        margin-top: 4px;
      }

      .line {
        position: absolute;
        background: linear-gradient(
                270deg,
                #c0e0ff 0%,
                rgba(229, 240, 255, 0) 100%
        );
        width: 50%;
        height: 12px;
        bottom: 0;
        right: 0;
      }
    }

    .right {
      background-image: url("https://bjsf.bjsky.top/img/ba50c8c946924b522a5979e2b54a6b9@2x.png");
    }
  }
}

.outoverFlow {
  //   background: red;
  position: relative;
  z-index: 2;
  //margin: 15px 16px 0 16px;
  padding: 15px 16px 0 16px;

  .innerScroll {
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: scroll;

    .li {
      // flex-direction: column;
      display: inline-block;
      width: 25%;
      text-align: center;
      margin-right: 10px;

      .imgBox {
        width: 74px;
        height: 74px;
        background: #fff;
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid transparent;

        .img {
          width: 80%;
          height: 80%;
        }
      }

      .name {
        font-weight: 400;
        font-size: 13px;
        color: #1b1b1b;
        line-height: 15px;
        text-align: center;
        margin-top: 5px;
        white-space: nowrap;
      }
    }

    .li:nth-last-of-type(1) {
      margin-right: 0px;
    }

    .chooseTop {
      .imgBox {
        border: 2px solid #0085ff;
      }
    }
  }

  .innerScroll::-webkit-scrollbar {
    display: none;
  }
}

.body {
  position: relative;
  z-index: 2;
  display: flex;
  margin-top: 10px;
  padding-bottom: 100px;
  background: #fff;

  .left {
    width: 25%;
    background: #fff;
    max-width: 170px;
    .li {
      font-weight: 400;
      font-size: 13px;
      color: #1b1b1b;
      line-height: 24px;
      padding: 14px 0;
      background: #fafafa;
    }

    .chooseLeft {
      background: #fff;
      font-size: 14px;
      color: #0085ff;
    }
  }

  .right {
    width: 75%;
    padding-left: 10px;

    .top {
      margin-right: 24px;
      display: flex;
      justify-content: space-between;
      //padding: 14px 0;
      font-weight: 400;
      font-size: 13px;
      color: #7f7f7f;
      line-height: 24px;
      align-items: center;
      padding: 14px 0;

      .leftSort {
        display: flex;
        align-items: center;

        .iconfont {
          font-size: 20px;
        }
      }

      .defaultS {
        color: #0085FF;
      }

      .rightSort {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .saleS {
          color: #0085FF;
        }

        .priceS3 {
          color: #0085FF !important;

          .icon-Vector {
            color: #0085FF !important;
          }
        }

        .priceS4 {
          color: #0085FF !important;

          .icon-a-Group2009 {
            color: #0085FF !important;
          }
        }

        .iconfont {
          font-size: 25px;
        }

        .sale {
          margin-right: 20px;
        }

        .priceSort {
          display: flex;
          align-items: center;

          .iconfontBox {
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 10px;
              margin-right: -5px;
              display: flex;
              color: #7f7f7f;
            }
          }
        }
      }
    }

    .ul {
      .li {
        display: flex;
        padding-top: 10px;

        .leftImgBox {
          display: flex;

          .img {
            width: 75px;
            height: 75px;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .info {
          width: 90%;
          border-bottom: 1px solid #e8e8e8;
          padding-left: 10px;
          padding-bottom: 10px;

          .brand {
            display: flex;
            width: 100%;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            font-weight: 500;
            font-size: 13px;
            color: #1b1b1b;
            line-height: 18px;

            .brandBox {
              font-weight: 500;
              font-size: 12px;
              color: #1b1b1b;
              line-height: 18px;
              padding: 0 4px;
              background: #e8e8e8;
              border-radius: 4px 4px 4px 4px;
              margin-right: 5px;
            }
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            color: #1b1b1b;
            line-height: 24px;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            text-align: left;
          }

          .detail {
            display: flex;
            font-weight: 400;
            font-size: 12px;
            color: #7f7f7f;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;

            .line {
              margin: 0 6px;
              width: 1px;
              height: 9px;
              background: #dddddd;
            }
          }

          .price_add {
            display: flex;
            justify-content: space-between;
            margin-right: 16px;
            align-items: flex-end;
            font-weight: 500;

            .leftPrice {
              display: flex;
              align-items: baseline;
              align-items: flex-end;

              .sellPrice {
                display: flex;
                font-size: 14px;
                color: #fd2a39;
                line-height: 18px;

                align-items: baseline;

                .yuan {
                  font-size: 20px !important;
                }
              }

              .lastPrice {
                text-decoration-line: line-through;
                line-height: 18px;
                color: #7f7f7f;
                font-size: 12px;
              }
            }

            .rightAdd {
              display: flex;

              .unadd,
              .add {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: #398ae9;
                position: relative;
              }

              .add::after,
              .unadd:after {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 10%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .add::before {
                content: "";
                display: block;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 10%;
                height: 50%;
                background-color: white;
                top: 50%;
                left: 50%;
                border-radius: 100px;
              }

              .input {
                height: 22px;
                width: 22px;
                line-height: 22px;
                margin: 0 5px;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                color: #1b1b1b;
                white-space: nowrap;
                overflow: scroll;
              }
              .input::-webkit-scrollbar{
                display: none;
              }

              .addBox {
                background: #0085FF;
                border-radius: 5px 5px 5px 5px;
                font-weight: 500;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 24px;
                text-align: center;
                padding: 0 10px;
              }
            }
          }

          .vipBox {
            display: flex;
            font-weight: 600;
            font-size: 14px;
            color: #392723;
            line-height: 18px;

            .vipPrice {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px -1px 2px 0px rgba(153, 153, 153, 0.15);

  z-index: 10;
}

.bottomBigbox {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .car {
      width: 72rpx;
      position: relative;
      display: flex;
      align-items: center;
    }

    .price {
      margin-left: 10rpx;
      font-size: 14px;
    }

    .yuan {
      font-size: 24px;
    }

    .image {
      width: 36px;
      max-height: 36px;
    }

    .nums {
      position: absolute;
      top: 0;
      right: 0;
      background-color: red;
      color: white;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      text-align: center;
      line-height: 14px;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .btnBox {
      padding: 10px 24px;
      border-radius: 32px;
      color: white;
      font-size: 14px;
      background: #0085FF;
    }
  }
}

.price {
  color: #fd2a39;
}

.container {
  height: 100vh;
  position: relative;
}

</style>