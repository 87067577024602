<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {Company_list, update_company_acquiesce} from "@/utils/api";
import {Dialog, Toast} from "vant";

export default {
  name: "corporation_list",
  components: {navigationBar},
  data(){
    return{
      list:[],
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    getList(){
      Company_list(this.$store.state.token).then(res=>{
        if(res.data.status==true){
          console.log(res.data.data)
          let list = res.data.data.data
          this.list = list
        }else{
          Toast(res.data.msg)
        }
      }).catch(er=>{
        console.log(er)
      })
    },

    checkCorporation(item){
      console.log(item)
      if(item.acquiesce == 1){
        return
      }

      Dialog.alert({
        title: '确定切换公司吗？',
        showCancelButton:true,
        confirmButtonText:'确定',
      }).then(()=>{
        update_company_acquiesce(this.$store.state.token,item.id).then(res=>{
          console.log(res)
          if(res.data.status==true){
            Toast('切换成功')
            this.$store.commit('updatenowCompany',item)
          }
          Toast(res.data.msg)

        }).catch(err=>{
          console.log(err)
        })
      }).catch(()=>{

      })

    },
  }
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="'我的公司'" class="navigationBar"></navigationBar>
    <div class="ul">
      <div class="li" v-for="(item,index) in list" :key="index" @click="checkCorporation(item)">
        <div class="in">
          <div class="name">{{item.name}}</div>
          <div class="tag" v-if="item.audit_state == 1">已认证</div>
          <span class="iconfont icon-a-Group20093"></span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.container{
  background: #f6f6f6;
  height:100vh;
  .area{
    background: #f6f6f6;
    height:100vh;
  }
}
.navigationBar{
  background: #fff;
}
.ul{

  .li{
    border-radius: 10px;
    background: #ffffff;
    margin: 10px 16px;
    padding: 10px;
    color: #1a1a1a;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    .in{
      display: flex;
      position: relative;
      .name{
        font-size: 16px;
        max-width: 75%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 10px;
      }
      .tag{
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        flex-shrink: 0;
        padding: 2px 6px;
        border-radius: 5px;
        border: 1px solid #0393f5;
        color: #0393f5;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
      }
      .iconfont{
        transform: rotate(90deg);
        font-size: 30px;
        color: #0393f5;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

</style>