<template>
  <div class="app" :class="!backBtn?'noback':''">
    <div class="backBtn"  v-if="backBtn">
      <span class="iconfont icon-Frame-4" @click="backBnt"></span>
    </div>
    <div class="title" v-if="title">
      {{title}}
    </div>
    <slot name="search"></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title:{type:[String]},
    backBtn:{type:[Boolean],default:true},
    particular:{type:[String,Number],default:1},
    backStep:{type:[Number],default:1}
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
    backBnt(){
      // console.log('推出');
      if(this.particular == 1){
        this.$router.go(-this.backStep)
        // window.history.back(-2)
      }else{
        //2 房产返回首页
        // console.log(this.particular);
        this.$router.push('/home')
      }
    },
  },
  created() {},
  mounted() {}
};
</script>
<style lang="scss" scoped>
.app{
  background:transition!important;
  display:flex;
  width:100%;
  align-items: center;
  padding: 20px 0;
  .title{
    width: 80%;
    font-size: 18px;
    line-height: 24px;
  }
  .backBtn{
    width: 10%;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont{
      font-size: 36px;
    }
  }
}
.noback{
  .title{
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }
}
</style>