<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {
  change_list_index,
  change_order_confirm,
  get_authority_OrderList_index,
  order_kt_goods, order_take_goods,
  refund_water, reship_list_index, reship_order_confirm,
  sub_verification_order_id,
  tuikuan_kt,
  upload_file
} from "@/utils/api";
import Compressor from "compressorjs";
import {Toast} from "vant";

export default {
  name: "order_details",
  components: {NavigationBar},
  data() {
    return {
      obj: {},
      fileLimit: 1,//图片最大上传数量
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg",],
      fileList: [],
      uploadFunLi: [
        {name: '订单完成照片', image: '', show: true},
        {name: '回收空桶照片', image: '', show: false},
        {name: '自有空桶照片', image: '', show: false},
        {name: '退水照片', image: '', show: false},
      ],
      huiNums: 0,//
      tuiNums: 0,
      songshuiNums: 0,
      buNums: 0,
      heNums: 0,
      tongNums: 0,
      tuihuo: 0,
      shijituiNums: 0,
      goodsType: -1,//1桶装水 2通用商品 3桶押金  4送水',5定制水,
      bottomFlag: false,
      shouhouIndex: -1,
      type: -1,
      sendingFlag: true,
      authority_remark: '',
    }
  },
  methods: {
    unadd(item) {
      console.log(item)
      let type = item.item
      if (type == 'song') {
        if (this.songshuiNums <= 0) {
          return
        }
        this.songshuiNums -= 1
      }
      if (type == 'tui') {
        if (this.tuiNums <= 0) {
          return
        }
        this.tuiNums -= 1
        this.obj.fourImgUrl = ''
      }
      if (type == 'hui') {
        if ((this.huiNums) <= 0) {
          this.obj.twoImgUrl = ''
          return
        }
        this.huiNums -= 1
      }
      if (type == 'he') {
        if (this.heNums <= 0) {
          return
        }
        this.heNums -= 1
        this.obj.kt_list.nums = this.heNums
      }
      if (type == 'tong') {
        if (this.tongNums <= 0) {
          return
        }
        this.tongNums -= 1
      }
      if (type == 'tuihuishiji') {
        if (this.shijituiNums <= 0) {
          return
        }
        this.shijituiNums -= 1
        if (this.tongNums <= 0) {
          return
        } else {
          this.tongNums -= 1
        }
      }
    },
    add(item) {
      let type = item.item
      if (type == 'song') {
        this.songshuiNums += 1
      }
      if (type == 'tui') {
        if (this.tuiNums >= this.songshuiNums) {
          return
        }
        this.tuiNums += 1
      }
      if (type == 'hui') {
        if (this.huiNums >= this.obj.user.now_water_quantity) {
          return
        }
        this.huiNums += 1
      }
      if (type == 'he') {
        this.heNums += 1
        this.obj.kt_list.nums = this.heNums
      }
      if (type == 'tong') {
        if (this.tongNums >= this.obj.items[0].nums) {
          return
        }
        if (this.tongNums >= this.obj.items[0].nums - ((this.obj.user.now_water_quantity >= this.obj.items[0].nums ? this.obj.items[0].nums : this.obj.user.now_water_quantity + this.obj.now_water_quantity_frozen) - this.shijituiNums)) {
          return
        }
        this.tongNums += 1
      }
      if (type == 'tuihuishiji') {
        if (this.shijituiNums >= (this.obj.user.now_water_quantity >= this.obj.items[0].nums ? this.obj.items[0].nums : this.obj.user.now_water_quantity + this.obj.now_water_quantity_frozen)) {
          return
        }

        this.shijituiNums += 1
        if (this.tongNums >= this.obj.items[0].nums) {
          return
        } else {
          this.tongNums += 1
        }
      }
    },
    async handleRemove() {
    },
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    fileChange(file, fileList) {
      this.fileList = fileList;
    },
    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },

    async beforeUpload(files) {
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },

    uploadFile(item) {
      console.log(item)
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            this.fileList.splice(0, 1); //删除上传的文件
            item.data.data.image = res.data.data.saveurl
            console.log(res.data.data)
          }
        })
      }
      // else if (item.file.type.startsWith('video/')) {
      //   upload_files(item.file).then(res => {
      //     if (res.data.status == true) {
      //       this.$message.close()
      //       this.$message.success("上传成功");
      //       console.log(res.data.data)
      //     }
      //   })
      // }
    },
    imageIsShow() {
      if (this.huiNums >= 1 && !this.obj.kt_list && !this.obj.aftersales_id) {
        this.uploadFunLi[1].show = true
      }
      if (this.obj.kt_list) {
        this.uploadFunLi[2].show = true
      }
      if (this.tuiNums >= 1 || this.tuihuo > 0) {
        this.uploadFunLi[3].show = true
      }

    },
    initObj(id) {
      console.log(id)
      if (this.shouhouIndex == 0) {
        get_authority_OrderList_index(this.$store.state.token, id).then(res => {
          if (res.data.status == true) {
            let obj = res.data.data;
            let nums = 0
            let tuihuo = 0
            obj.items.forEach(item => {
              if (item.goods_type_id == 1 || item.goods_type_id == 4) {
                nums += item.nums
                tuihuo += item.reship_nums
              }
            })
            obj.allums = nums
            if (obj.service_time.split(',')[1]) {
              obj.service_time1 = obj.service_time.split(',')[0] + '~' + obj.service_time.split(',')[1].split(' ')[1]
            }
            this.goodsType = obj.items.findIndex(e => {
              return e.goods_type_id == 1 || e.goods_type_id == 4
            })
            if (this.type == 1) {
              if (this.shouhouIndex == 0) {
                this.songshuiNums = nums
                this.huiNums = nums
                this.bottomFlag = obj.items.some(e => {
                  return e.goods_type_id != 2 || e.goods_type_id != 5
                })
                this.buNums = this.replenishment ?
                    this.replenishment :
                     (this.$store.state.user.kt_pledge - obj.kt_yj) >= 0 ?
                        this.$store.state.user.kt_pledge - obj.kt_yj : 0
                this.tongNums = obj.items[0].nums
                this.heNums = this.$store.state.user.my_kt_ktpledge
                this.tuihuo = tuihuo
                this.shijituiNums = obj.user.now_water_quantity >= obj.items[0].nums ?
                    obj.items[0].nums :
                    this.$store.state.user.now_water_quantity + obj.now_water_quantity_frozen

              }
              if (!obj.user) {
                obj.user = {}
                obj.user.now_water_quantity = obj.now_water_quantity
              }
              this.huiNums = obj.user.now_water_quantity > (nums - tuihuo) ? nums - tuihuo : obj.user.now_water_quantity
            } else {
              if (obj.authority_confirm_img) {
                this.uploadFunLi[0].image = obj.authority_confirm_img
              }
              if (obj.img) {
                this.uploadFunLi[0].image = obj.img
              }
              if (obj.confirm_kt_img) {
                this.uploadFunLi[1].image = obj.confirm_kt_img
              }
              if (obj.my_kt_img) {
                this.uploadFunLi[2].image = obj.my_kt_img
              }
              if (obj.refund_img) {
                this.uploadFunLi[3].image = obj.refund_img
              }
              this.buNums = obj.replenishment
              this.songshuiNums = nums - obj.refund_water
              this.tongNums = obj.items[0].nums
              this.authority_remark = obj.authority_remark
              this.heNums = obj.kt_list ? obj.kt_list.nums : 0
              this.tuihuo = tuihuo
              if (this.shouhouIndex == 1) {
                obj.authority_remark = obj.memo
              }


            }
            this.obj = obj
            console.log(this.obj)
            this.imageIsShow()

          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (this.shouhouIndex == 1) {
        reship_list_index(this.$store.state.token, id).then(res => {
          if (res.data.status == true) {
            let obj = res.data.data;

            obj.ship_address = obj.user.ship_address
            obj.ship_address_ds = obj.user.ship_address_ds
            obj.ship_address_ys = obj.user.ship_address_ys
            obj.ship_mobile = obj.user.ship_mobile
            obj.ship_name = obj.user.ship_name
            obj.ship_status = obj.user.ship_status

            console.log(obj.user.ship_name)

            this.obj = obj

          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      if (this.shouhouIndex == 2) {
        change_list_index(this.$store.state.token, id).then(res => {
          if (res.data.status == true) {
            let obj = res.data.data;

            obj.ship_address = obj.user.ship_address
            obj.ship_address_ds = obj.user.ship_address_ds
            obj.ship_address_ys = obj.user.ship_address_ys
            obj.ship_mobile = obj.user.ship_mobile
            obj.ship_name = obj.user.ship_name
            obj.ship_status = obj.user.ship_status
            console.log(obj.items.findIndex(e => {
              return e.goods_type_id == 1 || e.goods_type_id == 4
            }))
            console.log(obj)
            this.obj = obj
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      // get_authority_OrderList_index(this.$store.state.token, '072516185314354').then(res => {

    },

    sendIng() {
      console.log(this.sendingFlag)
      if (!this.sendingFlag) {
        return
      }
      let data = {
        token: this.$store.state.token,
        order_id: this.obj.order_id,
        authority_confirm_img: this.uploadFunLi[0].image ? this.uploadFunLi[0].image : '',
        confirm_kt_img: this.uploadFunLi[1].image ? this.uploadFunLi[1].image : '',
        replenishment: this.buNums,
        my_kt_img: this.uploadFunLi[2].image ? this.uploadFunLi[2].image : '',
        refund_img: this.uploadFunLi[3].image ? this.uploadFunLi[3].image : '',
        authority_remark: this.authority_remark,
        refund_water: this.tuiNums,
        huitong: (this.obj.kt_list) ? 0 : this.huiNums,
        songsui_count: this.songshuiNums - this.tuihuo,
      }
      console.log(data)

      if (!this.obj.aftersales_id) {
        // 送货单子
        if (this.obj.order_type == 11) {
          //空桶单子
          if (!this.uploadFunLi[0].image) {
            Toast('上传空桶照片')
            return;
          }
          // 退桶
          data = {
            token: this.$store.state.token,
            order_id: this.obj.order_id,
            confirm_kt_img: this.uploadFunLi[1].image ? this.uploadFunLi[1].image : '',
            nums: this.tongNums,
            authority_remark: this.authority_remark,
            real_nums: this.shijituiNums
          }

          order_kt_goods([...data]).then(res => {
            if (res.data.status == true) {
              Toast(res.data.msg)
              tuikuan_kt(this.$store.state.token, this.tongNums, this.obj.user.id).then(res => {
                console.log(res)
                this.sendingFlag = true
                this.$router.go(-1)
              }).catch(err => {
                console.log(err)
              })
            } else {
              Toast(res.data.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          // 正常
          if (!this.uploadFunLi[0].image) {
            Toast('上传完成照片')
            return;
          }
          if (this.obj.kt_list) {
            if (!this.uploadFunLi[2].image) {
              Toast('上传自有空桶照片')
              return;
            }
          }
          if (this.tuiNums >= 1) {
            if (!this.uploadFunLi[3].image) {
              Toast('请上传退水照片')
              return;
            }
            refund_water(this.$store.state.token, this.tuiNums, this.obj.order_id)
          }

          if (this.obj.kt_list) {
            //退桶
            sub_verification_order_id(this.$store.state.token, this.obj.kt_list.order_id, this.obj.kt_list.nums).then(res => {
              console.log(res)
              Toast(res.data.msg)
              this.$router.go(-1)
            }).catch(err => {
              console.log(err)
            })
          } else {
            if (this.huiNums >= 1 && !this.obj.kt_list && !this.obj.aftersales_id) {
              if (!this.uploadFunLi[1].image) {
                Toast('上传回收空桶照片')
                return
              }
            }
            this.sendingFlag = false
            order_take_goods(data.token,
                data.order_id,
                data.authority_confirm_img,
                data.confirm_kt_img,
                data.replenishment,
                data.my_kt_img,
                data.refund_img,
                data.authority_remark,
                data.refund_water,
                data.huitong,
                data.songsui_count).then(res => {
              this.sendingFlag = true
              if (res.data.status == true) {
                Toast(res.data.msg)
                this.$router.go(-1)
              } else {
                Toast(res.data.msg)
              }
            }).catch(err => {
              console.log(err)
            })
          }


        }

      } else {
        // 售后单子
        if (!this.uploadFunLi[0].image) {
          Toast('上传完成照片')
          return;
        }
        if (this.obj.reship_id) {
          // token, reship_id, img, Remarks,
          this.sendingFlag = false
          reship_order_confirm(this.$store.state.token, this.obj.reship_id, this.uploadFunLi[0].image, this.authority_remark).then(res => {
            this.sendingFlag = true
            if (res.data.status == true) {
              Toast('收货成功')
              this.$router.go(-1)
            } else {
              Toast(res.data.msg)
            }
          }).catch(err => {
            this.sendingFlag = true
            console.log(err)
          })

        } else if (this.obj.change_id) {
          // token, change_id, img, Remarks,
          this.sendingFlag = false
          change_order_confirm(this.$store.state.token, this.obj.change_id, this.uploadFunLi[0].image, this.authority_remark).then(res => {
            this.sendingFlag = true
            if (res.data.status == true) {
              Toast('收货成功')
              this.$router.go(-1)
            } else {
              Toast(res.data.msg)
            }
          }).catch(err => {
            this.sendingFlag = true
            console.log(err)
          })
        }

      }


      console.log(data)
    },
  },
  mounted() {
    this.type = this.$route.query.action
    this.shouhouIndex = this.$route.query.order_type
    this.initObj(this.$route.query.order_id)
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'订单详情'" class="navigation"></navigationBar>
      <div class="card topInfo">
        <div class="orderNumber">
          <div class="leftOrder">
            订单编号：{{ shouhouIndex == 0 ? obj.order_id : shouhouIndex == 1 ? obj.aftersales_id : obj.change_id }}
          </div>
          <a :href="'tel:'+obj.ship_mobile" class="telA">
            <div class="iconfont icon-Frame14"></div>
          </a>
        </div>
        <div class="userame infoItem">
          <div class="left">用户信息：</div>
          <div class="right">
            {{ obj.ship_name }} {{ obj.ship_mobile }}
          </div>
        </div>
        <div class="address infoItem">
          <div class="left">送单地址：</div>
          <div class="right">
            {{ obj.ship_address_ds }}{{ obj.ship_address }}
          </div>
        </div>
        <div class="userame infoItem">
          <div class="left">预约时间：</div>
          <div class="right">
            {{ obj.service_time1 }}
          </div>
        </div>
        <div class="goodsLi">
          <div class="goodsNumsTitle" v-if="obj.items">
            {{ obj.items.length > 1 ? `${obj.allums}件商品` : `` }}
          </div>
          <div class="goodsItemBox">
            <div class="items" v-for="(item,index) in obj.items" :key="index">
              <div class="leftImg">
                <img :src="$store.state.imageHost+item.image_url" class="image" alt="">
              </div>
              <div class="rightInfo">
                <div class="title"><span class="text">{{ item.name }}</span>
                  <div class="nums">x{{ item.nums }}</div>
                </div>
                <div class="specifications">规格：{{ item.specifications }}</div>
                <div class="unit">单位：{{ item.danwei }}</div>
                <div class="nums" style="color: rgb(255, 34, 34);" v-if="item.reship_nums!=0&&item.reship_nums">
                  退货：{{ item.reship_nums }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <!--      v-if="(!obj.aftersales_id&&!obj.change_id)&&obj.remark"-->
        <div class="remark" v-if="(shouhouIndex==0)&&obj.remark">
          客户备注：<span class="text">{{ obj.remark }}</span>
        </div>
        <div class="remark" v-if="(shouhouIndex!=0)&&obj.reason">
          客户备注：<span class="text">{{ obj.reason }}</span>
        </div>
      </div>

      <div class="img_box" v-if="type == 1">
        <div class="uploadItem" v-for="(item,index) in uploadFunLi" :key="index">
          <div v-if="item.show">
            <el-upload
                class="avatar-uploader"
                action
                :http-request="uploadFile"
                ref="upload"
                :limit="fileLimit"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :headers="headers"
                :on-change="fileChange"
                multiple
                :data="{ index: index, data: item }"
            >
              <div v-if="item.image">
                <el-image
                    style="width: 75px; height: 75px"
                    :src="$store.state.imageHost+item.image"
                    fit="cover"
                ></el-image>
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div class="el-upload__text">
                {{ index == 0 ? obj.order_type == 11 ? '空桶数量照片' : item.name : item.name }}
              </div>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="img_box" v-if="type == 2">
        <div class="" v-for="(item,index) in uploadFunLi" :key="index">
          <div v-if="item.image" class="uploadItem">
            <div
                class="avatar-uploader"
                action
                :http-request="uploadFile"
                ref="upload"
                :limit="fileLimit"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :show-file-list="false"
                :headers="headers"
                :on-change="fileChange"
                multiple
                :data="{ index: index, data: item }"
            >
              <div v-if="item.image">
                <el-image
                    style="width: 75px; height: 75px"
                    :src="$store.state.imageHost+item.image"
                    fit="cover"
                ></el-image>
              </div>
              <div class="el-upload__text">
                {{ index == 0 ? obj.order_type == 11 ? '空桶数量照片' : item.name : item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="userKtInfo card" v-if="goodsType!=-1&&!obj.aftersales_id">
        <div class="every">
          用户空桶总量
          <span class="nums" v-if="obj.user">{{
              obj.tk_yj + (obj.user.my_kt_ktpledge ? obj.user.my_kt_ktpledge : obj.user.my_kt_ktpledge)
                  ? obj.tk_yj + (obj.user.my_kt_ktpledge ? obj.user.my_kt_ktpledge : obj.user.my_kt_ktpledge)
                  : 0
            }}</span>
          桶
        </div>
        <div class="every">用户已购空桶<span class="nums">{{ obj.tk_yj }}</span>桶</div>
        <div class="every" v-if="obj.user">用户自有空桶<span
            class="nums">{{
            obj.user.my_kt_ktpledge ? obj.user.my_kt_ktpledge : obj.my_kt_ktpledge ? obj.my_kt_ktpledge : 0
          }}</span>桶
        </div>
        <div class="every" v-if="obj.user">用户应有空桶<span class="nums">{{ obj.user.now_water_quantity }}</span>桶
        </div>
        <div class="every" v-if="obj.user">应回收空桶数<span class="nums nums1">{{
            obj.user.now_water_quantity < (songshuiNums - tuihuo) ? obj.user.now_water_quantity : songshuiNums - tuihuo
          }}</span>桶
        </div>
      </div>
      <div class="card userTongInfo" v-if="bottomFlag">
        <div class="line" v-if="obj.order_type!=11&&goodsType!=-1">
          <div class="left">送水数量</div>
          <div class="right">
            <div class="fontBox noadd">
              <div class="nums">{{ songshuiNums - tuihuo }}</div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.items[0].goods_type_id!=3&&obj.order_type==3&&goodsType!=-1">
          <div class="left">退水数量</div>
          <div class="right">
            <div class="fontBox">
              <div class="unadd" @click="unadd({item:'tui'})"></div>
              <div class="nums">{{ tuiNums }}</div>
              <div class="add" @click="add({item:'tui'})"></div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.items[0].goods_type_id!=3&&obj.order_type!=11&&!obj.kt_list&&goodsType!=-1">
          <div class="left">实际回桶</div>
          <div class="right">
            <div class="fontBox" :class="obj.kt_list?'noadd':''">
              <div class="unadd" @click="unadd({item:'hui'})" v-if="!obj.kt_list"></div>
              <div class="nums">{{ huiNums }}</div>
              <div class="add" @click="add({item:'hui'})" v-if="!obj.kt_list"></div>
              桶
            </div>
          </div>
        </div>
        <div class="line " v-if="obj.items[0].goods_type_id!=3&&obj.order_type!=11&&goodsType!=-1">
          <div class="left">补桶数量</div>
          <div class="right">
            <div class="fontBox noadd">
              <div class="nums">{{ buNums }}</div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.kt_list">
          <div class="left">核验空桶</div>
          <div class="right">
            <div class="fontBox">
              <div class="unadd" @click="unadd({item:'he'})"></div>
              <div class="nums">{{ heNums }}</div>
              <div class="add" @click="add({item:'he'})"></div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.items[0].goods_type_id==3&&obj.order_type==11">
          <div class="left">桶押金退回数量</div>
          <div class="right">
            <div class="fontBox">
              <div class="unadd" @click="unadd({item:'tong'})"></div>
              <div class="nums">{{ tongNums }}</div>
              <div class="add" @click="add({item:'tong'})"></div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.items[0].goods_type_id==3&&obj.order_type==11">
          <div class="left">实际退回空桶数量</div>
          <div class="right">
            <div class="fontBox">
              <div class="unadd" @click="unadd({item:'tuihuishiji'})"></div>
              <div class="nums">{{ shijituiNums }}</div>
              <div class="add" @click="add({item:'tuihuishiji'})"></div>
              桶
            </div>
          </div>
        </div>
        <div v-if="obj.items[0].goods_type_id==3&&obj.order_type==11"
             style="display: flex;box-shadow: none;width: 100%;padding: 0rpx;margin-bottom: 0rpx;" class="userKtInfo">
          <div class="every">用户应有空桶<span
              class="nums">{{ obj.user.now_water_quantity + obj.now_water_quantity_frozen }}</span>桶
          </div>
          <div class="every">应回收空桶数<span
              class="nums nums1">{{
              obj.user.now_water_quantity >= obj.items[0].nums ? obj.items[0].nums : obj.user.now_water_quantity + obj.now_water_quantity_frozen
            }}</span>桶
          </div>
        </div>
      </div>
      <!--      type==2 && !obj.img-->
      <div class="userTongInfo card" v-if="type==2 && !obj.img">
        <div class="line" v-if="obj.order_type!=11&&goodsType!=-1">
          <div class="left">送水数量</div>
          <div class="right">
            <div class="fontBox noadd">
              <div class="ziyouKtNum">{{ songshuiNums - tuihuo }}</div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.order_type==3&&goodsType!=-1">
          <div class="left">退水数量</div>
          <div class="right">
            <div class="fontBox noadd">
              <div class="ziyouKtNum">{{ obj.refund_water ? obj.refund_water : 0 }}</div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.order_type!=11&&goodsType!=-1">
          <div class="left">补桶数量</div>
          <div class="right">
            <div class="fontBox noadd">
              <div class="ziyouKtNum">{{ buNums > 0 ? buNums : 0 }}</div>
              桶
            </div>
          </div>
        </div>
        <div class="line" v-if="obj.order_type!=11&&obj.kt_list">
          <div class="left">核验空桶</div>
          <div class="right">
            <div class="fontBox noadd">
              <div class="ziyouKtNum">{{ heNums }}</div>
              桶
            </div>
          </div>
        </div>
        <div class="line haveBtn" v-if="obj.items&&obj.items[0].goods_type_id==3&&obj.order_type==11">
          <div class="left">桶押金退回数量</div>
          <div class="right">
            <div class="fontBox noadd">
              <div class="ziyouKtNum">{{ tongNums }}</div>
              桶
            </div>
          </div>
        </div>
      </div>

      <div class="yuangongRemark" :class="obj.aftersales_id?'tuihuan':''" v-if="type == 1">
        备注: <textarea name="" id="" cols="30" rows="10" v-model="authority_remark" class="textarea"
                        maxlength="300"></textarea>
      </div>
      <div class="remark" :class="obj.aftersales_id?'tuihuan':''" v-if="type == 2">
        备注:{{ obj.authority_remark ? obj.authority_remark : '' }}
      </div>
      <div class="bottombutton" @click="sendIng" v-if="type == 1">配送完成</div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.topInfo {


  .orderNumber {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px;

    .leftOrder {
      font-weight: 400;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
      text-align: left;
    }

    .iconfont {
      font-size: 35px;
      color: #0085FF;
    }

  }

  .infoItem {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    margin-top: 8px;

    .left {
      min-width: 80px;
      width: 25%;
    }

    .right {
      width: 75%;
      text-align: left;
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient: vertical;
      /*要显示的行数*/
      -webkit-line-clamp: 2;
      /* 溢出部分隐藏 */
      overflow: hidden;
    }
  }

  .goodsLi {
    margin-top: 16px;

    .goodsNumsTitle {
      font-weight: 500;
      font-size: 14px;
      color: #1B1B1B;
      line-height: 24px;
      text-align: left;
    }

    .goodsItemBox {

      .items {
        display: flex;
        margin-top: 10px;

        .leftImg {
          width: 50px;
          height: 50px;
          border-radius: 10px;
          background-color: #f9fcff;
          overflow: hidden;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }

        .rightInfo {
          text-align: left;
          flex: 1;
          font-weight: 400;
          font-size: 16px;
          color: #7F7F7F;
          font-size: 14px;
          padding-left: 10px;

          .title {
            display: flex;
            justify-content: space-between;

            .text {
              max-width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              font-weight: 400;
              font-size: 16px;
              color: #1B1B1B;
            }

            .nums {
              font-size: 16px;
              color: #1B1B1B;
              font-weight: 400;
            }
          }
        }
      }

      .items:nth-of-type(1) {
        margin-top: 0px;
      }
    }
  }

  .remark {
    margin: 10px 10px 0 10px;
    background: #F1F6FD;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #398AE9;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 14px;
    color: #0085FF;
    line-height: 24px;
    text-align: left;
  }

}

.card {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  border-radius: 10px;
  margin: 10px 16px;
  padding: 10px;
  overflow: hidden;
}

.img_box {
  display: flex;
  margin-top: 10px;
  white-space: nowrap;
  overflow: scroll;
  margin-left: 16px;
  margin-right: 16px;

  .uploadItem {
    margin: 0 10px 0 0;

    .avatar-uploader {
      width: 75px;
      height: 75px;
      border-radius: 10px;
      overflow: hidden;
      background-color: #c9c9c9;
      position: relative;
      border: none;

      .avatar-uploader-icon {
        font-size: 20px;
        line-height: 70px !important;
        width: 70%;
        height: 70%;
      }

      .el-upload__text {
        position: absolute;
        left: 50%;
        bottom: 0;
        font-size: 10px;
        color: #fff;
        line-height: 15px;
        transform: translate(-50%, 0);
      }
    }
  }

  .uploadItem:nth-last-of-type(1) {
    //margin-right: 0px;
  }
}

.avatar-uploader .el-upload {
  width: 100%;
  height: 100%;
}

.userKtInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 0px;

  .every {
    width: 50%;
    color: #666666;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .nums {
      color: #0085FF;
      margin: 0 10px;
      font-size: 16px;
      max-width: 50px;
      overflow: scroll;
    }

    .nums::-webkit-scrollbar {
      display: none;
    }

    .nums1 {
      color: rgb(255, 34, 34);
    }
  }
}

.userTongInfo {
  padding: 0 10px;

  .line {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .left {
      color: #1b1b1b;
      font-size: 14px;
      margin-right: 14px;
      max-width: 40%;
      white-space: nowrap;
    }

    .right {
      width: 35%;

      .fontBox {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #1B1B1B;

        .unadd, .add {
          font-size: 20px;
          position: relative;
          background-color: #0085FF;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          text-align: center;
        }

        .unadd::after, .add::after {
          display: inline-block;
          content: '';
          position: absolute;
          width: 50%;
          height: 10%;
          background-color: white;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }

        .add::before {
          display: inline-block;
          content: '';
          position: absolute;
          width: 10%;
          height: 50%;
          background-color: white;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }

        .add {
          margin-right: 14px;
        }

        .nums {
          width: 17px;
          text-align: center;
          font-size: 36rpx;
          color: #1b1b1b;
          border-radius: 4px;
          margin: 0 9px;
        }
      }
    }
  }
}

.remark, .yuangongRemark {
  margin: 10px 16px 0 20px;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 12px;
  min-height: 50px;
  border: 1px solid #0085FF;
  background-color: #F1F6FD;
  color: #0085FF;
  text-align: left;
  align-items: baseline;

  .textarea {
    max-height: 75px;
    font-size: 12px;
    color: #666666;

  }
}

.yuangongRemark {
  .textarea {
    width: 100%;
    background-color: transparent;
    border: none;
  }
}

.bottombutton {
  background-color: #0085FF;
  color: white;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  padding: 13px 0;
  margin: 20px 16px;
}

//.noadd{
//  justify-content: space-between!important;
//  .nums{
//    width: 82%!important;
//    margin: 0!important;
//  }
//}
</style>