<template>
  <div>
    <div class="container">
      <div class="area">
        <!-- <works_weixiu></works_weixiu> -->
        <div class="order_typeBox">
          <van-radio-group v-model="radio" class="radionGourp">
            <van-radio :name="1" class="item" checked-color="#FC223B" icon-size='17'
                       v-if="radio==1">
              <template #default>
                <span class="text">质保外</span>
              </template>
            </van-radio>
            <van-radio :name="2" class="item" checked-color="#FC223B" icon-size='17'
                       v-if="radio==2">
              <template #default>
                <span class="text">快修质保</span>
              </template>
            </van-radio>
            <van-radio :name="3" class="item" checked-color="#FC223B" icon-size='17'
                       v-if="radio==3">
              <template #default>
                <span class="text">装修质保</span>
              </template>
            </van-radio>
          </van-radio-group>
        </div>
        <div class="innerarea">
          <div class="line border_bottom">
            <div class="lable">快修项目</div>
            <div class="right" style="color: #1b1b1b">{{ this.obj.items.title }}</div>
          </div>
          <div class="line">
            <div class="lable">勘查说明</div>
            <div class="right">
              <input
                  type="text"
                  class="input"
                  placeholder="勘察员现场勘查备注说明"
                  maxlength="50"
                  v-model="Instructions"
                  disabled
              />
            </div>
          </div>
        </div>

        <div class="img_box">
          <div class="img_list">
            <div class="evert li" v-for="(item,index) in kanchaUpload" :key="index+index">
              <el-image
                  class="avatar"
                  fit="cover"
                  :src="$store.state.imageHost+item.tempFilePath"
                  :preview-src-list="kanchasrcList">
              </el-image>
            </div>
          </div>
        </div>
        <div v-if="obj.wenti">
          <div class="twoStepContent" v-for="(item,index) in  obj.wenti" :key="index">
            <div class="twoTopLine">
              <div class="labelBox" v-if="obj.wenti.length>0">
                <div class="title">
                  <div class="leftBox"></div>
                  <div class="title">问题</div>
                  <div class="rightBox"></div>
                </div>
              </div>
            </div>

            <div class="workContent" style="margin-top: 0;padding-top: 0;">
              <span class="text" style="text-align: left;width: 10%;">问题: </span><input
                placeholder-style="color:#C7C7C7;" disabled type="text" v-model="item.title" class="input"
                placeholder=""/>
            </div>
<!--            <div style="display: flex;justify-content: center;padding: 20rpx;">-->
<!--              <div class="VoiceBoxBtn" v-if="item.mp32.tempFilePath" "-->
<!--                   data-item="{{item.mp32.tempFilePath}}">-->
<!--                {{ item.mp32.durationS }}s-->
<!--              </div>-->
<!--            </div>-->
            <div class="addImage">
              <div class="twoStepImglist">
                <div class="img_list">
                  <div class="evert li" v-for="(item2,index) in item.feliss" :key="index+index">
                    <el-image
                        class="avatar"
                        fit="cover"
                        :src="$store.state.imageHost+item2.tempFilePath"
                        :preview-src-list="item.srcList">
                    </el-image>
                  </div>
                </div>
                <!-- 验收图 -->
                <!--              <scroll-view class="scroll-view_H" scroll-x="true" bindscroll="scroll" style="width: 100%">-->
                <!--                <view style="display: inline-block;" class="listitem" wx:for="{{item.feliss}}" wx:for-item="items" wx:for-index="indexs" wx:key="weixiuImgList" style="position: relative;display: inline-block;margin: 0 10rpx 0 0;">-->
                <!--                  <image lazy-load	class="file Tadd" wx:if="{{items.fileType=='image'}}" src="{{globalData.imgHost}}{{ items.tempFilePath}}" mode="aspectFill" bind:tap="weixiuwentiLookImg" data-index="{{indexs}}" data-item="{{item.feliss}}"></image>-->
                <!--                  <video class="video" show-center-play-btn='{{false}}' autoplay='{{false}}' controls='{{false}}' wx:if="{{items.fileType=='video'}}" src="{{globalData.imgHost}}{{ items.tempFilePath}}">-->
                <!--                    <view bind:tap="weixiuwentiLookImg" class="moban" data-index="{{indexs}}" data-item="{{item.feliss}}"></view>-->
                <!--                    <image lazy-load	bind:tap="weixiuwentiLookImg" src="/images/playVideo.png" data-index="{{indexs}}" data-item="{{item.feliss}}" class="image" mode="widthFix" />-->
                <!--                  </video>-->
                <!--                </view>-->
                <!--                </scroll-view>-->
              </div>
            </div>
          </div>
        </div>
        <div class="twoTopLine" v-if="obj.confirm==1">
          <div class=" title">
            <div class="leftBox"></div>
            <div class="title">验收问题</div>
            <div class="rightBox"></div>
          </div>
          <div class="innerarea innerareaOne" style="margin-top: 10px;" v-if="identity==1||!identity">

            <input
                class="outinput"
                type="text"
                placeholder="例：未全部完成或工艺质量存在问题"
                max="50"
                v-model="userYanshouValue"
            />
          </div>
          <div class="img_box">
            <div class="img_list">
              <div class="evert li" v-for="(item,index) in yanshouload" :key="index+index">
                <el-image
                    class="avatar"
                    fit="cover"
                    :src="$store.state.imageHost+item.tempFilePath"
                    :preview-src-list="yanshouloadsrcList">
                </el-image>
                <van-icon name="clear" class="icon" @click.stop="deleteImg(yanshouload,index,yanshoufileList)"/>
              </div>
              <el-upload
                  v-if="yanshouload.length<=4"
                  class="avatar-uploader"
                  action
                  :http-request="uploadFile"
                  ref="upload"
                  :limit="yanshouLimit"
                  :on-remove="handleRemove"
                  :file-list="yanshoufileList"
                  :on-exceed="handleExceed"
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                  :headers="headers"
                  :on-change="fileChange2"
                  multiple
                  name="22"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </div>


        <div class="innerarea innerareaTwo">
          <div class="title">验收标准</div>
          <div class="li">维修内容已全部完成</div>
          <div class="li">维修内容的工艺优良，细节处理良好</div>
          <div class="li">维修完成后，维修区域干净整洁</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import works_weixiu from "./works_weixiu/works_weixiu.vue";

import Compressor from "compressorjs";
import {upload_file, upload_files} from "@/utils/api";

export default {
  components: {
    // works_weixiu,
  },
  props: {
    maintenance_type: {
      type: [String, Number],
    },
    obj: {
      type: [String, Number, Object],
    },
    obj1: {
      type: [String, Number, Object],
    },
    identity: {
      type: [String, Number],
    },
  },
  data() {
    return {
      radio: 1,
      Instructions: "",
      kanchaUpload: [],

      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],
      yanshouload: [],
      yanshoufileList: [],//照片 files
      yanshouLimit: 5,//照片最大数量
      userYanshouValue: '',//整改提交问题
    };
  },
  watch: {},
  computed: {
    kanchasrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.kanchaUpload)).forEach(item => {
        list.push(this.$store.state.imageHost + item.tempFilePath)
      })
      return list
    },
    yanshouloadsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.yanshouload)).forEach(item => {
        list.push(this.$store.state.imageHost + item.tempFilePath)
      })
      return list
    },

  },
  methods: {
    deleteImg(list, index, fileList) {
      list.splice(index, 1)
      fileList.splice(index, 1)
    },


    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      console.log('bef')

      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 22) {
              this.yanshouload.push({tempFilePath: res.data.data.saveurl, fileType: 'image'})
            }
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            console.log(res.data.data)
            // this.userUpload
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'video'})
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange2(file, yanshoufileList) {
      console.log('change')
      this.yanshoufileList = yanshoufileList;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.yanshoufileList.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.obj, '2.obj=====')
      this.Instructions = this.obj.items.notes
      if (this.obj.items.files_user_maintenance) {
        this.kanchaUpload = JSON.parse(this.obj.items.files_user_maintenance)
      }
      if(this.obj.wenti){
        if(this.obj.wenti.length>0){
          this.obj.wenti.forEach(item=>{
            if(item.mp3){
              item.mp32 = JSON.parse(item.mp3)
            }
            item.feliss = JSON.parse(item.felis)
            let list = []
            JSON.parse(JSON.stringify(JSON.parse(item.felis))).forEach(item => {
              list.push(this.$store.state.imageHost + item.tempFilePath)
            })
            item.srcList = list
            // this.$nextTick(()=>{
            //   item.srcList = list.map(item=>{return this.$store.state.imageHost+item.tempFilePath})
            // })
          })
        }
      }
    })
  },
};
</script>
<style lang="scss" scoped>
.area {
  // padding: 0 16px;
}

.innerarea {
  margin: 0 16px;

  margin-top: 10px;

  .outinput {
    border: none;
    text-align: left;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    background: transparent;
    width: 90%;
  }

  .outinput::placeholder {
    color: #999999;
  }

  .line {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 8px;
    padding: 10px 0;

    .checkGoods {
      background: #FC223B;
      color: #fff;
      font-size: 14px;
      padding: 4px 12px;
      border-radius: 8px;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #c7c7c7;
      font-size: 14px;
      width: 75%;
    }

    .lable {
      color: #666666;
      font-size: 14px;
      line-height: 24px;
    }

    .input {
      border: none;
      background: transparent;
      text-align: right;
      color: #1b1b1b;
      width: 100%;
    }

    .input_tow_box {
      border: 1px solid #FC223B;
      border-radius: 8px 8px 8px 8px;
      color: #FC223B;
      font-size: 14px;
      width: 72px;
      display: flex;
      padding: 4px 6px;
      margin-left: 10px;

      .input_tow {
        border: none;
        background: transparent;
        text-align: right;
        max-width: 80%;
        text-align: center;
        min-width: 80%;
      }
    }

    .input::placeholder {
      color: #c7c7c7;
    }
  }

  .border_bottom {
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);
  }
}

.twoTopLine {
  padding: 10px 0;
}

.innerareaOne {
  margin: 0 16px;

  display: flex;
  border-radius: 10px;
}

.innerareaTwo {
  padding: 10px;

  .title {
    font-weight: 400;
    font-size: 12px;
    color: #1b1b1b;
    line-height: 24px;
    text-align: left;
  }

  .li {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 24px;
    text-align: left;
    text-indent: 1em;
    position: relative;
  }

  .li::after {
    position: absolute;
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background: #FC223B;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0%;
    border-radius: 50%;
  }

}

.order_typeBox {
  margin: 0 16px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  .radionGourp {
    display: flex;
    .item{
      margin-left: 10px;
    }
    .text {
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 24px;
    }
  }

}

.img_box {
  margin-top: 10px;
  margin-left: 16px;

  .title {
    text-align: left;
    font-size: 12px;
    color: #999999;
  }

  .img_list {
    margin-top: 4px;
    display: flex;

    .li {
      width: 65px;
      height: 65px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

.twoStepContent{
  padding-top: 5px;
  .twoTopLine{
    width: 100%;
    padding: 10px 0;

    .labelBox{
      .title {
        justify-content: center!important;
        align-items: center;
        color: #1b1b1b;
        display: flex;
        .leftBox,.rightBox{
          width: 75px;
          height: 2px;
          background-color: #666666;
          margin: 0 10px;
        }
      }
    }
  }
  .workContent{
    margin-top: 10px;
    color: #6b6b6b;
    flex: 1;
    display: flex;
    vertical-align: middle;
    padding-top: 10px;
    align-items: center;
    margin-left: 16px;
    .input {
      height: 30px;
      line-height: 30px;
      margin-left: 10px;
      border-radius: 4px;
      width: 75%;
      padding-left: 10px;
      color: #1b1b1b;
      overflow: scroll;
      border: none;
    }
  }
  .img_list {
    margin-top: 4px;
    display: flex;
    margin-left: 16px;
    .li {
      width: 65px;
      height: 65px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

</style>