<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
export default {
  name: "myWallet",
  components:{
    navigationBar
  },
}
</script>

<template>
<div class="container">
  <div class="area">
    <navigationBar :title="'我的钱包'"></navigationBar>
  </div>
</div>
</template>

<style scoped lang="scss">

</style>