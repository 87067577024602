<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {brand, pandian, pandianList} from "@/utils/api";
import {Toast} from "vant";
import {_throttle} from "@/utils/tool";
import Loding from "@/components/common/loding/loding.vue";

export default {
  name: "inventory",
  components: {Loding, NavigationBar},
  computed:{
    allNums(){
      return this.commidyList.reduce((total, item) =>
              total += Number(item.num)
          , 0)
    },
  },
  data(){
    return{
      oldScrollTop: 0,
      commidyList:[],
      limit:10,
      loding:false,
      pandianList:[],
    }
  },
  methods:{
    getCommid(){
      brand(this.$store.state.token).then(res=>{
        console.log(res)
        if(res.data.status == true){
          let list = res.data.data

          list.forEach(item=>{
            item.num=0
          })
          list.push({id:-1,logo:'',num:0,sort:-1,name:'押金退桶'})
          this.commidyList = list
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    containerScroll(event){
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.scrollFlag) {
            this.loading = true
          }
          this.getQingqiu();
        }
      }
    },
    getQingqiu: _throttle(function () {
      if (this.$store.state.user.user_authority == 5) {
        if (!this.scrollFlag) {
          this.loading = false
          return
        }
        this.limit+=5
        this.getList()
      }
    }, 2000),

    getList(){
      pandianList(this.$store.state.token,this.limit).then(res=>{
        if(res.data.status == true){
          console.log(res.data)
          if(res.data.status==true){
            console.log(res.data.data.data)
            let list = res.data.data.data
            list.forEach((item,index)=>{
              item.time = item.ctime.split(' ')[0]
              item.datas = []
              item.data.split(';').forEach((item2)=>{
                list[index].datas.push({name:item2.split(' ')[0],nums:item2.split(' ')[1]})
              })
            })
            this.pandianList = list
          }else{
            Toast(res.data.msg)
          }
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    },

    ok:_throttle(function (){
      this.loding = false
      let list = []
      this.commidyList.forEach((item)=>{
        list.push({content:item.name,count:item.num})
      })
      let newList = list.map(item => { return { content: item.content, count: isNaN(item.count)?0:item.count} })
      pandian(this.$store.state.token,JSON.stringify(newList)).then((res)=>{
        this.loding = false
        if(res.data.status == true){
          Toast('盘点成功')
          this.getList()
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
        this.loding = false
      })
    },2000),

    submit(){
      this.loding = true
      this.ok()
    },

  },
  mounted() {
    this.getCommid()
    this.getList()
  }
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="'空桶盘点'" class="navigation"></navigationBar>
      <div class="inner">
        <div class="top">
          <div class="topTitle">
            今日库存空桶数量
            <div class="AllnumsBox">
              <span class="AllnumsText">{{ allNums ? allNums : 0 }}</span>
            </div>
            桶
          </div>
          <div class="topBody">
            <div class="list">
              <div class="topBodyList" v-for="(item,index) in commidyList" :key="index">
                <div class="leftName">
                  {{ item.name }}
                </div>
                <div class="rightNums">
                  <input type="number" class="numsInput" v-model='item.num' bindinput="nums"
                         maxlength="10"/>桶
                </div>
              </div>
              <div class="line"></div>
            </div>
            <div class="okBox">
              <div class="okBtn" @click="submit">确定</div>
            </div>

          </div>
        </div>
        <div class="ktpdTitle">
          空桶库存每日统计
        </div>
        <div class="ktpdList">
          <div class="ktpdEvery" v-for="(item,index) in pandianList"
               :key="index">
            <div class="ktpdTop">
              <div class="nums">
                空桶数量
                <span class="text">{{ item.sum }}</span>
                桶
              </div>
              <div class="num number">
                送水工号
                <span class="text">BJSS{{ item.user_id }}</span>
              </div>

            </div>
            <div class="ktpdBody">
              <div class="ktpdlistEvery" v-for="(item2,index2) in item.datas"
                   :key="index2">
                <div class="ktname">
                  {{ item2.name }}
                </div>
                <div class="ktnum">
                  <span class="nums">{{ item2.nums }}</span>
                  桶
                </div>
              </div>
            </div>
            <div class="time">
              <span class="text">{{ item.time }}</span>
            </div>
          </div>
        </div>
      </div>
      <loding v-if="loding"></loding>
    </div>
  </div>

</template>

<style scoped lang="scss">
.container{
  height: 100vh;
}
.inner {
  background-color: transparent;
  min-height: 90vh;
  position: relative;
  z-index: 22;
}

.top {
  margin: 5px 16px;
  overflow: hidden;
  border-radius: 7px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
  overflow: hidden;
  .AllnumsText{
    font-size: 16px;
    color: white;
  }
  .AllnumsBox{
    /* background-color: #feffff; */
    padding: 0 7px;
    margin: 0 2px;
    border-radius: 5px;
    color: white;
  }
  .topTitle{
    color: white;
    padding: 10px;
    font-size: 14px;
    display: flex;
    background-color: #286CFB;
    align-items: center;
  }
  .topBody{
    padding: 10px;
    .list{
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
    .line{
      position: absolute;
      background-color: #DBE6FF;
      width: 2px;
      height: 50%;
      transform: translate(-50%,-50%);
      left: 49%;
      top: 50%;
    }
    .topBodyList{
      width: 50%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;
    }
    .leftName{
      font-size: 14px;
      color: #1b1b1b;
    }
    .rightNums{
      display: flex;
      font-size: 14px;
      width: 50%;
      justify-content: center;
      align-items: center;
      .numsInput{
        background-color: #F1F6FD;
        width: 35px;
        margin-right: 5px;
        border-radius: 5px;
        padding: 2px 5px;
        text-align: center;
        color: #0085FF;
        border: 1px solid #0085FF;
      }
    }
    .okBox{
      width: 95%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      .okBtn{
        font-size: 16px;
        text-align: center;
        padding: 5px 15px;
        border-radius: 5px;
        background-color: #286CFB;
        color: #ffffff;
      }
    }
  }
}
.ktpdTitle{
  margin: 10px auto;
  text-align: center;
  font-size: 14px;
  position: relative;
  color: #1B1B1B;
}
.ktpdTitle::after{
  display: block;
  content:'';
  transform: translate(-50%,-50%);
  position: absolute;
  width: 95px;
  height: 1px;
  background-color: #666666;
  left: 20%;
  top: 50%;
}
.ktpdTitle::before{
  display: block;
  content:'';
  transform: translate(-50%,-50%);
  position: absolute;
  width: 95px;
  height: 1px;
  background-color: #666666;
  left:80%;
  top: 50%;
}
.ktpdList{
  margin: 5px 16px;
}
.ktpdEvery{
  background-color: white;
  box-shadow: 0px 0px 2px 0px rgba(102,102,102,0.25);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  .time{
    color: #666666;
    font-size: 14px;
    text-align: right;
  }
  .ktpdTop{
    display: flex;
    justify-content: space-between;
    .nums{
      .text{
        background-color: #FFFFFF;
        color: #999999;
        box-shadow: 0px 0px 2px 0px rgba(102,102,102,0.25);
        padding:0 10px;
        font-size: 13px;
        border-radius: 8px;
      }
    }
    .nums,.num,time{
      font-size: 14px;
      text-align: center;
      white-space: nowrap;
      max-width: 20%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #474747;
    }
    .number{
      font-size: 14px!important;
      font-weight: 400;
      color: #1b1b1b;
    }
    .time{
      .text{
        font-size: 12px;
        color: #828282;
        font-weight: 400;
      }
    }
  }
  .ktpdBody{
    display: flex;
    flex-wrap: wrap;
    margin: 7px 0 5px 0;
    justify-content: space-between;
    .ktpdlistEvery{
      width: 30%;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 5px 0;
      justify-content: space-between;
      .ktname{
        color: #666666;
        font-size: 13px;
        width: 60%;
        text-align-last:justify;
        overflow: scroll;
      }
      .ktnum{
        color: #666666;
        font-size: 13px;
        width: 40%;
        display: flex;
        .nums{
          color: #666666;
          font-size: 14px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }
      }
    }
  }
}

</style>