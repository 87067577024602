<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getUserShopDetail, PlategetProductdetail} from "@/utils/api";
import {_throttle, isValidJSON} from "@/utils/tool";
import {Toast} from "vant";
import minimist from "minimist";

export default {
  name: "idle_shop_details",
  computed: {
    minimist() {
      return minimist
    }
  },
  components: {NavigationBar},
  data() {
    return {
      id: this.$route.query.item,
      category_id: -1,
      swiperLi: [],
      swiper: {},
      shop: {},
      chooseNav: 0,


      hasMore: true,//是否可以继续滚动
      loadLock: true,//是否展示loding
      mainMenuList: [],
      leftList: [],
      rightList: [],
      leftHeight: 0,
      rightHeight: 0, //分别定义左右两边的高度
      oldScrollTop: 0,
      page: 0,
      limit: 10,
    }
  },
  mounted() {
    if (this.$route.query.type == 'person') {
      this.category_id = this.$route.query.category_id
    } else {
      this.qingqiu(this)
    }
    this.$nextTick(() => {
      this.getDetails()
    })
  },
  methods: {
    getDetails() {
      if (this.$route.query.type == 'shop') {
        PlategetProductdetail(this.$store.state.token, this.id).then(res => {
          if (res.data.status == true) {
            this.shop = res.data.data
            if (isValidJSON(this.shop.img)) {
              this.swiperLi = JSON.parse(this.shop.img)
              this.shop.imgLi = JSON.parse(this.shop.img)
              this.shop.tagLi = this.shop.tag.split(',')
            }
            this.mainMenuList = this.shop.product
            console.log(this.shop.rent_data,'办公转租')
            console.log(this.shop.product,'自由市场商品列表 以及办公闲置')
            console.log(this.shop.tition_product,'店铺闲置商品')
            console.log(this.shop.shop,'店铺转让')
            this.mainMenuList.forEach(item => {
              console.log(item.images.split(',')[0])
              if (item.img) {
                if (isValidJSON(item.img)) {

                  item.showImg = JSON.parse(item.img)[0]
                } else {
                  console.log(item.img,1223456)
                  item.showImg = item.img.split(',')[0]
                }
                if (typeof item.showImg == 'string') {
                  if (!item.showImg.startsWith('https:')) {
                    item.showImg = this.$store.state.imageHost + item.showImg
                  } else {
                    item.showImg = item.showImg
                  }
                } else {
                  item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
                }
              }
              if (item.images) {
                if (isValidJSON(item.images)) {
                  item.showImg = JSON.parse(item.images)[0]
                } else {
                  item.showImg = item.images.split(',')[0]
                }
                if (typeof item.showImg == 'string') {
                  if (!item.showImg.startsWith('https:')) {
                    item.showImg = this.$store.state.imageHost + item.showImg
                  } else {
                    item.showImg = item.showImg
                  }
                } else {
                  item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
                }
              }
            })

            this.shop.ip_city = this.shop.area.split('-')[0]
            console.log(this.shop)

            this.initList()
          } else {
            Toast(res.data.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }

      if (this.$route.query.type == 'person') {
        console.log(this.category_id)
        getUserShopDetail(this.$store.state.token, this.id, this.category_id).then(async res => {
          if (res.data.status == true) {
            console.log(res.data.data)
            this.shop = res.data.data
            this.mainMenuList = this.shop.product
            this.mainMenuList.forEach(item => {
              if (item.img) {
                if (isValidJSON(item.img)) {

                  item.showImg = JSON.parse(item.img)[0]
                } else {
                  console.log(item.img,1223456)
                  item.showImg = item.img.split(',')[0]
                }
                if (typeof item.showImg == 'string') {
                  if (!item.showImg.startsWith('https:')) {
                    item.showImg = this.$store.state.imageHost + item.showImg
                  } else {
                    item.showImg = item.showImg
                  }
                } else {
                  item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
                }
              }
              if (item.images) {
                if (isValidJSON(item.images)) {
                  item.showImg = JSON.parse(item.images)[0]
                } else {
                  item.showImg = item.images.split(',')[0]
                }
                if (typeof item.showImg == 'string') {
                  if (!item.showImg.startsWith('https:')) {
                    item.showImg = this.$store.state.imageHost + item.showImg
                  } else {
                    item.showImg = item.showImg
                  }
                } else {
                  item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
                }
              }
            })

            // for (const item of this.mainMenuList) {
            //   console.log("左边", this.leftHeight, "右边", this.rightHeight);
            //   if (this.leftHeight <= this.rightHeight) {
            //     this.leftList.push(item);
            //   } else {
            //     this.rightList.push(item);
            //   }
            //   await this.getBoxHeight(this.leftList, this.rightList, this);
            // }
            // this.loadLock = false
            // this.hasMore = false
            this.initList()

          }
        }).catch(err => {
          console.log(err)
        })
      }

    },
    chooseNavFun(e) {
      if (e == this.chooseNav) return;
      this.chooseNav = e
      this.mainMenuList = []
      if(this.chooseNav == 0){
        this.mainMenuList = this.shop.product
      }
      if(this.chooseNav == 1){
        this.mainMenuList = this.shop.shop
      }
      if(this.chooseNav == 2){
        this.mainMenuList = this.shop.tition_product
      }
      if(this.chooseNav == 3){
        this.mainMenuList = this.shop.rent_data
      }
      this.mainMenuList.forEach(item=>{
        if (item.img) {
          if (isValidJSON(item.img)) {

            item.showImg = JSON.parse(item.img)[0]
          } else {
            console.log(item.img,1223456)
            item.showImg = item.img.split(',')[0]
          }
          if (typeof item.showImg == 'string') {
            if (!item.showImg.startsWith('https:')) {
              item.showImg = this.$store.state.imageHost + item.showImg
            } else {
              item.showImg = item.showImg
            }
          } else {
            item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
          }
        }
        if (item.images) {
          if (isValidJSON(item.images)) {
            item.showImg = JSON.parse(item.images)[0]
          } else {
            item.showImg = item.images.split(',')[0]
          }
          if (typeof item.showImg == 'string') {
            if (!item.showImg.startsWith('https:')) {
              item.showImg = this.$store.state.imageHost + item.showImg
            } else {
              item.showImg = item.showImg
            }
          } else {
            item.showImg = this.$store.state.imageHost + item.showImg.tempFilePath
          }
        }
        console.log(item)
      })
      this.leftList = []
      this.rightList = []
      this.$nextTick(()=>{
        this.initList()
      })

    },

    async initList(){
      for (const item of this.mainMenuList) {
        console.log("左边", this.leftHeight, "右边", this.rightHeight);
        if (this.leftHeight <= this.rightHeight) {
          this.leftList.push(item);
        } else {
          this.rightList.push(item);
        }
        await this.getBoxHeight(this.leftList, this.rightList, this);
      }
      this.loadLock = false
      this.hasMore = false
    },


    containerScroll(event) {
      let scrollTop1 = event.target.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop1 - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop1;
      if (scrollStep < 0) {
        // console.log("滚动条向上滚动了！");
      } else {
        const {scrollHeight, clientHeight, scrollTop} = event.target;
        if (
            (scrollHeight - (scrollTop + clientHeight)) / clientHeight <=
            0.05
        ) {
          if (this.hasMore) {
            this.loadLock = true
            this.qingqiu(this);
          }
        }
      }
    },
    getBoxHeight(leftList, rightList, _this) {
      //获取左右两边高度
      return new Promise((resolve) => {
        _this.$nextTick(() => {
          _this.leftList = leftList;
          _this.rightList = rightList;
          _this.leftHeight = _this.$refs.left.offsetHeight;
          _this.rightHeight = _this.$refs.right.offsetHeight;
          // console.log("左边", _this.leftHeight, "右边", _this.rightHeight);
        });
        resolve();
      });
    },
    qingqiu: _throttle(async function (_that) {
      this.page += 1
      this.loadLock = false
      this.hasMore = false
      console.log(_that)
    }, 400),


    godetails(item) {
      console.log(item)
      if(this.chooseNav == 1 || this.chooseNav == 3){
        return
      }else{
        this.$router.push({
          path: '/idleDetails',
          query: {id: item.id}
        })
      }

    },
  },
}
</script>

<template>
  <div class="container" @scroll="containerScroll">
    <div class="area">
      <navigationBar :title="'返回'" class="navigationBar"></navigationBar>
      <div class="outSwiper">
        <div class="swiper">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide"      v-for="(item,index) in swiperLi"
                :key="index" ><img src="../../../../public/image/asdasd.png" alt="" class="img"></div> -->
            <div
                class="swiper-slide"
                :style="`background-image:url(https://picture.bjbjkj.top/tp2024071803001500257731617.png)`"
            >
            </div>
            <!-- <div class="swiper-slide"><img src="../../../../public/image/asdasd1.png" alt="" class="img"></div> -->
          </div>
          <!-- 如果需要分页器 -->
          <div class="pagination">
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>

      <div class="body">
        <div class="head">
          <div class="titleName">
            {{ category_id != -1 ? shop.nickname : shop.title }}
          </div>
          <div class="text">
            <span class="text">全北京可服务</span>
            <span class="tag">IP {{ shop.ip_city }}</span>
          </div>
          <div class="type">
            {{ category_id != -1 ? shop.category_name : shop.cate_name }}
          </div>
          <div class="tagUl">
            <div class="ul">
              <div class="li">卖家已实名</div>
              <div class="li">当面验货</div>
              <div class="li">随时交易</div>
              <div class="li">入驻30天</div>
              <div class="li">买家自提</div>
            </div>
          </div>
          <div class="nav">
            <div class="ul" v-if="shop.id">

              <div class="li" :class="chooseNav == 0?'chooseNav':''" @click="chooseNavFun(0)">
                <span class="textc">宝贝</span>
                <span class="nums">{{mainMenuList.length}}</span>
                <div class="line" v-if="chooseNav == 0"></div>
              </div>


              <div class="li" :class="chooseNav == 2?'chooseNav':''" @click="chooseNavFun(2)" v-if="category_id==-1&&shop.tition_product.length>0">
                <span class="textc">店铺闲置</span>
                <span class="nums">{{ shop.tition_product.length }}</span>
                <div class="line" v-if="chooseNav == 2"></div>
              </div>

              <div class="li" :class="chooseNav == 1?'chooseNav':''" @click="chooseNavFun(1)" v-if="category_id==-1&&shop.shop.length>0">
                <span class="textc">店铺转让</span>
                <span class="nums">{{shop.shop.length}}</span>
                <div class="line" v-if="chooseNav == 1"></div>
              </div>
              <div class="li" :class="chooseNav == 3?'chooseNav':''" @click="chooseNavFun(3)" v-if="category_id==-1&&shop.rent_data.length>0">
                <span class="textc">办公转让</span>
                <span class="nums">{{shop.rent_data.length}}</span>
                <div class="line" v-if="chooseNav == 3"></div>
              </div>

<!--              console.log(this.shop.rent_data,'办公转租')-->
<!--              console.log(this.shop.product,'自由市场商品列表 以及办公闲置')-->
<!--              console.log(this.shop.tition_product,'店铺闲置商品')-->
<!--              console.log(this.shop.shop,'店铺转让')-->

            </div>
          </div>

        </div>
        <div class="list">
          <div class="pubu">
            <div id="box">
              <div id="left-box" ref="left">
                <div class="item" v-for="(item,index) in leftList" :key="index" @click.stop="godetails(item)">
                  <div class="imageBox" @click.stop="godetails(item)">
                    <img :src="item.showImg" alt="" class="img">
                    <div class="tag" :class="item.is_sale==2?'yushou':''">{{ item.is_sale == 2 ? '预售' : '在售' }}
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="name">{{ item.product_name?item.product_name:item.title }}</div>
                    <div class="priceLine">
                      <div class="nowPrice">
                        <div class="leftUnit">￥</div>
                        <div class="rightPrice">{{ item.price?item.price:item.rent }}</div>
                      </div>
                      <div class="lastPrice">
                        浏览{{ item.visit_count }}
                      </div>
                    </div>
                    <div class="userInfo">
                      <div class="left">
                        <img class="avatar"
                             v-if="shop.avatar"
                             :src="$store.state.imageHost+shop.avatar"
                             alt="">
                        <span class="nickname">{{ shop.nickname }}</span>
                      </div>
                      <div class="rightAdd">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="right-box" ref="right">
                <div class="item" v-for="(item,index) in rightList" :key="index" @click.stop="godetails(item)">
                  <div class="imageBox">
                    <img :src="item.showImg" alt="" class="img">
                    <div class="tag" :class="item.is_sale==2?'yushou':''">{{ item.is_sale == 2 ? '预售' : '在售' }}
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="name">{{ item.product_name?item.product_name:item.title }}</div>
                    <div class="priceLine">
                      <div class="nowPrice">
                        <div class="leftUnit">￥</div>
                        <div class="rightPrice">{{ item.price?item.price:item.rent }}</div>
                      </div>
                      <div class="lastPrice">
                        浏览{{ item.visit_count }}
                      </div>
                    </div>
                    <div class="userInfo">
                      <div class="left">
                        <img class="avatar"
                             :src="$store.state.imageHost+shop.avatar"
                             alt="">
                        <span class="nickname">{{ shop.nickname }}</span>
                      </div>
                      <div class="rightAdd">
                        <!--                        <span class="iconfont icon-Frame11"></span>-->
                        <!--                        {{ item.distanceObj.distance_str }}-->
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="loader-box">
              <div id="loader" v-if="loadLock"></div>
              <div id="nothing" v-if="!loadLock&&!hasMore">没有更多了~</div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background: #f9f9f9;
  height: 100vh;
}

.navigationBar {
  text-align: left;
  position: relative;
  z-index: 25;
}

.area {
  position: relative;
  height: 100vh;
}

.outSwiper {
  position: absolute;
  top: 0;
  width: 100%;
}

.swiper-slide {
  // background: red;
  height: 160px;
  background-size: cover;
  background-position: center center;
}

.swiper {
  margin: 10px 0px;
  overflow: hidden;
  position: relative;
  border-radius: 0px;
  filter: blur(5px);

  .swiper-slide {
    max-height: 180px;
  }

  .img {
    width: 100%;
  }

  // 分页器
  /* 两种都可以 */
  .swiper-pagination-total {
    font-size: 12px;
  }

  .swiper-pagination {
    background: rgba(51, 51, 51, 0.75);
    width: auto;
    // right: 10px;
    border-radius: 64px;
    padding: 0 5px;
    color: white;
    line-height: 24px;
    font-size: 13px;
  }

  // 分页器
  .pagination {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 10px;
    z-index: 3;
    margin: 0;
  }
}

.body {
  position: relative;
  z-index: 5;
  margin-top: 80px;
}

.head {
  background: #ffffff;
  padding: 10px 8px;

  .titleName {
    text-align: left;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 21px;
    line-height: 29px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .text {
    margin-top: 10px;
    display: flex;
    color: #7f7f7f;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    align-items: center;

    .text {
      margin-top: 0;
      margin-right: 10px;
    }

    .tag {
      width: fit-content;
      height: fit-content;
      display: inline-flex;
      place-content: center;
      place-items: center;
      gap: 10px;
      padding: 1px 2px;
      border-radius: 2px;
      background: #f7f7f7;
      color: #999999;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      padding: 1px 2px;
    }
  }

  .type {
    color: #7f7f7f;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }

  .tagUl {
    margin-top: 8px;
    overflow: scroll;

    .ul {
      display: flex;
      white-space: nowrap;

      .li {
        white-space: nowrap;
        width: fit-content;
        height: fit-content;
        display: inline-flex;
        place-content: center;
        place-items: center;
        gap: 10px;
        padding: 4px 12px;
        border-radius: 4px;
        background: #f6f6f6;
        color: #666666;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        margin-right: 6px;
      }
    }
  }

  .tagUl::-webkit-scrollbar {
    display: none;
  }

  .nav {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #f4f4f4;

    .ul {
      display: flex;

      .li {
        position: relative;
        color: #666666;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        margin-right: 20px;
        .textc{
          position: relative;
          z-index: 2;
        }

        .nums {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(100%, 0);
          color: #666666;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
        }

        .line {
          width: 100%;
          height: 6px;
          background: #ffdd7c;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%,0);
          z-index: 1;
        }
      }

      .chooseNav {
        text-align: center;
        color: #1b1b1b;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }

}


.pubu {
  padding: 10px;

  .title {
    align-items: center;
    text-align: center;
    color: rgba(27, 27, 27, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display: inline-block;
  }

  .title::after, .title::before {
    content: '';
    display: block;
    width: 116px;
    height: 1px;
    background-color: rgb(221, 221, 221);
    position: absolute;
  }

  .title::after {
    left: -24px;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  .title::before {
    right: -24px;
    top: 50%;
    transform: translate(100%, -50%);
  }
}


#box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#box img {
  object-fit: cover;
  background-color: #eee;
  margin-bottom: 10px;
}

#left-box,
#right-box {
  width: 180px;


  .item {
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    /* box-shadow: 0rpx 0rpx 4rpx 0rpx rgba(102,102,102,0.25); */
    display: flex;
    flex-direction: column;
    text-align: left;

    .img {
      width: 100%;
      background-color: #fff;
    }

    .imageBox {
      background-color: #fff;
      position: relative;
      min-height: 70px;

      .tag {
        position: absolute;
        right: 10px;
        bottom: 10px;
        border-radius: 5px;
        background: #fd2a39;
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        padding: 1px 4px;
      }

      .yushou {
        background: #24b512;
      }
    }

    .bottom {
      padding: 6px 10px;

      .unit {
        font-size: 10px;
      }

      .price {
        color: #FC223B;
        font-size: 18px;
      }

      .priceLine {
        display: flex;
        color: #FC223B;
        align-items: baseline;
        width: 100%;
        margin-top: 6px;
        justify-content: space-between;

        .nowPrice, .lastPrice {
          display: flex;
          align-items: baseline;

          .leftUnit,
          .rightPrice {
            font-size: 12px;
            color: #7f7f7f;
          }
        }

        .leftUnit {
          font-size: 10px;
          line-height: 10px;
        }

        .rightPrice {
          font-weight: 400;
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .nowPrice {
          .rightPrice {
            font-size: 18px;
          }

          .leftUnit,
          .rightPrice {
            color: #FC223B !important;
          }
        }

        .lastPrice {
          text-align: right;
          color: #7f7f7f;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .nowPrice {
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }


      .userInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4px;

        .left {
          width: 50%;

          .avatar {
            width: 18px;
            height: 18px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 0;
            vertical-align: middle;
            margin-right: 4px;
          }

          .nickname {
            display: inline-block;
            color: #999999;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;

          }

        }

        .rightAdd {
          text-align: right;
          color: #999999;
          font-weight: 400;
          font-size: 11px;
          line-height: 18px;
          display: flex;
          align-items: center;
        }
      }

    }

    .name {
      display: -webkit-box;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
      -webkit-box-orient: vertical;
      /*要显示的行数*/
      -webkit-line-clamp: 2;
      /* 溢出部分隐藏 */
      overflow: hidden;
      font-size: 14px;
      color: #1b1b1b;
    }

    .sellNums {
      line-height: 18px;
      font-size: 12px;
      color: #7f7f7f;
    }
  }
}

.loader-box {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader {
  width: 25px;
  height: 25px;
  border: 3px solid #ddd;
  border-radius: 50%;
  border-bottom: 3px solid #717171;
  transform: rotate(0deg);
  animation: loading 1s linear infinite;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.idle_home {
  .iconf {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }

  .text {
    color: #333333;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
  }
}
</style>