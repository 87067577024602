<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Compressor from "compressorjs";
import {getCompany, update_company_introduction, upload_file} from "@/utils/api";
import {Toast} from "vant";
import MaskDiv from "@/components/common/invite_job/online/mask/mask.vue";

export default {
  name: "corporation_home_page",
  components: {MaskDiv, navigationBar},
  data() {
    return {
      disable: false,
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg"],
      Resume: {
        name: '',//企业证件照全程，
        type: '',//企业类型
        type_id: '',//企业类型
        license: '',//营业执照
        front: '',//正面
        contrary: '',//反面
        shop_name: '',//公司名称
        address: '',//公司地址
        address_province_id: '',//省
        address_province_name: '',//省
        address_city_id: '',//市
        address_city_name: '',//市
        address_district_id: '',//区
        address_district_name: '',//区
        address_details: '',//公司地址xx详细地址
        scale: '',//规模
        logo: '',//logo

        introduce:'',
      },

      rules: {
        name: [{required: true, message: '请输入企业证件照全称', trigger: 'blur'},],
        type: [{required: true, message: '请选择企业类型', trigger: 'blur'},],
        license: [{required: true, message: '请上传营业执照', trigger: 'blur'},],
        front: [{required: true, message: '请上传正面证件', trigger: 'blur'},],
        contrary: [{required: true, message: '请上传反面证件', trigger: 'blur'},],

        shop_name: [{required: true, message: '请输入公司名称', trigger: 'blur'},],
        address: [{required: true, message: '请选择公司地址', trigger: 'blur'},],
        address_details: [{required: true, message: '请选择详细地址', trigger: 'blur'},],
        scale: [{required: true, message: '请选择公司人员规模', trigger: 'blur'},],
        logo: [{required: true, message: '请上传公司logo', trigger: 'blur'},],
      },
      licenseFiles: [],
      frontFiles: [],
      contraryFiles: [],
      logoFiles: [],

      checked: false,
      pop_show: false,
      pop_type: '',
      maskType: '',

      address_li: this.$store.state.allAddress,
      scaleList: [
        '0-20人',
        '20-99人',
        '100-499人',
        '500-999人',
        '1000-9999人',
        '10000人以上',
      ],
      expect_job_show:false,
      chooseValue:'',
    }
  },
  methods: {
    submitForm(formName) {
      console.log(formName)
      if(this.disable)return
      update_company_introduction(this.$store.state.token,this.Resume.id,this.Resume.introduce,this.Resume.logo).then(res=>{
        console.log(res)
        if(res.data.status==true){
          Toast(res.data.msg)
          this.disable = true
        }else{
          Toast(res.data.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     //
      //     if (this.checked === false) {
      //       Toast('请阅读并同意协议')
      //       return
      //     }
      //     // name: '',//企业证件照全程，
      //     //     type: '',//企业类型
      //     //     license: '',//营业执照
      //     //     front: '',//正面
      //     //     contrary: '',//反面
      //     //     shop_name:'',//公司名称
      //     //     address:'',//公司地址
      //     //     address_details:'',//公司地址xx详细地址
      //     //     scale:'',//规模
      //     //     logo:'',//logo
      //     let corporate_capacity_images = `${this.Resume.front},${this.Resume.contrary}`
      //     create_company(this.$store.state.token, this.Resume.name, this.Resume.type_id, this.Resume.license, corporate_capacity_images, this.Resume.shop_name, this.Resume.address_province_id, this.Resume.address_city_id, this.Resume.address_district_id, this.Resume.address_province_name, this.Resume.address_city_name, this.Resume.address_district_name,this.Resume.address_details,this.Resume.scale,this.Resume.logo).then(res=>{
      //       if(res.data.status == true){
      //         Toast('提交成功')
      //         this.$router.go(-1)
      //       }else{
      //         Toast(res.data.msg)
      //       }
      //     }).catch(err=>{
      //       console.log(err)
      //     })
      //
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // })
    },

    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)
        }


      })
    },
    async beforeUpload(files) {
      console.log(files)
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {

      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 'license') {//营业执照
              this.Resume.license = res.data.data.saveurl
              this.licenseFiles = []
              // this.kanchaUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            if (item.filename == 'front') {//正面
              this.Resume.front = res.data.data.saveurl
              this.frontFiles = []
              // this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            if (item.filename == 'contrary') {//反面
              this.Resume.contrary = res.data.data.saveurl
              this.contraryFiles = []
              // this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            if (item.filename == 'logo') {//反面
              this.Resume.logo = res.data.data.saveurl
              this.logoFiles = []
              // this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
          }
        })
      }
    },
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange(file, infomationFiles) {
      this.avatarFiles = infomationFiles;
    },
    async handleRemove() {
    },
    handleClose(i) {
      this.avatarFiles.splice(i, 1); //删除上传的文件
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },

    openMask(e) {
      if(this.disable == true)return
      this.maskType = e
    },
    closeMask() {
      this.maskType = false
    },
    openPop(e) {
      if(this.disable)return;
      this.pop_show = true
      this.pop_type = e
    },
    popClosed() {
      this.pop_type = ''
    },
    closePop() {
      this.pop_show = false
    },
    save() {
      if (this.pop_type == 'scale') {
        this.Resume.scale = this.$refs.scale.getValues()[0]
      }
      if (this.pop_type == 'Working_city') {
        console.log(this.$refs.Working_city.getValues())

        this.Resume.address = String(this.$refs.Working_city.getValues().map(item => {
          return item.name
        })).replaceAll(',', '-')
        this.Resume.address_province_id = this.$refs.Working_city.getValues()[0].id
        this.Resume.address_province_name = this.$refs.Working_city.getValues()[0].name
        this.Resume.address_city_id = this.$refs.Working_city.getValues()[1].id
        this.Resume.address_city_name = this.$refs.Working_city.getValues()[1].name
        this.Resume.address_district_id = this.$refs.Working_city.getValues()[2].id
        this.Resume.address_district_name = this.$refs.Working_city.getValues()[2].name
      }
      this.closePop()
    },
    submitMask(e) {
      console.log(e)
      this.maskType = ''
      this.chooseValue = e
      this.Resume.introduce = e
    },
    edit(){
      this.disable = !this.disable
    },

  },
  mounted() {
    if(this.$route.query.item){
      getCompany(this.$store.state.token,this.$route.query.item).then(res=>{
        if(res.data.status==true){
          this.disable = true
          let Resume = res.data.data
          console.log(Resume)
          let list = [
            {name:Resume.province_name,id:Resume.province_id},
            {name:Resume.city_name,id:Resume.city_id},
            {name:Resume.district_name,id:Resume.district_id},
          ]
          console.log()
          this.Resume = {
            name: Resume.business_license_name,//企业证件照全程，
            type: Resume.business_license_name,//企业类型
            type_id: Resume.business_license_name,//企业类型
            license: Resume.business_license_name,//营业执照
            front: Resume.business_license_name,//正面
            contrary: Resume.business_license_name,//反面
            shop_name: Resume.name,//公司名称
            address: String(list.map(item=>{return item.name})),//公司地址
            address_province_id: list[0].name,//省
            address_province_name:list[0].id,//省
            address_city_id: list[1].id,//市
            address_city_name: list[1].name,//市
            address_district_id:list[2].id,//区
            address_district_name: list[2].name,//区
            address_details: Resume.address,//公司地址xx详细地址
            scale: Resume.company_nums,//规模
            logo: Resume.logo_image,//logo
            id:Resume.id,
            introduce:Resume.introduction,
          }
          this.chooseValue = Resume.introduction
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }

}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'公司简介'" class="navigationBar" v-if="Resume.id">
        <template #search >
          <div class="edit" @click="edit" v-if="disable">编辑</div>
          <div class="edit" @click="edit" v-if="!disable">取消</div>
        </template>
      </navigationBar>

      <MaskDiv @closePop="closeMask"
               @submit="submitMask"
               class="popmask"
               v-if="maskType"
               :maskType="maskType"
               :chooseValue="chooseValue"
      >
      </MaskDiv>






      <el-form :model="Resume" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm" >

        <van-cell class="cell selectCell selectCell2" size="large" label="" v-if="Resume.id">
          <template #title>
            <span class="custom-title">公司logo</span>
          </template>
          <template #label>
            <el-form-item label="" prop="logo">
              <div class="" v-if="!disable">
                <el-upload
                    class="logo-uploader logo-uploader2"
                    action
                    :http-request="uploadFile"
                    ref="upload"
                    :limit="1"
                    :file-list="logoFiles"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="fileChange"
                    multiple
                    name="logo"
                >
                  <div class="logoInner" v-if="!Resume.logo">
                    <span class="iconfont icon-xiangji"></span>
                    <div>添加图片</div>
                  </div>
                  <img v-if="Resume.logo" :src="$store.state.imageHost+Resume.logo" alt="" class="image">
                </el-upload>
              </div>
              <div class="" v-else>
                <el-image
                    class="showImg"
                    v-if="$store.state.user.avatar&&Resume.logo"
                    :src="$store.state.imageHost+Resume.logo"
                    :preview-src-list="[$store.state.imageHost+Resume.logo]">
                </el-image>
              </div>
            </el-form-item>
          </template>

          <template #right-icon v-if="!disable">
            <span class="iconfont icon-Frame-4"></span>
          </template>
        </van-cell>


<!--        <van-cell class="cell" size="large" label="">-->
<!--          <template #title>-->
<!--            <span class="custom-title">公司名称</span>-->
<!--          </template>-->
<!--          <template #label>-->
<!--            <el-form-item label="" prop="shop_name">-->
<!--              <input class="input" type="text" placeholder="请输入" v-model="Resume.shop_name"-->
<!--                     :disabled="disable">-->
<!--            </el-form-item>-->
<!--          </template>-->
<!--        </van-cell>-->

<!--        <van-cell class="cell selectCell" size="large" label="" @click="openPop('Working_city')">-->
<!--          <template #title>-->
<!--            <span class="custom-title">公司地址</span>-->
<!--          </template>-->
<!--          <template #label>-->
<!--            <el-form-item label="" prop="address">-->
<!--              <span class="text" :class="Resume.address ? '' : 'placeholder'">{{-->
<!--                  Resume.address ? Resume.address : '请选择'-->
<!--                }}</span>-->
<!--            </el-form-item>-->
<!--            <span class="iconfont icon-Frame-4" v-if="!disable"></span>-->
<!--          </template>-->
<!--        </van-cell>-->


<!--        <van-cell class="cell" size="large" label="">-->
<!--          <template #title>-->
<!--            <span class="custom-title">详细地址</span>-->
<!--          </template>-->
<!--          <template #label>-->
<!--            <el-form-item label="" prop="address_details">-->
<!--              <input class="input" type="text" placeholder="请输入" v-model="Resume.address_details"-->
<!--                     :disabled="disable">-->
<!--            </el-form-item>-->
<!--          </template>-->
<!--        </van-cell>-->

<!--        <van-cell class="cell selectCell" size="large" label="" @click="openPop('scale')">-->
<!--          <template #title>-->
<!--            <span class="custom-title">人员规模</span>-->
<!--          </template>-->
<!--          <template #label>-->
<!--            <el-form-item label="" prop="scale">-->
<!--              <span class="text" :class="Resume.scale ? '' : 'placeholder'">{{-->
<!--                  Resume.scale ? Resume.scale : '请选择公司人员规模'-->
<!--                }}</span>-->
<!--            </el-form-item>-->
<!--            <span class="iconfont icon-Frame-4" v-if="!disable"></span>-->
<!--          </template>-->
<!--        </van-cell>-->


<!--        <van-cell class="cell selectCell" size="large" label="" v-if="!Resume.id">-->
<!--          <template #title>-->
<!--            <span class="custom-title">公司logo</span>-->
<!--          </template>-->
<!--          <template #label>-->
<!--            <el-form-item label="" prop="logo">-->
<!--              <div class="">-->
<!--                <el-upload-->
<!--                    class="logo-uploader"-->
<!--                    action-->
<!--                    :http-request="uploadFile"-->
<!--                    ref="upload"-->
<!--                    :limit="1"-->
<!--                    :file-list="logoFiles"-->
<!--                    :on-remove="handleRemove"-->
<!--                    :on-exceed="handleExceed"-->
<!--                    :before-upload="beforeUpload"-->
<!--                    :show-file-list="false"-->
<!--                    :headers="headers"-->
<!--                    :on-change="fileChange"-->
<!--                    multiple-->
<!--                    name="logo"-->
<!--                >-->
<!--                  <div class="logoInner" v-if="!Resume.logo">-->
<!--                    <span class="iconfont icon-xiangji"></span>-->
<!--                    <div>添加图片</div>-->
<!--                  </div>-->
<!--                  <img v-if="Resume.logo" :src="$store.state.imageHost+Resume.logo" alt="" class="image">-->
<!--                </el-upload>-->
<!--              </div>-->
<!--            </el-form-item>-->
<!--          </template>-->
<!--        </van-cell>-->

        <van-cell class="cell" size="large" label="" @click="openMask('corporation')">
          <template #title>
            <span class="custom-title">公司介绍</span>
          </template>
          <template #label>
            <el-form-item label="" prop="introduce">
              <span class="text" :class="Resume.introduce ? '' : 'placeholder'" v-html="Resume.introduce?Resume.introduce:'请介绍公司的具体情况'"></span>
            </el-form-item>
          </template>
          <template #right-icon>
            <span class="iconfont icon-changyongyu-xiugai" v-if="!disable"></span>
          </template>
        </van-cell>








        <div class="bottomBtn" v-if="Resume.id">
          <div @click="submitForm('ruleForm')" class="oneSubmit" :class="disable?'noneBtn':''">提交审核</div>
        </div>
      </el-form>

      <van-popup v-model="pop_show" position="bottom" @closed="popClosed">
        <div class="pop_inner">
          <van-picker
              v-if="pop_type == 'scale'"
              ref="scale"
              :columns="scaleList"
          />

          <van-picker
              v-if="pop_type == 'Working_city'"
              ref="Working_city"
              value-key="name"
              :columns="address_li"
          />

        </div>
        <div class="pop_bottom">
          <div class="close btn" @click="closePop">取消</div>
          <div class="save btn" @click="save">确定</div>
        </div>
      </van-popup>

    </div>
  </div>
</template>

<style scoped lang="scss">
.Tips{
  margin-top: 20px;
  display: inline-flex;
  flex-direction: column;
  place-content: center;
  place-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ffdce0;
  background: #fff3f4;
  text-align: left;
  margin-left: 16px;
  margin-right: 16px;
  color: #fc223b;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.popmask {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 0;
}

.logoInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #c7c7c7;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 100%;

  span, div {
    height: 24px;
    font-size: 16px;

  }

  .iconfont {
    margin-bottom: 0px !important;
  }
}

.demo-ruleForm {
  padding-bottom: 100px;
}

.bottomBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 0;
  justify-content: center;
  box-shadow: 0px -0.5px 2px #99999926;

  .oneSubmit {
    border-radius: 10px;
    background-color: #fc223b;
    color: #fff;
    text-align: center;
    line-height: 45px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .noneBtn{
    background: #fec7ce;
  }

  .agreementBpox {
    margin: 10px 16px;

    .checkboxText {
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      a {
        color: #F96422;
      }
    }
  }
}

a {
  color: #F96422;
}

input:disabled {
  background-color: #fff;
}

.navigationBar {
  position: relative;

  .edit {
    position: absolute;
    right: 20px;
  }
}

.pop_bottom {
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-around;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #1b1b1b;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .save {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 40px;
    border-radius: 10px;
    background: #05c160;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

}
.showImg {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
}
.popmask, .expect_job {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: #fff;
  left: 0;
  top: 0;
}

.avatar-uploader {
  width: 162px;
  height: 127px;
  border-radius: 10px;
  background: #eeeeee;
  border: none;
  padding-bottom: 6px;

  .imageBox {
    display: flex;
    flex-direction: column;

    .text {
      text-align: center;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 18.2px;
      margin: 6px;
    }
  }

  .innerBox {
    flex-direction: column;
    width: 146px;
    height: 90px;
    border-radius: 10px;
    background: #fff;
    margin: 6px 8px;
    min-height: 90px;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .inn {
      width: 146px;
      height: 90px;
      border-radius: 10px;
      background: #ffffff;
    }

    .iconfont {
      font-size: 24px;
      margin-bottom: 0px;
    }

    .text {
      text-align: center;
      color: #1b1b1b;
      font-weight: 400;
      font-size: 14px;
      line-height: 18.2px;
    }

    .image {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }


}

.logo-uploader {
  width: 97px;
  height: 97px;
  border-radius: 10px;
  border: 1.3px dashed #c7c7c7;
  box-sizing: border-box;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.showImgBox {
  position: relative;

  .iconfont {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.imageShow {
  width: 76px;
  height: 76px;
  border-radius: 50px;
  object-fit: cover;
}

.container {
  .area {
    min-height: 100vh;
  }
}

.van-cell__title {
  text-align: left;
  width: 80%;
}

.van-cell__value {
  width: 10%;
  -webkit-box-flex: initial;
  -webkit-flex: initial;
  flex: initial;
}

.van-cell__label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 10px;
  color: #1b1b1b;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 22px;
}

.van-cell__label {

  .text {
    display: inline-block;
    display: -webkit-box;
    /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
    -webkit-box-orient: vertical;
    /*要显示的行数*/
    -webkit-line-clamp: 2;
    /* 溢出部分隐藏 */
    overflow: hidden;
  }
}

.el-form-item__content {
  font-size: 16px;
  line-height: 22px;
}

.el-form-item {
  margin-bottom: 20px;
}

.cell {
  border-bottom: 1px solid var(--房产描边F6F6F6, #f6f6f6);
  padding: 20px 0 0 0;
  margin: 0 16px;
  width: initial;

  .custom-title {
    color: #1b1b1b;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    .essential {
      color: #fc223b;
    }
  }

  .placeholder {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .input {
    border: none;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
  }

  .input::placeholder {
    color: #c7c7c7;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .iconfont {
    font-size: 24px;
    color: #666666;
    margin-bottom: 20px;
  }
}

.avatar {
  .van-cell__value {
    width: 40%
  }

  .van-cell__title {
    width: 50%;
  }
}

.selectCell {
  .van-cell__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    vertical-align: middle;
  }

  .text {
    vertical-align: middle;
    color: #1b1b1b;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.placeholder {
  color: #c7c7c7 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.bottomBox {
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;

  .btn {
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 12px 13px;
    border-radius: 12px;
    background: #0393f5;
    margin: 15px 16px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}
.selectCell2{
  .logo-uploader2{
    width: 50px;
    height: 50px;

  }
  .van-cell__label{
    justify-content: flex-end;
    margin-top: 0px;
  }
  .el-form-item{
    margin-bottom: 0px;
  }
  .icon-Frame-4{
    display: flex;
    margin-bottom: 0px;
    align-items: center;
  }
}

</style>


<style>
.logo-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
</style>
