<script>
import navigationBar from "@/components/navigation-bar/navigation-bar.vue";
import {getbaijiesp, water_list} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "water_coupon",
  components: {
    navigationBar
  },
  data() {
    return {
      topList: ['品牌/规格',
        // '购买累计','使用累计',
        '水票剩余'],
      topListTow: [
        '品牌/规格',
        '时间',
        '数量',
        '水票剩余',
      ],
      tableTopList: [
        {name: '农夫山泉20L', num: 20},
        {name: '农夫山泉20L', num: 20},
      ],
      tableTowList: [
        {name:'农夫山泉20L',time:'22.12.25-02:07',num:4,type:1,Remaining:20},
        // {name:'农夫山泉20L',time:'22.12.25-02:07',num:4,type:2,Remaining:20},
        // {name:'农夫山泉20L',time:'22.12.25-02:07',num:4,type:1,Remaining:20},
        // {name:'农夫山泉20L',time:'22.12.25-02:07',num:4,type:2,Remaining:20},
        // {name:'农夫山泉20L',time:'22.12.25-02:07',num:4,type:1,Remaining:20},
      ],
    }
  },
  methods:{
    getTime(e){
      var myDate = new Date(e);
      var year = myDate.getFullYear(); //获取当前年
      var mon = myDate.getMonth() + 1; //获取当前月
      var date = myDate.getDate(); //获取当前日
      var hours = myDate.getHours(); //获取当前小时
      var minutes = myDate.getMinutes(); //获取当前分钟
      minutes = minutes < 10 ? '0' + minutes : minutes;
      mon = mon < 10 ? '0' + mon : mon;
      hours = hours < 10 ? '0' + hours : hours;
      return year+'.'+mon+'.'+date+'-'+hours+':'+minutes
    },
    getList(){
      if(this.$store.state.token){
        water_list(this.$store.state.token).then(res=>{
          if(res.data.status==true){
            let tableList = res.data.data
            console.log(res.data.data)
            tableList.reverse()
            tableList.forEach(item=>{
              item.createtime = this.getTime(item.createtime*1000)
            })
            this.tableTowList = tableList
          }else {
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
        // console.log(getbaijiesp(this.$store.state.token))
        getbaijiesp(this.$store.state.token).then(res=>{
          console.log(res)
          if(res.data.status==true){
            this.tableTopList = res.data.data
          }else {
            Toast(res.data.msg)
          }
        }).catch(err=>{
          console.log(err)
        })
      }
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'百街水票'" class="navigationBar"></navigationBar>
      <div class="inner">
        <div class="table clastopTabb">
          <div class="tableTitle">
            <div class="item" v-for="(item,index) in topList" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="table_body">
            <div class="item" v-for="(item,index) in tableTopList" :key="index">
              <div class="left">{{ item.brand }}</div>
              <div class="right" style="color: #398ae9">{{ item.nums-item.sum?item.nums-item.sum:0 }}张</div>
            </div>

          </div>
        </div>
        <div class="table">
          <div class="top">水票使用详情</div>
          <div class="tableTitle">
            <div class="item" v-for="(item,index) in topListTow" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="table_body">
            <div class="item" :class="item.type==1?'addItem':''" v-for="(item,index) in tableTowList" :key="index">
              <div class="every">{{ item.remark }}</div>
              <div class="every">{{ item.createtime }}</div>
              <div class="every">{{item.type==1?'+':'-'}}{{ item.nums }}</div>
              <div class="every">{{ item.num }}张</div>
            </div>
            <div class="item" v-if="tableTowList.length<=0">
              <div class="every">无</div>
              <div class="every">无</div>
              <div class="every">无</div>
              <div class="every">无</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.inner {

  .table {

    margin: 10px 16px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);

    .tableTitle {
      display: flex;
      //justify-content: space-around;
      margin: 0 10px;
      .item {
        //width: 25%;
      }
      .item:nth-child(1) {
        width: 35%;
      }
      .item:nth-child(2) {
        width: 35%;
      }
      .item:nth-child(3) {
        width: 10%;

      }
      .item:nth-child(4) {
        width: 20%;
      }
    }

    .top {
      font-weight: 400;
      font-size: 16px;
      color: #1B1B1B;
      line-height: 24px;
      margin: 10px 0 4px 0;
    }
    .table_body{
      padding-bottom: 6px;
      max-height: 600px;
      overflow: scroll;
      .item{
        display: flex;
        padding: 4px 0;
        margin: 10px 10px;
        .every:nth-child(1) {
          width: 35%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .every:nth-child(2) {
          width: 35%;
        }
        .every:nth-child(3) {
          width: 10%;
        }
        .every:nth-child(4) {
          width: 20%;
        }
      }
      .addItem{
        background: #D3E9FE;
        border-radius: 5px 5px 5px 5px;
      }

      .item:nth-last-of-type(1){
        border-bottom: none;
      }
    }

  }

  .clastopTabb {
    overflow: hidden;

    .tableTitle {
      background-color: #286CFB;
      color: #fff;
      padding: 8px;
      margin: 0 0px;

      .item {
        width: 50%!important;
      }
    }
    .table_body{
      padding: 4px 0;
      .item{
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid rgba(153,153,153,0.15);;
        margin:  0 10px;
        padding: 4px 0;
        .left,.right{
          width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .item:nth-last-of-type(1){
        border-bottom: none;
      }
    }
  }
}
</style>