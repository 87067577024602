<script>
import NavigationBar from "@/components/navigation-bar/navigation-bar.vue";
import Bus from "@/utils/Bus";
import Compressor from "compressorjs";
import {maintenance_order_ship_ok, up_maintenance_user, upload_file, upload_files} from "@/utils/api";
import {Toast} from "vant";

export default {
  name: "service_detail",
  components: {NavigationBar},
  data() {
    return {
      obj: {},
      userChooseWeixiu: '',//快修项目
      maintenance_notes: '',//勘察说明
      kanchaUpload: [],//勘察员上传的图片
      yvyue_maintenance_time: '',//上门时间
      maintenance_price: '',//包干公式
      toolStr: '',//工具
      userInfo: {},//

      maintenance_content1: '',
      weisiugongYanshouFlag: false,
      promitListTwo: [
        '维修内容已全部完成',
        '维修内容工艺细节处理规范标准',
        '维修区域清扫干净整洁',
      ],


      DooorfileList: [],//上门照片 files
      DoorLimit: 3,//上门照片最大数量
      DoorUpload: [],//上门照片
      headers: {"Content-Type": "multipart/form-data"},
      fileType: ["png", "jpg", "jpeg", 'mp4', 'mp3'],


      weixiuUpload: [],
      weixiufileList: [],
      weixiuLimit: 5,
    }
  },
  computed: {
    kanchaUploadsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.kanchaUpload)).forEach(item => {
        list.push(this.$store.state.imageHost + item.tempFilePath)
      })
      return list
    },
    weixiuUploadsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.weixiuUpload)).forEach(item => {
        list.push(this.$store.state.imageHost + item.tempFilePath)
      })
      return list
    },
    DoorUploadsrcList() {
      let list = []
      JSON.parse(JSON.stringify(this.DoorUpload)).forEach(item => {
        list.push(this.$store.state.imageHost + item.tempFilePath)
      })
      return list
    },
  },
  methods: {
    initUserAddress() {
      if (this.obj.order_id) {
        this.userInfo = {
          name: this.obj.ship_name,
          mobile: this.obj.ship_mobile,
          addId: this.obj.addId,
          shipAddress: this.obj.ship_address,
          ys: this.obj.ship_address_yx,
          address: this.obj.ship_address_ds,
        }
      }
    },

    shouye() {
      this.$router.push("/employee_home")
    },
    iphone() {
    },
    shangmendaka() {
      if (this.DoorUpload.length <= 0) {
        Toast('请上传打卡图片')
        return
      }
      if (this.obj.maintenance_user_status == 2) {
        return;
      }
      up_maintenance_user(this.$store.state.token, JSON.stringify(this.DoorUpload), this.obj.order_id).then(res => {
        if (res.data.status == true) {
          this.obj.maintenance_user_status = 2
          Toast('打卡成功')
        } else {
          Toast(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
      })
      console.log(this.DoorUpload)

    },


    yasuo(files) {
      return new Promise(resolve => {
        if (files.type.startsWith('image/')) {
          new Compressor(files, {
            success: (result) => {
              resolve(result)
              // this.uploadImage(result);
            },
            error: (error) => {
              console.log(error.message);
            },
            // 可选的压缩选项
            quality: 0.7, // 图片压缩质量
            maxWidth: 1024, // 图片最大宽度
            maxHeight: 1024, // 图片最大高度
            resize: true, // 是否改变图片大小
          });
        } else if (files.type.startsWith('video/')) {
          // 压缩视频
          // this.compressVideo(file); // 视频压缩和上传通常需要服务器端支持
          resolve(files)

        }


      })
    },
    async beforeUpload(files) {
      let file = await this.yasuo(files)
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        if (files.type.startsWith('image/')) {   //图片
          //计算文件的大小
          const isLt5M = file.size / 1024 / 1024 < 1; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            return false;
          }
        } else if (files.type.startsWith('video/')) {//视频
          const isLt5M = file.size / 1024 / 1024 < 5; //这里做文件大小限制
          //如果大于50M
          if (!isLt5M) {
            this.$message.error("上传视频大小不能超过 5MB!");
            return false;
          }
        }
        //如果文件类型不在允许上传的范围内
        if (this.fileType.includes(FileExt)) {
          return true;
        } else {
          this.$message.error("上传文件格式不正确!");
          return false;
        }
      }
    },
    uploadFile(item) {
      // console.log(JSON.stringify(item))
      this.$message("文件上传中........");
      if (item.file.type.startsWith('image/')) {
        console.log('图片')
        console.log(item.file)
        upload_file(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.weixiuUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'image'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'image'})
            }
            console.log(res.data.data)
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'image'})
          }
        })
      } else if (item.file.type.startsWith('video/')) {
        upload_files(item.file).then(res => {
          if (res.data.status == true) {
            this.$message.close()
            this.$message.success("上传成功");
            if (item.filename == 11) {
              this.kanchaUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            if (item.filename == 22) {
              this.DoorUpload.push({tempFilePath: res.data.data.saveurl, fileType: 'video'})
            }
            console.log(res.data.data)
            // this.userUpload
            // this.userUpload.push({tempFilePath:res.data.data.saveurl,fileType:'video'})
          }
        })
      }
    },
    //上传成功后的回调
    handleSuccess() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.handleClose();
    },
    fileChange2(file, DooorfileList) {
      console.log(file)
      this.DooorfileList = DooorfileList;
    },
    fileChange1(file, kanchafileList) {
      this.weixiufileList = kanchafileList;
    },
    async handleRemove() {
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },


    deleteImg(list, index, fileList) {
      list.splice(index, 1)
      fileList.splice(index, 1)
    },


    init() {
      if (this.obj.maintenance_user_img) {
        this.DoorUpload = JSON.parse(this.obj.maintenance_user_img)
      }
      if (this.obj.items.files_user_maintenance) {
        this.weixiuUpload = JSON.parse(this.obj.items.files_user_maintenance)
      }

      if (this.obj.confirm_time) {
        if (this.obj.confirm == 2) {
          this.weisiugongYanshouFlag = true
        }
      }
      if (this.obj.items.maintenance_status == 3) {
        this.weisiugongYanshouFlag = true
      } else {
        this.weisiugongYanshouFlag = false
      }
      if(this.obj.items!=undefined){
        this.maintenance_content1 = this.obj.items.maintenance_tishi
      }
        if(this.obj.wenti){
          if(this.obj.wenti.length>0){
            this.obj.wenti.forEach(item=>{
              item.mp32 = JSON.parse(item.mp3)
              item.feliss = JSON.parse(item.felis)
              item.felisssrcLi = item.feliss.map(e=>this.$store.state.imageHost+e.tempFilePath)
            })
          }
        }
    },

    wanchengBtn() {
      if (this.obj.maintenance_user_status == 1) {
        Toast('请上门打卡')
        return
      }
      if (this.weisiugongYanshouFlag && this.obj.confirm == 2) {
        return
      }
      if (this.obj.ship_status == 3 && this.obj.confirm == 1) {
        Toast('请等待核验完成')
        return
      }
      if (this.weixiuUpload.length <= 0) {
        Toast('请上传完成图片')
        return
      }


      maintenance_order_ship_ok(this.$store.state.token, this.obj.order_id, JSON.stringify(this.weixiuUpload), this.maintenance_content1 ? this.maintenance_content1 : '').then(res => {
        if (res.data.status) {
          console.log(res.data.data)
          Toast('提交成功')
          let msg = {type: 'u_order_4', user_id: this.obj.user_id}
          this.$store.state.websocket.send(JSON.stringify(msg))
          this.$router.go(-1)
        }
      }).catch(err => {
        console.log(err)
      })


    },
  },
  mounted() {
    this.obj = JSON.parse(this.$route.query.item)
    console.log(this.obj)
    this.userChooseWeixiu = this.obj.items.title
    this.maintenance_notes = this.obj.items.notes
    this.kanchaUpload = JSON.parse(this.obj.items.files_user_surveyor)
    this.yvyue_maintenance_time = this.obj.is_up_dizhi_status == 1 ? this.obj.service_time : this.obj.user_weixin_order_times
    this.maintenance_price = this.obj.items.maintenance_price
    this.toolStr = this.obj.items.tool

    this.initUserAddress()

    this.init()
  },
  beforeDestroy() {
    Bus.$off("sendUserInfo");
  }
}
</script>

<template>
  <div class="container">
    <div class="area">
      <navigationBar :title="'室内快修'" class="navigation"></navigationBar>
      <div class="innerarea">
        <div class="card">
          <div class="line border_bottom">
            <div class="lable">快修项目</div>
            <div class="right" style="color: #1b1b1b">
              {{ userChooseWeixiu }}
            </div>
          </div>
          <div class="line">
            <div class="lable">勘查说明</div>
            <div class="right">
              <input
                  v-model="maintenance_notes"
                  type="text"
                  class="input"
                  placeholder="勘察员现场勘查备注说明"
                  maxlength="50"
                  disabled
              />
            </div>
          </div>
        </div>
        <div class="img_box">
          <div class="img_list">
            <div class="evert li" v-for="(item,index) in kanchaUpload" :key="index+index">
              <el-image
                  class="avatar"
                  fit="cover"
                  :src="$store.state.imageHost+item.tempFilePath"
                  :preview-src-list="kanchaUploadsrcList">
              </el-image>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="line border_bottom">
            <div class="lable">上门时间</div>
            <div class="right" :class="yvyue_maintenance_time?'y':''">
              {{ yvyue_maintenance_time ? yvyue_maintenance_time : '请选择时间' }}
            </div>
          </div>

          <div class="line">
            <div class="lable">包干工时</div>
            <div class="right">
              人工费用，变更另算
              <div class="input_tow_box">
                <input
                    v-model="maintenance_price"
                    type="number"
                    class="input_tow"
                    placeholder=""
                    maxlength="10"
                    oninput="if(value.length>11)value=value.slice(0,11)"
                    disabled
                />元
              </div>
            </div>
          </div>
          <!--      <div class="line">-->
          <!--        <div class="lable">材料清单</div>-->
          <!--        <div class="right">-->
          <!--          <div class="checkGoods" @click="openCai()">查看材料清单</div>-->
          <!--          <div class="input_tow_box">-->
          <!--            <div class="input_tow"></div>-->
          <!--            元-->
          <!--          </div>-->
          <!--        </div>-->
          <!--        <van-popup-->
          <!--          get-container="app"-->
          <!--          v-model="cailiaoshow"-->
          <!--          round-->
          <!--          closeable-->
          <!--          position="bottom"-->
          <!--        >-->
          <!--          <div class="cailiaoBox">-->
          <!--            <div class="cailiaoTitle">材料清单</div>-->
          <!--            <div class="cailiaoBody">-->
          <!--              <div class="ul">-->
          <!--                <div class="li" v-for="(t, i) in 15" :key="i">-->
          <!--                  <img-->
          <!--                    class="leftImg"-->
          <!--                    src="https://img.zcool.cn/community/0121a55d903ad6a801211d53066683.jpg@1280w_1l_2o_100sh.jpg"-->
          <!--                    alt=""-->
          <!--                  />-->
          <!--                  <div class="infoBox">-->
          <!--                    <div class="brandBox">-->
          <!--                      <div class="bradn">品牌</div>-->
          <!--                      美耐特-->
          <!--                    </div>-->
          <!--                    <div class="name">改锥螺丝而分为氛围分为氛围</div>-->
          <!--                    <div class="size">尺寸：30*30*2.0mm*6mm</div>-->
          <!--                    <div class="weight">重量：0.35kg/个</div>-->
          <!--                    <div class="price">-->
          <!--                      <div class="leftPrice">-->
          <!--                        ￥<span class="yuan">8</span>.9-->
          <!--                      </div>-->
          <!--                      <div class="rightNums">x2</div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="bottomBtn">-->
          <!--              <div class="left">-->
          <!--                <div class="top">-->
          <!--                  合计：<span class="price"-->
          <!--                    >￥<span class="yuan">19</span>.60</span-->
          <!--                  >-->
          <!--                </div>-->
          <!--                <div class="bot"></div>-->
          <!--              </div>-->
          <!--              <div class="right">-->
          <!--                <div class="btn">确认清单</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </van-popup>-->
          <!--      </div>-->

          <!--      <div class="line">-->
          <!--        <div class="lable">材料配送费</div>-->
          <!--        <div class="right">-->
          <!--          <div class="input_tow_box">-->
          <!--            <input-->
          <!--              type="number"-->
          <!--              class="input_tow"-->
          <!--              placeholder=""-->
          <!--              maxlength="10"-->
          <!--            />元-->
          <!--          </div>-->
          <!--        </div>-->
          <!--      </div>-->
          <div class="line">
            <div class="lable">工具清单</div>
            <div class="right" :class="toolStr?'y':''">
              <span class="text">{{ toolStr }}</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="line border_bottom">
            <div class="lable">联系人</div>
            <div class="right" :class="userInfo.name?'haveTime':''">
              {{ userInfo.name ? userInfo.name : '请选择联系人' }}
              <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
            </div>

          </div>
          <div class="line">
            <div class="lable">联系电话</div>
            <div class="right" :class="userInfo.mobile?'haveTime':''">
              {{ userInfo.mobile ? userInfo.mobile : '请添加联系电话' }}
              <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
            </div>

          </div>
          <div class="line">
            <div class="lable">快修地址</div>
            <div class="right" :class="userInfo.shipAddress?'haveTime':''">
              {{ userInfo.shipAddress ? userInfo.shipAddress : '请选择地址' }}
              <!-- <input type="text" class="input" placeholder="请您选择勘察时间"> -->
            </div>

          </div>
        </div>
      </div>

      <div class="Botmstep" v-if="obj.wenti">
        <div class="labelBox" v-if="$store.state.user.user_authority==3&&obj.wenti.length>0">
          <div class=" title">
            <div class="leftBox"></div>
            <div class="title">问题</div>
            <div class="rightBox"></div>
          </div>
        </div>
        <div v-if="obj.wenti.length>0">
          <div class="twoStepContent wentimiaoshu" v-for='(item,index) in obj.wenti' :key="index">
            <div class="workContent">
              <span class="text" v-if="item.title">问题:</span>
              <span v-if="item.title" disabled type="text" style="border: none;" class="input">{{ item.title }}
              </span>
            </div>
            <div class="addImage">
              <div class="twoStepImglist">
                <!-- 验收图 -->
                <div class="img_box">
                  <div class="img_list">
                    <div class="evert li" v-for="(item2,index) in item.feliss" :key="index+index">
                      <el-image
                          v-if="item.felisssrcLi"
                          class="avatar"
                          fit="cover"
                          :src="$store.state.imageHost+item2.tempFilePath"
                          :preview-src-list="item.felisssrcLi">
                      </el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>


        <div class="labelBox">
          <div class=" title">
            <div class="leftBox"></div>
            <div class="title">提交验收</div>
            <div class="rightBox"></div>
          </div>
        </div>

        <div class="twoStepContent">
          <div class="workContent">
            <span class="text">温馨提示: </span><input :disabled="weisiugongYanshouFlag&&obj.confirm==2" type="text"
                                                       bindinput="wenxintishi" v-model="maintenance_content1"
                                                       class="input" placeholder="" maxlength="50"/>
          </div>
          <div class="img_box">
            <div class="img_list">
              <div class="evert li" v-for="(item,index) in weixiuUpload" :key="index+index">
                <el-image
                    class="avatar"
                    fit="cover"
                    :src="$store.state.imageHost+item.tempFilePath"
                    :preview-src-list="weixiuUploadsrcList">
                </el-image>
                <van-icon name="clear" class="icon" @click.stop="deleteImg(weixiuUpload,index,weixiufileList)"
                          v-if="obj.confirm!=2&&obj.ship_status ==2"/>
              </div>
              <el-upload
                  v-if="weixiuUpload.length<=4&&obj.confirm!=2&&obj.ship_status ==2"
                  class="avatar-uploader"
                  action
                  :http-request="uploadFile"
                  ref="upload"
                  :limit="weixiuLimit"
                  :on-remove="handleRemove"
                  :file-list="weixiufileList"
                  :on-exceed="handleExceed"
                  :before-upload="beforeUpload"
                  :show-file-list="false"
                  :headers="headers"
                  :on-change="fileChange1"
                  multiple
                  name="11"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>

            </div>
          </div>


          <div class="kanchaDoorBox">
            <div class="doorTitle">
              <div class="leftline"></div>
              <div class="title">上门打卡</div>
              <div class="rightline"></div>
            </div>
            <div class="kanchaDoorBody">
              <div class="twoStepImglist">
                <!-- 勘察员打卡图片 -->
                <div class="img_box">
                  <div class="img_list">
                    <div class="evert li" v-for="(item,index) in DoorUpload" :key="index+index">
                      <el-image
                          class="avatar"
                          fit="cover"
                          :src="$store.state.imageHost+item.tempFilePath"
                          :preview-src-list="DoorUploadsrcList">
                      </el-image>
                      <van-icon name="clear" class="icon" @click.stop="deleteImg(DoorUpload,index,DooorfileList)"
                                v-if="obj.maintenance_user_status == 1"/>
                    </div>
                    <el-upload
                        v-if="DoorUpload.length<=2&&obj.maintenance_user_status == 1"
                        class="avatar-uploader"
                        action
                        :http-request="uploadFile"
                        ref="upload"
                        :limit="DoorLimit"
                        :on-remove="handleRemove"
                        :file-list="DooorfileList"
                        :on-exceed="handleExceed"
                        :before-upload="beforeUpload"
                        :show-file-list="false"
                        :headers="headers"
                        :on-change="fileChange2"
                        multiple
                        name="22"
                    >


                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="twoStepPromit">
            <div class="title">
              验收标准
            </div>
            <div class="promitEvery" v-for="(item,index) in promitListTwo" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottomBox weixiugongBottom">
        <div class="leftBox">
          <div class="every" @click="shouye">
            <img :src="$store.state.imageHost+'/img/Frame@2x.png'" alt="" class="imagea">
            <div class="text">首页</div>
          </div>
          <a href="tel:13213074005">
            <div class="every" @click="iphone">
              <img :src="$store.state.imageHost+'/img/weixiukefu.png'" alt="" class="imagea">
              <div class="text">客服</div>
            </div>
          </a>
        </div>
        <div class="rightBox">
          <div class="btnBox">
            <div class="priceBox leftBtnBox " @click="shangmendaka" v-if="obj.maintenance_user_status == 1"><span
                class=" text">{{ obj.maintenance_user_status == 1 ? '上门打卡' : '打卡成功' }}</span
            ></div>
            <div v-if="obj.maintenance_user_status==2" class="button" @click="wanchengBtn">
              {{ weisiugongYanshouFlag && obj.confirm != 3 ? '已提交' : '提交验收' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.area {
  margin-bottom: 100px;
}

.area::-webkit-scrollbar {
  display: none;
}

.app {
  // background-color: red;
  //padding: 0 16px;
}

.innerarea {
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 16px;

  .card {
    box-shadow: 0px 0px 2px 0px rgba(102, 102, 102, 0.25);
    width: 95%;
    margin: 10px auto;
    border-radius: 10px;
    padding: 0 10px;
    background-color: #FFFFFF;
  }

  .line {
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    margin-left: 8px;
    margin-right: 8px;
    padding: 10px 0;

    .checkGoods {
      background: #FC223B;
      color: #fff;
      font-size: 14px;
      padding: 4px 12px;
      border-radius: 8px;
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #c7c7c7;
      font-size: 14px;
      width: 75%;
      text-align: right;
    }

    .y {
      color: #1b1b1b;
    }

    .lable {
      color: #666666;
      font-size: 14px;
      line-height: 24px;
    }

    .input {
      border: none;
      background: transparent;
      text-align: right;
      color: #1b1b1b;
      width: 100%;
    }

    .input_tow_box {
      border: 1px solid #FC223B;
      border-radius: 8px 8px 8px 8px;
      color: #FC223B;
      font-size: 14px;
      width: 72px;
      display: flex;
      padding: 4px 6px;
      margin-left: 10px;

      .input_tow {
        border: none;
        background: transparent;
        text-align: right;
        max-width: 80%;
        text-align: center;
        min-width: 80%;
      }
    }

    .input::placeholder {
      color: #c7c7c7;
    }
  }

  .border_bottom {
    border-bottom: 1px solid rgba(153, 153, 153, 0.15);
  }


  .img_box {
    margin-top: 10px;

    .title {
      text-align: left;
      font-size: 12px;
      color: #999999;
    }

    .img_list {
      margin-top: 4px;
      display: flex;

      .li {
        width: 65px;
        height: 65px;
        margin-right: 5px;
        position: relative;
        border-radius: 10px;

        .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }

        .icon {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(20%, -50%);
          font-size: 20px;
        }
      }

      .addImg {
        border: 1px dashed #999999;
        border-radius: 10px;
        position: relative;

      }

      .addImg:after,
      .addImg:before {
        position: absolute;
        content: '';
        display: block;
        width: 30%;
        height: 2px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background-color: #a6a6a6;
      }

      .addImg:before {
        width: 2px;
        height: 30%;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        background-color: #a6a6a6;
      }
    }

  }

  .haveTime {
    color: #1B1B1B !important;

    .iconf {
      color: #C7C7C7;
    }
  }

}

.Botmstep {
  margin-top: 20px;

  .labelBox {
    width: 100%;
    padding: 10px 0;
    background-color: white;

    .title {
      justify-content: center !important;
      align-items: center;
      font-size: 15px;
      font-weight: 800;
      display: flex;

      .rightBox, .leftBox {
        width: 75px;
        height: 2px;
        background-color: #DCDCDC;
        margin: 0 10px;
      }

    }

  }
}

.twoStepContent {

  .workContent {
    flex: 1;
    font-size: 15px;
    line-height: 30px;
    display: flex;
    vertical-align: middle;
    padding-top: 15px;
    margin: 10px 16px 0 16px;

    .text {
      width: 16%;
      font-size: 12px;
      color: #a6a6a6;
    }

    .input {
      display: block;
      font-size: 12px;
      line-height: 30px;
      border: 1px solid rgba(102, 102, 102, .15);
      margin-left: 10px;
      border-radius: 4px;
      width: 75%;
      padding-left: 10px;
      padding-right: 10px;
      overflow: scroll;
      white-space: pre-wrap;
      text-align: left;
    }
  }

  .kanchaDoorBox {
    margin-top: 10px;
  }

  .doorTitle {
    display: flex;
    justify-content: center;
    align-items: center;

    .rightline, .leftline {
      width: 90px;
      height: 1px;
      background-color: #666666;
      margin: 0 10px;
    }
  }


  .twoStepPromit {
    margin: 20px 16px;
    padding: 10px;
    box-shadow: 0px 0px 4px 0px rgba(102, 102, 102, 0.25);
    text-align: left;
    border-radius: 10px;

    .title {
      font-size: 12px;
      color: #1b1b1b;
      margin-left: 10px;
    }

    .promitEvery {
      font-size: 12px;
      color: #666666;
      margin-left: 10px;
      position: relative;
    }

    .promitEvery::before {
      content: '';
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      left: -3%;
      top: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #398ae9;
    }
  }
}

.img_box {
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 16px;

  .title {
    text-align: left;
    font-size: 12px;
    color: #999999;
  }

  .img_list {
    margin-top: 10px;
    display: flex;

    .li {
      width: 65px;
      height: 65px;
      margin-right: 5px;
      position: relative;
      border-radius: 10px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .icon {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -50%);
        font-size: 20px;
      }
    }

    .addImg {
      border: 1px dashed #999999;
      border-radius: 10px;
      position: relative;

    }

    .addImg:after,
    .addImg:before {
      position: absolute;
      content: '';
      display: block;
      width: 30%;
      height: 2px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }

    .addImg:before {
      width: 2px;
      height: 30%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-color: #a6a6a6;
    }
  }

}

.bottomBox {
  position: fixed;
  width: 95%;
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: white;
  border-top: 1px solid #ccc;
  z-index: 99;
  bottom: 0;
  left: 0;

  .leftBox {
    display: flex;
    text-align: center;
    width: 45%;

    .every {
      margin: 5px 7px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .imagea {
        width: 19px;
        height: 19px;
        object-fit: cover;
      }

      .text {
        font-size: 14px;
        color: #666666;
      }
    }

  }

  .rightBox {
    width: 55%;
  }

  .btnBox {
    text-align: right;

    .button {
      display: inline-block;

      width: 50%;
      text-align: center;
      background: #398ae9;
      border-radius: 25px;
      color: white;
      font-size: 14px;
      padding: 10px 0;
      margin-left: 10px;
    }
  }

  .priceBox {
    display: inline-block;
    width: 50%;
    padding: 10px 0;
    box-shadow: inset 0px 0px 4px 0px #5199E1;
    border-radius: 25px;
    text-align: center;
    font-size: 14px;
    color: #398ae9;
  }
}

</style>