!
<template>
  <div class="container">
    <div class="area">
      <div class="navigation">
        <div class="title">消息</div>
        <div class="clear">
          <van-icon name="filter-o"/>
          清除未读
        </div>
      </div>
      <div class="list">
        <div class="li" v-for="(item,index) in 25" :key="index">
          <div class="leftImg">
            <img class="avatar" src="https://picture.bjbjkj.top/tp2024062803111511572202305.png" alt="">
          </div>
          <div class="rightBig">
            <div class="Rittop">
              <div class="rttopTitle">名称</div>
              <div class="rttopTime">12：12</div>
            </div>
            <div class="Rittop">
              <div class="rttopTitle message">
                xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息xx消息
              </div>
            </div>
          </div>
        </div>
      </div>
      <tabbar :active="3"></tabbar>
    </div>


  </div>
</template>

<script>
import tabbar from '@/components/common/tabbar/home_tabbar.vue'
import navigationBar from '../../navigation-bar/navigation-bar.vue';

export default {
  components: {
    tabbar,
    // eslint-disable-next-line vue/no-unused-components
    navigationBar
  },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
  },
  mounted() {
  }
};
</script>
<style lang="scss" scoped>
.navigation {
  display: flex;
  width: 100%;
  padding: 20px 16px;
  align-items: baseline;

  .title {
    line-height: 16px;
    font-size: 20px;

  }

  .van-icon {
    font-size: 12px;
  }

  .clear {
    font-size: 14px;
    margin-left: 5px;
  }

}

.list {
  height: 85vh;
  overflow-y: scroll;
  padding: 0 16px;

  .li {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    .leftImg {
      display: flex;

      .avatar {
        width: 54px;
        height: 54px;
        border-radius: 10px;
      }
    }

    .rightBig {
      flex: 1;
      margin-left: 10px;
      //padding-right: 10px;
      border-bottom: 1px solid #99999926;
      //border-bottom: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //padding-bottom: 5px;
      width:80%;
      .Rittop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .rttopTitle {
          max-width: 55%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #1b1b1b;
        }

        .rttopTime {
          max-width: 40%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          color: #999999;
        }

        .message {
          font-size: 14px;
          color: #999999;
          max-width: 80%;
          min-height: 20px;
        }
      }

      .Rittop:nth-last-of-type(1) {
        margin-top: 2px;
      }

      padding-bottom: 12px;
    }
  }
}

.list::-webkit-scrollbar {
  display: none;
}
</style>